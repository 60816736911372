import { Box, HStack, Heading } from "@chakra-ui/react";
import { ReactNode } from "react";

export default function DashboardBloc({
  extra,
  children,
  title,
}: {
  extra?: ReactNode;
  children: ReactNode;
  title: string;
}) {
  return (
    <Box
      bg={"lightGrey"}
      // bgGradient={"linear(to-r, #F5F5FD, #F4F4F4)"}
      p="6"
      rounded="1rem"
      my="6"
    >
      <HStack justify={"space-between"} align="center" w="full" mb="2">
        <Heading size={"md"} color="blue-light">
          {title}
        </Heading>
        {extra}
      </HStack>
      {children}
    </Box>
  );
}
