
export default function InfoIcon({ color = "#292D32" }: { color?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21.5"
      height="21.5"
      viewBox="0 0 21.5 21.5"
    >
      <g
        id="Group_1370"
        data-name="Group 1370"
        transform="translate(740 -1802)"
      >
        <g id="close-square" transform="translate(-741.25 1800.75)">
          <path
            id="Path_289"
            data-name="Path 289"
            d="M9.013,14.085a.587.587,0,0,1-.419-.174.6.6,0,0,1,0-.839l4.478-4.478a.593.593,0,1,1,.839.839L9.433,13.911A.574.574,0,0,1,9.013,14.085Z"
            transform="translate(28.167 14.044) rotate(135)"
            fill={color}
          />
          <path
            id="Path_291"
            data-name="Path 291"
            d="M15,22.75H9c-5.43,0-7.75-2.32-7.75-7.75V9C1.25,3.57,3.57,1.25,9,1.25h6c5.43,0,7.75,2.32,7.75,7.75v6C22.75,20.43,20.43,22.75,15,22.75Zm-6-20C4.39,2.75,2.75,4.39,2.75,9v6c0,4.61,1.64,6.25,6.25,6.25h6c4.61,0,6.25-1.64,6.25-6.25V9c0-4.61-1.64-6.25-6.25-6.25Z"
            fill={color}
          />
        </g>
        <circle
          id="Ellipse_9"
          data-name="Ellipse 9"
          cx="1"
          cy="1"
          r="1"
          transform="translate(-730 1808.5)"
          fill={color}
        />
      </g>
    </svg>
  );
}
