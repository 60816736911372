import axiosInstance from "../../utils/axiosInstance";

export const createNewAttempt = async ({qcmId, type}: {qcmId: string, type: string}) => {
  let token = localStorage.getItem("access_token");
  return await axiosInstance({
    method: "post",
    url: `/attempts/new-attempt/${qcmId}/${type}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res: any) => res.data);
};

export const createNewExamAttempt = async ({qcmId, type, isSerieMode}: {qcmId: string, type: string, isSerieMode?: string}) => {
  let token = localStorage.getItem("access_token");
  return await axiosInstance({
    method: "post",
    url: `/attempts/new-attempt/${qcmId}/${type}`,
    data: {
      isSerieMode
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res: any) => res.data);
};

export const getOneAttempt = async (attemptId: string, retry?: any) => {
  let token = localStorage.getItem("access_token");
  return await axiosInstance({
    method: "get",
    url: `/attempts/single/${attemptId}${retry ? `?retry=true` : ''}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res: any) => res.data);
};

// submit answer
export const submitAnswer = async (data: {
  attempt: string;
  question: string;
  selected_options: string[];
  last_question: number;
  retry?: any
}) => {
  let token = localStorage.getItem("access_token");
  return await axiosInstance({
    method: "post",
    url: `/attempts-answers/submit-answer${data.retry ? "?retry=true" : ""}`,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res: any) => res.data);
};

export const submitManyAnswers = async (data: Array<{
  attempt: string;
  question: string;
  selected_options: string[];
  last_question: number;
  retry?: any
}>) => {
  let token = localStorage.getItem("access_token");
  return await axiosInstance({
    method: "post",
    url: `/attempts-answers/submit-answer/many`,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res: any) => res.data);
};


export const getAttemptResult = async ({attemptId, type}: {attemptId: string, type: string}) => {
  let token = localStorage.getItem("access_token");
  return await axiosInstance({
    method: "get",
    url: `/attempts/result/${attemptId}?type=${type}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res: any) => res.data);
};

export const checkQcmAttempt = async (qcmId: string) => {
  let token = localStorage.getItem("access_token");
  return await axiosInstance({
    method: "get",
    url: `/attempts/single-by-qcm/${qcmId}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res: any) => res.data);
};


export const resumeAttempt = async (attemptId: string) => {
  let token = localStorage.getItem("access_token");
  return await axiosInstance({
    method: "get",
    url: `/attempts/${attemptId}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res: any) => res.data);
};


export const saveAttemptProgress = async ({attemptId, duration}:{attemptId: string, duration: number}) => {
  let token = localStorage.getItem("access_token");
  return await axiosInstance({
    method: "put",
    url: `/attempts/save-progress/${attemptId}`,
    data: {
      duration
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res: any) => res.data);
};

export const getExams = async () => {
  let token = localStorage.getItem("access_token");
  return await axiosInstance({
    method: "get",
    url: `/qcm/exams-by-year/all`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res: any) => res.data);
};

export const getExamByUniversity = async ({universityName, year}:{universityName: string, year: string}) => {
  let token = localStorage.getItem("access_token");
  return await axiosInstance({
    method: "get",
    url: `/qcm/exams-by-university/${year}/${universityName}/all`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res: any) => res.data);
};

export const getResultsByDay = async ({universityName, year}:{universityName: string, year: string}) => {
  let token = localStorage.getItem("access_token");
  return await axiosInstance({
    method: "get",
    url: `/attempts/results-by-year-university/${year}/${universityName}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res: any) => res.data);
};
export const getQuestionssCounts = async ({universityName, year}:{universityName: string, year: string}) => {
  let token = localStorage.getItem("access_token");
  return await axiosInstance({
    method: "get",
    url: `/attempts/results-by-year-university-count/${year}/${universityName}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res: any) => res.data);
};


export const getUpcomingExams = async () => {
  let token = localStorage.getItem("access_token");
  return await axiosInstance({
    method: "get",
    url: `/qcm/upcoming-exams/count`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res: any) => res.data);
};

export const getHistoric = async (qcmId: string) => {
  let token = localStorage.getItem("access_token");
  return await axiosInstance({
    method: "get",
    url: `/attempts/historic/${qcmId}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res: any) => res.data);
};

export const getExamResult = async (attemptId: string) => {
  let token = localStorage.getItem("access_token");
  return await axiosInstance({
    method: "get",
    url: `/attempts/examen/stats/${attemptId}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res: any) => res.data);
};

export const getExamsStats = async () => {
  let token = localStorage.getItem("access_token");
  return await axiosInstance({
    method: "get",
    url: `/attempts/exam/results-by-university-year`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res: any) => res.data);
};

export const getQcmRecentAttempts = async (qcmId: string) => {
  let token = localStorage.getItem("access_token");
  return await axiosInstance({
    method: "get",
    url: `/attempts/recent-attempts/${qcmId}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res: any) => res.data);
};