export default function SquareYellowIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18.72"
      height="18.72"
      viewBox="0 0 18.72 18.72"
      style={{position: "absolute", bottom: "-25px", right:0}}
    >
      <g
        id="Rectangle_6"
        data-name="Rectangle 6"
        transform="matrix(0.899, 0.438, -0.438, 0.899, 6.137, 0)"
        fill="none"
        stroke="#f7d372"
        strokeLinejoin="round"
        strokeWidth="3"
      >
        <rect width="14" height="14" rx="2" stroke="none" />
        <rect x="1.5" y="1.5" width="11" height="11" rx="0.5" fill="none" />
      </g>
    </svg>
  );
}
