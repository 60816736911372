import {
  Box,
  Grid,
  GridItem,
  Heading,
  HStack,
  Skeleton,
  Stack,
  Tag,
  Text,
  useBreakpoint,
  useBreakpointValue,
} from "@chakra-ui/react";
import moment from "moment";
import React from "react";
import Marquee from "react-fast-marquee";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { SwiperSlide } from "swiper/react";
import SeriesIcon from "../../assets/icons/SeriesIcon";
import TestIcon from "../../assets/icons/TestIcon";
import { getRecentSeriesUpdates } from "../../services/series/services";
import SliderContainer from "../Slider/SliderContainer";
import DashboardBloc from "./DashboardBloc";

export default function SeriesUpdatesSection() {
  const { data: seriesUpdate, isLoading: loadingUpdates } = useQuery(
    "overview-updates",
    getRecentSeriesUpdates,
    {
      staleTime: 60000 * 15
    }
  );

  return (
    <SliderContainer title="Mise à jour">
      {loadingUpdates
        ? Array.apply(null, Array(4)).map((el: any, index: number) => (
          <SwiperSlide key={index}>
              <Skeleton
                rounded={"0.7rem"}
                w="full"
                h="103px"
                endColor={"white"}
                startColor={"whiteAlpha.600"}
              />
            </SwiperSlide>
          ))
        : seriesUpdate.map((serie: any) => (
            <SwiperSlide key={serie._id}>
              <Link
                to={`/series/${serie.speciality.name}/${serie.subject.name}/${serie._id}`}
              >
                <UpdatesItem serie={serie} />
              </Link>
            </SwiperSlide>
          ))}
    </SliderContainer>
  );
}

const UpdatesItem = ({ serie }: any) => {
  const breakpointsValues = useBreakpointValue({
    base: true,
    md: true,
    lg: true,
    xl: true,
    "2xl": false,
  });
  const bkPoints = useBreakpoint();
  const shortnameBreakpoints = useBreakpointValue({
    base: serie.subject.shortname ?? serie.subject.name,
    xl: serie.subject.name,
  });

  return (
    <HStack bg="whiteAlpha.700" rounded={"0.7rem"} p="4" position={"relative"}>
      {/* //TODO: update speciality when add new question */}
      {/* {moment(serie.createdAt).format() === moment(serie.updatedAt).format() && (
        <Tag
          rounded={"full"}
          color="white"
          bg="blue-light"
          size={"sm"}
          position="absolute"
          right={"10px"}
          top="10px"
        >
          NEW!
        </Tag>
      )} */}
      <Box p={{ base: "0", md: "2" }} py={{ base: "0", md: "4" }}>
        <TestIcon color="#F7D372" size="lg" />
      </Box>
      <Stack flex={1} spacing={0} align="flex-start" overflow={"hidden"}>
        <HStack spacing={2}>
          {/* <Text
            isTruncated
            color="gray.700"
            maxW={{ base: "9rem", md: "9rem", "2xl": "unset" }}
          >
            {serie.speciality.name}
          </Text>
          <Text>{">"}</Text> */}
          {(bkPoints === "xl" || bkPoints === "2xl" || !serie.subject?.shortname) && (
            <Marquee
              style={{ marginRight: "-30px" }}
              play={serie.subject.name.length >= 26 && breakpointsValues}
              gradient={false}
              pauseOnHover={true}
            >
              <Text color="gray.700">{serie.subject.name}</Text>
            </Marquee>
          )}
          <Text color="gray.700" display={{ base: !serie.subject?.shortname ? "none" : "inline", xl: "none" }}>
            {shortnameBreakpoints}
          </Text>
          {/* <Text
            isTruncated
            color="gray.700"
            // maxW={{ base: "9rem", md: "9rem", "2xl": "unset" }}
          >
            {serie.subject.name}
          </Text> */}
        </HStack>
        <HStack spacing={1}>
          <Text color="gray.600" fontWeight={"light"} fontSize="sm">
            {serie.university.name}
          </Text>
          <Text color="gray.400" fontSize={"sm"}>•</Text>
          <Text color="gray.400" fontWeight={"light"} fontSize="sm">
            {serie.year}
          </Text>
        </HStack>
      </Stack>
    </HStack>
  );
};
