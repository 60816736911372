import {
  LinkBox,
  Stack,
  VStack,
  Icon,
  HStack,
  Box,
  IconButton,
  useColorModeValue,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { useRef, useState } from "react";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import { useHistory, useParams } from "react-router-dom";
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import AnswersIcon from "../../assets/icons/AnswersIcon";
import TestIcon from "../../assets/icons/TestIcon";
import LockCircle from "../../assets/icons/LockCircle";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { ProgressBar } from "../FolderBox/FolderBox";
import { motion } from "framer-motion";
import { useAuthProvider } from "../../auth-provider/AuthProvider";
import { useModal } from "../../modal-provider/ModalProvider";

export default function SeriesSlider({
  data,
  filters,
  url,
  statsPerQCMId,
  statsFormat,
}: any) {
  const [progress, setProgress] = useState(0);
  const { data: auth } = useAuthProvider();
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const bgBox = useColorModeValue("lightGrey", "card-dark");
  const bgStack = useColorModeValue("gray.800", "white");
  const history = useHistory();
  const params = useParams<{ specialityName: string }>();
  const { onOpen } = useModal();

  return (
    <Swiper
      slidesPerView={1.25}
      spaceBetween={20}
      pagination={{
        clickable: false,
        type: "custom",
      }}
      modules={[Navigation]}
      onBeforeInit={(swiper: any) => {
        swiper.params.navigation.prevEl = prevRef.current
          ? prevRef.current
          : undefined;
        swiper.params.navigation.nextEl = nextRef.current
          ? nextRef.current
          : undefined;
      }}
      breakpoints={{
        390: {
          slidesPerView: 1.25,
          spaceBetween: 20,
        },
        425: {
          slidesPerView: 1.5,
          spaceBetween: 20,
        },
        600: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
        768: {
          slidesPerView: 2.75,
          spaceBetween: 10,
        },
        1024: {
          slidesPerView: 3.5,
          spaceBetween: 20,
        },
        1200: {
          slidesPerView: 4,
          spaceBetween: 20,
        },
        1600: {
          slidesPerView: 4,
          spaceBetween: 30,
        },
        // 320: {
        //   slidesPerView: 1,
        //   spaceBetween: 30,
        // },
        // 480: {
        //   slidesPerView: 2,
        //   spaceBetween: 30,
        // },
        // 1024: {
        //   slidesPerView: 3,
        //   spaceBetween: 30,
        // },
        // 1200: {
        //   slidesPerView: 4,
        //   spaceBetween: 30,
        // },
        // 1400: {
        //   slidesPerView: 4,
        //   spaceBetween: 30,
        // },
      }}
      style={{ position: "relative" }}
      grabCursor={true}
      onSlideChange={(swipe: any) => setProgress(swipe.progress)}
      className="swiper-container-example"
    >
      {data.map((serie: any) => (
        <SwiperSlide key={serie._id}>
          <LinkBox
            as="article"
            w="full"
            maxW={"400px"}
            bg={bgBox}
            borderWidth="1px"
            borderColor={"transparent"}
            rounded=".8rem"
            transition={"all 0.15s ease-in-out"}
            _hover={{
              borderColor: "darkSky",
              boxShadow: "xs",
              cursor: "pointer",
              bg: "darkGrey",
              // "& .xxx": { bg: "transparent" },
            }}
            overflow="hidden"
            onClick={() => {
              if (
                (auth?.days_left && auth?.days_left > 0) ||
                params?.specialityName === "Cardiologie-CCV"
              ) {
                history.push(`${url}/${serie._id}`);
              } else {
                onOpen();
              }
            }}
          >
            <Stack
              color={bgStack}
              h="full"
              justify={"space-between"}
              align="center"
              position="relative"
            >
              {((!auth?.current_order &&
                params?.specialityName !== "Cardiologie-CCV") ||
                (auth?.current_order &&
                  auth?.days_left <= 0 &&
                  params?.specialityName !== "Cardiologie-CCV")) && (
                    <Box position="absolute" top="2" right="2">
                      <Icon as={LockCircle} />
                    </Box>
                  )}
              <VStack align={"center"} p="5">
                <Icon as={TestIcon} />
                {filters.groupBy !== "Faculté" && (
                  <Text fontWeight={"bold"}>{serie.university.name}</Text>
                )}
                <Text fontSize="lg">{serie.year}</Text>
              </VStack>
              {statsFormat === "progress" ? (
                <VStack
                  align={"stretch"}
                  bg="darkGrey"
                  p="5"
                  className="xxx"
                  transition={"all 0.3s"}
                  w="full"
                >
                  <HStack>
                    <AnswersIcon />
                    <Text fontSize="sm">
                      {statsPerQCMId(serie._id)?.answersCount ?? 0} /{" "}
                      {serie?.questionCount.toLocaleString().replace(",", " ")}
                    </Text>
                  </HStack>
                  <HStack
                    spacing={0}
                    bg="white"
                    rounded={"md"}
                    overflow="hidden"
                    width={"100%"}
                  >
                    <Tooltip
                      label="Bonne réponse"
                      hasArrow
                      openDelay={2000}
                      rounded={"md"}
                    >
                      <motion.div
                        initial={{ width: "0px" }}
                        style={{ height: "8px", background: "#7FCACF" }}
                        animate={{
                          width:
                            (((statsPerQCMId(serie._id)?.answersCount ?? 0) /
                              serie?.questionCount) *
                              100 || 0) + "%",
                        }}
                        exit={{ width: "0px" }}
                        transition={{ default: { duration: 0.75 } }}
                      />
                    </Tooltip>
                  </HStack>
                </VStack>
              ) : (
                <Stack w="full">
                  <ProgressBar
                    values={{
                      correct: statsPerQCMId(serie._id)?.correct,
                      fausse: statsPerQCMId(serie._id)?.fausse,
                      partiel: statsPerQCMId(serie._id)?.partiel,
                      overall: statsPerQCMId(serie._id)?.overall,
                    }}
                  />
                </Stack>
              )}
            </Stack>
          </LinkBox>
        </SwiperSlide>
      ))}
      <IconButton
        className="my-arrow"
        zIndex={2}
        aria-label="next-btn"
        icon={<AiOutlineLeft />}
        ref={prevRef}
        fontSize={"xl"}
        bg="whiteAlpha.600"
        h="full"
        _hover={{ bg: "whiteAlpha.800" }}
        _active={{ bg: "whiteAlpha.800" }}
        _focus={{ outline: "none" }}
        size={"sm"}
        position="absolute"
        top="50%"
        left={0}
        transform={"translateY(-50%)"}
        visibility={progress > 0 ? "visible" : "hidden"}
      />
      <IconButton
        className="my-arrow"
        zIndex={2}
        aria-label="next-btn"
        icon={<AiOutlineRight />}
        ref={nextRef}
        fontSize={"xl"}
        bg="whiteAlpha.600"
        h="full"
        _hover={{ bg: "whiteAlpha.800" }}
        _active={{ bg: "whiteAlpha.800" }}
        _focus={{ outline: "none" }}
        size={"sm"}
        position="absolute"
        top="50%"
        right={0}
        transform={"translateY(-50%)"}
        visibility={progress !== 1 ? "visible" : "hidden"}
      />
    </Swiper>
  );
}
