export default function AccountIcon() {
    return (
        <svg id="user-edit" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
  <rect id="Rectangle_1069" data-name="Rectangle 1069" width="32" height="32" rx="5" fill="#4b62f0" opacity="0"/>
  <g id="Group_1225" data-name="Group 1225" transform="translate(4.792 3.1)">
    <path id="Path_236" data-name="Path 236" d="M12.525,14.925a6.9,6.9,0,1,1,6.9-6.9A6.909,6.909,0,0,1,12.525,14.925Zm0-12a5.1,5.1,0,1,0,5.1,5.1A5.112,5.112,0,0,0,12.525,2.925Z" transform="translate(-1.317 -1.125)" fill="#4b62f0"/>
    <path id="Path_237" data-name="Path 237" d="M14.657,22.974a1.687,1.687,0,0,1-1.2-.48A1.707,1.707,0,0,1,13,21.018l.228-1.62a2.423,2.423,0,0,1,.612-1.224l4.248-4.248a2.53,2.53,0,0,1,3.936,0,2.822,2.822,0,0,1,.948,1.968,2.75,2.75,0,0,1-.948,1.956L17.777,22.1a2.377,2.377,0,0,1-1.224.612l-1.62.228a1.089,1.089,0,0,1-.276.036Zm5.388-8.2c-.216,0-.408.144-.684.42l-4.248,4.248a.456.456,0,0,0-.1.2l-.216,1.5,1.5-.216a.667.667,0,0,0,.2-.108l4.248-4.248a1.246,1.246,0,0,0,.42-.684,1.136,1.136,0,0,0-.42-.7c-.288-.288-.5-.42-.708-.42Z" transform="translate(1.135 2.826)" fill="#7fcacf"/>
    <path id="Path_238" data-name="Path 238" d="M19.718,18.413a.774.774,0,0,1-.24-.036,4.772,4.772,0,0,1-3.288-3.288.9.9,0,0,1,1.728-.48,2.959,2.959,0,0,0,2.04,2.04.9.9,0,0,1-.24,1.764Z" transform="translate(2.194 3.151)" fill="#7fcacf"/>
    <path id="Path_239" data-name="Path 239" d="M3.294,23.025a.907.907,0,0,1-.9-.9c0-5.124,5.028-9.3,11.208-9.3a13.46,13.46,0,0,1,3.816.552.9.9,0,1,1-.516,1.716,11.613,11.613,0,0,0-3.3-.48c-5.184,0-9.408,3.36-9.408,7.5A.909.909,0,0,1,3.294,23.025Z" transform="translate(-2.394 2.775)" fill="#4b62f0"/>
  </g>
</svg>

    )
}
