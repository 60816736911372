import { useBreakpointValue } from "@chakra-ui/react";

export default function DayTwoIcon({color="#7fcacf", size="lg"}:{color?:string, size?:string}) {
  const lgIconSize = useBreakpointValue({base: "40", "2xl": "48"})
  const sizePx = size === "lg" ? lgIconSize : "20";
  return (
    <svg
      id="Group_1327"
      data-name="Group 1327"
      xmlns="http://www.w3.org/2000/svg"
      width={sizePx}
      height={sizePx}
      viewBox="0 0 35.1 38.7"
    >
      <path
        id="Path_274"
        data-name="Path 274"
        d="M7.875,9.225a1.36,1.36,0,0,1-1.35-1.35v-5.4a1.35,1.35,0,1,1,2.7,0v5.4A1.36,1.36,0,0,1,7.875,9.225Z"
        transform="translate(2.475 -1.125)"
        fill={color}
      />
      <path
        id="Path_275"
        data-name="Path 275"
        d="M15.075,9.225a1.36,1.36,0,0,1-1.35-1.35v-5.4a1.35,1.35,0,0,1,2.7,0v5.4A1.36,1.36,0,0,1,15.075,9.225Z"
        transform="translate(9.675 -1.125)"
        fill={color}
      />
      <path
        id="Path_276"
        data-name="Path 276"
        d="M24.975,38.475h-10.8c-10.116,0-12.15-4.77-12.15-12.474V14.895c0-8.532,2.88-12.006,10.278-12.42H26.847c7.4.414,10.278,3.888,10.278,12.42V26C37.125,33.705,35.091,38.475,24.975,38.475Zm-12.6-33.3c-5.04.288-7.65,1.872-7.65,9.72V26c0,6.894,1.314,9.774,9.45,9.774h10.8c8.136,0,9.45-2.88,9.45-9.774V14.895c0-7.83-2.592-9.432-7.686-9.72Z"
        transform="translate(-2.025 0.225)"
        fill={color}
      />
      <path
        id="Path_277"
        data-name="Path 277"
        d="M35.1,17.865H3.6a1.35,1.35,0,1,1,0-2.7H35.1a1.35,1.35,0,1,1,0,2.7Z"
        transform="translate(-1.8 12.915)"
        fill={color}
      />
      <path
        id="Path_286"
        data-name="Path 286"
        d="M7.56,4.048q.96,0,.96.816,0,.848-.96.848H1.48a.87.87,0,0,1-.656-.248.851.851,0,0,1-.24-.616,1.218,1.218,0,0,1,.368-.864L4.6.064a6.316,6.316,0,0,0,.984-1.352A2.691,2.691,0,0,0,5.9-2.512a1.492,1.492,0,0,0-.432-1.152,1.748,1.748,0,0,0-1.232-.4,3.376,3.376,0,0,0-1.272.248,6.363,6.363,0,0,0-1.24.68,1.967,1.967,0,0,1-.32.184.774.774,0,0,1-.32.072.587.587,0,0,1-.472-.256.958.958,0,0,1-.2-.608A.92.92,0,0,1,.5-4.16,1.069,1.069,0,0,1,.776-4.5a5.545,5.545,0,0,1,1.648-.888,5.721,5.721,0,0,1,1.888-.328,3.942,3.942,0,0,1,2.632.832,2.782,2.782,0,0,1,.984,2.24A4.136,4.136,0,0,1,7.512-.808,8.119,8.119,0,0,1,6.1,1.1L3.336,4.048Z"
        transform="translate(13.086 17.638)"
        fill={color}
      />
    </svg>
  );
}
