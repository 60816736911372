import {
  Box,
  Button,
  Grid,
  GridItem,
  Heading,
  HStack,
  Image,
  List,
  ListIcon,
  ListItem,
  Skeleton,
  Stack,
  Text,
  Tooltip,
  UnorderedList,
  useBreakpointValue,
  useColorModeValue,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useMutation, useQuery } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import BreadCrumb from "../../../components/BreadCrumb/BreadCrumb";
import SidebarLayout from "../../../layouts/SidebarLayout";
import { getSerieById } from "../../../services/series/services";
import illustration2 from "../../../assets/img/illustration2.png";
import AnswersIcon from "../../../assets/icons/AnswersIcon";
import ProgressIcon from "../../../assets/icons/ProgressIcon";
import NotFound from "../../Authorized/Notfound/Notfound";
import MyToast from "../../../components/Toast/Toast";
import {
  checkQcmAttempt,
  createNewExamAttempt,
  getHistoric,
  getQcmRecentAttempts,
} from "../../../services/qcm/services";
import { BsClockHistory } from "react-icons/bs";
import moment from "moment";
import { MdCheckCircle, MdError } from "react-icons/md";
import RecentAttemptsList from "../../../components/RecentAttemptsList/RecentAttemptsList";
import LoadingSingleQCM from "../../../components/LoadingQcm/LoadingQcm";
import ExamModeSelectorDialog from "./ExamModeSelectorDialog";

export default function SingleExam() {
  const history = useHistory();
  const toast = useToast();
  const textColor = useColorModeValue("blue-light", "blue-light");
  const layoutHeight = useBreakpointValue({ base: "auto", md: "92vh" });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const headingBreakPoints = useBreakpointValue({
    base: "3xl",
    lg: "xl",
    xl: "2xl",
    "2xl": "3xl",
  });
  const { year, universityName, examId, type, qcmId } = useParams<{
    year: string;
    universityName: string;
    examId: string;
    type: string;
    qcmId: string;
  }>();

  const { data, isLoading, isError } = useQuery(
    `exam-${examId}`,
    () => getSerieById(examId),
    {
      refetchOnWindowFocus: false,
    }
  );

  const breadcrumb = [
    {
      title: "Examen blanc",
      link: "/examen-blanc",
      isCurrent: false,
    },
    {
      title: "Épreuve",
      link: `/examen-blanc/${year}/${universityName}`,
      isCurrent: false,
    },
    {
      title: isLoading ? "" : "Jour " + data.jour,
      link: `#`,
      isCurrent: false,
    },
  ];

  const { data: recentAttempts, isLoading: loadingRecentAttempts } = useQuery(
    `recent-attempt-${examId}`,
    () => getQcmRecentAttempts(examId),
    {
      refetchOnWindowFocus: false,
      retry: false,
    }
  );

  const { data: checkAttempt, isLoading: checkAttemptLoading } = useQuery(
    `check-attempt-${examId}`,
    () => checkQcmAttempt(examId),
    {
      refetchOnWindowFocus: false,
    }
  );

  const { mutateAsync: startNewAttempt } = useMutation(createNewExamAttempt, {
    onError: (err: any) => {
      if(err.response?.status === 403){
        window.location.href = `/connexion?session=invalid`
      } else {
        MyToast({
          toast,
          description: err.response.data.message,
          status: "error",
          title: "Erreur",
        });
      }
    },
    onSuccess: (res: any) => {
      history.push(
        `/qcm/examen/${year}/${data.jour}/${examId}/${res.attemptId}`
      );
    },
  });

  const start = (isSerieMode: string) => {
    startNewAttempt({ qcmId: examId, type: "examen", isSerieMode });
  };

  return (
    <SidebarLayout>
      <Grid
        gridTemplateColumns={{ base: "1fr", xl: "1fr 1fr", "2xl": "1fr 0.6fr" }}
        height={layoutHeight}
        gap={10}
      >
        <GridItem>
          <VStack
            align={"stretch"}
            spacing={6}
            flex={1}
            h={
              isLoading || checkAttemptLoading || loadingRecentAttempts
                ? "unset"
                : "full"
            }
          >
            <BreadCrumb items={breadcrumb} />
            {isLoading || checkAttemptLoading ? (
              <LoadingSingleQCM />
            ) : isError ? (
              <NotFound />
            ) : (
              <Stack spacing={6} justify="space-between" h="full">
                <Stack
                  spacing={6}
                  justify="space-between"
                  flex={{ base: 0, md: 1 }}
                >
                  <Stack spacing={6}>
                    <Heading as="h1" size="lg" color={textColor}>
                      {data.university.name} {data.year}
                    </Heading>
                    <Stack
                      direction={{ base: "column", md: "row" }}
                      spacing={{ base: 3, md: 8 }}
                      justify={"space-between"}
                      alignSelf={"stretch"}
                    >
                      <Stack
                        direction={{ base: "row", md: "row" }}
                        justify="space-between"
                        flex={1}
                        spacing={{ base: 3, md: 8 }}
                      >
                        <HStack>
                          <AnswersIcon />
                          <Text>{data.count} QCM</Text>
                        </HStack>
                        {checkAttempt?.attemptId && (
                          <HStack>
                            <ProgressIcon size="22" />
                            <Text>
                              Progrès: {checkAttempt?.questionsAnswered}/
                              {data?.count}
                            </Text>
                          </HStack>
                        )}
                      </Stack>
                      {/* {checkAttempt.attemptId && (
                      <HStack
                        alignSelf={{ base: "flex-start", md: "flex-end" }}
                      >
                        <BsClockHistory color="gray" />
                        <Tooltip
                          label={moment(checkAttempt.updatedAt).fromNow()}
                          transition="all 0.2s 2s"
                          hasArrow
                        >
                          <Text color="gray">
                            {moment(checkAttempt.updatedAt).format(
                              "DD MMMM YYYY - HH:mm"
                            )}
                          </Text>
                        </Tooltip>
                      </HStack>
                    )} */}
                    </Stack>
                    {/* <Box bg="lightGrey" p="6" rounded={"1rem"}>
                    <Text>
                      Vestibulum ante ipsum primis in faucibus orci luctus et
                      ultrices posuere cubilia Curae; Donec velit neque, auctor
                      sit amet aliquam vel, ullamcorper sit amet ligula. Donec
                      sollicitudin molestie malesuada. Nulla quis lorem ut
                      libero malesuada feugiat. Vestibulum ac diam sit amet quam
                      vehicula elementum sed sit amet dui. Donec rutrum congue
                      leo eget malesuada.
                    </Text>
                  </Box> */}
                    <Stack
                      direction={{ base: "column-reverse", md: "row" }}
                      align={{ base: "stretch", md: "center" }}
                      spacing={8}
                      justify={"space-between"}
                      width="full"
                    >
                      <Button
                        variant={"solid"}
                        bg={"#fae5aa"}
                        color="blue-light"
                        height={{ base: "2.5rem", "2xl": "3rem" }}
                        flexGrow={{ base: 1, md: 0.1 }}
                        _hover={{}}
                        onClick={() =>
                          history.push(
                            `/examen-blanc/${year}/${universityName}`
                          )
                        }
                      >
                        Retour
                      </Button>
                      {checkAttempt.attemptId ? (
                        <Stack
                          direction={{ base: "column-reverse", md: "row" }}
                        >
                          {/* <Button variant={"secondary"} onClick={start}>
                          Recommencer à zéro
                        </Button> */}
                          <ExamModeSelectorDialog
                            label="Recommencer à zéro"
                            isOpen={isOpen}
                            onOpen={onOpen}
                            onClose={onClose}
                            buttonVariant="secondary"
                            start={start}
                          />
                          <Button
                            variant={"primary"}
                            onClick={() =>
                              history.push(
                                // `/qcm/serie/${serieId}/${checkAttempt.attemptId}`
                                `/qcm/examen/${year}/${data.jour}/${examId}/${
                                  checkAttempt.attemptId
                                }${
                                  checkAttempt.questionsAnswered >= data.count
                                    ? "?view=true"
                                    : ""
                                }`
                              )
                            }
                          >
                            {checkAttempt.questionsAnswered >= data.count
                              ? "Voir les questions"
                              : "Continuer"}
                          </Button>
                        </Stack>
                      ) : (
                        // <Button variant={"secondary"} onClick={start}>
                        //   Commencer
                        // </Button>
                        <ExamModeSelectorDialog
                          label="Commencer"
                          isOpen={isOpen}
                          onOpen={onOpen}
                          onClose={onClose}
                          buttonVariant="primary"
                          start={start}
                        />
                      )}
                    </Stack>
                  </Stack>
                  {recentAttempts?.length !== 0 && (
                    <RecentAttemptsList
                      isLoading={loadingRecentAttempts}
                      data={recentAttempts}
                    />
                  )}
                </Stack>
                {/* <Box>
                  <Heading color="blue-light" as="h4" fontSize={"lg"}>
                    Tentatives récentes:
                  </Heading>
                  <Box bg="lightGrey" p="4" rounded={"xl"}>
                    <List>
                      {historic?.map((item: any) => (
                        <ListItem key={item._id}>
                          <HStack justify={"space-between"}>
                            <HStack>
                              {item?.correct + item?.partiel + item?.fausse ===
                              item?.count ? (
                                <ListIcon
                                  as={MdCheckCircle}
                                  color="green.500"
                                />
                              ) : (
                                <ListIcon as={MdError} color="red.500" />
                              )}
                              <Text>Moyenne: {item?.overall}</Text>
                            </HStack>
                            <Text>
                              {moment(item?.updatedAt).format(
                                "DD MMM YYYY - HH:mm"
                              )}
                            </Text>
                          </HStack>
                        </ListItem>
                      ))}
                    </List>
                  </Box>
                </Box> */}
              </Stack>
            )}
          </VStack>
        </GridItem>
        <GridItem w={"full"} display={{ base: "none", xl: "block" }}>
          <VStack
            height="100%"
            w="full"
            p="10"
            bg="light-blue-light"
            rounded={"1rem"}
            align="center"
            justify={"space-around"}
            spacing={10}
          >
            <Heading textAlign={"left"} size={headingBreakPoints}>
              Don't stress, do your{" "}
              <span style={{ color: "#F7D372" }}>best</span>
            </Heading>
            <Image
              alt={"Série Illustration"}
              src={illustration2}
              width="100%"
            />
          </VStack>
        </GridItem>
      </Grid>
    </SidebarLayout>
  );
}
