import axiosInstance from "../../utils/axiosInstance";

export const getPlanById = async ({ planId }: { planId: string }) => {
  let token = localStorage.getItem("access_token");
  return await axiosInstance({
    method: "get",
    url: `/plan/${planId}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res: any) => res.data);
};
