import {
  HStack,
  Skeleton,
  Stack,
  Text,
  Tooltip,
  useBreakpointValue,
  VStack,
  Icon as ChakraIcon,
  useDisclosure,
} from "@chakra-ui/react";
import { ReactNode } from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import DayOneIcon from "../../../assets/icons/DayOneIcon";
import DayTwoIcon from "../../../assets/icons/DayTwoIcon";
import PercentageIcon from "../../../assets/icons/PercentageIcon";

export default function PerformanceBanner({
  moyenneDays,
  isLoadingStats,
  isExam,
}: any) {
  const percentageSize = useBreakpointValue({ base: "46", "2xl": "52" });
  const avg = +((moyenneDays?.moy1?.moy + moyenneDays?.moy2?.moy) /2).toFixed(2)

  return (
    <Stack
      rounded={"1rem"}
      bg="white"
      p="0"
      direction={{ base: "column", lg: "row" }}
      spacing={6}
    >
      <BannerItem
        flex={1}
        Icon={<PercentageIcon bg={"#7fcacf"} size={percentageSize} />}
        title={"Moyenne générale"}
        note={
          isExam
            ? moyenneDays?.avg?.toFixed(2) || 0
            : moyenneDays?.moy1?.showOverall && moyenneDays?.moy2?.showOverall
            ? avg : "n/a"
        }
        isLoadingStats={isLoadingStats}
        isExam={isExam}
      />
      <BannerItem
        flex={1}
        Icon={<DayOneIcon />}
        title={"Jour 1"}
        note={
          isExam
            ? +moyenneDays?.moy1?.toFixed(2) || 0
            : moyenneDays?.moy1?.showOverall
            ? +moyenneDays?.moy1?.moy?.toFixed(2)
            : "n/a"
        }
        isLoadingStats={isLoadingStats}
        isExam={isExam}
      />
      <BannerItem
        flex={1}
        Icon={<DayTwoIcon />}
        title={"Jour 2"}
        note={
          isExam
            ? +moyenneDays?.moy2?.toFixed(2) || 0
            : moyenneDays?.moy2?.showOverall
            ? +moyenneDays?.moy2?.moy?.toFixed(2)
            : "n/a"
        }
        isLoadingStats={isLoadingStats}
        isExam={isExam}
      />
    </Stack>
  );
}

const BannerItem = ({
  flex,
  Icon,
  title,
  note,
  isLoadingStats,
  isExam,
}: {
  flex: number;
  Icon: ReactNode;
  title: string;
  note: number | string;
  isLoadingStats: boolean;
  isExam: boolean;
}) => {
  const { isOpen, onClose, onToggle } = useDisclosure();

  return (
    <HStack bg={"lightGrey"} rounded="1rem" p="6" flex={flex} h="24">
      {Icon}
      <VStack align={"flex-start"} spacing={0} minW={"80px"}>
        <Text color={"gray.500"} fontSize="lg">
          {title}
        </Text>
        {isLoadingStats ? (
          <Skeleton
            height="16px"
            width={"100%"}
            rounded="full"
            endColor={"midGrey"}
            startColor={"lightGrey"}
          />
        ) : isExam ? (
          <HStack justify={"space-between"} flex={1} align="center">
            <Text fontWeight={"bold"}>{note ?? 0}/20</Text>
          </HStack>
        ) : (
          <Tooltip
            label="Le calcul de la moyenne ne sera fait que si vous avez une moyenne dans tous les sujets."
            placement="right"
            hasArrow
            rounded={"md"}
            isOpen={isOpen}
            onClose={onClose}
          >
            <HStack justify={"space-between"} flex={1} align="center">
              <Text fontWeight={"bold"}>
                {note || 0}
                {note === "n/a" ? "" : "/20"}
              </Text>
              <ChakraIcon
                as={AiOutlineInfoCircle}
                fontSize="1.2rem"
                cursor={"pointer"}
                onClick={(e: any) => {
                  e.stopPropagation();
                  onToggle();
                }}
              />
            </HStack>
          </Tooltip>
        )}
      </VStack>
    </HStack>
  );
};
