import { Heading, Stack, Text } from "@chakra-ui/react";

const Congrats = () => (
  <Stack textAlign={"center"}>
    <Heading color="blue-light">
      Félicitations!<span title="party-popper">🎉</span>
    </Heading>
    <Text>
      Votre abonnement est maintenant activé. Désormais, vous pouvez profiter de
      nos examens et séries!
    </Text>
  </Stack>
);

export default Congrats;
