import { Route, Switch } from "react-router-dom";
import LoginContainer from "./containers/Authorized/LoginContainer";
import RegisterContainer from "./containers/Authorized/RegisterContainer";
import ForgetPassword from "./pages/Authorized/ResetPassword/ForgetPassword";
import Router from "./Routes";
import Test from "./Test";
import AuthProvider from "./auth-provider/AuthProvider";
import DashboardContainer from "./containers/Unauthorized/DashboardContainer";
import Connecting from "./pages/Authorized/Connecting/Connecting";
import VerifyEmail from "./pages/Unauthorized/VerifyEmail/VerifyEmail";
import EnterEmail from "./pages/Authorized/ResetPassword/EnterEmail";
import Onboarding from "./pages/Unauthorized/Onboarding/Onboarding";
import SeriesContainer from "./containers/Unauthorized/SeriesContainer";
import ExamContainer from "./containers/Unauthorized/ExamsContainer";
import PlaylistContainer from "./containers/Unauthorized/PlaylistContainer";
import PerformanceContainer from "./containers/Unauthorized/PerformanceContainer";
import QuestionContainer from "./containers/Unauthorized/QuestionContainer";
import ProfileContainer from "./containers/Unauthorized/ProfileContainer";
import "./App.css";
import QcmContainer from "./containers/Unauthorized/QcmContainer";
import { SocketContext, socket } from "./context/socket";
import NotificationsContainer from "./containers/Unauthorized/NotificationsContainer";
import ConfirmEmailMessage from "./pages/Unauthorized/ConfirmEmailMessage";
import RedirectComponent from "./components/RedirectComponent";
import PlanContainer from "./containers/Unauthorized/PlanContainer";
import { useModal } from "./modal-provider/ModalProvider";
import UpgradeModal from "./components/UpgradeModal";
import { useQuery, useQueryClient } from "react-query";
import { UseQueryDataType } from "./types";
import { loadUser } from "./services/auth/services";
import { PlanProvider } from "./auth-provider/PlanProvider";
import VoucherContainer from "./containers/Unauthorized/VoucherContainer";

export default function Main() {
  const { isOpen } = useModal();
  // const queryClient = useQueryClient();
  // queryClient.invalidateQueries({ queryKey: ['user'], active: true });

  // useQuery<UseQueryDataType | undefined>(["user"], loadUser, {
  //   refetchOnWindowFocus: false,
  //   // enabled: Boolean(localStorage.getItem("access_token")),
  //   retry: false,
  //   // onSuccess: () => 
  // });

  return (
    <SocketContext.Provider value={socket}>
      <div onContextMenu={(e) => e.preventDefault()}>
        <PlanProvider>
        <Router>
          <Switch>
            <Route exact path="/">
              <Test />
            </Route>
            <Route path="/connexion">
              <LoginContainer />
            </Route>
            <Route path="/inscription">
              <RegisterContainer />
            </Route>
            <Route exact path={`/mot-de-passe-oublie/reinitialisation/:token`}>
              <ForgetPassword />
            </Route>
            <Route path={`/mot-de-passe-oublie/email`}>
              <EnterEmail />
            </Route>
            <Route exact path="/confirmation-email">
              <ConfirmEmailMessage />
            </Route>
            <Route exact path={`/connexion-en-cours`}>
              <Connecting />
            </Route>
            <AuthProvider>
                <Route exact path="/bienvenue">
                  <Onboarding />
                </Route>
                <RedirectComponent />
                <Route path="/tableau-de-bord">
                  <DashboardContainer />
                </Route>
                <Route path="/qcm">
                  <QcmContainer />
                </Route>
                <Route path="/series">
                  <SeriesContainer />
                </Route>
                <Route path="/examen-blanc">
                  <ExamContainer />
                </Route>
                <Route path="/playlist">
                  <PlaylistContainer />
                </Route>
                <Route exact path="/performance">
                  <PerformanceContainer />
                </Route>
                <Route exact path="/question/:questionId">
                  <QuestionContainer />
                </Route>
                <Route exact path="/notifications">
                  <NotificationsContainer />
                </Route>
                <Route exact path="/profile">
                  <ProfileContainer />
                </Route>
                <Route exact path="/verify">
                  <VerifyEmail />
                </Route>
                <Route exact path="/coupon">
                  <VoucherContainer />
                </Route>
                <Route exact path="/plan/:planId">
                  <PlanContainer />
                </Route>
            </AuthProvider>
          </Switch>
        </Router>
        {
          isOpen && <UpgradeModal />
        }
        </PlanProvider>
      </div>
    </SocketContext.Provider>
  );
}
