
export default function AnswerPartialSquareIcon() {
  return (
    <svg
      id="percentage-square"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <rect
        id="Rectangle_1083"
        data-name="Rectangle 1083"
        width="24"
        height="24"
        rx="5"
        fill="#fff"
        opacity="0"
      />
      <g id="Group_1250" data-name="Group 1250" transform="translate(1.2 1.2)">
        <path
          id="Path_251"
          data-name="Path 251"
          d="M13.5,20.475H8.1c-4.887,0-6.975-2.088-6.975-6.975V8.1c0-4.887,2.088-6.975,6.975-6.975h5.4c4.887,0,6.975,2.088,6.975,6.975v5.4C20.475,18.387,18.387,20.475,13.5,20.475Zm-5.4-18c-4.149,0-5.625,1.476-5.625,5.625v5.4c0,4.149,1.476,5.625,5.625,5.625h5.4c4.149,0,5.625-1.476,5.625-5.625V8.1c0-4.149-1.476-5.625-5.625-5.625Z"
          fill="#fff"
        />
        <path
          id="Path_252"
          data-name="Path 252"
          d="M7.713,14.418a.668.668,0,0,1-.477-.2.679.679,0,0,1,0-.954l5.895-5.9a.675.675,0,0,1,.954.954L8.19,14.22a.668.668,0,0,1-.477.2Z"
          fill="#fff"
        />
      </g>
    </svg>
  );
}
