import { ChangeEvent, FormEvent, useState } from "react";
import { Stack, useBreakpointValue, useToast } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "react-query";
import Lottie from "react-lottie";
import MyToast from "../../../components/Toast/Toast";
import SidebarLayout from "../../../layouts/SidebarLayout";
import { activateVoucher } from "../../../services/voucher/services";
import tadaLottie from "./tada.json";
import Congrats from "./components/Congrats";
import VoucherForm from "./components/VoucherForm";
interface Props {
  base_url: string;
}

export default function Voucher({ base_url }: Props) {
  const [isSuccess, setIsSuccess] = useState(false);
  const [voucher, setVoucher] = useState("");
  const [isError, setIsError] = useState(false);
  const [animationFinished, setAnimationFinished] = useState(false);
  const toast = useToast();
  const queryClient = useQueryClient();
  const minHeightBreakPoint = useBreakpointValue({
    base: "500px",
    md: "calc(100vh - 64px)",
  });

  const { mutateAsync: sendVoucher, isLoading } = useMutation(activateVoucher, {
    onError: (err: any) => {
      MyToast({
        toast,
        description: err.response.data.message,
        status: "error",
        title: "Erreur",
      });
    },
  });

  function checkFormat(input: string): boolean {
    const regex =
      /^[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}$/;
    return regex.test(input);
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value.trim();
    const formattedValue = inputValue
      .replace(/-/g, "")
      .replace(/(.{4})(?!$)/g, "$1-");
    const isCorrectFormat = checkFormat(formattedValue);
    setVoucher(formattedValue);
    if (isCorrectFormat) {
      setIsError(false);
    } else {
      setIsError(true);
    }
  };

  const onSubmit = async (e: FormEvent<HTMLDivElement>) => {
    e.preventDefault();
    const isCorrectFormat = checkFormat(voucher);
    if (!voucher || !isCorrectFormat) {
      setIsError(true);
      return;
    }
    setIsError(false);
    await sendVoucher({ voucher: voucher.toLowerCase().replace(/-/g, "") });
    setIsSuccess(true);
    queryClient.invalidateQueries([`user`]);
  };

  return (
    <SidebarLayout>
      <Stack
        position={"relative"}
        minH={minHeightBreakPoint}
        w="full"
        rounded={"1rem"}
        background="linear-gradient(190deg,#ebf4fd,hsla(0,0%,100%,0) 75%)"
        p="4"
        pt="8"
        spacing={8}
        justifyContent="center"
      >
        {isSuccess ? (
          <Congrats />
        ) : (
          <VoucherForm
            onSubmit={onSubmit}
            isError={isError}
            handleChange={handleChange}
            voucher={voucher}
            isLoading={isLoading}
          />
        )}
        {isSuccess && !animationFinished && (
          <LottieAnimation setAnimationFinished={setAnimationFinished} />
        )}
      </Stack>
    </SidebarLayout>
  );
}

const LottieAnimation = ({
  setAnimationFinished,
}: {
  setAnimationFinished: (x: boolean) => void;
}) => {
  const topBreakPoint = useBreakpointValue({ base: "-30%", md: 0 });

  return (
    <div
      style={{
        position: "fixed",
        top: topBreakPoint,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: 1,
      }}
    >
      <Lottie
        options={{
          animationData: tadaLottie,
          loop: false,
          autoplay: true,
        }}
        isClickToPauseDisabled={true}
        height={"100%"}
        width={"100%"}
        eventListeners={[
          {
            eventName: "complete",
            callback: () => setAnimationFinished(true),
          },
        ]}
      />
    </div>
  );
};
