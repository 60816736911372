import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import Performance from "../../pages/Unauthorized/Performance/Performance";
import { useAuthProvider } from "../../auth-provider/AuthProvider";

export default function PerformanceContainer() {
  const { data: auth } = useAuthProvider();
  const { path, url } = useRouteMatch();

  if (!auth?.email_verified) {
    return <Redirect to="/confirmation-email" exact />;
  }

  return (
    <Switch>
      <Route exact path={`${path}`}>
        <Performance base_url={url} />
      </Route>
      <Route>
        <h1>Page Not Found</h1>
      </Route>
    </Switch>
  );
}
