import axiosInstance from "../../utils/axiosInstance";

export const getNotSeenNotificationCount = async () => {
    let access_token = localStorage.getItem("access_token");
    return await axiosInstance({
      method: "get",
      url: "/notification/count",
      headers:{
        Authorization: `Bearer ${access_token}`
      }
    }).then((res: any) => res.data);
};

export const getNotifications = async (page:number) => {
    let access_token = localStorage.getItem("access_token");
    return await axiosInstance({
      method: "get",
      url: `/notification?skip=${page*2}&limit=2`,
      headers:{
        Authorization: `Bearer ${access_token}`
      }
    }).then((res: any) => {
      return {
        results: res?.data?.results,
        nextPage: page + 1,
        totalPages: res?.data?.totalPages
      }
    });
};

export const fetchNotifications = async (page: number) => {
  let access_token = localStorage.getItem("access_token");
  const response = await axiosInstance({
    url: `/notification?skip=${page * 20}&limit=20`,
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  });
  return {
    results: response.data.results,
    nextPage: page + 1,
    totalPages: response.data.totalPages,
  };
};
// export const fetchNotifications = async ({ pageParam = 1 }) => {
//   let access_token = localStorage.getItem("access_token");
//   const response = await axiosInstance({
//     url: `/notification?skip=${(pageParam - 1) * 20}&limit=20`,
//     headers: {
//       Authorization: `Bearer ${access_token}`,
//     },
//   });
//   return {
//     results: response.data.results,
//     nextPage: pageParam + 1,
//     totalPages: response.data.totalPages,
//   };
// };

export const setNotificationsAsSeen = async (ids: Array<string>) => {
  let access_token = localStorage.getItem("access_token");
  return await axiosInstance({
    method: "put",
    url: "/notification/many",
    data:{
      ids
    },
    headers:{
      Authorization: `Bearer ${access_token}`
    }
  }).then((res: any) => res.data);
};