import {
  Box,
  Button,
  Divider,
  Grid,
  GridItem,
  Heading,
  HStack,
  Stack,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useMutation, useQuery } from "react-query";
import { Link, useHistory, useParams } from "react-router-dom";
import AnswersIcon from "../../../assets/icons/AnswersIcon";
import ChronoIcon from "../../../assets/icons/ChronoIcon";
import PageHeading from "../../../components/PageHeading/PageHeading";
import StatsChart from "../../../components/StatsChart/StatsChart";
import StatsTable from "../../../components/StatsTable/StatsTable";
import MyToast from "../../../components/Toast/Toast";
import SidebarLayout from "../../../layouts/SidebarLayout";
import {
  createNewAttempt,
  getExamResult,
} from "../../../services/qcm/services";
import { secondsToHMS } from "../../../utils/timerConverter";

export default function ExamResult() {
  const { attemptId, qcmId } = useParams<{
    attemptId: string;
    qcmId: string;
  }>();
  const toast = useToast();
  const history = useHistory();

  const { data: result, isLoading: loadingResult } = useQuery(
    `result-exam-${attemptId}`,
    () => getExamResult(attemptId)
  );

  const { mutateAsync: startNewAttempt } = useMutation(createNewAttempt, {
    onError: (err: any) => {
      MyToast({
        toast,
        description: err.response.data.message,
        status: "error",
        title: "Erreur",
      });
    },
    onSuccess: (res: any) => {
      history.push(
        `/qcm/examen/${result?.year}/${result?.jour}/${qcmId}/${res.attemptId}`
      );
    },
  });

  const start = () => {
    startNewAttempt({ qcmId: qcmId, type: "examen" });
  };

  return (
    <SidebarLayout>
      {loadingResult ? (
        <p>Loading...</p>
      ) : (
        <VStack align="stretch" spacing={6}>
          <HStack justify={"space-between"}>
            <VStack align={"flex-start"} spacing={1} pb="2" flexGrow={0.4}>
              <HStack>
                <Text color="blue-light" fontSize={"sm"}>
                  Epreuve / Jour {result?.jour}
                </Text>
              </HStack>
              <PageHeading title={result?.university + " " + result?.year} />
            </VStack>
          </HStack>
          <Grid gridTemplateColumns={{ base: "1fr", md: "1fr 0.5fr" }} gap={10}>
            <GridItem>
              <VStack spacing={{ base: 4, md: 8 }} h="full">
                <Stack
                  bg="lightGrey"
                  borderRadius={"lg"}
                  minW={{ base: "100%", md: "unset" }}
                  direction={{ base: "column", md: "row" }}
                  h="full"
                  w="100%"
                  p="6"
                  spacing={3}
                  alignSelf="stretch"
                  flex={1}
                >
                  <Stack flex={1} spacing={2} justify="center">
                    <Heading
                      as="h6"
                      color="blue-light"
                      fontWeight={"bold"}
                      fontSize="lg"
                    >
                      Moyenne générale
                    </Heading>
                    <HStack spacing={1}>
                      <AnswersIcon />
                      <Text>{result?.overall?.toFixed(2)} / 20</Text>
                    </HStack>
                  </Stack>
                  <Divider
                    orientation="vertical"
                    borderColor="#B7B8BA"
                    bg={"#B7B8BA"}
                    rounded={"xl"}
                    w={"2px"}
                    h="full"
                    display={{ base: "none", md: "inherit" }}
                  />
                  <Stack flex={1} spacing={2} justify="center">
                    <Heading
                      as="h6"
                      color="blue-light"
                      fontWeight={"bold"}
                      fontSize="lg"
                    >
                      Temps passé
                    </Heading>
                    <HStack spacing={1}>
                      <ChronoIcon bg="black" />
                      <Text>
                        {secondsToHMS(result?.totalDuration).hours
                          ? secondsToHMS(result?.totalDuration).hours + "h "
                          : ""}
                        {secondsToHMS(result?.totalDuration).minutes
                          ? secondsToHMS(result?.totalDuration).minutes + "min "
                          : ""}
                        {Math.floor(
                          secondsToHMS(result?.totalDuration).seconds
                        ) + "sec"}
                      </Text>
                    </HStack>
                  </Stack>
                  <Divider
                    orientation="vertical"
                    borderColor="#B7B8BA"
                    bg={"#B7B8BA"}
                    rounded={"xl"}
                    w={"2px"}
                    h="full"
                    display={{ base: "none", md: "inherit" }}
                  />
                  <Stack flex={1} spacing={2} justify="center">
                    <Heading
                      as="h6"
                      color="blue-light"
                      fontWeight={"bold"}
                      fontSize="lg"
                    >
                      Temps par question
                    </Heading>
                    <HStack spacing={1}>
                      <ChronoIcon bg="black" />
                      <Text>
                        {secondsToHMS(result?.averageTime).hours
                          ? secondsToHMS(result?.averageTime).hours + "h "
                          : ""}
                        {secondsToHMS(result?.averageTime).minutes
                          ? secondsToHMS(result?.averageTime).minutes + "min "
                          : ""}
                        {Math.floor(secondsToHMS(result?.averageTime).seconds) +
                          "sec"}
                      </Text>
                    </HStack>
                  </Stack>
                  <Box display={{ base: "inherit", md: "none" }}>
                    <StatsChart
                      stats={{
                        correct: result?.correct,
                        fausse: result?.fausse,
                        partial: result?.partiel,
                      }}
                      questionsCount={
                        result?.partiel + result?.fausse + result?.correct
                      }
                    />
                  </Box>
                </Stack>
                <Stack direction={{base: "column", md: "row"}} justify={"space-between"} alignSelf={"stretch"} >
                  <Button
                    variant={"secondary"}
                    bg="lightRed"
                    px="8"
                    color={"white"}
                    _hover={{
                      bg: "darkRed",
                      "& svg": { filter: "brightness(3)" },
                    }}
                    height={{ base: "2.5rem", "2xl": "3rem" }}
                    flex={{base: "unset", md: 1}}
                    onClick={() =>
                      history.push(
                        `/examen-blanc/${result?.year}/${result?.university}`
                      )
                    }
                  >
                    Quitter
                  </Button>
                  <Link to={`/qcm/examen/${result?.year}/${result?.jour}/${qcmId}/${attemptId}?view=true`} style={{alignSelf: "stretch", flex: 1}}>
                    <Button
                      variant={"primary"}
                      alignSelf={{base: "stretch", md: "flex-end"}}
                      flex={{base: "unset", md: 1}}
                      w="100%"
                    >
                      Voir les questions
                    </Button>
                  </Link>
                  <Button
                    variant={"secondary"}
                    flex={{base: "unset", md: 1}}
                    alignSelf={{base: "stretch", md: "flex-end"}}
                    onClick={start}
                  >
                    Recommencer à zéro
                  </Button>
                </Stack>
              </VStack>
            </GridItem>
            <GridItem display={{ base: "none", md: "inherit" }}>
              <StatsChart
                stats={{
                  correct: result?.correct,
                  fausse: result?.fausse,
                  partial: result?.partiel,
                }}
                questionsCount={
                  result?.partiel + result?.fausse + result?.correct
                }
              />
            </GridItem>
          </Grid>
          <StatsTable rows={result.specialities} isLoading={loadingResult} />
        </VStack>
      )}
    </SidebarLayout>
  );
}
