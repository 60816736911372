import { Box, Heading, useBreakpointValue } from "@chakra-ui/react";
import Lines from "../../assets/icons/Lines";

export default function HeadingWithLines({
  children,
  linesColor = "yellow",
  position = "end",
  full = false,
  light = false,
  withLines = true
}: {
  full?: boolean;
  linesColor?: string;
  children: string;
  position?: "end" | "middle" | "start";
  light?: boolean;
  withLines?: boolean
}) {
    const sizes = useBreakpointValue({base: "xl", "2xl": "2xl"})
  return (
    <Heading
      position={"relative"}
      w={"fit-content"}
      wordBreak={"break-word"}
      // whiteSpace="nowrap"
      maxW={full ? "unset" : "27rem"}
      size={sizes}
      color={"blue-light"}
      fontWeight={light ? "light":"900"}
    >
      {children}
      {withLines && <Box
        position={"absolute"}
        top={"-30px"}
        left={position === "end" ? "97%" : "85%"}
      >
        <Lines color={linesColor} />
      </Box>}
    </Heading>
  );
}
