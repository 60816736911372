import {
  TabPanel,
  TabPanels,
  Tabs,
  useBreakpointValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import ExamQcmBody from "../../../components/ExamQcm/ExamQCMBody";
import ExamQCMHeader from "../../../components/ExamQcm/ExamQCMHeader";
import MyToast from "../../../components/Toast/Toast";
import usePrevious from "../../../hooks/usePrevious";
import SidebarLayout from "../../../layouts/SidebarLayout";
import { getOneAttempt, resumeAttempt } from "../../../services/qcm/services";
import { useTimer } from "../../../timer-provider/TimerProvider";
import ExamSkippedQCMModal from "../../../components/ExamQcm/ExamSkippedQCM";
// import { useStopwatch as useReactTimer } from "react-timer-hook";
import { useTimer as useReactTimer } from "react-use-precision-timer";
import useQueryParams from "../../../hooks/useQueryParams";
import LoadingQuestions from "../../../components/LoadingQuestions"

export default function ExamPage() {
  const [tabIndex, setTabIndex] = useState(0);
  const [counterIsRunning, setCounterIsRunning] = useState(true);
  const [answers, setAnswers] = useState<any>([]);
  const [selectedOptions, setSelectedOptions] = useState<any>([]);
  const [isFixed, setisFixed] = useState(true);
  const [skippedQuestions, setSkippedQuestions] = useState<any>([]);
  const [isSerieMode, setIsSerieMode] = useState(false)
  const containerRef = useRef<any>();
  const headerRef = useRef<any>();
  const { startInitialTimer, time } = useTimer();
  const scrollValue: number | undefined = useBreakpointValue({
    base: 66,
    md: 66,
    "2xl": 76,
  });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const timer = useReactTimer({delay: 0, startImmediately: false});
  // const isSerieMode = false // TODO: this will be in the response


  const toast = useToast();
  const { attemptId, qcmId } = useParams<{
    type: string;
    qcmId: string;
    attemptId: string;
  }>();

  const {
    data: questions,
    isLoading: loadingAttempt,
    isError: isErrorLoadingAttempt,
    error: errorLoadingAttempt,
  } = useQuery(
    `questions-examen-${attemptId}`,
    () => getOneAttempt(attemptId),
    {
      refetchOnWindowFocus: false,
      retry: false,
      onSuccess: (response: any) => {
        startInitialTimer(response?.duration * 60, true, true)
      },
      onError: (err: any) => {
        MyToast({
          toast,
          description: err.response.data.message,
          status: "error",
          title: "Erreur",
        });
      },
    }
  );

  const {
    data: lastAttempt,
    isLoading: resumeAttemptLoading,
    isFetching: resumeAttemptRefetch,
    isFetched,
    isSuccess,
  } = useQuery(`resume-attempts-${qcmId}`, () => resumeAttempt(attemptId), {
    refetchOnWindowFocus: false,
    retry: false,
    onError: (err: any) => {
      MyToast({
        toast,
        description: err.response.data.message,
        status: "error",
        title: "Erreur",
      });
    },
    onSuccess: (response: any) => {
      // setAnswers(response?.questions ?? []);
      setIsSerieMode(response.isSerieMode)
      setAnswers([...answers, ...response.questions]);
      setSelectedOptions([...selectedOptions, ...response.selectedOptions]);

      if (response?.questions?.length > 0 && !isFetched) {
        startInitialTimer(response?.duration, true, true);
      }
      
      if (!isFetched && queryString.get("view") !== "true") {
        setTabIndex(response?.last_question ?? 0);
        timer.start();
      }

      if(queryString.get("view") === "true"){
        setTabIndex(0)
      }
      // startTimeSpentOnQuestion();
    },
  });

  const queryString = useQueryParams();
  const [correction, setCorrection] = useState<Array<any>>([]);

  const generateCorrections = () => {
    for (const answer of answers) {
      for (const questionAnswer of answer.questions) {
        const correctAndNotSelected: any = [];
        const correctAndSelected: any = [];
        const wrongAndSelected: any = [];
        const wrongNotSelected: any = [];
        const questionSelectedOptions = selectedOptions.find(
          (el: any) => el.questionId === questionAnswer.questionId
        );

        // loopi al questionAnswer options, and check if the option's id exist in the selectedOptions.options array
        // if don't exist push the questionAnswer option object to correctNotSelectedOptions
        questionAnswer.options.forEach((option: any) => {
          const isCorrectSelected = questionSelectedOptions?.options.includes(
            option._id
          );

          if (!isCorrectSelected) {
            correctAndNotSelected.push(option);
          }
        });

        // loopi al questionAnswer wrongOptions, and check if the option's id exist in the selectedOptions.options array
        // if don't exist push the questionAnswer wrongOption object to wrongNotSelected
        questionAnswer.wrongOptions.forEach((option: any) => {
          const isWrongSelected = questionSelectedOptions?.options.includes(
            option._id
          );

          if (!isWrongSelected) {
            wrongNotSelected.push(option);
          }
        });

        // loopi al selectedOptions.options, and check if the option's exist or not
        // if exist, push the object in correctSelectedOptions
        // if not exist, push the object to wrongSelectedOptions
        questionSelectedOptions?.options.forEach((optionSelectedId: any) => {
          const isItSelectedOption = questionAnswer.options.find(
            (option: any) => option._id === optionSelectedId
          );

          if (isItSelectedOption) {
            correctAndSelected.push(isItSelectedOption);
          } else {
            const wrongOpt = questionAnswer.wrongOptions.find(
              (opt: any) => opt._id === optionSelectedId
            );
            wrongAndSelected.push(wrongOpt);
          }
        });

        const questionCorrection = {
          questionId: questionAnswer.questionId,
          correction: {
            correctNotSelectedOptions: correctAndNotSelected,
            correctSelectedOptions: correctAndSelected,
            wrongSelectedOptions: wrongAndSelected,
            wrongNotSelected: wrongNotSelected,
          },
        };
        setCorrection((prevState) => [...prevState, questionCorrection]);
      }
    }
  };


  useEffect(() => {
    if (headerRef.current && queryString.get("view") !== "true") {
      scrollRight((scrollValue ?? 1) * lastAttempt?.last_question);
    }
  }, [isSuccess]);

  const select = (questionId: string, optionId: string) => {
    const currentQuestion = selectedOptions.find(
      (question: any) => question.questionId === questionId
    );

    if (currentQuestion) {
      if (currentQuestion.options.includes(optionId)) {
        const index = currentQuestion.options.indexOf(optionId);
        const _currentQuestion = { ...currentQuestion };
        _currentQuestion.options.splice(index, 1);
        setSelectedOptions(
          selectedOptions.map((el: any) =>
            el.questionId === questionId ? _currentQuestion : el
          )
        );
      } else {
        setSelectedOptions(
          selectedOptions.map((el: any) =>
            el.questionId === questionId
              ? { ...el, options: [...el.options, optionId] }
              : el
          )
        );
      }
    } else {
      setSelectedOptions([
        ...selectedOptions,
        { questionId, options: [optionId] },
      ]);
    }
  };

  const scrollRight = (scrollOffset: number) => {
    if (headerRef?.current) {
      headerRef.current.scrollLeft += scrollOffset;
    }
  };

  const handleChangeIndex = (index: number) => {
    setTabIndex(index);
  };

  const goToTop = () => {
    setisFixed(false);
    containerRef.current.scrollIntoView();
    setisFixed(true);
  };

  const prevTabIndexRef: any = usePrevious(tabIndex);

  useEffect(() => {
    if (tabIndex !== prevTabIndexRef && questions && lastAttempt) {
      // resetTimeSpentOnQuestion();
      timer.start();
    }
  }, [tabIndex]);

  useEffect(() => {
    if (tabIndex !== prevTabIndexRef && headerRef?.current) {
      headerRef.current.scrollTo(tabIndex * (scrollValue ?? 1), 0);
    }
  }, [tabIndex, headerRef?.current]);

  const checkAllQuestionsAnswered = () => {
    const difference = questions?.questions?.filter((question: any) => {
      return !answers?.some((answer: any) => {
        return question._id === answer.question;
      });
    });
    if (
      difference?.length !== 0 &&
      tabIndex === questions?.questions?.length - 1
    ) {
      onOpen();
    }
    setSkippedQuestions(difference);
    return difference;
  };

  useEffect(() => {
    if((queryString.get("view")==="true" || isSerieMode) && answers){
      generateCorrections();
    }
  }, [queryString.get("view"), answers]);

  useEffect(() => {
    if(queryString.get("view") !== "true" && time <= 0 && checkAllQuestionsAnswered()){
      checkAllQuestionsAnswered()
      onOpen();
    }
  }, [time]);

  // if (resumeAttemptLoading || loadingAttempt) return <p>Loading...</p>;
  if (resumeAttemptLoading || loadingAttempt) {
    return <LoadingQuestions />;
  }

  return (
    <SidebarLayout withoutPadding>
      <Tabs
        isFitted
        variant={"unstyled"}
        onChange={handleChangeIndex}
        index={tabIndex}
        isLazy
        ref={containerRef}
        draggable
      >
        <ExamQCMHeader
          questions={questions.questions}
          headerRef={headerRef}
          answers={answers}
          isFixed={isFixed}
          setisFixed={setisFixed}
          tabIndex={tabIndex}
          scrollRight={scrollRight}
          scrollValue={scrollValue}
          viewMode={queryString.get("view") === "true"}
          isSerieMode={isSerieMode}
        />
        <TabPanels p={{ base: "2", "2xl": "8" }} pt={{ "2xl": "2" }}>
          {questions.questions.map((question: any, index: number) => (
            <TabPanel key={index} tabIndex={question._id}>
              <ExamQcmBody
                questions={questions.questions}
                question={question}
                university={questions.university}
                year={questions.year}
                jour={questions.jour}
                counterIsRunning={counterIsRunning}
                select={select}
                selectedOptions={selectedOptions}
                setTabIndex={setTabIndex}
                scrollRight={scrollRight}
                tabIndex={tabIndex}
                alreadyAnswered={answers?.find(
                  (el: any) => el.question === question._id
                )}
                resumeAttemptLoading={resumeAttemptLoading}
                resumeAttemptRefetch={resumeAttemptRefetch}
                goToTop={goToTop}
                checkAllQuestionsAnswered={checkAllQuestionsAnswered}
                skippedQuestions={skippedQuestions}
                answers={answers}
                timeSpentOnQuestion={Math.floor(timer.getElapsedRunningTime() / 1000)}
                pauseTimeSpentOnQuestion={() => timer.pause()}
                resumeTimeSpentOnQuestion={() => timer.start()}
                setAnswers={setAnswers}
                correction={correction}
                isSerieMode={isSerieMode}
              />
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
      <ExamSkippedQCMModal
        isOpen={isOpen}
        onClose={onClose}
        skippedQuestions={skippedQuestions}
        setTabIndex={setTabIndex}
        // timeOut={time <= 0}
        timeOut={time < 0}
      />
    </SidebarLayout>
  );
}
