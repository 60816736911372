import { useColorModeValue } from "@chakra-ui/react";

export default function CancelIcon({color="#d76161"}:{color?: string}) {
  const colorFill = color;
  // const colorFill = useColorModeValue(color, "white")
  return (
    <svg
      id="close-square"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <rect
        id="Rectangle_94"
        data-name="Rectangle 94"
        width="24"
        height="24"
        rx="5"
        fill={colorFill}
        opacity="0"
      />
      <g id="Group_160" data-name="Group 160" transform="translate(1.2 1.2)">
        <path
          id="Path_115"
          data-name="Path 115"
          d="M8.253,14.022a.668.668,0,0,1-.477-.2.679.679,0,0,1,0-.954L12.87,7.776a.675.675,0,0,1,.954.954L8.73,13.824A.653.653,0,0,1,8.253,14.022Z"
          fill={colorFill}
        />
        <path
          id="Path_116"
          data-name="Path 116"
          d="M13.347,14.022a.668.668,0,0,1-.477-.2L7.776,8.73a.675.675,0,0,1,.954-.954l5.094,5.094a.679.679,0,0,1,0,.954.668.668,0,0,1-.477.2Z"
          fill={colorFill}
        />
        <path
          id="Path_117"
          data-name="Path 117"
          d="M13.5,20.475H8.1c-4.887,0-6.975-2.088-6.975-6.975V8.1c0-4.887,2.088-6.975,6.975-6.975h5.4c4.887,0,6.975,2.088,6.975,6.975v5.4C20.475,18.387,18.387,20.475,13.5,20.475Zm-5.4-18c-4.149,0-5.625,1.476-5.625,5.625v5.4c0,4.149,1.476,5.625,5.625,5.625h5.4c4.149,0,5.625-1.476,5.625-5.625V8.1c0-4.149-1.476-5.625-5.625-5.625Z"
          fill={colorFill}
        />
      </g>
    </svg>
  );
}
