import {
  Box,
  Button,
  Grid,
  GridItem,
  Heading,
  HStack,
  Image,
  Stack,
  Text,
  useBreakpointValue,
  useColorModeValue,
  useToast,
  VStack,
} from "@chakra-ui/react";
import SidebarLayout from "../../../layouts/SidebarLayout";
import illustration from "../../../assets/img/serie-welcome.png";
import BreadCrumb from "../../../components/BreadCrumb/BreadCrumb";
import { useHistory, useParams } from "react-router-dom";
import capitalizeText from "../../../utils/capitalizeText";
import { useMutation, useQuery } from "react-query";
import { getSerieById } from "../../../services/series/services";
import AnswersIcon from "../../../assets/icons/AnswersIcon";
import {
  checkQcmAttempt,
  createNewAttempt,
  getQcmRecentAttempts,
} from "../../../services/qcm/services";
import MyToast from "../../../components/Toast/Toast";
import ProgressIcon from "../../../assets/icons/ProgressIcon";
import NotFound from "../../Authorized/Notfound/Notfound";
import RecentAttemptsList from "../../../components/RecentAttemptsList/RecentAttemptsList";
import LoadingSingleQCM from "../../../components/LoadingQcm/LoadingQcm";

export default function SingleSerie() {
  return (
    <SidebarLayout>
      <SingleSerieLayout />
    </SidebarLayout>
  );
}

const SingleSerieLayout = () => {
  const history = useHistory();
  const toast = useToast();
  const headingBreakPoints = useBreakpointValue({
    base: "3xl",
    lg: "xl",
    xl: "2xl",
    "2xl": "3xl",
  });
  const { specialityName, serieId, subjectName } = useParams<{
    specialityName: string;
    subjectName: string;
    serieId: string;
  }>();
  const breadcrumb = [
    {
      title: "Série",
      link: "/series",
      isCurrent: false,
    },
    {
      title: capitalizeText(specialityName),
      link: `/series/${specialityName}`,
      isCurrent: false,
    },
    {
      title: capitalizeText(subjectName),
      link: `/series/${specialityName}/${subjectName}`,
      isCurrent: false,
    },
  ];
  const textColor = useColorModeValue("blue-light", "blue-light");
  const layoutHeight = useBreakpointValue({base: "auto", md: "92vh"});

  const { data, isLoading } = useQuery(
    `serie-${serieId}`,
    () => getSerieById(serieId),
    {
      refetchOnWindowFocus: false,
    }
  );
  const { data: checkAttempt, isLoading: checkAttemptLoading } = useQuery(
    `check-attempt-${serieId}`,
    () => checkQcmAttempt(serieId),
    {
      refetchOnWindowFocus: false,
    }
  );
  const { data: recentAttempts, isLoading: loadingRecentAttempts } = useQuery(
    `recent-attempt-${serieId}`,
    () => getQcmRecentAttempts(serieId),
    {
      refetchOnWindowFocus: false,
      retry: false
    }
  );

  const { mutateAsync: startNewAttempt } = useMutation(createNewAttempt, {
    onError: (err: any) => {
      if(err.response?.status === 403){
        window.location.href = `/connexion?session=invalid`
      }else{
        MyToast({
          toast,
          description: err.response.data.message,
          status: "error",
          title: "Erreur",
        });
      }
    },
    onSuccess: (res: any) => {
      history.push(`/qcm/serie/${serieId}/${res.attemptId}`);
    },
  });

  const start = () => {
    startNewAttempt({ qcmId: serieId, type: "serie" });
  };

  // if (isLoading || checkAttemptLoading) {
  //   return <p>Loading...</p>;
  // }
  if (data && !data.hasOwnProperty("_id")) {
    return <NotFound />;
  }

  return (
    <Grid
      gridTemplateColumns={{ base: "1fr", xl: "1fr 1fr", "2xl": "1fr 0.6fr" }}
      height={layoutHeight}
      flex={1}
      gap={10}
    >
      <GridItem>
        <VStack
          align={"stretch"}
          spacing={6}
          flex={1}
          h={
            isLoading || checkAttemptLoading || loadingRecentAttempts
              ? "unset"
              : "full"
          }
        >
          <BreadCrumb items={breadcrumb} />
          {isLoading || checkAttemptLoading ? (
            <LoadingSingleQCM />
          ) : (
            <Stack spacing={6} justify="space-between" h="full">
              <Stack spacing={6} justify="space-between" flex={{base: 0, md: 1}}>
                <Stack spacing={6}>
                  <Heading
                    as="h1"
                    fontSize={{ base: "20px", md: "25px" }}
                    color={textColor}
                  >
                    {data.university.name} {data.year}
                  </Heading>
                  <Stack
                    direction={{ base: "column", md: "row" }}
                    spacing={{ base: 3, md: 8 }}
                    justify={"space-between"}
                    alignSelf={"stretch"}
                  >
                    <Stack
                      direction={{ base: "row", md: "row" }}
                      justify="space-between"
                      // flex={1}
                      spacing={{ base: 3, md: 8 }}
                    >
                      <HStack>
                        <AnswersIcon />
                        <Text>{data.count} QCM</Text>
                      </HStack>
                      {checkAttempt.attemptId && (
                        <HStack>
                          <ProgressIcon size="22"/>
                          <Text>
                            Progrès: {checkAttempt.questionsAnswered}/
                            {data.count}
                          </Text>
                        </HStack>
                      )}
                    </Stack>
                    {/* {checkAttempt.attemptId && (
                  <HStack alignSelf={{ base: "flex-start", md: "flex-end" }}>
                    <BsClockHistory color="gray" fontSize={"20px"}/>
                    <Clock />
                    <Tooltip
                      label={moment(checkAttempt.updatedAt).fromNow()}
                      transition="all 0.2s 2s"
                      hasArrow
                    >
                      <Text color="gray">
                        {moment(checkAttempt.updatedAt).format(
                          "DD MMMM YYYY - HH:mm"
                        )}
                      </Text>
                    </Tooltip>
                  </HStack>
                )} */}
                  </Stack>
                  {/* <Box bg="lightGrey" p="6" rounded={"1rem"}>
                  <Text>
                    Vestibulum ante ipsum primis in faucibus orci luctus et
                    ultrices posuere cubilia Curae; Donec velit neque, auctor
                    sit amet aliquam vel, ullamcorper sit amet ligula. Donec
                    sollicitudin molestie malesuada. Nulla quis lorem ut libero
                    malesuada feugiat. Vestibulum ac diam sit amet quam vehicula
                    elementum sed sit amet dui. Donec rutrum congue leo eget
                    malesuada.
                  </Text>
                </Box> */}
                  <Stack
                    direction={{ base: "column", md: "row" }}
                    align={{
                      base: "stretch",
                      md: "flex-start",
                      "2xl": "center",
                    }}
                    justify={{base: "flex-start", md: "space-between"}}
                    width="full"
                  >
                    <Button
                      variant={"solid"}
                      bg={"#fae5aa"}
                      color="blue-light"
                      height={{ base: "2.5rem", "2xl": "3rem" }}
                      flexGrow={{ base: 1, md: 0.1 }}
                      _hover={{}}
                      onClick={() =>
                        history.push(`/series/${specialityName}/${subjectName}`)
                      }
                    >
                      Retour
                    </Button>
                    {checkAttempt.attemptId ? (
                      <Stack
                        direction={{ base: "column-reverse", "2xl": "row" }}
                      >
                        <Button variant={"secondary"} onClick={start}>
                          Recommencer à zéro
                        </Button>
                        {checkAttempt.allowRetry &&
                          checkAttempt.questionsAnswered >= data.count && (
                            <Button
                              variant={"secondary"}
                              onClick={() =>
                                history.push(
                                  `/qcm/serie/${serieId}/${checkAttempt.attemptId}?retry=true`
                                )
                              }
                            >
                              Refaire les questions ratées
                            </Button>
                          )}
                        <Button
                          variant={"primary"}
                          onClick={() =>
                            history.push(
                              `/qcm/serie/${serieId}/${checkAttempt.attemptId}${
                                checkAttempt.questionsAnswered >= data.count
                                  ? "?view=true"
                                  : ""
                              }`
                            )
                          }
                        >
                          {checkAttempt.questionsAnswered >= data.count
                            ? "Voir les questions"
                            : "Continuer"}
                        </Button>
                      </Stack>
                    ) : (
                      <Button variant={"secondary"} onClick={start}>
                        Commencer
                      </Button>
                    )}
                  </Stack>
                </Stack>
                {recentAttempts?.length !== 0 && (
                  <RecentAttemptsList
                    isLoading={loadingRecentAttempts}
                    data={recentAttempts}
                  />
                )}
              </Stack>
            </Stack>
          )}
          {/* {recentAttempts?.length !== 0 && (
            <RecentAttemptsList
              isLoading={loadingRecentAttempts}
              data={recentAttempts}
            />
          )} */}
        </VStack>
      </GridItem>
      <GridItem w={"full"} display={{ base: "none", xl: "block" }}>
        <VStack
          height="100%"
          w="full"
          p="10"
          bg="light-blue-light"
          rounded={"1rem"}
          align="center"
          justify={"space-around"}
          spacing={10}
        >
          <Heading textAlign={"left"} size={headingBreakPoints}>
              Don't stress, do your{" "}
              <span style={{ color: "#4B62F0" }}>best</span>
            </Heading>
          <Image alt={"Série Illustration"} src={illustration} width="100%" />
        </VStack>
      </GridItem>
    </Grid>
  );
};
