import { Box, HStack, Stack, useColorModeValue, useRadio, useRadioGroup } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import AllIcon from "../../assets/icons/AllIcon";
import CalendarIcon from "../../assets/icons/CalendarIcon";
import UniversityIcon from "../../assets/icons/UniversityIcon";

// 1. Create a component that consumes the `useRadio` hook
function RadioCard(props: any) {
  const { getInputProps, getCheckboxProps } = useRadio(props);

  const input: any = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box as="label">
      <input {...input} />
      <Box
        {...checkbox}
        cursor="pointer"
        borderRadius="xl"
        bg={useColorModeValue("lightGrey", "dark-blue-light")}
        _checked={{
          bg: "blue-light",
          color: "white",
          borderColor: "teal.600",
        }}
        _focus={{
          boxShadow: "none",
        }}
        p={2}
        pr="4"
        display="flex"
        alignItems={"center"}
        verticalAlign="middle"
        fontWeight={"light"}
      >
        <Box mx="2">
          {input.value === "Année" ? (
            <CalendarIcon isCheked={input.checked} />
          ) : input.value === "Rien" ? (
            <AllIcon isCheked={input.checked} />
          ) : (
            <UniversityIcon isCheked={input.checked} />
          )}
        </Box>
        {props.children}
      </Box>
    </Box>
  );
}

// Step 2: Use the `useRadioGroup` hook to control a group of custom radios.
export default function RadioGroup({
  options,
  name,
  defaultValue,
  setFilters,
}: {
  options: any[];
  name: string;
  defaultValue?: any;
  setFilters: any;
}) {
  const history = useHistory();

  const { getRootProps, getRadioProps } = useRadioGroup({
    name,
    defaultValue,
    onChange: (x: any) => {
      setFilters((c: any) => ({ ...c, [name]: x }));
      const params = history.location.search.split("&");
      if (params.find((el) => el.includes(name))) {
        history.push({
          pathname: history.location.pathname,
          search:
            params.length > 1
              ? params
                  .map((y) => (y.includes(name) ? `${name}=${x}` : y))
                  .join("&")
              : `${name}=${x}`,
        });
      } else {
        history.push({
          pathname: history.location.pathname,
          search:
            params.length === 0
              ? `${name}=${x}`
              : history.location.search + `&${name}=${x}`,
        });
      }
    },
  });

  const group = getRootProps();

  return (
    <Stack {...group} direction={{base: "column", sm:"row"}}>
      {options.map((value) => {
        const radio = getRadioProps({ value });
        return (
          <RadioCard key={value} {...radio}>
            {value}
          </RadioCard>
        );
      })}
    </Stack>
  );
}
