
export default function BookmarkIcon({bookmarked=false}:{bookmarked?:boolean}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g id="save-2" transform="translate(0)">
        <rect
          id="Rectangle_85"
          data-name="Rectangle 85"
          width="24"
          height="24"
          rx="5"
          transform="translate(0)"
          fill={bookmarked ? "white" : "#4b62f0"}
          opacity="0"
        />
        <g
          id="Group_148"
          data-name="Group 148"
          transform="translate(2.185 2.334)"
        >
          <path
            id="Path_95"
            data-name="Path 95"
            d="M2.97,20.475a1.763,1.763,0,0,1-.909-.243,2.125,2.125,0,0,1-.945-1.926V8.082A3.476,3.476,0,0,1,4.59,4.608h7a3.476,3.476,0,0,1,3.474,3.474V18.306a2.135,2.135,0,0,1-.945,1.926,2.14,2.14,0,0,1-2.142-.108L8.442,18.153a.867.867,0,0,0-.7,0L4.2,20.124a2.524,2.524,0,0,1-1.233.351ZM4.6,5.967A2.128,2.128,0,0,0,2.475,8.091V18.315a.875.875,0,0,0,.279.756.892.892,0,0,0,.8-.126l3.537-1.971a2.269,2.269,0,0,1,2.016,0l3.537,1.971a.89.89,0,0,0,.8.126.875.875,0,0,0,.279-.756V8.091A2.128,2.128,0,0,0,11.6,5.967Z"
            transform="translate(-1.116 -1.134)"
            fill={bookmarked ? "white" : "#4b62f0"}
          />
          <path
            id="Path_96"
            data-name="Path 96"
            d="M13.221,20.475a2.637,2.637,0,0,1-1.233-.342L8.451,18.162a.97.97,0,0,0-.711,0L4.212,20.133a2.14,2.14,0,0,1-2.142.108,2.119,2.119,0,0,1-.945-1.917V8.1A3.476,3.476,0,0,1,4.6,4.626h7A3.476,3.476,0,0,1,15.075,8.1V18.324a2.119,2.119,0,0,1-.945,1.917A1.822,1.822,0,0,1,13.221,20.475ZM8.1,16.74a2.112,2.112,0,0,1,1.008.243l3.537,1.971a.857.857,0,0,0,.8.117.875.875,0,0,0,.279-.756V8.091A2.128,2.128,0,0,0,11.6,5.967h-7A2.128,2.128,0,0,0,2.475,8.091V18.315a.875.875,0,0,0,.279.756.892.892,0,0,0,.8-.126l3.537-1.971A2.189,2.189,0,0,1,8.1,16.74Z"
            transform="translate(-1.116 -1.134)"
            fill={bookmarked ? "white" : "#4b62f0"}
          />
          <path
            id="Path_97"
            data-name="Path 97"
            d="M18.621,16.983a2.637,2.637,0,0,1-1.233-.342l-3.321-1.854a.659.659,0,0,1-.342-.585V8.1A2.128,2.128,0,0,0,11.6,5.976H7.2A.68.68,0,0,1,6.525,5.3V4.608A3.476,3.476,0,0,1,10,1.134h7a3.476,3.476,0,0,1,3.474,3.474V14.832a2.119,2.119,0,0,1-.945,1.917A1.822,1.822,0,0,1,18.621,16.983ZM15.075,13.8l2.97,1.665a.855.855,0,0,0,.8.117.862.862,0,0,0,.279-.756V4.6A2.128,2.128,0,0,0,17,2.475H10A2.128,2.128,0,0,0,7.875,4.6v.018H11.6a3.476,3.476,0,0,1,3.474,3.474V13.8Z"
            transform="translate(-1.116 -1.134)"
            fill={bookmarked ? "white" : "#4b62f0"}
            opacity="0.5"
          />
        </g>
      </g>
    </svg>
  );
}
