import {
  Button,
  Grid,
  GridItem,
  Heading,
  HStack,
  Icon,
  Spinner,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { FormProvider } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import {
  serilize,
  SpecialitiesDropDown,
} from "../../pages/Unauthorized/Onboarding/steps/OnboardingFirstStep";
import { resetAccount } from "../../services/profile/services";
import { IInputField } from "../../types";
import Dialog from "../Dialog/Dialog";
import Field from "../Field/Field";
import { SelectField } from "../Form";
import MyToast from "../Toast/Toast";

export default function ProfileBlock({
  fields,
  title,
  block,
  icon,
  methods,
  onSubmit,
  updateAccountLoading,
  changePasswordLoading,
  selectfields,
  universities,
  status,
  promotions,
}: any) {
  const selectFieldsList = [
    {
      type: "select",
      name: "university",
      placeholder: "Votre faculté",
      options: selectfields ? serilize(universities) : [],
    },
    {
      type: "select",
      name: "status",
      placeholder: "Statut",
      options: selectfields ? serilize(status) : [],
    },
    {
      type: "select",
      name: "promotion",
      placeholder: "Votre promotion",
      options: selectfields ? serilize(promotions) : [],
    },
  ];

  return (
    <Stack
      bg={block === "reset-account" ? "red.50" : "lightGrey"}
      p={{ base: 4, "2xl": "8" }}
      borderRadius={"1rem"}
      mt="6"
      as="form"
      onSubmit={methods.handleSubmit((x: any) => onSubmit(x, block))}
      spacing={4}
      border={block === "reset-account" ? "1px solid" : "none"}
      borderColor={block === "reset-account" ? "red.200" : "none"}
    >
      <HStack spacing={4} align={"center"}>
        <Icon
          as={icon}
          fontSize={block === "reset-account" ? "24px" : "inherit"}
          css={{ "& > path": { stroke: "#4B62F0" } }}
        />
        <Heading
          fontSize={{ base: "lg", xl: "xl", "2xl": "2xl" }}
          color="blue-light"
        >
          {title}
        </Heading>
      </HStack>
      {block === "reset-account" ? (
        <ResetAccountBlock />
      ) : (
        <>
          <FormProvider {...methods}>
            {fields.map((field: IInputField) => (
              <Grid
                templateColumns={
                  block === "account"
                    ? { base: "1fr", "2xl": "0.2fr 1fr" }
                    : { base: "1fr", "2xl": "0.2fr 1fr" }
                }
                gap={{ base: 2, "2xl": 6 }}
                alignItems={"center"}
                key={field.name}
              >
                <Text>{field.placeholder}</Text>
                <Field
                  value={field.name === "role" ? methods.getValues("role") : ""}
                  {...field}
                  bg="darkGrey"
                  key={field.name}
                />
              </Grid>
            ))}
            {selectfields &&
              selectFieldsList.map((field: any) => (
                <Grid
                  templateColumns={
                    block === "account"
                      ? { base: "1fr", "2xl": "0.2fr 1fr" }
                      : { base: "1fr", "2xl": "0.2fr 1fr" }
                  }
                  gap={{ base: 2, "2xl": 6 }}
                  alignItems={"center"}
                  key={field.name}
                >
                  <Text>{field.placeholder}</Text>
                  <SelectField
                    key={field.name}
                    bg="darkGrey"
                    options={field.options}
                    placeholder={field.placeholder}
                    name={field.name}
                    bg_hover="darkGrey"
                    value={methods.getValues(field.name)}
                    height={field.height}
                  />
                </Grid>
              ))}
            <Grid
              templateColumns={
                block === "account"
                  ? { base: "1fr", "2xl": "0.2fr 1fr" }
                  : { base: "1fr", "2xl": "0.2fr 1fr" }
              }
              gap={{ base: 2, "2xl": 6 }}
              alignItems={"center"}
            >
              <GridItem>
                <Text>Specialité</Text>
              </GridItem>
              <GridItem>
                <SpecialitiesDropDown methods={methods} bg_hover="darkGrey" bg="darkGrey"/>
              </GridItem>
            </Grid>
          </FormProvider>
          <Grid
            templateColumns={
              block === "account"
                ? { base: "1fr", "2xl": "0.2fr 1fr" }
                : { base: "1fr", "2xl": "0.2fr 1fr" }
            }
            gap={{ base: 2, "2xl": 6 }}
            alignItems={"center"}
          >
            <Text></Text>
            <Dialog
              question="Êtes-vous sûr?"
              description={
                block === "reset-account"
                  ? "Êtes-vous sûr de réinitialiser les données de votre compte?"
                  : "Êtes-vous sûr de modifier les données de votre compte?"
              }
              isLoading={updateAccountLoading || changePasswordLoading}
              onClick={methods.handleSubmit((x: any) => onSubmit(x, block))}
            >
              <Button
                //   my="4"
                variant={"primary"}
                alignSelf={{ base: "center", md: "flex-start" }}
                disabled={updateAccountLoading || changePasswordLoading}
                px="10"
              >
                {block === "account" && updateAccountLoading ? (
                  <Spinner size={"md"} color="gold-yellow" />
                ) : (
                  block === "account" && "Sauvegarder"
                )}
                {block === "security" && changePasswordLoading ? (
                  <Spinner size={"md"} color="gold-yellow" />
                ) : (
                  block === "security" && "Sauvegarder"
                )}
              </Button>
            </Dialog>
          </Grid>
        </>
      )}
    </Stack>
  );
}

const ResetAccountBlock = () => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const { mutateAsync, isLoading } = useMutation(resetAccount, {
    onSuccess: () => {
      queryClient.invalidateQueries(`series-stats`);
      queryClient.invalidateQueries(`exams-stats`);
      queryClient.invalidateQueries(`stats-Examen`);
      queryClient.invalidateQueries(`stats-Série`);
      queryClient.invalidateQueries(`stats-analyse-Série`);
      queryClient.invalidateQueries(`stats-analyse-Examen`);
      queryClient.invalidateQueries(`moyenne-Série`);
      queryClient.invalidateQueries(`moyenne-Examen`);
      MyToast({
        toast,
        status: "success",
        title: "Compte réinitialisé",
      });
    },
  });
  return (
    <Stack
      direction={{ base: "column", md: "row" }}
      justify={"space-between"}
      align={{ base: "flex-start", md: "center" }}
    >
      <Stack flex={1}>
        <Text>
          Une fois que vous avez réinitialisé votre compte, il n'y a plus de
          retour en arrière. Toutes les séries, les examens terminées et les
          statistiques seront perdues.
        </Text>
        <Text>Soyez certain.</Text>
      </Stack>
      <Dialog
        question="Êtes-vous sûr?"
        description={
          "Êtes-vous sûr de réinitialiser les données de votre compte?"
        }
        isLoading={isLoading}
        onClick={() => mutateAsync()}
      >
        <Button colorScheme={"red"} flex={1}>
          Réinitialiser
        </Button>
      </Dialog>
    </Stack>
  );
};
