import {
  Button,
  FormControl,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { ChangeEvent, FormEvent } from "react";
import VoucherIcon from "../../../../assets/icons/VoucherIcon";

const VoucherForm = ({
  onSubmit,
  isError,
  handleChange,
  voucher,
  isLoading,
}: {
  onSubmit: (e: FormEvent<HTMLDivElement>) => void;
  isError: boolean;
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
  voucher: string;
  isLoading: boolean;
}) => {
  const mtBreakPoint = useBreakpointValue({ base: "10", md: "20" });

  return (
    <>
      <Stack alignItems={"center"} mt={mtBreakPoint}>
        <Heading color="darkSky" textAlign={"center"}>
          Appliquer le code du coupon
        </Heading>
        <Text textAlign={"center"} maxW="md">
          Entrez le code du coupon que vous avez reçu ci-dessous pour activer
          votre abonnement.
        </Text>
      </Stack>
      <Stack as="form" alignItems="center" onSubmit={onSubmit}>
        <Stack
          width={useBreakpointValue({ base: "full", md: "60%" })}
          minWidth="200px"
          maxWidth={"600px"}
          spacing="4"
        >
          <FormControl isInvalid={isError}>
            <InputGroup>
              <InputLeftElement
                pointerEvents="none"
                children={<VoucherIcon />}
                h={"full"}
              />
              <Input
                bg={isError ? "red.50" : "white"}
                type={"text"}
                variant={"primary"}
                placeholder={"Le code du coupon"}
                size={"lg"}
                borderRadius={"8px"}
                fontWeight={"normal"}
                _placeholder={{
                  fontWeight: "light",
                  color: "#bbb",
                  fontSize: { base: "0.9rem", "2xl": "1rem" },
                }}
                borderWidth={"1px"}
                borderColor={isError ? "red.500" : "#ddd"}
                height={{ base: "2.5rem", "2xl": "3rem" }}
                onChange={handleChange}
                value={voucher}
                maxLength={19}
              />
            </InputGroup>
          </FormControl>
          <Button
            type="submit"
            alignSelf={"flex-end"}
            minWidth="150px"
            height="2.8rem"
            variant={"tertiary"}
            isLoading={isLoading}
          >
            Soumettre
          </Button>
        </Stack>
      </Stack>
    </>
  );
};

export default VoucherForm;
