import { useRef } from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
} from "@chakra-ui/react";
import PlaylistModeGroup from "./RadioGroup";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  createQcm: (subjectId: string) => void;
  isCreatingQcm: boolean;
  playlistMode: string;
  setPlaylistMode: (mode: string) => void;
  start: () => void;
}

const PlaylistModeSelectorDialog = ({
  isOpen,
  onClose,
  isCreatingQcm,
  playlistMode,
  setPlaylistMode,
  start,
}: Props) => {
  const cancelRef = useRef<HTMLButtonElement>(null);

  const handleCloseDialog = () => {
    if (!isCreatingQcm) {
      setPlaylistMode("");
      onClose();
    }
  };

  return (
    <>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={handleCloseDialog}
        isOpen={isOpen}
        isCentered
        closeOnOverlayClick={!isCreatingQcm}
      >
        <AlertDialogOverlay />
        <AlertDialogContent rounded="1rem" maxW={"md"}>
          <AlertDialogHeader>Sélectionnez le mode</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            <PlaylistModeGroup
              onChange={(x: string) => setPlaylistMode(x)}
              playlistMode={playlistMode}
              disabled={isCreatingQcm}
            />
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              variant={"secondary"}
              ref={cancelRef}
              onClick={handleCloseDialog}
              disabled={isCreatingQcm}
            >
              Annuler
            </Button>
            <Button
              variant={"primary"}
              ml={3}
              disabled={!playlistMode}
              onClick={start}
              isLoading={isCreatingQcm}
            >
              Commencer
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default PlaylistModeSelectorDialog;
