import {
  Avatar,
  Box,
  Button,
  Image,
  keyframes,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useBreakpointValue,
  useDisclosure,
} from "@chakra-ui/react";
import { createContext, useContext, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { Redirect } from "react-router-dom";
import Loading from "../components/Loading";
import { getAnnounces, setReadAnnounce } from "../services/announces/services";
import { loadUser } from "../services/auth/services";
import { AuthContextType, AuthProviderProps, UseQueryDataType } from "../types";

const auth = createContext<AuthContextType>({} as AuthContextType);

export const useAuthProvider = () => useContext(auth);

const pulseRing = keyframes`
0% {
  transform: scale(0.33);
}
40%,
50% {
  opacity: 0;
}
100% {
  opacity: 0;
}
`;

const AuthProvider = ({ children }: AuthProviderProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isLoading, data, isSuccess, remove, isError, error } = useQuery<
    UseQueryDataType | undefined
  >(["user"], loadUser, {
    refetchOnWindowFocus: false,
    refetchOnReconnect: true,
    enabled: Boolean(localStorage.getItem("access_token")),
    retry: false,
  });
  const { data: announces } = useQuery("announces", getAnnounces, {
    refetchOnWindowFocus: false,
    retry: false,
    enabled: Boolean(localStorage.getItem("access_token")),
    refetchOnReconnect: true,
    onSuccess: (response) => {
      if (response?.length !== 0) {
        onOpen();
      }
    },
  });
  if (isLoading) {
    return (
      <Stack align={"center"} justify="center" h="100vh">
        <Loading />
      </Stack>
    );
  }
  if (isSuccess) {
    // if(data?.email_verified){
    return (
      <auth.Provider value={{ data, remove }}>
        {children}
        {announces?.length !== 0 && (
          <AnnouncementsModal
            onClose={onClose}
            isOpen={isOpen}
            announces={announces}
          />
        )}
      </auth.Provider>
    );
    // }
    // return <Redirect to="/confirmation-email" exact/>;
  }

  if (
    !localStorage.getItem("access_token") ||
    (isError && (error as any)?.response?.status === 401) ||
    (error as any)?.response?.status === 403
  ) {
    localStorage.removeItem("access_token");
    return <Redirect to="/connexion" />;
  }

  return <h1>Error</h1>;
};

export default AuthProvider;

function AnnouncementsModal({
  onClose,
  isOpen,
  announces,
}: {
  onClose: () => void;
  isOpen: boolean;
  announces: any;
}) {
  const [indexAnnounce, setIndexAnnounce] = useState(0);
  const { mutateAsync, isLoading } = useMutation(setReadAnnounce);
  const modalSizeBp = useBreakpointValue({base: "xs", md: "xl"})

  const setToReadAndClose = async () => {
    await mutateAsync(announces?.[indexAnnounce]?._id);
    onClose();
  };
  const setToReadAndGoNext = async () => {
    await mutateAsync(announces?.[indexAnnounce]?._id);
    setIndexAnnounce(indexAnnounce + 1);
  };

  return (
    <>
      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        closeOnEsc={false}
        size={modalSizeBp}
      >
        <ModalOverlay />
        <ModalContent rounded="1rem">
          <ModalHeader>{announces?.[indexAnnounce]?.title}</ModalHeader>
          <hr />
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Text>{announces?.[indexAnnounce]?.content}</Text>
          </ModalBody>

          <ModalFooter>
            {announces?.length === indexAnnounce + 1 && (
              <Button
                variant="primary"
                minW={"100px"}
                onClick={setToReadAndClose}
                isLoading={isLoading}
              >
                Ok
              </Button>
            )}
            {indexAnnounce + 1 < announces?.length && (
              <Button
                variant="primary"
                minW={"100px"}
                onClick={setToReadAndGoNext}
                isLoading={isLoading}
              >
                Suivant
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
