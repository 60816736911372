import React, { createContext, useState, useContext, ReactNode } from "react";

// Create a context
interface PlanContextType {
  plan: string;
  updatePlan: (newPlan: string) => void;
}

const PlanContext = createContext<PlanContextType | undefined>(undefined);

// Create a Provider component
export const PlanProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [plan, setPlan] = useState<string>(""); // Initial plan value

  // You can define functions to update the plan here
  const updatePlan = (newPlan: string) => {
    setPlan(newPlan);
  };

  return (
    <PlanContext.Provider value={{ plan, updatePlan }}>
      {children}
    </PlanContext.Provider>
  );
};

const usePlan = (): PlanContextType => {
  const context = useContext(PlanContext);

  if (!context) {
    throw new Error("usePlan must be used within a PlanProvider");
  }

  return context;
};

export default usePlan;
