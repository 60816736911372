import { useHistory } from "react-router-dom";

export default function MyListIcon({ color = "#ffffff"}: {color: string}) {
  const { location } = useHistory()

  return (
    <svg
      id="note-21"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <rect
        id="Rectangle_24"
        data-name="Rectangle 24"
        width="24"
        height="24"
        rx="5"
        fill={location.pathname.includes("/playlist") ? "#4B62F0" : color}
        opacity="0"
      />
      <g id="Group_25" data-name="Group 25" transform="translate(1.2 1.2)">
        <path
          id="Path_38"
          data-name="Path 38"
          d="M14.4,20.475H7.2A3.89,3.89,0,0,1,2.925,16.2V7.425C2.925,4.59,4.365,3.15,7.2,3.15a.68.68,0,0,1,.675.675,1.349,1.349,0,0,0,1.35,1.35h3.15a1.348,1.348,0,0,0,1.35-1.35A.68.68,0,0,1,14.4,3.15c2.835,0,4.275,1.44,4.275,4.275V16.2A3.89,3.89,0,0,1,14.4,20.475ZM6.615,4.518c-1.422.117-2.34.756-2.34,2.907V16.2c0,2,.927,2.925,2.925,2.925h7.2c2,0,2.925-.927,2.925-2.925V7.425c0-2.151-.918-2.781-2.34-2.907a2.705,2.705,0,0,1-2.61,2.007H9.225a2.675,2.675,0,0,1-1.908-.792,2.719,2.719,0,0,1-.7-1.215Z"
          fill={location.pathname.includes("/playlist") ? "#4B62F0" : color}
        />
        <path
          id="Path_39"
          data-name="Path 39"
          d="M12.375,6.525H9.225a2.7,2.7,0,0,1-2.7-2.7,2.708,2.708,0,0,1,2.7-2.7h3.15a2.7,2.7,0,0,1,2.7,2.7A2.708,2.708,0,0,1,12.375,6.525Zm-3.15-4.05a1.35,1.35,0,0,0,0,2.7h3.15a1.35,1.35,0,0,0,0-2.7Z"
          fill={location.pathname.includes("/playlist") ? "#4B62F0" : color}
          />
        <path
          id="Path_40"
          data-name="Path 40"
          d="M10.8,12.375H7.2a.675.675,0,0,1,0-1.35h3.6a.675.675,0,0,1,0,1.35Z"
          fill={location.pathname.includes("/playlist") ? "#F7D372" : color}
          />
        <path
          id="Path_41"
          data-name="Path 41"
          d="M14.4,15.975H7.2a.675.675,0,1,1,0-1.35h7.2a.675.675,0,1,1,0,1.35Z"
          fill={location.pathname.includes("/playlist") ? "#F7D372" : color}
        />
      </g>
    </svg>
  );
}
