import axiosInstance from "../../utils/axiosInstance";

export const createCustomQcm = async (subjectId: string) => {
    let token = localStorage.getItem("access_token");
    return await axiosInstance({
      method: "get",
      url: `/custom-qcm/${subjectId}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((res: any) => res.data);
  };