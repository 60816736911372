
export default function AnswerRightSquareIcon() {
  return (
    <svg
      id="tick-square"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <rect
        id="Rectangle_1084"
        data-name="Rectangle 1084"
        width="24"
        height="24"
        rx="5"
        fill="#fff"
        opacity="0"
      />
      <g id="Group_1251" data-name="Group 1251" transform="translate(1.2 1.2)">
        <path
          id="Path_255"
          data-name="Path 255"
          d="M13.5,20.475H8.1c-4.887,0-6.975-2.088-6.975-6.975V8.1c0-4.887,2.088-6.975,6.975-6.975h5.4c4.887,0,6.975,2.088,6.975,6.975v5.4C20.475,18.387,18.387,20.475,13.5,20.475Zm-5.4-18c-4.149,0-5.625,1.476-5.625,5.625v5.4c0,4.149,1.476,5.625,5.625,5.625h5.4c4.149,0,5.625-1.476,5.625-5.625V8.1c0-4.149-1.476-5.625-5.625-5.625Z"
          fill="#fff"
        />
        <path
          id="Path_256"
          data-name="Path 256"
          d="M9.522,14.022a.675.675,0,0,1-.477-.2L6.5,11.277a.675.675,0,0,1,.954-.954l2.07,2.07,4.626-4.626a.675.675,0,1,1,.954.954l-5.1,5.1a.675.675,0,0,1-.477.2Z"
          fill="#fff"
        />
      </g>
    </svg>
  );
}
