import { HStack, Tooltip, IconButton, Divider } from "@chakra-ui/react";
import React from "react";
import AnswersIcon from "../../assets/icons/AnswersIcon";
import FilterIcon from "../../assets/icons/FilterIcon";
import PercentageIcon from "../../assets/icons/PercentageIcon";

type StatsFormat = "average" | "progress";

export default function SeriesFilterButtons({
  statsFormat,
  setStatsFormat,withFilter = true
}: {
  statsFormat: StatsFormat;
  setStatsFormat: (format: StatsFormat) => void;
  withFilter?:boolean
}) {
  return (
    <HStack>
      <Tooltip label="Afficher moyenne" hasArrow>
        <IconButton
          aria-label="afficher moyenne"
          icon={
            <PercentageIcon
              size="24"
              bg={statsFormat === "average" ? "white" : "black"}
            />
          }
          bg={statsFormat === "average" ? "blue-light" : "lightGrey"}
          _hover={{}}
          _focus={{}}
          _active={{}}
          onClick={() => setStatsFormat("average")}
          sx={{
            "&:hover *": {
              fill: statsFormat === "average" ? "white" :  "blue-light",
            },
          }}
        />
      </Tooltip>
      <Tooltip label="Afficher l'avancement" hasArrow>
        <IconButton
          aria-label="afficher l'avancement"
          icon={
            <AnswersIcon
              color={statsFormat === "progress" ? "white" : "black"}
            />
          }
          bg={statsFormat === "progress" ? "blue-light" : "lightGrey"}
          _hover={{}}
          _focus={{}}
          _active={{}}
          onClick={() => setStatsFormat("progress")}
          sx={{
            "&:hover *": {
              fill: statsFormat === "progress" ? "white" : "blue-light",
            },
          }}
        />
      </Tooltip>
      {/* {
        withFilter && <>
        <Divider
          orientation="vertical"
          h="20px"
          borderWidth={"2px"}
          rounded="xl"
        />
        <Tooltip label="Filtrer" hasArrow>
          <IconButton aria-label="filtrer" icon={<FilterIcon />} bg="lightGrey" />
        </Tooltip>
        
        </>
      } */}
    </HStack>
  );
}
