import {
  TabPanel,
  TabPanels,
  Tabs,
  useBreakpointValue,
  useToast,
} from "@chakra-ui/react";
import { useMutation, useQuery } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import ErrorBanner from "../../../components/ErrorBanner/ErrorBanner";
import MyToast from "../../../components/Toast/Toast";
import SidebarLayout from "../../../layouts/SidebarLayout";
import {
  getOneAttempt,
  resumeAttempt,
  submitAnswer,
} from "../../../services/qcm/services";
import { useCallback, useEffect, useRef, useState } from "react";
import QcmHeader from "../../../components/QcmHeader/QcmHeader";
import QcmBody from "../../../components/QcmBody/QcmBody";
import { useTimer } from "../../../timer-provider/TimerProvider";
import useQueryParams from "../../../hooks/useQueryParams";
import { useTimer as useReactTimer } from "react-use-precision-timer";
import LoadingQuestions from "../../../components/LoadingQuestions"

export default function Qcm() {
  const [tabIndex, setTabIndex] = useState(0);
  const [selectedOptions, setSelectedOptions] = useState<any[]>([]);
  const [correction, setCorrection] = useState<any[]>([]);
  const [answers, setAnswers] = useState<any[]>([]);
  const [counterIsRunning, setCounterIsRunning] = useState(true);
  const [isFixed, setisFixed] = useState(true);
  const { startInitialTimer } = useTimer();
  const qcmBodyRef = useRef<any>();
  const { attemptId, qcmId } = useParams<{
    type: string;
    qcmId: string;
    attemptId: string;
  }>();
  const toast = useToast();
  const history = useHistory()
  const headerRef = useRef<any>();
  const containerRef = useRef<any>();
  const scrollValue: number | undefined = useBreakpointValue({
    base: 66,
    md: 76,
  });
  let queryParams: any = useQueryParams();
  const timer = useReactTimer({delay: 0, startImmediately: false});

  const {
    data: questions,
    isLoading: loadingAttempt,
    isError: isErrorLoadingAttempt,
    error: errorLoadingAttempt,
  } = useQuery(
    `questions-${attemptId}`,
    () => getOneAttempt(attemptId, queryParams.get("retry")),
    {
      refetchOnWindowFocus: false,
      retry: false,
      onError: (err: any) => {
        MyToast({
          toast,
          description: err.response.data.message,
          status: "error",
          title: "Erreur",
        });
      },
    }
  );
  const {
    data: lastAttempt,
    isLoading,
    isSuccess,
  } = useQuery(`resume-attempt-${qcmId}`, () => resumeAttempt(attemptId), {
    refetchOnWindowFocus: false,
    retry: false,
    onError: (err: any) => {
      MyToast({
        toast,
        description: err.response.data.message,
        status: "error",
        title: "Erreur",
      });
      history.goBack()
    },
    onSuccess: (response: any) => {
      startInitialTimer(response?.duration || 0);
      if (queryParams.get("retry")) {
        setTabIndex(0);
      } else if(queryParams.get("view") === "true"){
        setTabIndex(0);
        setAnswers([...answers, ...response.questions]);
        setSelectedOptions([...selectedOptions, ...response.selectedOptions]);
      } else {
        setAnswers([...answers, ...response.questions]);
        setSelectedOptions([...selectedOptions, ...response.selectedOptions]);
        setTabIndex(response?.last_question ?? 0);
        timer.start();
      }
      // scrollRight((scrollValue ?? 1) * response?.last_question)
    },
  });

  useEffect(() => {
    if (headerRef.current) {
      if (!queryParams.get("retry")) {
        scrollRight((scrollValue ?? 1) * lastAttempt?.last_question);
      }
    }
  }, [isSuccess]);

  const scrollRight = (scrollOffset: number) => {
    if (headerRef?.current) {
      headerRef.current.scrollLeft += scrollOffset;
    }
  };

  const select = (questionId: string, optionId: string) => {
    const currentQuestion = selectedOptions.find(
      (question: any) => question.questionId === questionId
    );

    if (currentQuestion) {
      if (currentQuestion.options.includes(optionId)) {
        const index = currentQuestion.options.indexOf(optionId);
        const _currentQuestion = { ...currentQuestion };
        _currentQuestion.options.splice(index, 1);
        setSelectedOptions(
          selectedOptions.map((el: any) =>
            el.questionId === questionId ? _currentQuestion : el
          )
        );
      } else {
        setSelectedOptions(
          selectedOptions.map((el: any) =>
            el.questionId === questionId
              ? { ...el, options: [...el.options, optionId] }
              : el
          )
        );
      }
    } else {
      setSelectedOptions([
        ...selectedOptions,
        { questionId, options: [optionId] },
      ]);
    }
  };

  const generateCorrections = () => {
    for (const answer of answers) {
      for (const questionAnswer of answer.questions) {
        const correctAndNotSelected: any = [];
        const correctAndSelected: any = [];
        const wrongAndSelected: any = [];
        const wrongNotSelected: any = [];
        const questionSelectedOptions = selectedOptions.find(
          (el: any) => el.questionId === questionAnswer.questionId
        );

        // loopi al questionAnswer options, and check if the option's id exist in the selectedOptions.options array
        // if don't exist push the questionAnswer option object to correctNotSelectedOptions
        questionAnswer.options.forEach((option: any) => {
          const isCorrectSelected = questionSelectedOptions?.options.includes(
            option._id
          );

          if (!isCorrectSelected) {
            correctAndNotSelected.push(option);
          }
        });

        // loopi al questionAnswer wrongOptions, and check if the option's id exist in the selectedOptions.options array
        // if don't exist push the questionAnswer wrongOption object to wrongNotSelected
        questionAnswer.wrongOptions.forEach((option: any) => {
          const isWrongSelected = questionSelectedOptions?.options.includes(
            option._id
          );

          if (!isWrongSelected) {
            wrongNotSelected.push(option);
          }
        });

        // loopi al selectedOptions.options, and check if the option's exist or not
        // if exist, push the object in correctSelectedOptions
        // if not exist, push the object to wrongSelectedOptions
        questionSelectedOptions?.options.forEach((optionSelectedId: any) => {
          const isItSelectedOption = questionAnswer.options.find(
            (option: any) => option._id === optionSelectedId
          );

          if (isItSelectedOption) {
            correctAndSelected.push(isItSelectedOption);
          } else {
            const wrongOpt = questionAnswer.wrongOptions.find(
              (opt: any) => opt._id === optionSelectedId
            );
            wrongAndSelected.push(wrongOpt);
          }
        });

        const questionCorrection = {
          questionId: questionAnswer.questionId,
          correction: {
            correctNotSelectedOptions: correctAndNotSelected,
            correctSelectedOptions: correctAndSelected,
            wrongSelectedOptions: wrongAndSelected,
            wrongNotSelected: wrongNotSelected,
          },
        };
        setCorrection((prevState) => [...prevState, questionCorrection]);
      }
    }
  };

  useEffect(() => {
    generateCorrections();
  }, [answers]);

  const { mutateAsync: submit, isLoading: submissionLoading } =
    useMutation(submitAnswer);

  // useEffect(() => {
  //   if(containerRef.current){
  //     window.addEventListener("beforeunload", (e: any) => {
  //       e.returnValue = ''
  //     })
  //   }
  // }, [containerRef])

  const handleChangeIndex = useCallback((index: number) => {
    setTabIndex(index);
  }, []);

  // if (loadingAttempt || isLoading) {
  //   return <p>Loading...</p>;
  // }

  if (loadingAttempt || isLoading) {
    return <LoadingQuestions />;
  }

  const goToTop = () => {
    setisFixed(false);
    containerRef.current.scrollIntoView();
    setisFixed(true);
  };
  return (
    <SidebarLayout withoutPadding>
      {/* <IconButton
        as={FiChevronLeft}
        aria-label="question suivante"
        onClick={() => scrollRight(-200)}
        size="xs"
        cursor={"pointer"}
        variant="outline"
        rounded={"full"}
      />
      <IconButton
        as={FiChevronRight}
        aria-label="question précedente"
        onClick={() => scrollRight(200)}
        size="xs"
        cursor={"pointer"}
        variant="outline"
        rounded={"full"}
      /> */}
      <Tabs
        isFitted
        variant={"unstyled"}
        onChange={queryParams.get("view") && handleChangeIndex}
        index={tabIndex}
        isLazy
        ref={containerRef}
      >
        <QcmHeader
          questions={questions.questions}
          headerRef={headerRef}
          answers={answers}
          setCounterIsRunning={setCounterIsRunning}
          setisFixed={setisFixed}
          isFixed={isFixed}
          tabIndex={tabIndex}
          scrollRight={scrollRight}
          scrollValue={scrollValue}
          isViewMode={queryParams.get("view") === "true"}
          isCustomQcm={questions?.isCustomQcm}
        />

        <TabPanels p={{ base: "2", "2xl": "8" }} pt={{ "2xl": "2" }}>
          {questions.questions.map((question: any, index: number) => (
            <TabPanel key={index} tabIndex={question._id}>
              <QcmBody
                question={question}
                questions={questions}
                setTabIndex={setTabIndex}
                scrollRight={scrollRight}
                tabIndex={tabIndex}
                submit={submit}
                submissionLoading={submissionLoading}
                answers={answers}
                answered={answers.find(
                  (el: any) => el.question === question._id && question.isParent
                )}
                counterIsRunning={counterIsRunning}
                select={select}
                selectedOptions={selectedOptions}
                correction={correction}
                setAnswers={setAnswers}
                goToTop={goToTop}
                timeSpentOnQuestion={Math.floor(timer.getElapsedRunningTime() / 1000)}
                pauseTimeSpentOnQuestion={() => timer.pause()}
                resumeTimeSpentOnQuestion={() => timer.start()}
              />
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>

      {isErrorLoadingAttempt && (
        <ErrorBanner description={errorLoadingAttempt?.response.data.message} />
      )}
    </SidebarLayout>
  );
}
