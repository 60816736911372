import { useHistory } from "react-router-dom";

export default function PerformanceIcon() {
  const { location } = useHistory()

  return (
    <svg
      id="favorite-chart"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <rect
        id="Rectangle_22"
        data-name="Rectangle 22"
        width="24"
        height="24"
        rx="5"
        fill={location.pathname.includes("/performance") ? "#4B62F0" : "#fff"}
        opacity="0"
        />
      <g id="Group_23" data-name="Group 23" transform="translate(1.2 1.2)">
        <path
          id="Path_31"
          data-name="Path 31"
          d="M11.7,20.475H8.1c-4.887,0-6.975-2.088-6.975-6.975V8.1c0-4.887,2.088-6.975,6.975-6.975h5.4c4.887,0,6.975,2.088,6.975,6.975v3.6a.675.675,0,1,1-1.35,0V8.1c0-4.149-1.476-5.625-5.625-5.625H8.1c-4.149,0-5.625,1.476-5.625,5.625v5.4c0,4.149,1.476,5.625,5.625,5.625h3.6a.675.675,0,1,1,0,1.35Z"
          fill={location.pathname.includes("/performance") ? "#4B62F0" : "#fff"}
          />
        <path
          id="Path_32"
          data-name="Path 32"
          d="M6.6,13.716a.662.662,0,0,1-.414-.144.674.674,0,0,1-.126-.945L8.2,9.846a1.582,1.582,0,0,1,1.053-.6,1.561,1.561,0,0,1,1.17.324l1.647,1.3a.224.224,0,0,0,.171.045.2.2,0,0,0,.153-.09l2.079-2.682a.673.673,0,1,1,1.062.828l-2.079,2.682a1.577,1.577,0,0,1-2.214.27l-1.647-1.3a.23.23,0,0,0-.324.045L7.128,13.446a.637.637,0,0,1-.531.27Z"
          fill={location.pathname.includes("/performance") ? "#4B62F0" : "#fff"}
          />
        <path
          id="Path_33"
          data-name="Path 33"
          d="M18.234,20.475a2.347,2.347,0,0,1-1.2-.387l-.225-.135a.472.472,0,0,0-.315,0l-.225.135a1.7,1.7,0,0,1-1.98.144,1.722,1.722,0,0,1-.486-1.944l.045-.189a.494.494,0,0,0-.081-.3l-.315-.315A1.739,1.739,0,0,1,12.9,15.75a1.722,1.722,0,0,1,1.458-1.08l.342-.054a.52.52,0,0,0,.225-.171l.252-.513a1.732,1.732,0,0,1,1.485-1.116,1.732,1.732,0,0,1,1.485,1.116l.252.5a.52.52,0,0,0,.225.171l.342.054a1.722,1.722,0,0,1,1.458,1.08,1.731,1.731,0,0,1-.558,1.737l-.315.315a.494.494,0,0,0-.081.3l.045.189a1.722,1.722,0,0,1-.486,1.944,1.269,1.269,0,0,1-.792.252Zm-1.593-6.3a.992.992,0,0,0-.261.36l-.252.513a1.781,1.781,0,0,1-1.206.9L14.58,16c-.288.045-.387.144-.4.162a.7.7,0,0,0,.234.369l.315.315a1.788,1.788,0,0,1,.441,1.539l-.045.189a1.051,1.051,0,0,0-.027.558,2.028,2.028,0,0,0,.477-.216l.225-.135a1.735,1.735,0,0,1,1.7,0l.225.135c.369.225.522.2.531.2-.036,0,.009-.18-.072-.54l-.045-.189a1.781,1.781,0,0,1,.441-1.539l.315-.315a.675.675,0,0,0,.234-.378c-.009-.018-.108-.108-.4-.162l-.342-.054a1.762,1.762,0,0,1-1.206-.9l-.252-.5a.79.79,0,0,0-.3-.36Z"
          fill={location.pathname.includes("/performance") ? "#F7D372" : "#fff"}
        />
      </g>
    </svg>
  );
}
