import { HStack, TabList, VStack, Button, IconButton, useBreakpointValue } from "@chakra-ui/react";
import { useEffect } from "react";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import ChronoIcon from "../../assets/icons/ChronoIcon";
import useQueryParams from "../../hooks/useQueryParams";
import { useTimer } from "../../timer-provider/TimerProvider";
import { secondsToHMS } from "../../utils/timerConverter";
import ExamQcmCustomTab from "./ExamQCMCustomTab";

const ExamQCMHeader = ({
  questions,
  headerRef,
  answers,
  isFixed,
  setisFixed,
  tabIndex,
  scrollRight,
  scrollValue,
  viewMode,
  isSerieMode
}: any) => {
  const { time } = useTimer();
  const { hours, minutes, seconds } = secondsToHMS(time);
  const queryParams = useQueryParams();


  useEffect(() => {
    const scrollTop: any = window.addEventListener("scroll", () => {
      setisFixed(true);
    });

    return () => {
      window.removeEventListener("scroll", scrollTop);
    };
  }, [tabIndex]);

  return (
    <HStack
      align={"stretch"}
      spacing={0}
      bg={"lightGrey"}
      position={isFixed ? "fixed" : "inherit"}
      width={{
        base: "100vw",
        md: "calc(100vw - 240px)",
        "2xl": "calc(100vw - 320px)",
      }}
      top="0px"
      borderTopLeftRadius={{ base: "0px", md: "1.5rem" }}
      overflow={"hidden"}
      zIndex="2"
      _hover={{ "& .arrow-btn": { display: "inherit" } }}
      transition="all 0.25s"
    >
      {" "}
      <IconButton
        className={useBreakpointValue({base: "", md: "arrow-btn"})}
        icon={<FiChevronLeft fontSize={"2rem"} />}
        aria-label="question suivante"
        onClick={() => scrollRight(-scrollValue)}
        size="xs"
        cursor={"pointer"}
        variant="solid"
        rounded={"0px"}
        bg="blue-light"
        h="100%"
        position="sticky"
        zIndex={2}
        left="0px"
        color="white"
        borderEndRadius={"1rem"}
        alignSelf="center"
        display={"none"}
        _hover={{}}
        _disabled={{}}
      />
      <TabList
        position={"relative"}
        width={"100%"}
        overflow="scroll"
        ref={headerRef}
        scrollBehavior="smooth"
        className="tab-item-qcm"
        p="4"
        pt="6"
        bg="lightGrey"
      >
        {questions.map((tab: any, index: number) => (
          <ExamQcmCustomTab
            key={index}
            alreadyAnswered={Boolean(
              answers?.find((el: any) => el.question === tab._id)
            )}
            status={answers.find((el: any) => el.question === tab._id)}
            viewMode={viewMode}
            isSerieMode={isSerieMode}
          >
            {tab.order}
          </ExamQcmCustomTab>
        ))}
      </TabList>
      <IconButton
        className={useBreakpointValue({base: "", md: "arrow-btn"})}
        icon={<FiChevronRight fontSize={"2rem"} />}
        aria-label="question précedente"
        onClick={() => scrollRight(scrollValue)}
        size="xs"
        cursor={"pointer"}
        variant="solid"
        rounded={"0px"}
        bg="blue-light"
        height="100%"
        position="sticky"
        zIndex={2}
        right="0px"
        color="white"
        borderStartRadius={"1rem"}
        alignSelf="center"
        display={"none"}
        _hover={{}}
        _disabled={{}}
      />
      <VStack
        bg={time <= 900 ? "#ffcece" : "#E2E5F4"}
        width="200px"
        borderBottomLeftRadius={"1rem"}
        align="center"
        justify={"center"}
        display={queryParams.get("view") === "true" ? "none" : "inherit"}
      >
        <Button
          variant={"primary"}
          bg={time <= 900 ? "lightRed" : "blue-light"}
          leftIcon={<ChronoIcon />}
          size="sm"
          boxShadow={time <= 900 ? "0px 5px 20px 10px rgb(167,10,10,0.4)" : "0px 5px 20px 10px rgba(75, 98, 240,.4)"}
          color={"white"}
          _hover={{}}
          _active={{}}
          _focus={{}}
        >
          {hours ? `0${hours}:` : ""}
          {minutes < 10 ? `0${minutes}` : minutes}:
          {seconds < 10 ? `0${seconds}` : seconds}
        </Button>
      </VStack>
    </HStack>
  );
};

export default ExamQCMHeader;
