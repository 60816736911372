import AuthLayout from "../../../layouts/AuthLayout";
import register from "../../../assets/img/register.png";
import HeadingWithLines from "../../../components/Heading/HeadingWithLines";
import {
  Box,
  Button,
  Flex,
  Heading,
  Spinner,
  Text,
  useBreakpointValue,
  useToast,
} from "@chakra-ui/react";
import Field from "../../../components/Field/Field";
import { FormProvider, useForm } from "react-hook-form";
import AvatarIcon from "../../../assets/icons/AvatarIcon";
import KeyIcon from "../../../assets/icons/KeyIcon";
import EmailIcon from "../../../assets/icons/EmailIcon";
import { Link, useHistory, useLocation } from "react-router-dom";
import { IInputField } from "../../../types";
import { useEffect } from "react";
import SocialNetWorkBtns from "../../../components/Social Network Buttons/SocialNetWorkBtns";
import { useMutation } from "react-query";
import { registerUser } from "../../../services/auth/services";
import usePlan from "../../../auth-provider/PlanProvider";

const registerFields: IInputField[] = [
  {
    type: "text",
    name: "fullname",
    placeholder: "Nom et prénom",
    icon: <AvatarIcon />,
    rules: {
      required: "Veuillez saisir votre nom et prénom",
    },
  },
  {
    type: "text",
    name: "username",
    placeholder: "Pseudo",
    icon: <AvatarIcon />,
    rules: {
      required: "Veuillez saisir votre pseudo",
    },
  },
  {
    type: "text",
    name: "email",
    placeholder: "Adresse email",
    icon: <EmailIcon />,
    rules: {
      required: "Veuillez saisir votre adresse email",
    },
  },
  {
    type: "password",
    name: "password",
    placeholder: "Mot de passe",
    icon: <KeyIcon />,
    rules: {
      required: "Veuillez saisir votre mot de passe",
    },
  },
];

export default function Register({ base_url }: { base_url: string }) {
  const methods = useForm<any>({
    defaultValues: {
      fullname: "",
      username: "",
      email: "",
      password: "",
    },
  });
  const history = useHistory();
  const toast = useToast();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const { updatePlan } = usePlan()

  const onSubmit = (data: any) => {
    mutateAsync({...data, email: data?.email?.toLowerCase()?.trim()});
  };

  useEffect(() => {
    if (localStorage.getItem("access_token")) {
      history.push("/tableau-de-bord");
    }
  }, [localStorage.getItem("access_token")]);

  useEffect(() => {
    const newPlanId = searchParams.get("plan");
    if(newPlanId){
      updatePlan(String(newPlanId))
    }
    if(!newPlanId){
      updatePlan('')
    }
  }, [])

  const { mutateAsync, isLoading } = useMutation(registerUser, {
    onError: (err: any) => {
      if (
        err.response.data.message.includes(
          "Adresse e-mail et Pseudo sont déjà utilisés"
        )
      ) {
        const errorsArray = err.response.data.message.split(",");
        const errorsField = Object.fromEntries(
          errorsArray.map((x: any) => x.split(":"))
        );
        methods.setError("email", { message: errorsField.email });
        methods.setError("username", { message: errorsField.username });
        toast({
          title: "Impossible de se connecter.",
          description: errorsField.msg,
          status: "error",
          duration: 9000,
          isClosable: true,
          position: "bottom-right",
        });
      } else if (typeof err.response.data.message === "string") {
        const xxx = err.response.data.message.split(":");
        methods.setError(xxx[0], { message: xxx[1] });
        toast({
          title: "Impossible de se connecter.",
          description: xxx[1],
          status: "error",
          duration: 9000,
          isClosable: true,
          position: "bottom-right",
        });
      } else {
        const errorsArray = err.response.data.message.map((el: string) =>
          el.split(":")
        );
        const errorsField = errorsArray.reduce(
          (a: any, v: any) => ({ ...a, [v[0]]: v[1].trim() }),
          {}
        );
        Object.keys(errorsField).map((field: string) =>
          methods.setError(field, { message: errorsField[field] })
        );
        toast({
          title: "Impossible de se connecter.",
          description:
            Object.keys(errorsField).length > 1
              ? "Votre saisie est incorrecte merci de vérifier les données saisies"
              : errorsField[Object.keys(errorsField)[0]],
          status: "error",
          duration: 9000,
          isClosable: true,
          position: "bottom-right",
        });
      }
    },
    onSuccess: async (res) => {
      await localStorage.setItem("access_token", res.access_token);
      history.push("/bienvenue");
    },
  });
  const sizes = useBreakpointValue({ base: "sm", "2xl": "md" });
  return (
    <AuthLayout illustration={register}>
      <Box mt='6'>
      <HeadingWithLines linesColor="yellow" position="end">
        Bienvenue
      </HeadingWithLines>

      </Box>
      <Heading size={sizes}>Continuez avec</Heading>
      <SocialNetWorkBtns isLoading={isLoading} />
      <Heading size={sizes}>Ou bien</Heading>
      <Box flexGrow={1}>
        <FormProvider {...methods}>
          <Flex
            as="form"
            direction={"column"}
            // flexGrow={1}
            onSubmit={methods.handleSubmit(onSubmit)}
          >
            {registerFields.map((field: IInputField, i: number) => (
              <Box key={field.name} mt={i !== 0 ? "4" : 0}>
                <Field {...field} bg="light-blue-light" />
              </Box>
            ))}
            {isLoading ? (
              <Button
                variant={"primary"}
                mt="4"
                leftIcon={<Spinner color="white" />}
                opacity={0.7}
                _hover={{}}
              >
                <span>Connexion en cours...</span>
              </Button>
            ) : (
              <Button type="submit" variant={"primary"} mt="4">
                Créer un compte
              </Button>
            )}
          </Flex>
        </FormProvider>
        <Link to={`/connexion?${searchParams.toString()}`}>
          <Button
            variant={"ghost"}
            _hover={{bg: "#ECEFFE"}}
            wordBreak={"break-all"}
            whiteSpace={"initial"}
            w="full"
            my="4"
            color="blue-light"
            fontSize={{ base: "0.8rem", "2xl": "1rem" }}
            height={{ base: "2.5rem", "2xl": "3rem" }}
          >
            Vous avez déjà un compte ? Se connecter
          </Button>
        </Link>
      </Box>
      <Text
        textAlign={"center"}
        fontSize={{ base: "0.5rem", "2xl": "0.7rem" }}
        mt="auto"
      >
        La création d'un compte signifie que vous êtes d'accord avec nos{" "}
        <Link to="/" style={{ textDecoration: "underline" }}>
          conditions d'utilisation
        </Link>{" "}
        et notre{" "}
        <Link to="/" style={{ textDecoration: "underline" }}>
          politique de confidentialité.
        </Link>
      </Text>
    </AuthLayout>
  );
}
