import { Container, useToast } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import AccountIcon from "../../../assets/icons/AccountIcon";
import AvatarIcon from "../../../assets/icons/AvatarIcon";
import EmailIcon from "../../../assets/icons/EmailIcon";
import KeyIcon from "../../../assets/icons/KeyIcon";
import SecurityIcon from "../../../assets/icons/SecurityIcon";
import { useAuthProvider } from "../../../auth-provider/AuthProvider";
import PageHeading from "../../../components/PageHeading/PageHeading";
import ProfileBlock from "../../../components/ProfileBlock/ProfileBlock";
import MyToast from "../../../components/Toast/Toast";
import SidebarLayout from "../../../layouts/SidebarLayout";
import {
  changePassword,
  updateProfile,
} from "../../../services/profile/services";
import { getPromotion } from "../../../services/promotion/services";
import { getStatus } from "../../../services/status/services";
import { getUniversities } from "../../../services/university/services";
import { IInputField } from "../../../types";
import { GrPowerReset } from "react-icons/gr"

export default function Profile({ base_url }: { base_url: string }) {
  const { data: auth } = useAuthProvider();
  const toast = useToast();
  const accountMethods = useForm<any>({
    defaultValues: {
      fullname: auth?.fullname,
      email: auth?.email,
      university: auth?.university,
      status: auth?.status,
      promotion: auth?.promotion,
      speciality_to_learn: auth?.speciality_to_learn,
    },
  });

  const passwordMethods = useForm<any>({
    defaultValues: {
      password_old: "",
      password_new: "",
      password_confirm: "",
    },
  });
  const queryClient = useQueryClient();

  const { data: universities, isLoading: universitiesLoading } = useQuery(
    "universities",
    getUniversities,
    {
      refetchOnWindowFocus: false,
    }
  );
  const { data: status, isLoading: statusIsLoading } = useQuery(
    "status",
    getStatus,
    {
      refetchOnWindowFocus: false,
    }
  );
  const { data: promotions, isLoading: promotionIsLoading } = useQuery(
    "promotions",
    getPromotion,
    {
      refetchOnWindowFocus: false,
    }
  );

  const accountFields: IInputField[] = [
    {
      type: "text",
      name: "fullname",
      placeholder: "Nom et prénom",
      icon: <AvatarIcon />,
      rules: {
        required: "Veuillez saisir le nom et prénom",
      },
    },
    {
      type: "text",
      name: "email",
      placeholder: "Adresse email",
      icon: <EmailIcon />,
      rules: {
        required: "Veuillez saisir l'adresse email",
      },
    },
  ];

  const securityFields: IInputField[] = [
    {
      type: "password",
      name: "password_old",
      placeholder: "Mot de passe",
      icon: <KeyIcon />,
      rules: {
        required: "Veuillez saisir le nom et prénom",
      },
    },
    {
      type: "password",
      name: "password_new",
      placeholder: "Nouveau mot de passe",
      icon: <KeyIcon />,
      rules: {
        required: "Veuillez saisir votre nouveau mot de passe",
      },
    },
    {
      type: "password",
      name: "password_confirm",
      placeholder: "Confirmer le nouveau mot de passe",
      icon: <KeyIcon />,
      rules: {
        required: "Veuillez confirmer votre nouveau mot de passe",
      },
    },
  ];

  const {
    mutateAsync: changePasswordMutation,
    isLoading: changePasswordLoading,
  } = useMutation(changePassword, {
    onSuccess: () => {
      MyToast({ toast, status: "success", title: "Mot de passse modifié." });
      passwordMethods.reset();
    },
    onError: (err: any) => {
      if (err.response.data.statusCode === 400) {
        passwordMethods.setError("password_new", {
          message: err.response.data.message,
        });
      }
      if (err.response.data.statusCode === 401) {
        passwordMethods.setError("password_old", {
          message: err.response.data.message,
        });
      }
      MyToast({
        toast,
        description: err.response.data.message,
        status: "warning",
        title: "Impossible",
      });
    },
  });

  const { mutateAsync: updateProfileMutation, isLoading } = useMutation(
    updateProfile,
    {
      onSuccess: () => {
        MyToast({ toast, status: "success", title: "Profil modifié." });
        queryClient.invalidateQueries(`user`);
      },
      onError: (err: any) => {
        MyToast({
          toast,
          description: err.response.data.message,
          status: "warning",
          title: "Impossible",
        });
      },
    }
  );

  const onSubmit = (data: any, block: string) => {
    if (block === "account") {
      const dirtyFields = Object.keys(accountMethods.formState.dirtyFields); // ["fullname", "email"]
      const obj: any = {};
      for (const key in dirtyFields) {
        if (Object.prototype.hasOwnProperty.call(dirtyFields, key)) {
          const element = dirtyFields[key];
          obj[element] = data[element];
        }
      }

      updateProfileMutation(obj);
    } else {
      if (data.password_new !== data.password_confirm) {
        passwordMethods.setError("password_confirm", {
          message: "Mot de passe n'est pas identique",
        });
      }
      changePasswordMutation({
        password: data.password_old,
        password_new: data.password_new,
      });
    }
  };

  return (
    <SidebarLayout>
      {universitiesLoading || statusIsLoading || promotionIsLoading ? (
        <p>Loading...</p>
      ) : (
        <Container maxW="7xl" px="0">
          <PageHeading title={auth?.fullname || ""} />
          <ProfileBlock
            title="Détails du compte"
            block="account"
            icon={AccountIcon}
            fields={accountFields}
            methods={accountMethods}
            onSubmit={onSubmit}
            updateAccountLoading={isLoading}
            changePasswordLoading={false}
            selectfields={true}
            universities={universities}
            status={status}
            promotions={promotions}
          />
          {auth?.provider === "email" && (
            <ProfileBlock
              title="Sécurité"
              block="security"
              icon={SecurityIcon}
              fields={securityFields}
              methods={passwordMethods}
              onSubmit={onSubmit}
              updateAccountLoading={isLoading}
              changePasswordLoading={changePasswordLoading}
              selectfields={false}
            />
          )}
          <ProfileBlock
            title="Réinitialiser votre compte"
            block="reset-account"
            icon={GrPowerReset}
            fields={securityFields}
            methods={passwordMethods}
            onSubmit={onSubmit}
            updateAccountLoading={isLoading}
            changePasswordLoading={changePasswordLoading}
            selectfields={false}
          />
        </Container>
      )}
    </SidebarLayout>
  );
}
