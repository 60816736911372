import { Stack, HStack, Box, Divider, Button, Text } from "@chakra-ui/react";
import { Link } from "react-router-dom";

const NotificationWrapper = ({
  notificationTitle,
  notificationIcon,
  notificationDate,
  notificationBody,
  notificationLink,
  seen,
}: any) => {
  return (
    <Stack
      direction={{ base: "column", lg: "row" }}
      bg="lightGrey"
      p="4"
      rounded="lg"
      align={{ base: "stretch", lg: "center" }}
      justify="space-between"
      borderLeft={seen ? "none" : "5px solid"}
      borderColor={seen ? "none" : "#475de4"}
    >
      <Stack
        spacing={{ base: 2, lg: 6 }}
        direction={{ base: "column", lg: "row" }}
        align={{ base: "stretch", lg: "center" }}
        justify={{ base: "space-between", lg: "flex-start" }}
      >
        <HStack justify={{ base: "space-between", lg: "flex-start" }}>
          <HStack>
            <Box bg="darkGrey" p="2" rounded={"md"}>
              {notificationIcon}
            </Box>
            <Text color="blue-light" fontWeight={500}>
              {notificationTitle}
            </Text>
          </HStack>
          <Text
            color="gray.400"
            fontWeight={"light"}
            fontSize="xs"
            display={{ base: "flex", lg: "none" }}
          >
            {notificationDate}
          </Text>
        </HStack>
        <Divider
          orientation="vertical"
          h="25px"
          borderColor={"gray.300"}
          display={{ base: "none", lg: "flex" }}
        />
        {notificationBody}
        <Divider
          orientation="vertical"
          h="25px"
          borderColor={"gray.300"}
          display={{ base: "none", lg: "flex" }}
        />
      </Stack>
      <Stack
        direction={{ base: "column", lg: "row" }}
        align={{ base: "stretch", lg: "center" }}
      >
        <Text
          color="gray.400"
          fontWeight={"light"}
          fontSize="xs"
          display={{ base: "none", lg: "flex" }}
        >
          {notificationDate}
        </Text>
        <Link to={notificationLink}>
          <Button variant={"primary"} minWidth="100px" width={"100%"} flex={1} height="2.5rem">
            Voir
          </Button>
        </Link>
      </Stack>
    </Stack>
  );
};

export default NotificationWrapper;
