import React, { useCallback } from "react";
import {
  useDisclosure,
  Button,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogBody,
  Image,
  Heading,
  VStack,
  IconButton,
  Tooltip,
} from "@chakra-ui/react";
import { useTimer } from "../../timer-provider/TimerProvider";
import ChronoPausedIcon from "../../assets/icons/ChronoPausedIcon";
import ChronoResumeIcon from "../../assets/icons/ChronoResumeIcon";
import pauseImage from "../../assets/img/pause.png";

export default function PauseBtn({
  counterIsRunning,
  pauseTimeSpentOnQuestion,
resumeTimeSpentOnQuestion
}: {
  counterIsRunning: boolean;
  pauseTimeSpentOnQuestion: () => void;
resumeTimeSpentOnQuestion: () => void;
}) {
  const { stopTimer, startTimer } = useTimer();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef<any>();

  const togglePopUpAndToggleTimer = useCallback((open: boolean) => {
    if (open) {
      onOpen();
      stopTimer();
      pauseTimeSpentOnQuestion()
    } else {
      onClose();
      startTimer();
      resumeTimeSpentOnQuestion()
    }
  },[]);

  const reprendre = useCallback(() => {
    startTimer()
  },[]);

  return (
    <>
    <Tooltip label="Pause" hasArrow>
      <IconButton
        variant={"secondaryWithIcon"}
        icon={<ChronoPausedIcon bg={"#4B62F0"} />}
        aria-label="pause"
        bg={counterIsRunning ? "light-blue-light" : "gold-yellow"}
        onClick={() => counterIsRunning ? togglePopUpAndToggleTimer(true) : reprendre()}
      />
      </Tooltip>
        {/* {counterIsRunning ? "Pause" : "Reprendre"}
      </Button> */}
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
        closeOnOverlayClick={false}
        closeOnEsc={false}
      >
        <AlertDialogOverlay />

        <AlertDialogContent background={"transparent"} boxShadow="none">
          <AlertDialogBody>
            <VStack align={"center"} spacing={6}>
              <Image src={pauseImage} objectFit="cover" minW={"300px"} />
              <VStack spacing={4}>
                <Heading color="white">Allez... reprend vite!</Heading>
                <Button
                  variant={"secondaryWithIcon"}
                  leftIcon={<ChronoResumeIcon />}
                  onClick={() => togglePopUpAndToggleTimer(false)}
                >
                  Continuer
                </Button>
              </VStack>
            </VStack>
          </AlertDialogBody>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
}
