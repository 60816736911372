export default function FolderDoneIcon() {
  return (
    <svg
      id="folder-minus"
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
    >
      <rect
        id="Rectangle_43"
        data-name="Rectangle 43"
        width="40"
        height="40"
        rx="5"
        fill="#292d32"
        opacity="0"
      />
      <g id="Group_55" data-name="Group 55" transform="translate(3.875 3.875)">
        <path
          id="Path_75"
          data-name="Path 75"
          d="M16.437,14.67h-7.5a1.125,1.125,0,1,1,0-2.25h7.5a1.125,1.125,0,1,1,0,2.25Z"
          transform="translate(3.333 6.405)"
          fill="#7fcacf"
        />
        <path
          id="Path_76"
          data-name="Path 76"
          d="M24.75,33.375h-15c-6.615,0-8.625-2.01-8.625-8.625v-15c0-6.615,2.01-8.625,8.625-8.625H12a4.638,4.638,0,0,1,4.5,2.25l2.25,3c.495.66.57.75,1.5.75h4.5c6.615,0,8.625,2.01,8.625,8.625v9C33.375,31.365,31.365,33.375,24.75,33.375Zm-15-30c-5.37,0-6.375,1.02-6.375,6.375v15c0,5.355,1.005,6.375,6.375,6.375h15c5.37,0,6.375-1.02,6.375-6.375v-9c0-5.355-1.005-6.375-6.375-6.375h-4.5a3.444,3.444,0,0,1-3.3-1.65l-2.25-3A2.523,2.523,0,0,0,12,3.375Z"
          transform="translate(-1.125 -1.125)"
          fill="#292d32"
        />
      </g>
    </svg>
  );
}
