import { Box, Heading, Text, Button } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";

export default function NotFound({isSearchResult=false}:{isSearchResult?: boolean}) {
    const history = useHistory();

  return (
    <Box textAlign="center" w="full" py={10} px={6}>
      <Heading
        display="inline-block"
        as="h2"
        size={isSearchResult ? "2xl" : "4xl"}
        bgGradient={
          isSearchResult ? "linear-gradient(90deg, #e53e3e 0%, #b71212 100%)" : "linear-gradient(90deg, rgb(152 165 242) 0%, rgba(69, 90, 222, 1) 100%)"
        }
        backgroundClip="text"
      >
        {isSearchResult ? "Aucun résultat trouvé" : "404"}
      </Heading>
      <Text fontSize="18px" mt={3} mb={2}>
        {isSearchResult ? "Veuillez raffiner votre recherche" : "Page Introuvable"}
      </Text>
      {!isSearchResult && <Text color={"gray.500"} mb={6}>
        La page que vous voulez consulter n'existe pas ou n'est plus en ligne.
      </Text>}

      <Button
        _hover={{
          bgGradient:
            "linear-gradient(90deg, rgba(69, 90, 222, 1) 0%, rgba(69, 90, 222, 1) 100%)",
        }}
        bgGradient="linear-gradient(90deg, rgb(152 165 242) 0%, rgba(69, 90, 222, 1) 100%)"
        onClick={() => history.push("/tableau-de-bord")}
        color="white"
      >
        Retourner
      </Button>
    </Box>
  );
}
