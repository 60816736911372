import {
  Box,
  Button,
  Heading,
  HStack,
  Stack,
  Text,
  useBreakpointValue,
  useToast,
  VStack,
} from "@chakra-ui/react";
import QcmActions from "./QcmActions";
import { useHistory, useParams } from "react-router-dom";
import { useTimer } from "../../timer-provider/TimerProvider";
import QcmQuestion from "./QcmQuestion";
import MyToast from "../Toast/Toast";
import useQueryParams from "../../hooks/useQueryParams";

function QcmBody({
  questions,
  question,
  setTabIndex,
  scrollRight,
  tabIndex,
  submit,
  submissionLoading,
  answers,
  answered,
  counterIsRunning,
  select,
  selectedOptions,
  correction = [],
  setAnswers,
  goToTop,
  timeSpentOnQuestion,
  pauseTimeSpentOnQuestion,
  resumeTimeSpentOnQuestion,
}: any) {
  const { attemptId, type, qcmId } = useParams<{
    attemptId: string;
    type: string;
    qcmId: string;
  }>();
  const history = useHistory();
  let queryParams: any = useQueryParams();
  const { startTimer, stopTimer, time, initialTime } = useTimer();
  const toast = useToast();
  const scrollValue = useBreakpointValue({ base: 66, md: 76 });
  const verifyAnswer = async () => {
    const questionIds = answers
      .map((x: any) => x.questions.map((y: any) => y.questionId))
      .flat();
    const _selectedOptions = selectedOptions.filter(
      (el: any) => !questionIds.some((el2: any) => el2 === el.questionId)
    );

    let allClinicQuestions: string[] = [question._id];
    if (question.children.length > 0) {
      allClinicQuestions = [
        ...allClinicQuestions,
        ...question.children.map((el: any) => el._id),
      ];
    }

    for (const clinicQuestion of allClinicQuestions) {
      const exist = _selectedOptions.find(
        (el: any) => el.questionId === clinicQuestion
      );
      if (!exist) {
        _selectedOptions.push({ options: [], questionId: clinicQuestion });
      }
    }

    try {
      // send user's answers and await for the correction from the api
      const response = await submit({
        attempt: attemptId,
        questions: _selectedOptions,
        duration: time,
        question_duration: timeSpentOnQuestion,
        last_question:
          tabIndex === questions.questions.length - 1 ? tabIndex : tabIndex + 1,
        ...(queryParams.get("retry") ? { retry: true } : {}),
      });
      pauseTimeSpentOnQuestion();

      // save correction (answers) in the state
      setAnswers([
        ...answers,
        {
          status: response.status,
          questions: response.questions,
          question: response.parentId,
        },
      ]);
      // then stop the timer
      stopTimer();
      goToTop();
    } catch (err: any) {
      if (err.response?.status === 403) {
        window.location.href = `/connexion?session=invalid`;
      } else {
        MyToast({
          toast,
          description: err.response.data.message,
          status: "error",
          title: "Erreur",
        });
      }
    }
  };

  return (
    <Stack spacing={8}>
      <HStack justify={"space-between"} mt={{ base: "20", md: "24" }}>
        <VStack
          align={"flex-start"}
          spacing={1}
          borderBottom="2px solid"
          borderColor={"gold-yellow"}
          pb="2"
          flexGrow={0.4}
        >
          <HStack>
            <Text
              color="blue-light"
              fontSize={"sm"}
              whiteSpace="nowrap"
              isTruncated
              maxW={{ base: "12rem", sm: "20rem", md: "unset" }}
            >
              <Text as="span" display={{ base: "none", md: "inline" }}>
                {questions.speciality.name} /{" "}
              </Text>
              {questions.subject.name}
            </Text>
          </HStack>
          <Heading size={"md"}>
            {questions?.isCustomQcm
              ? question.qcm.university.name + " " + question.qcm.year
              : questions.university.name + " " + questions.year}
          </Heading>
        </VStack>
        <Box display={{ base: "none", md: "block" }}>
          <QcmActions
            counterIsRunning={counterIsRunning}
            answered={Boolean(answered)}
            question={question}
            pauseTimeSpentOnQuestion={pauseTimeSpentOnQuestion}
            resumeTimeSpentOnQuestion={resumeTimeSpentOnQuestion}
            isCustomQcm={questions?.isCustomQcm}
            speciality={questions?.speciality?.name}
          />
        </Box>
      </HStack>
      <QcmQuestion
        question={question}
        answered={answered?.questions.find(
          (el: any) => el.questionId === answered.question
        )}
        selectedOptions={selectedOptions.find(
          (el: any) => el.questionId === question._id
        )}
        correction={
          correction?.find((el: any) => el.questionId === question._id)
            ?.correction
        }
        select={select}
      />
      {question.children.length > 0 &&
        question?.children.map((child: any) => (
          <QcmQuestion
            key={child._id}
            question={child}
            answered={answered?.questions.find(
              (el: any) => el.questionId === child._id
            )}
            selectedOptions={selectedOptions.find(
              (el: any) => el.questionId === child._id
            )}
            correction={
              correction?.find((el: any) => el.questionId === child._id)
                ?.correction
            }
            select={select}
          />
        ))}
      <HStack
        justify={"space-between"}
        alignSelf={{ base: "inherit", md: "flex-end" }}
      >
        <Box display={{ base: "block", md: "none" }}>
          <QcmActions
            counterIsRunning={counterIsRunning}
            answered={Boolean(answered)}
            question={question}
            pauseTimeSpentOnQuestion={pauseTimeSpentOnQuestion}
            resumeTimeSpentOnQuestion={resumeTimeSpentOnQuestion}
            isCustomQcm={questions?.isCustomQcm}
            speciality={questions?.speciality?.name}
          />
        </Box>
        {answered && (
          <Button
            variant={answered ? "primary" : "secondary"}
            px="10"
            onClick={() => {
              if (tabIndex < questions.questions.length - 1) {
                setTabIndex((x: number) => x + 1);
                resumeTimeSpentOnQuestion();
              }
              // if (tabIndex > (questions.questions.length - 1) / 3) {
              //   scrollRight(100);
              // }
              if (answered && tabIndex !== questions.questions.length - 1) {
                startTimer();
                resumeTimeSpentOnQuestion();
              }
              if (tabIndex === questions.questions.length - 1) {
                history.push(`/qcm/${questions?.isCustomQcm ? 'custom' : 'serie'}/${qcmId}/${attemptId}/result`);
              }
              scrollRight(scrollValue);
              goToTop();
            }}
          >
            {answered
              ? tabIndex === questions.questions.length - 1
                ? "Terminer"
                : "Suivant"
              : "Passer"}
          </Button>
        )}
        {!answered && (
          <Button
            variant={"primary"}
            px="10"
            onClick={verifyAnswer}
            isLoading={submissionLoading}
            loadingText={""}
          >
            Vérifier
          </Button>
        )}
      </HStack>
    </Stack>
  );
}

export default QcmBody;
