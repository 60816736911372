import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import Playlist from "../../pages/Unauthorized/MyListes/MyListes";
import PlaylistQuestions from "../../pages/Unauthorized/MyListes/PlaylistQuestions";
import PlaylistSubjects from "../../pages/Unauthorized/MyListes/PlaylistSubjects";
import { useAuthProvider } from "../../auth-provider/AuthProvider";

export default function PlaylistContainer() {
  const { data: auth } = useAuthProvider();
  const { path, url } = useRouteMatch();

  if (!auth?.email_verified) {
    return <Redirect to="/confirmation-email" exact />;
  }
  return (
    <Switch>
      <Route exact path={`${path}`}>
        <Playlist base_url={url} />
      </Route>
      <Route exact path={`${path}/:specialityName`}>
        <PlaylistSubjects />
      </Route>
      <Route exact path={`${path}/:specialityName/:subjectName`}>
        <PlaylistQuestions />
      </Route>
      <Route>
        <h1>Page Not Found</h1>
      </Route>
    </Switch>
  );
}
