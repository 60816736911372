import { useCallback } from "react";
import {
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  HStack,
  IconButton,
  Text,
} from "@chakra-ui/react";
import { ChevronUpIcon } from "@chakra-ui/icons";
import alphabet from "../../../../utils/alphabets.json";

export interface IOption {
  _id: string;
  justification: string;
  isCorrect: boolean;
  value: string;
}

export default function Option({
  option,
  index,
}: {
  option: IOption;
  index: number;
}) {
  const generateBorderColor = useCallback((isCorrect: boolean) => {
    if (isCorrect) {
      return {
        bg: "#EBF7F0",
        borderColor: "#C8E5CF",
        textColor: "#7FCF91",
        textBackground: "#E8EFEA",
        justificationBorderColor: "#7FCACF",
      };
    } else {
      return {
        bg: "#F4F9FA",
        borderColor: "#E8EDEF",
        textColor: "black",
        textBackground: "darkGrey",
        justificationBorderColor: "#7FCACF",
      };
    }
  }, []);

  return (
    <AccordionItem
      key={option._id}
      bg={generateBorderColor(option.isCorrect).bg}
      rounded={"xl"}
      overflow="hidden"
      border={`2px solid ${generateBorderColor(option.isCorrect).borderColor}`}
      transition="all 0.5s"
    >
      {({ isExpanded }) => (
        <>
          <AccordionButton
            _active={{}}
            _focus={{}}
            _expanded={{
              bg: generateBorderColor(option.isCorrect).bg,
            }}
            as="span"
            cursor={option.justification ? "pointer" : "unset"}
            _hover={{}}
            p="0"
            pr="4"
          >
            <HStack justify={"space-between"} w="full">
              <HStack spacing={2} align="stretch">
                <Text
                  p="3"
                  fontSize={"14px"}
                  alignSelf="center"
                  color="gray.600"
                >
                  <Text as="span" fontWeight={"bold"}>
                    {alphabet[index]}-{" "}
                  </Text>
                  {option.value}
                </Text>
              </HStack>
              {option.justification && (
                <IconButton
                  icon={
                    <ChevronUpIcon
                      color={"gray.600"}
                      fontSize="2rem"
                      transform={
                        isExpanded ? "rotate(0deg)" : "rotate(-180deg)"
                      }
                      transition="all 0.25s ease-in-out"
                    />
                  }
                  aria-label="justification"
                  bg={"transpqrent"}
                  size="md"
                  _active={{}}
                  _hover={{}}
                />
              )}
            </HStack>
          </AccordionButton>
          {option.justification && (
            <AccordionPanel
              pb={4}
              bg={generateBorderColor(option.isCorrect).borderColor}
            >
              <Box py="2" px="0" pb="0" rounded={"lg"}>
                <Text fontSize={"14px"}>{option.justification}</Text>
              </Box>
            </AccordionPanel>
          )}
        </>
      )}
    </AccordionItem>
  );
}
