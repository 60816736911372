import { useColorModeValue } from "@chakra-ui/react";

export default function AllIcon({ isCheked = false }: { isCheked?: boolean }) {
  const color = useColorModeValue("#292d32", "white")
  if (isCheked) {
    return (
      <svg
        id="document-copy"
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
      >
        <rect
          id="Rectangle_1015"
          data-name="Rectangle 1015"
          width="16"
          height="16"
          rx="5"
          fill={isCheked ? "#fff" : "#000"}
          opacity="0"
        />
        <g id="Group_1175" data-name="Group 1175" transform="translate(2 2)">
          <path
            id="Path_190"
            data-name="Path 190"
            d="M9.9,9.99H8.6A1.937,1.937,0,0,1,6.66,8.052V6.75a.449.449,0,0,0-.45-.45h-1.9A2.376,2.376,0,0,0,1.8,8.808v3.984A2.376,2.376,0,0,0,4.308,15.3H7.842a2.376,2.376,0,0,0,2.508-2.508V10.44A.449.449,0,0,0,9.9,9.99Z"
            transform="translate(-1.8 -3.3)"
            fill={isCheked ? "#fff" : "#000"}
          />
          <path
            id="Path_191"
            data-name="Path 191"
            d="M13.02,1.8H9.486a2.368,2.368,0,0,0-2.5,2.406c.036,0,.066-.006.1-.006H10.62a2.376,2.376,0,0,1,2.508,2.508V10.7c0,.036-.006.066-.006.1a2.359,2.359,0,0,0,2.406-2.5V4.308A2.376,2.376,0,0,0,13.02,1.8Z"
            transform="translate(-3.528 -1.8)"
            fill={isCheked ? "#fff" : "#000"}
          />
          <path
            id="Path_192"
            data-name="Path 192"
            d="M10.53,6.392a.293.293,0,0,0-.5.2V8.162a1.225,1.225,0,0,0,1.242,1.2c.426.006,1.02.006,1.53.006a.282.282,0,0,0,.21-.48c-.654-.654-1.818-1.824-2.478-2.5Z"
            transform="translate(-4.542 -3.301)"
            fill={isCheked ? "#fff" : "#000"}
          />
        </g>
      </svg>
    );
  } else {
    return (
      <svg
        id="document-copy"
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
      >
        <rect
          id="Rectangle_1011"
          data-name="Rectangle 1011"
          width="16"
          height="16"
          rx="5"
          fill={color}
          opacity="0"
        />
        <g
          id="Group_1171"
          data-name="Group 1171"
          transform="translate(1.55 1.55)"
        >
          <path
            id="Path_176"
            data-name="Path 176"
            d="M7.215,15.525H4.935c-2.634,0-3.81-1.176-3.81-3.81V9.435c0-2.634,1.176-3.81,3.81-3.81h1.8a.45.45,0,1,1,0,.9h-1.8c-2.148,0-2.91.762-2.91,2.91v2.28c0,2.148.762,2.91,2.91,2.91h2.28c2.148,0,2.91-.762,2.91-2.91v-1.8a.45.45,0,1,1,.9,0v1.8C11.025,14.349,9.849,15.525,7.215,15.525Z"
            transform="translate(-1.125 -2.625)"
            fill={color}
          />
          <path
            id="Path_177"
            data-name="Path 177"
            d="M13.155,10.365h-1.92c-1.686,0-2.37-.684-2.37-2.37V6.075a.445.445,0,0,1,.276-.414.454.454,0,0,1,.492.1l3.84,3.84a.452.452,0,0,1,.1.492A.444.444,0,0,1,13.155,10.365Zm-3.39-3.2V8c0,1.194.276,1.47,1.47,1.47h.834Z"
            transform="translate(-3.705 -2.625)"
            fill={color}
          />
          <path
            id="Path_178"
            data-name="Path 178"
            d="M12.615,2.025h-2.4a.45.45,0,0,1,0-.9h2.4a.45.45,0,0,1,0,.9Z"
            transform="translate(-4.005 -1.125)"
            fill={color}
          />
          <path
            id="Path_179"
            data-name="Path 179"
            d="M6.075,3.825a.453.453,0,0,1-.45-.45,2.251,2.251,0,0,1,2.25-2.25H9.447a.45.45,0,0,1,0,.9H7.875a1.352,1.352,0,0,0-1.35,1.35A.453.453,0,0,1,6.075,3.825Z"
            transform="translate(-2.625 -1.125)"
            fill={color}
          />
          <path
            id="Path_180"
            data-name="Path 180"
            d="M17.046,12.825a.45.45,0,0,1,0-.9,1.238,1.238,0,0,0,1.236-1.236V6.975a.45.45,0,0,1,.9,0v3.714A2.141,2.141,0,0,1,17.046,12.825Z"
            transform="translate(-6.282 -2.925)"
            fill={color}
          />
          <path
            id="Path_181"
            data-name="Path 181"
            d="M17.775,5.625h-1.8c-1.6,0-2.25-.654-2.25-2.25v-1.8A.445.445,0,0,1,14,1.161a.454.454,0,0,1,.492.1l3.6,3.6a.452.452,0,0,1,.1.492A.444.444,0,0,1,17.775,5.625Zm-3.15-2.964v.714c0,1.1.252,1.35,1.35,1.35h.714Z"
            transform="translate(-5.325 -1.125)"
            fill={color}
          />
        </g>
      </svg>
    );
  }
}
