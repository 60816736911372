import { Box, Heading, HStack, useBreakpointValue, VStack } from "@chakra-ui/react";
import { ReactElement } from "react";
import IconButtonWithTooltip from "../components/IconButtonWithTooltip";
import SidebarLayout from "./SidebarLayout";
import { button, HeaderProps } from "./types";

const Header = ({ breadcrumbs, buttons, title, subTitle }: HeaderProps) => {
  //   const {
  //     state: { sidebar },
  //   } = useSidebar();
  return (
    <HStack
      pos="sticky"
      top="0"
      py="4"
      px="8"
      borderBottom="2px solid"
      borderColor="light-gray-new"
      h="20"
      justifyContent={"space-between"}
      bg="white"
    >
      {subTitle && title ? (
        <VStack spacing={0} alignItems={"flex-start"}>
          <Heading fontWeight={"normal"} fontSize={"md"} color="blue-primary-new">
            {subTitle}
          </Heading>
          <Heading fontSize={"xl"} color="blue-primary-new">
            {title}
          </Heading>
          {/* {breadcrumbs && <BreadcrumbComponent {...breadcrumbs} />} */}
        </VStack>
      ) : (
        <HStack gap="0">
          <Heading fontSize={"2xl"} color="blue-light">
            {title}
          </Heading>
          {/* {breadcrumbs && <BreadcrumbComponent {...breadcrumbs} />} */}
        </HStack>
      )}
      <HStack>
        {buttons?.map(
          (button) =>
            !button.isHidden && (
              <IconButtonWithTooltip
                key={button.title}
                // isActive={sidebar === button.value}
                {...button}
              />
            )
        )}
      </HStack>
    </HStack>
  );
};

export const LeftSide = ({
  children,
}: {
  children: ReactElement | ReactElement[];
}) => {
  return (
    <Box flex="2" p="2" pl={useBreakpointValue({base: "4", md: "8"})} pr={useBreakpointValue({base: "4", md: "6"})} overflow="auto">
      {children}
    </Box>
  );
};
export const RightSide = ({
  children,
  footerButtons,
}: {
  children: ReactElement;
  footerButtons?: button[];
}) => {
  //   const {
  //     state: { sidebar, isSidebarOpen },
  //   } = useSidebar();
  return (
    <Box
      //   flex={isSidebarOpen ? "1" : "0"}
      flex={1}
      transition="all 0.3s ease-in-out"
      borderLeft="2px solid"
      borderColor="light-gray-new"
      display="flex"
      flexDirection={"column"}
      overflow="auto"
    >
      <Box flex="1" p="4">
        {children}
      </Box>
      <HStack
        position="sticky"
        bottom="0px"
        bg="white"
        p="4"
        borderTop="2px solid"
        borderColor="light-gray-new"
        minHeight={"70px"}
      >
        {footerButtons?.map((button) => (
          <IconButtonWithTooltip
            key={button.title}
            {...button}
            // isActive={sidebar === button.value}
          />
        ))}
      </HStack>
    </Box>
  );
};
export default function InnerPageLayout({
  children,
  breadcrumbs,
  buttons,
  title,
  subTitle,
}: HeaderProps & {
  children: ReactElement | ReactElement[];
}) {
  return (
    <SidebarLayout withoutPadding>
      <Header
        title={title}
        subTitle={subTitle}
        breadcrumbs={breadcrumbs}
        buttons={buttons}
      />
      <HStack align="stretch" height="calc(100vh - 80px)">
        {children}
      </HStack>
    </SidebarLayout>
  );
}
