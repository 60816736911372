export default function SquareGreenIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="27.212"
      height="27.212"
      viewBox="0 0 27.212 27.212"
      style={{position: "absolute", bottom: -50}}
    >
      <g
        id="Rectangle_6"
        data-name="Rectangle 6"
        transform="translate(21.148) rotate(74)"
        fill="none"
        stroke="#7fcacf"
        strokeLinejoin="round"
        strokeWidth="3"
      >
        <rect width="22" height="22" rx="2" stroke="none" />
        <rect x="1.5" y="1.5" width="19" height="19" rx="0.5" fill="none" />
      </g>
    </svg>
  );
}
