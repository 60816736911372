import {
  Box,
  Heading,
  HStack,
  Icon,
  LinkBox,
  Spinner,
  Stack,
  Tag,
  Text,
  Tooltip,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import { useState } from "react";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import AnswersIcon from "../../../assets/icons/AnswersIcon";
import TestIcon from "../../../assets/icons/TestIcon";
import { ProgressBar } from "../../../components/FolderBox/FolderBox";
import LoadingExams from "../../../components/LoadingExams/LoadingExams";
import PageHeading from "../../../components/PageHeading/PageHeading";
import SidebarLayout from "../../../layouts/SidebarLayout";
import {
  getExams,
  getExamsStats,
  getUpcomingExams,
} from "../../../services/qcm/services";
import { getUniversities } from "../../../services/university/services";
import { motion } from "framer-motion";
import SeriesFilterButtons from "../../../components/SeriesFilterButtons/SeriesFilterButtons";
import {
  getAttemptsStatsByType,
  getAttemptsStatsExamen,
} from "../../../services/series/services";
import { useAuthProvider } from "../../../auth-provider/AuthProvider";
import { useModal } from "../../../modal-provider/ModalProvider";
import LockCircle from "../../../assets/icons/LockCircle";

export default function Exams({ base_url }: { base_url: string }) {
  const [statsFormat, setStatsFormat] = useState<"average" | "progress">(
    "progress"
  );
  const { data: years, isLoading: examsLoading } = useQuery(
    "exam-years",
    getExams,
    {
      staleTime: 60000 * 60,
    }
  );
  const { data: universities, isLoading: universitiesLoading } = useQuery(
    "universities",
    getUniversities,
    {
      staleTime: 60000 * 60,
    }
  );
  const { data: upComingExams, isLoading: upComingExamsLoading } = useQuery(
    "upcoming-exams",
    getUpcomingExams,
    {
      staleTime: 60000 * 60,
    }
  );

  const { data: examQuestionsCount, isLoading: examQuestionsCountIsLoading } =
    useQuery("examen-count", () => getAttemptsStatsExamen(), {
      refetchOnWindowFocus: false,
      staleTime: 60000 * 60,
    });
  const { data: examsStats, isLoading: isLoadingExamsStats } = useQuery(
    "exams-stats",
    getExamsStats,
    {
      refetchOnWindowFocus: false,
      staleTime: 60000 * 15,
      retry: false
    }
  );

  // const { data: examStats, isLoading: examStatsIsLoading } = useQuery(
  //   "series-stats",
  //   () => getAttemptsStatsByType("examen"),
  //   {
  //     refetchOnWindowFocus: false,
  //   }
  // );

  return (
    <SidebarLayout>
      <HStack wrap="wrap" justify={"space-between"}>
        <PageHeading title="Examen" />
        <SeriesFilterButtons
          setStatsFormat={setStatsFormat}
          statsFormat={statsFormat}
          withFilter={false}
        />
      </HStack>
      <Stack my="4" mt="8" spacing={6}>
        {examsLoading ||
        universitiesLoading ||
        upComingExamsLoading ||
        isLoadingExamsStats
          ? // || examStatsIsLoading
            Array.apply(null, Array(3)).map((el: any, index: number) => (
              <LoadingExams key={index} />
            ))
          : years.map((examGroup: any) => (
              <ExamSlider
                key={examGroup._id}
                year={examGroup._id}
                universities={universities}
                statsFormat={statsFormat}
                upComingExams={upComingExams.filter(
                  (el: any) => el._id.year === examGroup._id
                )}
                examQuestionsCount={examQuestionsCount?.filter(
                  (el: any) => el._id.year === examGroup._id
                )}
                // examStats={examStats.filter((el: any) => el.speciality.year === examGroup._id)}
                examStats={[]}
                examsStats={examsStats?.find(
                  (exams: any) => exams.year === examGroup._id
                )}
                examQuestionsCountIsLoading={examQuestionsCountIsLoading}
              />
            ))}
      </Stack>
    </SidebarLayout>
  );
}

const ExamSlider = ({
  year,
  universities,
  statsFormat,
  upComingExams,
  examQuestionsCount,
  examStats,
  examQuestionsCountIsLoading,
  examsStats,
}: any) => {
  // const prevRef = useRef(null);
  // const nextRef = useRef(null);

  return (
    <Stack spacing={5}>
      <HStack justify={"space-between"}>
        <Heading color="blue-light" fontSize={{ base: "20px", md: "25px" }}>
          {year}
        </Heading>
        {/* <Controller nextRef={nextRef} prevRef={prevRef} /> */}
      </HStack>
      <Swiper
        slidesPerView={1.25}
        spaceBetween={20}
        pagination={{
          clickable: false,
          type: "custom",
        }}
        modules={[Navigation]}
        // onBeforeInit={(swiper: any) => {
        //   swiper.params.navigation.prevEl = prevRef.current
        //     ? prevRef.current
        //     : undefined;
        //   swiper.params.navigation.nextEl = nextRef.current
        //     ? nextRef.current
        //     : undefined;
        // }}
        breakpoints={{
          390: {
            slidesPerView: 1.25,
            spaceBetween: 20,
          },
          425: {
            slidesPerView: 1.5,
            spaceBetween: 20,
          },
          600: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 2.75,
            spaceBetween: 10,
          },
          1024: {
            slidesPerView: 3.5,
            spaceBetween: 20,
          },
          1200: {
            slidesPerView: 4,
            spaceBetween: 20,
          },
          1600: {
            slidesPerView: 4,
            spaceBetween: 30,
          },
        }}
        grabCursor={true}
        className="mySwiper"
        style={{ alignItems: "stretch" }}
      >
        {universities.map((university: any) => (
          <SwiperSlide key={university._id}>
            <ExamBox
              university={university}
              statsFormat={statsFormat}
              link={`/examen-blanc/${year}/${university.name}`}
              upComingExams={upComingExams.find(
                (el: any) => el._id.university === university.name
              )}
              examQuestionsCount={examQuestionsCount?.find(
                (el: any) => el._id.university === university._id
              )}
              examStats={examsStats?.results?.find(
                (el: any) => el.university === university.name
              )}
              examQuestionsCountLoading={examQuestionsCountIsLoading}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </Stack>
  );
};

export const ExamBox = ({
  university,
  statsFormat,
  link,
  upComingExams,
  examQuestionsCount,
  examStats,
  examQuestionsCountLoading,
  isProtected = false,
}: any) => {
  const bgBox = useColorModeValue("lightGrey", "card-dark");
  const bgStack = useColorModeValue("gray.800", "white");
  const history = useHistory();
  const { data: auth } = useAuthProvider();
  const { onOpen } = useModal();

  return (
    <LinkBox
      as="article"
      w="full"
      maxW={"400px"}
      bg={bgBox}
      borderWidth="1px"
      borderColor={"transparent"}
      rounded=".8rem"
      transition={"all 0.15s ease-in-out"}
      _hover={{
        borderColor: "darkSky",
        boxShadow: "xs",
        cursor: "pointer",
        bg: "darkGrey",
        // "& .xxx": { bg: "transparent" },
      }}
      overflow="hidden"
      // onClick={() => history.push(link || "#")}
      onClick={() => {
        if (isProtected) {
          if (auth?.days_left && auth?.days_left > 0) {
            history.push(link || "#");
          } else {
            onOpen();
          }
        } else {
          history.push(link || "#");
        }
      }}
      // h="48"
      // h="56"
      h="full"
    >
      <Stack
        color={bgStack}
        h="full"
        justify={"space-between"}
        align="center"
        position={"relative"}
      >
        <Tag
          position={"absolute"}
          top="4"
          right="4"
          bg={
            !upComingExams && history.location.pathname === "/examen-blanc"
              ? "blue-light"
              : "transparent"
          }
          rounded="full"
          fontWeight="light"
          color="white"
        >
          {!upComingExams && history.location.pathname === "/examen-blanc"
            ? "Bientôt"
            : ""}
        </Tag>
        {(!auth?.current_order ||
          (auth?.current_order && auth?.days_left <= 0)) && (
          <Box position="absolute" top="2" right="2">
            <Icon as={LockCircle} />
          </Box>
        )}

        <VStack
          align={"center"}
          p="5"
          flex={1}
          alignItems="center"
          justify={"center"}
        >
          <Icon as={TestIcon} />
          <Text textAlign={"center"} fontSize="lg">
            {university.name}
          </Text>
        </VStack>
        {statsFormat === "progress" ? (
          <VStack
            align={"stretch"}
            bg="darkGrey"
            p="5"
            className="xxx"
            transition={"all 0.3s"}
            w="full"
          >
            <HStack>
              <AnswersIcon />
              <Text fontSize="sm">
                {examStats?.result?.answersCount ?? 0} /{" "}
                {examQuestionsCountLoading ? (
                  <Spinner as={"span"} size={"xs"} opacity={0.2} />
                ) : (
                  examQuestionsCount?.exams ?? 0
                )}
              </Text>
            </HStack>
            <HStack
              spacing={0}
              bg="white"
              rounded={"md"}
              overflow="hidden"
              width={"100%"}
            >
              <Tooltip
                label="Bonne réponse"
                hasArrow
                openDelay={2000}
                rounded={"md"}
              >
                <motion.div
                  initial={{ width: "0px" }}
                  style={{ height: "8px", background: "#7FCACF" }}
                  animate={{
                    // width: "50%",
                    width: examQuestionsCount?.exams
                      ? `${
                          ((examStats?.result?.answersCount ?? 0) /
                            (examQuestionsCount?.exams ?? 0)) *
                          100
                        }%`
                      : `0%`,
                    //   width: ((stats?.result?.fausse ?? 0 / stats?.answersCount) ||
                    //   0) *
                    //   100 +
                    // "%",
                  }}
                  exit={{ width: "0px" }}
                  transition={{ default: { duration: 0.75 } }}
                />
              </Tooltip>
            </HStack>
          </VStack>
        ) : (
          <Stack w="full">
            <ProgressBar
              values={{
                correct: examStats?.result?.correct ?? 0,
                fausse: examStats?.result?.fausse ?? 0,
                partiel: examStats?.result?.partiel ?? 0,
                overall: examStats?.result?.overall ?? 0,
              }}
            />
          </Stack>
        )}
      </Stack>
    </LinkBox>
  );
};
