import {
  Divider,
  Grid,
  Heading,
  HStack,
  IconButton,
  Skeleton,
  Stack,
  Tooltip,
} from "@chakra-ui/react";
import { useCallback, useState } from "react";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import AnswersIcon from "../../../assets/icons/AnswersIcon";
import FilterIcon from "../../../assets/icons/FilterIcon";
import PercentageIcon from "../../../assets/icons/PercentageIcon";
import FolderBox from "../../../components/FolderBox/FolderBox";
import PageHeading from "../../../components/PageHeading/PageHeading";
import SeriesFilterButtons from "../../../components/SeriesFilterButtons/SeriesFilterButtons";
import SidebarLayout from "../../../layouts/SidebarLayout";
import { getAttemptsStatsByType } from "../../../services/series/services";
import { getSpecialities } from "../../../services/specialities/services";

type SerieStat = {
  speciality: string;
  answersCount: number;
  result: {
    overall: number;
    correct: number;
    fausse: number;
    partiel: number;
  };
};

export default function Series({ base_url }: { base_url: string }) {
  const [statsFormat, setStatsFormat] = useState<"average" | "progress">(
    "progress"
  );

  const { data: specialities, isLoading: isLoadingSpecialities } = useQuery(
    "specialities",
    getSpecialities,
    {
      refetchOnWindowFocus: false,
      staleTime: 60000 * 60
    }
  );
  const { data: seriesStats, isLoading: seriesStatsIsLoading } = useQuery(
    "series-stats",
    () => getAttemptsStatsByType("serie"),
    {
      refetchOnWindowFocus: false,
      retry: false
      // staleTime: 60000 * 15
    }
  );

  const statsPerSpeciality = useCallback(
    (specialityName: string) => {
      if (seriesStats) {
        return seriesStats.find(
          (stat: SerieStat) => stat.speciality === specialityName
        );
      }
    },
    [seriesStats]
  );

  const history = useHistory();

  return (
    <SidebarLayout>
      <HStack justify={"space-between"}>
        <PageHeading title="Série" />
        <SeriesFilterButtons
          setStatsFormat={setStatsFormat}
          statsFormat={statsFormat}
        />
      </HStack>
      <Stack spacing={6} my="4" mt="8" >
        <Stack spacing={5}>
          <Heading color="blue-light" fontSize={{base: "20px", md: "25px"}}>
            Jour 1
          </Heading>
          <Grid
            gridTemplateColumns={"repeat(auto-fill, minmax(300px, 1fr))"}
            justifyItems="center"
            gap={{base: "20px", lg: "20px", "2xl": "30px"}}
            my="4"
          >
            {isLoadingSpecialities || seriesStatsIsLoading
              ? Array.apply(null, Array(12)).map((el: any, index: number) => (
                  <Skeleton
                    rounded={"0.8rem"}
                    w="full"
                    h="36"
                    key={index}
                    startColor="darkGrey"
                    endColor="lightGrey"
                  />
                ))
              : specialities
                  .filter((el: any) => el.jour === "1")
                  .map((speciality: any) => (
                    <FolderBox
                      goTo={() =>
                        history.push(`${base_url}/${speciality.name}`)
                      }
                      item={speciality}
                      key={speciality._id}
                      stats={statsPerSpeciality}
                      statsFormat={statsFormat}
                      seriesPage={true}
                    />
                  ))}
          </Grid>
        </Stack>
        <Stack spacing={5}>
          <Heading color="blue-light" fontSize={{base: "20px", md: "25px"}}>
            Jour 2
          </Heading>
          <Grid
            gridTemplateColumns={"repeat(auto-fill, minmax(300px, 1fr))"}
            justifyItems="center"
            gap={{base: "20px", lg: "20px", "2xl": "30px"}}
            my="4"
          >
            {isLoadingSpecialities || seriesStatsIsLoading
              ? Array.apply(null, Array(12)).map((el: any, index: number) => (
                  <Skeleton
                    rounded={"0.8rem"}
                    w="full"
                    h="36"
                    key={index}
                    startColor="darkGrey"
                    endColor="lightGrey"
                  />
                ))
              : specialities
                  .filter((el: any) => el.jour === "2")
                  .map((speciality: any) => (
                    <FolderBox
                      goTo={() =>
                        history.push(`${base_url}/${speciality.name}`)
                      }
                      item={speciality}
                      key={speciality._id}
                      stats={statsPerSpeciality}
                      statsFormat={statsFormat}
                      seriesPage={true}
                    />
                  ))}
          </Grid>
        </Stack>
      </Stack>
    </SidebarLayout>
  );
}
