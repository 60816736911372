import { Grid, Stack } from "@chakra-ui/react";
import SidebarLayout from "../../layouts/SidebarLayout";
import Loading from "../Loading";

export default function index() {
  return (
    <SidebarLayout withoutPadding>
      <Stack align={"center"} justify="center" h="100vh">
        <Loading />
      </Stack>
    </SidebarLayout>
  );
}
