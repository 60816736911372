import axiosInstance from "../../utils/axiosInstance";

export const getQuestion = async ({
  questionId,
}: {
  questionId: string;
}) => {
  let access_token = localStorage.getItem("access_token");
  return await axiosInstance({
    method: "get",
    url: `/question/search/${questionId}`,
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  }).then((res: any) => res.data);
};
