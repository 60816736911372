import {
  Box,
  Divider,
  Flex,
  Grid,
  Heading,
  HStack,
  Icon,
  IconButton,
  LinkBox,
  Stack,
  Text,
  Tooltip,
  useColorModeValue,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { useCallback, useRef, useState } from "react";
import { useQuery } from "react-query";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import AnswersIcon from "../../../assets/icons/AnswersIcon";
import FilterIcon from "../../../assets/icons/FilterIcon";
import PercentageIcon from "../../../assets/icons/PercentageIcon";
import TestIcon from "../../../assets/icons/TestIcon";
import BreadCrumb from "../../../components/BreadCrumb/BreadCrumb";
import FilterSeries from "../../../components/Filter/FilterSeries";
import useQueryParams from "../../../hooks/useQueryParams";
import SidebarLayout from "../../../layouts/SidebarLayout";
import {
  getAttemptsStatsBySubject,
  getSeriesBySubject,
} from "../../../services/series/services";
import capitalizeText from "../../../utils/capitalizeText";
import SeriesSlider from "../../../components/SeriesSlider/SeriesSlider";
import SeriesFilterButtons from "../../../components/SeriesFilterButtons/SeriesFilterButtons";
import LoadingExams from "../../../components/LoadingExams/LoadingExams";

export default function SeriesBySubject() {
  const [statsFormat, setStatsFormat] = useState<"average" | "progress">(
    "progress"
  );
  const { url } = useRouteMatch();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const query = useQueryParams();
  const [filters, setFilters] = useState({
    groupBy: query.get("groupBy") || "Faculté",
    orderBy: query.get("orderBy") || "Facluté",
  });
  const history = useHistory();

  const { subjectName, specialityName } = useParams<{
    subjectName: string;
    specialityName: string;
  }>();
  const {
    data: series,
    isLoading: isLoadingSeries,
    isFetching: isFetchingSeries,
  } = useQuery(
    [`series-${subjectName}`, query.getAll("groupBy"), query.getAll("orderBy")],
    () => getSeriesBySubject(subjectName, query),
    { refetchOnWindowFocus: false, staleTime: 60000 * 60 }
  );

  const { data: seriesStats, isLoading: seriesStatsIsLoading } = useQuery(
    `series-stats-subject-${subjectName}`,
    () => getAttemptsStatsBySubject(subjectName),
    {
      refetchOnWindowFocus: false,
      staleTime: 60000 * 15
    }
  );

  const breadcrumb = [
    {
      title: "Série",
      link: "/series",
      isCurrent: false,
    },
    {
      title: capitalizeText(specialityName),
      link: `/series/${specialityName}`,
      isCurrent: false,
    },
    {
      title: capitalizeText(subjectName),
      link: `/series/${specialityName}/${subjectName}`,
      isCurrent: true,
    },
  ];
  const bgBox = useColorModeValue("lightGrey", "card-dark");
  const bgStack = useColorModeValue("gray.800", "white");
  const textColor = useColorModeValue("blue-light", "blue-light");
  const btnRef = useRef<any>();

  function compare(a: any, b: any) {
    if (+a.year < +b.year) {
      return 1;
    }
    if (+a.year > +b.year) {
      return -1;
    }
    return 0;
  }

  const statsPerQCMId = useCallback(
    (qcmId: string) => {
      if (seriesStats) {
        return seriesStats.find((stat: any) => stat.qcm === qcmId);
      }
    },
    [seriesStats]
  );

  return (
    <SidebarLayout>
      <HStack justify={"space-between"} alignItems={"center"}>
        {series && !isLoadingSeries && (
          <>
            <BreadCrumb
              items={breadcrumb}
              isArchived={
                filters.groupBy === "Rien"
                  ? series[0]?.subject?.archived
                  : series[0]?.data[0]?.subject?.archived
              }
            />
            <SeriesFilterButtons
              setStatsFormat={setStatsFormat}
              statsFormat={statsFormat}
            />
          </>
        )}
        {/* <HStack>
          <HStack>
            <Tooltip label="Afficher moyenne" hasArrow>
              <IconButton
                aria-label="afficher moyenne"
                icon={<PercentageIcon size="24" />}
                bg="lightGrey"
              />
            </Tooltip>
            <Tooltip label="Afficher l'avancement" hasArrow>
              <IconButton
                aria-label="afficher l'avancement"
                icon={<AnswersIcon />}
                bg="lightGrey"
              />
            </Tooltip>
            <Divider
              orientation="vertical"
              h="20px"
              borderWidth={"2px"}
              rounded="xl"
            />
            <Tooltip label="Filtrer" hasArrow>
              <IconButton
                ref={btnRef}
                onClick={onOpen}
                icon={<FilterIcon />}
                aria-label="filter"
                _hover={{ bg: "darkGrey" }}
                _active={{ bg: "blue-light", color: "white" }}
              />
            </Tooltip>
          </HStack>
        </HStack> */}
      </HStack>
      {isLoadingSeries || isFetchingSeries || seriesStatsIsLoading ? (
        Array.apply(null, Array(3)).map((el: any, index: number) => (
          <Box my="4" key={index}>
            <LoadingExams />
          </Box>
        ))
      ) : filters.groupBy !== "Rien" ? (
        series.map((el: any, index: number) => (
          <Box key={el._id._id || el._id} my="4">
            <Heading
              as="h1"
              fontSize={{ base: "20px", md: "25px" }}
              color={textColor}
              my="6"
            >
              {el._id.name || el._id}
            </Heading>
            <SeriesSlider
              data={el.data.sort(compare)}
              filters={filters}
              url={url}
              statsPerQCMId={statsPerQCMId}
              statsFormat={statsFormat}
            />
          </Box>
        ))
      ) : (
        <Box my="4">
          <HStack align={"center"}>
            <Heading as="h1" size="md" color={textColor}>
              Toutes les séries
            </Heading>
          </HStack>
          <Grid
            gridTemplateColumns={"repeat(auto-fill, minmax(200px, 1fr))"}
            justifyItems="center"
            gap="20px"
            my="4"
          >
            {series.sort(compare).map((serie: any) => (
              <LinkBox
                key={serie._id}
                as="article"
                w="full"
                maxW={"400px"}
                bg={bgBox}
                borderWidth="1px"
                borderColor={"transparent"}
                rounded=".8rem"
                transition={"all 0.15s ease-in-out"}
                _hover={{
                  borderColor: "darkSky",
                  boxShadow: "xs",
                  cursor: "pointer",
                  "& .xxx": { bg: "transparent" },
                }}
                overflow="hidden"
                onClick={() => history.push(`${url}/${serie._id}`)}
              >
                <Stack
                  color={bgStack}
                  h="full"
                  justify={"space-between"}
                  align="center"
                >
                  <VStack align={"center"} p="5">
                    <Icon as={TestIcon} />
                    <Heading fontSize={"md"}>{serie?.university?.name}</Heading>
                    <Text>{serie.year}</Text>
                  </VStack>
                  <VStack
                    align={"stretch"}
                    bg="darkGrey"
                    alignSelf={"stretch"}
                    p="4"
                    className="xxx"
                    transition={"all 0.3s"}
                  >
                    <HStack>
                      <AnswersIcon />
                      <Text fontSize="xs">768 / 3 840</Text>
                    </HStack>
                    <HStack
                      spacing={0}
                      bg="white"
                      rounded={"md"}
                      overflow="hidden"
                    >
                      <Tooltip label="Bonne réponse" hasArrow>
                        <Box width={"55%"} bg="light-green" h="1" />
                      </Tooltip>
                      <Tooltip label="Réponse partielle" hasArrow>
                        <Box width={"25%"} bg="lightOrange" h="1" />
                      </Tooltip>
                      <Tooltip label="Mauvaise réponse" hasArrow>
                        <Box width={"10%"} bg="lightRed" h="1" />
                      </Tooltip>
                    </HStack>
                  </VStack>
                </Stack>
              </LinkBox>
            ))}
          </Grid>
        </Box>
      )}
      <FilterSeries
        btnRef={btnRef}
        isOpen={isOpen}
        onClose={onClose}
        filters={filters}
        setFilters={setFilters}
      />
    </SidebarLayout>
  );
}
