import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import Main from "./Main";
import reportWebVitals from "./reportWebVitals";
import { ChakraProvider } from "@chakra-ui/react";
import myTheme from "./theme";
import { QueryClientProvider, QueryClient } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { ModalProvider } from "./modal-provider/ModalProvider";
import "moment/locale/fr";
import ReactGA from 'react-ga4';

ReactGA.initialize('G-7QZHT95REV');

const queryClient = new QueryClient({
  defaultOptions: { 
    queries: { 
      refetchOnWindowFocus: false,
      onError: (err: any) => {
        if(err.response?.status === 401 && err.response?.data?.message === "Unauthorized"){
          localStorage.removeItem("access_token");
          window.location.href = `/connexion`
        } else if(err.response?.status === 403){
          window.location.href = `/connexion?session=invalid`
        }
      }
    } 
  }
});

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ChakraProvider theme={myTheme}>
      <ModalProvider>
        <Main />
      </ModalProvider>
      </ChakraProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
