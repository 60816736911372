export default function AnswerRightIcon() {
  return (
    <svg
      id="tick-circle"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <rect
        id="Rectangle_90"
        data-name="Rectangle 90"
        width="24"
        height="24"
        rx="5"
        fill="#7fcf91"
        opacity="0"
      />
      <g
        id="Group_156"
        data-name="Group 156"
        transform="translate(2.325 2.325)"
      >
        <path
          id="Path_106"
          data-name="Path 106"
          d="M10.8,20.475A9.675,9.675,0,1,1,20.475,10.8,9.683,9.683,0,0,1,10.8,20.475Zm0-18A8.325,8.325,0,1,0,19.125,10.8,8.335,8.335,0,0,0,10.8,2.475Z"
          transform="translate(-1.125 -1.125)"
          fill="#7fcf91"
        />
        <path
          id="Path_107"
          data-name="Path 107"
          d="M9.522,14.022a.675.675,0,0,1-.477-.2L6.5,11.277a.675.675,0,0,1,.954-.954l2.07,2.07,4.626-4.626a.675.675,0,1,1,.954.954l-5.1,5.1a.675.675,0,0,1-.477.2Z"
          transform="translate(-1.125 -1.125)"
          fill="#7fcf91"
        />
      </g>
    </svg>
  );
}
