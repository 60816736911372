import { Button, SimpleGrid } from '@chakra-ui/react';
import FacebookIcon from '../../assets/icons/FacebookIcon';
import GoogleIcon from '../../assets/icons/GoogleIcon';

export default function SocialNetWorkBtns({isLoading}: {isLoading: boolean}) {
  return <SimpleGrid gap={"4"} columns={[1, 2]}>
  <a href={`${process.env.REACT_APP_API_URL}/api/auth/google`}>
  <Button
    bg={"#4285F4"}
    color="white"
    w="full"
    fontSize={"lg"}
    _hover={{ bg: "blue-light" }}
    height={{base: "2.5rem", "2xl":"3rem"}}
    leftIcon={<GoogleIcon />}
    disabled={isLoading}
    >
      Google
  </Button>
    </a>
    <a href={`${process.env.REACT_APP_API_URL}/api/auth/facebook`}>
  <Button
    colorScheme={"facebook"}
    w="full"
    fontSize={"lg"}
    height={{base: "2.5rem", "2xl":"3rem"}}
    leftIcon={<FacebookIcon />}
    disabled={isLoading}
  >
      Facebook
  </Button>
    </a>
</SimpleGrid>;
}
