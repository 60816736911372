import { ReactNode } from "react";
import { BrowserRouter, Route, Redirect } from "react-router-dom";

const Router = ({ children }: { children: ReactNode }) => {
  return (
    <BrowserRouter>
      <Route
        // @ts-ignore
        render={({ location: { pathname, hash, search } }) => {
          const ends_with_slash =
            pathname.slice(-1) === "/" && pathname !== "/";
          return ends_with_slash ? (
            <Redirect to={`${pathname.slice(0, -1)}${search}${hash}`} />
          ) : (
            children
          );
        }}
      />
    </BrowserRouter>
  );
};

export default Router;
