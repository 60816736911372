import {
  Box,
  Divider,
  Grid,
  GridItem,
  HStack,
  Skeleton,
  Stack,
  Text,
  useBreakpoint,
  useBreakpointValue,
} from "@chakra-ui/react";
import React, { useCallback } from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { SwiperSlide } from "swiper/react";
import { getWorseAttempts } from "../../services/dashboard/dashboard";
import SliderContainer from "../Slider/SliderContainer";
import DashboardBloc from "./DashboardBloc";
import Marquee from "react-fast-marquee";
import noData from "../../assets/img/no_data.svg"

export default function WorseAttemptsSection() {
  const { data: worseAttempts, isLoading: loadingWorseAttempts } = useQuery(
    "worse-attempts",
    getWorseAttempts,
    {
      staleTime: 60000 * 15
    }
  );

  return (
    <SliderContainer title="À retravailler">
      {loadingWorseAttempts
        ? Array.apply(null, Array(4)).map((el: any, index: number) => (
            <SwiperSlide key={index}>
              <Skeleton
                rounded={"0.8rem"}
                w="full"
                h="103px"
                endColor={"white"}
                startColor={"whiteAlpha.600"}
              />
            </SwiperSlide>
          ))
        : worseAttempts?.length === 0 ? (
          <Stack align={"center"}>
            <img src={noData} width="80px" />
            <Text>Commencer maintenant</Text>
          </Stack>
        ) : worseAttempts.map((attempt: any) => (
            <SwiperSlide key={attempt._id}>
              <Link
                to={`/series/${attempt.qcm.speciality.name}/${attempt.qcm.subject.name}/${attempt.qcm._id}`}
              >
                <WorseAttempt attempt={attempt} />
              </Link>
            </SwiperSlide>
          ))}
    </SliderContainer>
  );
}

export const WorseAttempt = ({ attempt }: any) => {
  const attemtpColor = useCallback(() => {
    if (attempt.overall >= 15) {
      return "darkSky";
    } else if (attempt.overall >= 10) {
      return "gold-yellow";
    } else {
      return "lightRed";
    }
  }, []);

  const breakpointsValues = useBreakpointValue({
    base: true,
    md: true,
    lg: true,
    xl: true,
    "2xl": false,
  });
  const bkPoints = useBreakpoint();
  const shortnameBreakpoints = useBreakpointValue({
    base: attempt.qcm.subject.shortname ?? attempt.qcm.subject.name,
    xl: attempt.qcm.subject.name,
  })


  return (
    <HStack
      bg="whiteAlpha.700"
      rounded={"0.7rem"}
      px="4"
      py="3"
      position={"relative"}
      // overflow="hidden"
    >
      <Box p={{ base: "2", md: "4" }}>
        <Stack spacing={-1} align="center">
          <Text fontWeight={"medium"} fontSize="lg" color={attemtpColor()}>
            {attempt.overall
              ? attempt.overall % 1 === 0
                ? attempt.overall
                : Math.round(attempt.overall * 100) / 100
              : 0}
          </Text>
          <Divider opacity={1} borderColor={attemtpColor()} />
          <Text fontWeight={"medium"} fontSize="lg" color={attemtpColor()}>
            20
          </Text>
        </Stack>
      </Box>
      <Stack spacing={0} align="flex-start" overflow={"hidden"}>
        <HStack spacing={2}>
          {/* <Text
            isTruncated
            color="gray.700"
            maxW={{ base: "9rem", md: "9rem", "2xl": "unset" }}
          >
            {attempt.qcm.speciality.name}
          </Text>
          <Text>{">"}</Text> */}
          {/* <Text
            isTruncated
            color="gray.700"
            maxW={{ base: "12rem", sm: "unset", md:"7.5rem", xl: "8rem", "2xl": "unset" }}
          >
            {attempt.qcm.subject.name}
          </Text> */}
          {(bkPoints === "xl" || bkPoints === "2xl") && (
            <Marquee
              style={{ marginRight: "-30px" }}
              play={attempt.qcm.subject.name.length >= 26 && breakpointsValues}
              gradient={false}
              pauseOnHover={true}
            >
              <Text color="gray.700">{attempt.qcm.subject.name}</Text>
            </Marquee>
          )}
          <Text color="gray.700" display={{ base: "inline", xl: "none" }}>
            {shortnameBreakpoints}
          </Text>
        </HStack>
        <HStack spacing={1}>
          <Text color="gray.600" fontWeight={"light"} fontSize="sm">
            {attempt.qcm.university.name}
          </Text>
          <Text color="gray.400" fontSize={"sm"}>•</Text>
          <Text color="gray.400" fontWeight={"light"} fontSize="sm">
            {attempt.qcm.year}
          </Text>
        </HStack>
      </Stack>
    </HStack>
  );
};
