import {
  Box,
  HStack,
  IconButton,
  Skeleton,
  Stack,
  Text,
} from "@chakra-ui/react";
import { RefObject, useRef } from "react";
import { SwiperSlide } from "swiper/react";
import FolderOpenIcon from "../../assets/icons/FolderOpenIcon";
import { AiOutlineRight, AiOutlineLeft } from "react-icons/ai";
import { getAttemptsStatsByType } from "../../services/series/services";
import { useQuery } from "react-query";
import SliderContainer from "./SliderContainer";
import noData from "../../assets/img/no_data.svg"

type Speciality = {
  speciality: string;
  questionsCount: number;
  answersCount: number;
  result: {
    overall: number;
    correct: number;
    fausse: number;
    partiel: number;
  };
};

export default function SliderWrapper() {
  const { data: seriesStats, isLoading: seriesStatsIsLoading } = useQuery(
    "series-stats",
    () => getAttemptsStatsByType("serie"),
    {
      refetchOnWindowFocus: false,
      retry: false
      // staleTime: 60000 * 15
    }
  );

  return (
    <SliderContainer title="Progrès">
      {seriesStatsIsLoading
        ? Array.apply(null, Array(4)).map((el: any, index: number) => (
            <SwiperSlide key={index}>
              <Skeleton
                rounded={"0.7rem"}
                w="full"
                h="103px"
                endColor={"white"}
                startColor={"whiteAlpha.600"}
              />
            </SwiperSlide>
          ))
        : seriesStats?.length === 0 ? (
          <Stack align={"center"}>
          <img src={noData} width="80px" />
          <Text>Commencer maintenant</Text>
        </Stack>
        ) : seriesStats.map((speciality: Speciality) => (
            <SwiperSlide key={speciality.speciality}>
              <SpecialityProgressItem
                value={speciality?.speciality || ""}
                subValue={String(
                  speciality?.result?.overall % 1 === 0
                    ? speciality?.result?.overall
                    : speciality?.result?.overall?.toFixed(2) || 0
                )?.concat("/20")}
              />
            </SwiperSlide>
          ))}
    </SliderContainer>
  );
}

export const SpecialityProgressItem = ({
  value,
  subValue,
  bg="whiteAlpha.700"
}: {
  value: string;
  subValue: string | number;
  bg?: string
}) => {
  return (
    <HStack bg={bg} rounded={"0.7rem"} p="4">
      <Box p={{ base: "0", md: "2" }} py={{ base: "0", md: "4" }}>
        <FolderOpenIcon />
      </Box>
      <Stack flex={1} spacing={0} align="flex-start">
        <Text isTruncated maxW={{ base: "9rem", md: "9rem", "2xl": "unset" }}>
          {value}
        </Text>
        <HStack>
          <Text color="gray.600" fontWeight={"light"}>
            {/* {speciality.result.overall.toFixed(2)}/20 */}
            {subValue}
          </Text>
        </HStack>
        {/* <table style={{ alignSelf: "stretch" }}>
          <tbody>
            <tr>
              <td style={{ width: "fit-content" }}>
                <HStack spacing={1}>
                  <PercentageIcon bg="#7FCACF" size="24" />
                  <Text fontSize={"xs"} color="gray">
                    {(
                      (speciality?.answersCount / speciality?.questionsCount) *
                      100
                    ).toFixed(2) + "%"}
                  </Text>
                </HStack>
              </td>
              <td style={{ width: "100%" }}>
                <HStack
                  spacing={0}
                  bg="#E3F3F4"
                  rounded={"md"}
                  overflow="hidden"
                  width={"70%"}
                  minWidth="70px"
                  mx="auto"
                  // width={"100%"}
                >
                  <motion.div
                    initial={{ width: "0px" }}
                    style={{ height: "8px", background: "#7FCACF" }}
                    animate={{
                      width:
                        (((speciality?.answersCount ?? 0) /
                          speciality?.questionsCount) *
                          100 || 0) + "%",
                    }}
                    exit={{ width: "0px" }}
                    transition={{ default: { duration: 0.75 } }}
                  />
                </HStack>
              </td>
            </tr>
            <tr>
              <td style={{ width: "fit-content" }}>
                <HStack spacing={1}>
                  <AnswersIcon color="#7FCACF" />
                  <Text fontSize={"xs"} color="gray">
                    {speciality.result.overall.toFixed(2)}/20
                  </Text>
                </HStack>
              </td>
              <td style={{ width: "100%" }}>
                <HStack
                  spacing={0}
                  bg="#E3F3F4"
                  rounded={"md"}
                  overflow="hidden"
                  width={"70%"}
                  minWidth="70px"
                  // width={"100%"}
                  mx="auto"
                >
                  <motion.div
                    initial={{ width: "0px" }}
                    style={{ height: "8px", background: "#7FCACF" }}
                    animate={{
                      width:
                        (((speciality.result.overall ?? 0) / 20) * 100 || 0) +
                        "%",
                    }}
                    exit={{ width: "0px" }}
                    transition={{ default: { duration: 0.75 } }}
                  />
                </HStack>
              </td>
            </tr>
          </tbody>
        </table> */}
      </Stack>
    </HStack>
  );
};

export const Controller = ({
  prevRef,
  nextRef,
}: {
  prevRef: RefObject<HTMLButtonElement>;
  nextRef: RefObject<HTMLButtonElement>;
}) => {
  return (
    <HStack align="center" spacing={-1}>
      <IconButton
        zIndex={2}
        aria-label="next-btn"
        icon={<AiOutlineLeft color="#4B62F0" />}
        ref={prevRef}
        fontSize={"xl"}
        _hover={{}}
        _active={{}}
        _focus={{ outline: "none" }}
        rounded="full"
        bg="transparent"
      />
      <IconButton
        zIndex={2}
        aria-label="next-btn"
        icon={<AiOutlineRight color="#4B62F0" />}
        ref={nextRef}
        fontSize={"xl"}
        _hover={{}}
        _active={{}}
        rounded="full"
        bg="transparent"
        _focus={{ outline: "none" }}
      />
    </HStack>
  );
};
