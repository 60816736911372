import axiosInstance from "../../utils/axiosInstance";

export const updateProfile = async (data: any) => {
  let token = localStorage.getItem("access_token");
  return await axiosInstance({
    method: "put",
    url: `/user/update`,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res: any) => res.data);
};

export const changePassword = async (data: any) => {
  let token = localStorage.getItem("access_token");
  return await axiosInstance({
    method: "put",
    url: `/user/profile/password/update`,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res: any) => res.data);
};


export const resetAccount = async () => {
  let token = localStorage.getItem("access_token");
  return await axiosInstance({
    method: "get",
    url: `/user/account/reset`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res: any) => res.data);
};


