import { useRef, useState } from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
} from "@chakra-ui/react";
import ExamModeGroup from "./RadioGroup";

interface Props {
  label: string;
  buttonVariant: string;
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  start: (mode: string) => void;
}

const ExamModeSelectorDialog = ({
  label,
  buttonVariant,
  isOpen,
  onOpen,
  onClose,
  start,
}: Props) => {
  const cancelRef = useRef<HTMLButtonElement>(null);
  const [isSerieMode, setIsSerieMode] = useState<string>("");

  const handleCloseDialog = () => {
    setIsSerieMode("");
    onClose();
  };

  return (
    <>
      <Button variant={buttonVariant} onClick={onOpen}>
        {label}
      </Button>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={handleCloseDialog}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />
        <AlertDialogContent rounded="1rem">
          <AlertDialogHeader>Sélectionnez le mode</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            <ExamModeGroup
              onChange={(x) => setIsSerieMode(x)}
              isSerieMode={isSerieMode}
            />
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              variant={"secondary"}
              ref={cancelRef}
              onClick={handleCloseDialog}
            >
              Annuler
            </Button>
            <Button
              variant={"primary"}
              ml={3}
              disabled={!isSerieMode}
              onClick={() => start(isSerieMode)}
            >
              Commencer
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default ExamModeSelectorDialog;
