import { Box, Center, Spinner, Stack, Text } from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import PageHeading from "../../../components/PageHeading/PageHeading";
import { SocketContext } from "../../../context/socket";
import SidebarLayout from "../../../layouts/SidebarLayout";
import {
  fetchNotifications,
  setNotificationsAsSeen,
} from "../../../services/notifications/services";
import ExamNotification from "../../../components/Notification/ExamNotification";
import SerieNotification from "../../../components/Notification/SerieNotification";

export default function Notifications() {
  const [page, setPage] = useState(0);
  const [newNotification, setNewNotification] = useState(false);
  const socket = useContext(SocketContext);

  const { mutateAsync: notificationsSeen } = useMutation(
    setNotificationsAsSeen
  );

  const [notifications, setNotifications] = useState<any>({
    results: []
  });
  const { isLoading: isLoadingNotifications, isFetching: isFetchingNextPage } = useQuery(
    ["notifications", newNotification, page],
    () => fetchNotifications(page),
    { 
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      onSuccess: (response: any) => {
        if(response.nextPage !== notifications?.nextPage){
          setNotifications((prevState: any) => ({...response, results: [...prevState.results, ...response.results]}))
        } else {
          setNotifications(response)
        }
      }
    }
  );

  useEffect(() => {
    socket.on("message", () => {
      setNewNotification(true);
    });
    return () => {
      socket.off("message");
    };
  }, [socket]);

  useEffect(() => {
    if (notifications?.results?.length) {
      const notSeenNotifications = notifications?.results.filter((notif: any) => !notif.seen).map((el: any) => el._id);
      notificationsSeen(notSeenNotifications);
    }
  }, [notifications]);


  return (
    <SidebarLayout>
      <PageHeading title="Notifications" />
      {isLoadingNotifications ? (
        <p>Chargement...</p>
      ) : notifications?.results?.length === 0 ? (
        <Text mt="8">Aucune notification à afficher</Text>
      ) : (
        <Stack mt="8" spacing={3}>
          {notifications?.results?.map(
            (notification: any) => (
              <Box key={notification._id}>
                {notification.type === "examen" ? (
                  <ExamNotification
                    notification={notification.data}
                    createdAt={notification.createdAt}
                    seen={notification.seen}
                  />
                ) : notification.type === "serie" ? (
                  <SerieNotification
                    notification={notification.data}
                    createdAt={notification.createdAt}
                    seen={notification.seen}
                  />
                ) : (
                  <p>other</p>
                )}
              </Box>
            )
          )}
          {isFetchingNextPage && (
              <Center>
                <Spinner size={"md"} color="gold-yellow" />
              </Center>
            )}
          {notifications.nextPage < notifications.totalPages && !isFetchingNextPage && <button onClick={() => setPage((prevState) => prevState + 1)}>
            Page suivante
          </button>}
        </Stack>
      )}
    </SidebarLayout>
  );
}
