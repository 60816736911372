import { Box } from "@chakra-ui/react";
// @ts-ignore
import Output from "editorjs-react-renderer";

export default function QuestionEnnonce({ ennonce }: any) {
  return (
    <Box
      mt="4"
      rounded={"xl"}
      bg="#F4F9FA"
      px="4"
      py="2"
      fontSize={"14px"}
      position="relative"
      border="1px solid"
      borderColor={"light-gray-new"}
    >
      <Output data={ennonce} />
    </Box>
  );
}
