import { ReactNode } from "react";
import {
  Box,
  Heading,
  HStack,
  List,
  ListIcon,
  ListItem,
  Skeleton,
  Stack,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import ProgressIcon from "../../assets/icons/ProgressIcon";
import StatsChart from "../StatsChart/StatsChart";
import { FcCancel, FcCheckmark } from "react-icons/fc";
import PercentageIcon from "../../assets/icons/PercentageIcon";
import moment from "moment";
import ChronoIcon from "../../assets/icons/ChronoIcon";
import { secondsToHMS } from "../../utils/timerConverter";
import CalendarIcon from "../../assets/icons/CalendarIcon";

type Props = {
  isLoading: boolean;
  data: any;
};

export default function RecentAttemptsList({ isLoading, data }: Props) {

  if (isLoading) {
    return (
      <Box alignSelf={"stretch"}>
        <Skeleton
          height="20px"
          width={"100px"}
          startColor="darkGrey"
          endColor={"lightGrey"}
          mb="2"
        />
        <Skeleton
          height="100px"
          width={"full"}
          rounded={"xl"}
          startColor="darkGrey"
          endColor={"lightGrey"}
        />
      </Box>
    );
  }

  return (
    <Box alignSelf={"stretch"} pb={{base: "6", xl: "0"}}>
      <Heading color="blue-light" as="h4" fontSize={"lg"} mb="2">
        Tentatives récentes:
      </Heading>
      <Box bg="lightGrey" p="4" rounded={"xl"} w="full">
        <List spacing={4}>
          {data.map((attempt: any) => (
            <AttemptItem attempt={attempt} key={attempt._id} />
          ))}
        </List>
      </Box>
    </Box>
  );
}

const AttemptItem = ({ attempt }: any) => {
  const answeredCount = attempt.correct + attempt.fausse + attempt.partiel;
  const finished = answeredCount === attempt.questionsCount;
  const hours = secondsToHMS(attempt.duration).hours;
  const minutes = secondsToHMS(attempt.duration).minutes;
  const seconds = secondsToHMS(attempt.duration).seconds;
  const duration =
    (hours ? hours + "h " : "") +
    (minutes ? minutes + "min " : "") +
    Math.floor(seconds) +
    "sec";

  return (
    <>
      <Tooltip label={finished ? "Terminée" : "Incomplète"} hasArrow>
        <ListItem>
          <Stack
            spacing={3}
            align={{ base: "flex-start", md: "center" }}
            direction={{ base: "column", md: "row" }}
            mb="2"
          >
            <HStack spacing={2} align="baseline">
              {/* <ListIcon
                as={finished ? FcCheckmark : FcCancel}
                color={finished ? "green.500" : "yellow.500"}
              /> */}
              <RecentAttemptCell
                title="Progrès"
                icon={<ProgressIcon size="20" />}
                value={answeredCount}
                dividedBy={attempt.questionsCount}
              />
              <RecentAttemptCell
                title="Moyenne"
                icon={<PercentageIcon size="20" />}
                value={
                  attempt.overall % 1 === 0
                    ? attempt.overall
                    : attempt.overall?.toFixed(2)
                }
                // dividedBy={20}
              />
            {/* </HStack> */}
            {/* <HStack spacing={3}> */}
              <Box ml={{ base: "9", md: "unset" }}>
                <RecentAttemptCell
                  title="Durée"
                  icon={<ChronoIcon bg="#000" size="20" />}
                  value={duration}
                />
              </Box>
              <RecentAttemptCell
                title="Date"
                icon={<CalendarIcon size="20" />}
                value={moment(attempt.updatedAt).format("DD/MM/YY")}
              />
            </HStack>
          </Stack>
          <StatsChart
            orientation="horizontal"
            questionsCount={attempt.questionsCount}
            stats={{
              correct: attempt.correct,
              fausse: attempt.fausse,
              partial: attempt.partiel,
            }}
          />
        </ListItem>
      </Tooltip>
    </>
  );
};

const RecentAttemptCell = ({
  title,
  icon,
  value,
  dividedBy,
}: {
  title: string;
  icon: ReactNode;
  value: number | string;
  dividedBy?: number | string;
}) => {
  return (
    // <Stack
    //   spacing={{ base: 0, md: 1 }}
    //   direction={{ base: "column", md: "row" }}
    //   align={{ base: "baseline", md: "center" }}
    // >
      <HStack spacing={1}>
        {icon}
        <Text fontSize={{base: "xs", md: "sm"}} fontWeight={600} display={{base: "none", md:"inline"}}>
          {title}:
        </Text>
      <Text fontSize={{base: "xs", md: "sm"}} color="gray" whiteSpace={"nowrap"}>
        {value}
        {dividedBy ? `/${dividedBy}` : ""}
      </Text>
      </HStack>

    // </Stack>
  );
};
