export default function PercentageIcon({bg="#292d32", size="40"}:{bg?: string, size?:string}) {
  return (
    <svg
      id="percentage-square"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 16 16"
    >
      <rect
        id="Rectangle_1052"
        data-name="Rectangle 1052"
        width={size}
        height={size}
        rx="5"
        fill={bg}
        opacity="0"
      />
      <g
        id="Group_1193"
        data-name="Group 1193"
        transform="translate(1.55 1.55)"
      >
        <path
          id="Path_210"
          data-name="Path 210"
          d="M9.375,14.025h-3.6c-3.258,0-4.65-1.392-4.65-4.65v-3.6c0-3.258,1.392-4.65,4.65-4.65h3.6c3.258,0,4.65,1.392,4.65,4.65v3.6C14.025,12.633,12.633,14.025,9.375,14.025Zm-3.6-12c-2.766,0-3.75.984-3.75,3.75v3.6c0,2.766.984,3.75,3.75,3.75h3.6c2.766,0,3.75-.984,3.75-3.75v-3.6c0-2.766-.984-3.75-3.75-3.75Z"
          transform="translate(-1.125 -1.125)"
          fill={bg}
        />
        <path
          id="Path_211"
          data-name="Path 211"
          d="M7.489,12a.445.445,0,0,1-.318-.132.452.452,0,0,1,0-.636l3.93-3.93a.45.45,0,0,1,.636.636l-3.93,3.93A.445.445,0,0,1,7.489,12Z"
          transform="translate(-3.097 -3.142)"
          fill={bg}
        />
        <path
          id="Path_212"
          data-name="Path 212"
          d="M7.488,8.811A1.188,1.188,0,1,1,8.676,7.623,1.191,1.191,0,0,1,7.488,8.811Zm0-1.47a.288.288,0,0,0-.288.288.288.288,0,1,0,.576,0,.288.288,0,0,0-.288-.288Z"
          transform="translate(-2.85 -2.895)"
          fill={bg}
        />
        <path
          id="Path_213"
          data-name="Path 213"
          d="M13.373,13.968a1.188,1.188,0,1,1,1.188-1.188,1.191,1.191,0,0,1-1.188,1.188Zm0-1.47a.288.288,0,0,0-.288.288.288.288,0,1,0,.288-.288Z"
          transform="translate(-4.812 -4.614)"
          fill={bg}
        />
      </g>
    </svg>
  );
}
