import { Flex, Icon, Menu, MenuButton, MenuItemOption, MenuList, MenuOptionGroup } from '@chakra-ui/react'
import { ChevronDownIcon } from "@chakra-ui/icons";
import { ISelectField } from '../../types';
import { Controller, useFormContext } from 'react-hook-form';
import _ from "lodash";

export default function SelectField({
    options,
    name,
    value,
    closeOnSelect = true,
    bg = "darkGrey",
    bg_hover="lightGrey",
    placeholder,
    type = "radio",
    height
}: ISelectField) {
  const { control, watch } = useFormContext();

    return (
      <Controller
        name={name}
        control={control}
        defaultValue={_.get(watch(), name)}
        render={({ field: { ref, ...rest } }) => (
          <Menu closeOnSelect={closeOnSelect}>
            <MenuButton
              px={4}
              py={2}
              w="100%"
              height={{base: "2.5rem", "2xl":"3rem"}}
              // height={"3rem"}
              bg={bg}
              transition="all 0.2s"
              borderRadius="md"
              _hover={{bg: bg_hover}}
              _active={{bg: bg_hover}}
              type='button'
            >
              <Flex as={"div"} align="center" justifyContent="space-between">
                {(value && options.find(el => el.value === value)?.label) || placeholder} <Icon as={ChevronDownIcon} />
              </Flex>
            </MenuButton>
            <MenuList width={"100%"} h={height || "auto"} overflowY={"auto"}>
              <MenuOptionGroup
                {...rest}
                  value={value}
                type={type}

              >
                  {
                      options.map((option: any) => (
                          <MenuItemOption value={option.value} key={option.id} _hover={{bg: "blue-light", color:"white"}}>
                              {option?.label}
                          </MenuItemOption>
                      ))
                  }
              </MenuOptionGroup>
            </MenuList>
          </Menu>
        )}
      />
    )
}
