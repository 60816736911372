import axiosInstance from "../../utils/axiosInstance";

export const getStats = async (type: string) => {
    let access_token = localStorage.getItem("access_token");
    return await axiosInstance({
      method: "get",
      url: `/attempts/performance/${type}`,
      headers:{
        Authorization: `Bearer ${access_token}`
      }
    }).then((res: any) => res.data);
};
export const getResultsSeriesByDay = async (type: string) => {
    let access_token = localStorage.getItem("access_token");
    return await axiosInstance({
      method: "get",
      url: type === "examen" ? "/attempts/exam/results-by-day" : type === "serie" ? `/attempts/results/results-by-day/${type}` : "",
      headers:{
        Authorization: `Bearer ${access_token}`
      }
    }).then((res: any) => res.data);
};