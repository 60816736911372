

export default function VoucherIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.02 20.25H7.5C6.75 20.25 6.2 20.22 5.73 20.15C2.4 19.78 1.75 17.8 1.75 14.5V9.5C1.75 6.2 2.41 4.21 5.76 3.84C6.2 3.78 6.75 3.75 7.5 3.75H10.96C11.37 3.75 11.71 4.09 11.71 4.5C11.71 4.91 11.37 5.25 10.96 5.25H7.5C6.82 5.25 6.34 5.28 5.95 5.33C3.92 5.55 3.25 6.19 3.25 9.5V14.5C3.25 17.81 3.92 18.44 5.92 18.67C6.34 18.73 6.82 18.75 7.5 18.75H11.02C11.43 18.75 11.77 19.09 11.77 19.5C11.77 19.91 11.43 20.25 11.02 20.25Z"
        fill="#bbb"
      />
      <path
        d="M16.4995 20.25H15.0195C14.6095 20.25 14.2695 19.91 14.2695 19.5C14.2695 19.09 14.6095 18.75 15.0195 18.75H16.4995C17.1795 18.75 17.6595 18.72 18.0495 18.67C20.0795 18.45 20.7495 17.81 20.7495 14.5V9.5C20.7495 6.19 20.0795 5.56 18.0795 5.33C17.6595 5.27 17.1795 5.25 16.4995 5.25H15.0195C14.6095 5.25 14.2695 4.91 14.2695 4.5C14.2695 4.09 14.6095 3.75 15.0195 3.75H16.4995C17.2495 3.75 17.7995 3.78 18.2695 3.85C21.5995 4.22 22.2495 6.2 22.2495 9.5V14.5C22.2495 17.8 21.5895 19.79 18.2395 20.16C17.7995 20.22 17.2495 20.25 16.4995 20.25Z"
        fill="#bbb"
      />
      <path
        d="M15 22.75C14.59 22.75 14.25 22.41 14.25 22V2C14.25 1.59 14.59 1.25 15 1.25C15.41 1.25 15.75 1.59 15.75 2V22C15.75 22.41 15.41 22.75 15 22.75Z"
        fill="#bbb"
      />
      <path
        d="M6.69922 13C6.56922 13 6.43922 12.97 6.31922 12.92C6.19922 12.87 6.08922 12.8 5.98922 12.71C5.89922 12.61 5.81922 12.5 5.76922 12.38C5.71922 12.26 5.69922 12.13 5.69922 12C5.69922 11.74 5.80922 11.48 5.98922 11.29C6.35922 10.92 7.02922 10.92 7.40922 11.29C7.58922 11.48 7.69922 11.74 7.69922 12C7.69922 12.13 7.66922 12.26 7.61922 12.38C7.56922 12.5 7.49922 12.61 7.40922 12.71C7.30922 12.8 7.19922 12.87 7.07922 12.92C6.95922 12.97 6.82922 13 6.69922 13Z"
        fill="#bbb"
      />
      <path
        d="M10.6992 13C10.5692 13 10.4392 12.97 10.3192 12.92C10.1992 12.87 10.0892 12.8 9.98922 12.71C9.89922 12.61 9.82922 12.5 9.76922 12.38C9.72922 12.26 9.69922 12.13 9.69922 12C9.69922 11.74 9.80922 11.48 9.98922 11.29C10.3592 10.92 11.0392 10.92 11.4092 11.29C11.5892 11.48 11.6992 11.74 11.6992 12C11.6992 12.13 11.6692 12.26 11.6192 12.38C11.5692 12.5 11.4992 12.61 11.4092 12.71C11.3092 12.8 11.1992 12.87 11.0792 12.92C10.9592 12.97 10.8292 13 10.6992 13Z"
        fill="#bbb"
      />
    </svg>
  );
}
