import { Accordion, Box, Stack, useBreakpoint, VStack } from "@chakra-ui/react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import ErrorBanner from "../../../components/ErrorBanner/ErrorBanner";
import Loading from "../../../components/Loading";
import InnerPageLayout, {
  LeftSide,
  RightSide,
} from "../../../layouts/InnerPageLayout";
import SidebarLayout from "../../../layouts/SidebarLayout";
import { getQuestion } from "../../../services/question/services";
import QuestionEnnonce from "./components/QuestionEnnonce";
import Option, { IOption } from "./components/Option";

export default function Question() {
  const { questionId } = useParams<{ questionId: string }>();
  const screenWidth = useBreakpoint();

  const { data, isLoading, isError } = useQuery(
    ["question", questionId],
    () => getQuestion({ questionId }),
    {
      retry: false,
      staleTime: Infinity,
    }
  );
  const question = data?.[0];

  if (isLoading) {
    return (
      <SidebarLayout>
        <Stack
          align={"center"}
          justify="center"
          h="calc(100vh - 80px)"
          w="100%"
        >
          <Loading />
        </Stack>
      </SidebarLayout>
    );
  }
  if (isError) {
    return (
      <SidebarLayout>
        <Stack
          align={"center"}
          justify="center"
          h="calc(100vh - 80px)"
          w="100%"
        >
          <ErrorBanner />
        </Stack>
      </SidebarLayout>
    );
  }
  return (
    <InnerPageLayout
      subTitle={
        screenWidth === "base" || screenWidth === "sm"
          ? (question.qcm.speciality.shortname ??
              question.qcm.speciality.name) +
            " / " +
            (question.qcm.subject.shortname ?? question.qcm.subject.name)
          : question.qcm.speciality.name + " / " + question.qcm.subject.name
      }
      title={question?.qcm?.university.name + " " + question.qcm.year}
    >
      <LeftSide>
        <VStack alignItems={"stretch"} spacing="6" mb="8">
          <Box>
            <QuestionEnnonce ennonce={question.value} />
            <Accordion allowMultiple rounded="md" mt="4">
              <VStack align={"stretch"} spacing={2}>
                {question.options.map((option: IOption, index: number) => (
                  <Option key={option._id} index={index} option={option} />
                ))}
              </VStack>
            </Accordion>
          </Box>
          {question.children.length !== 0 &&
            question.children.map((childQuestion: any) => (
              <Box key={childQuestion._id}>
                <QuestionEnnonce ennonce={question.value} />
                <Accordion allowMultiple rounded="md" mt="4">
                  <VStack align={"stretch"} spacing={2}>
                    {childQuestion.options.map(
                      (option: IOption, index: number) => (
                        <Option
                          key={option._id}
                          index={index}
                          option={option}
                        />
                      )
                    )}
                  </VStack>
                </Accordion>
              </Box>
            ))}
        </VStack>
      </LeftSide>
      {/* <RightSide>
        <h1>Empty</h1>
      </RightSide> */}
    </InnerPageLayout>
  );
}
