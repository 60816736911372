import { HStack, Text } from "@chakra-ui/react";
import moment from "moment";
import ExamsIcon from "../../assets/icons/ExamsIcon";
import NotificationWrapper from "./NotificationWrapper";

const ExamNotification = ({ notification, createdAt, seen }: any) => {
    return (
      <NotificationWrapper
        notificationTitle="Nouvel examen"
        notificationIcon={<ExamsIcon color="#4B62F0" />}
        notificationDate={moment(createdAt).fromNow()}
        notificationBody={
          <HStack spacing={2}>
            <Text fontSize={"xs"} color="gray.500">
              <strong>Jour:</strong> {notification.jour}
            </Text>
            <Text fontSize={"xs"} color="gray.500">
              <strong>Année:</strong> {notification.year}
            </Text>
            <Text fontSize={"xs"} color="gray.500">
              <strong>Faculté:</strong> {notification.university}
            </Text>
            <Text fontSize={"xs"} color="gray.500">
              <strong>Durée:</strong> {notification.duration}min
            </Text>
          </HStack>
        }
        notificationLink={`/examen-blanc/${notification.year}/${notification.university}/${notification.qcmId}`}
        seen={seen}
      />
    );
  };

  export default ExamNotification