export default function ViewOffIcon() {
    return (
        <svg id="eye-slash" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
  <rect id="Rectangle_1002" data-name="Rectangle 1002" width="24" height="24" rx="5" fill="#f9f9f9" opacity="0"/>
  <g id="Group_1163" data-name="Group 1163" transform="translate(1.2 1.2)">
    <path id="Path_142" data-name="Path 142" d="M8.523,13.752a.668.668,0,0,1-.477-.2,3.895,3.895,0,1,1,5.508-5.508.675.675,0,0,1,0,.954L9,13.554A.668.668,0,0,1,8.523,13.752Zm2.277-5.5A2.551,2.551,0,0,0,8.586,12.06L12.06,8.586A2.534,2.534,0,0,0,10.8,8.253Z" fill="#292d32"/>
    <path id="Path_143" data-name="Path 143" d="M5.04,16.659A.685.685,0,0,1,4.6,16.5a13.063,13.063,0,0,1-2.565-3,5.452,5.452,0,0,1,0-5.4C4.23,4.662,7.425,2.682,10.8,2.682a9.373,9.373,0,0,1,5.643,1.971.675.675,0,1,1-.81,1.08,8.008,8.008,0,0,0-4.833-1.7c-2.907,0-5.688,1.746-7.632,4.8a4.09,4.09,0,0,0,0,3.942,12.357,12.357,0,0,0,2.3,2.7.681.681,0,0,1,.072.954.654.654,0,0,1-.5.234Z" fill="#292d32"/>
    <path id="Path_144" data-name="Path 144" d="M10.8,18.918a8.887,8.887,0,0,1-3.492-.72.674.674,0,1,1,.522-1.242,7.553,7.553,0,0,0,2.961.612c2.907,0,5.688-1.746,7.632-4.8a4.09,4.09,0,0,0,0-3.942,12.819,12.819,0,0,0-.909-1.26.684.684,0,0,1,.1-.954.675.675,0,0,1,.954.1,14.3,14.3,0,0,1,1,1.386,5.452,5.452,0,0,1,0,5.4c-2.2,3.438-5.391,5.418-8.766,5.418Z" fill="#292d32"/>
    <path id="Path_145" data-name="Path 145" d="M11.421,14.643a.682.682,0,0,1-.666-.549.662.662,0,0,1,.54-.783,2.538,2.538,0,0,0,2-2,.678.678,0,1,1,1.332.252,3.867,3.867,0,0,1-3.078,3.078A.59.59,0,0,0,11.421,14.643Z" fill="#292d32"/>
    <path id="Path_146" data-name="Path 146" d="M1.8,20.475a.668.668,0,0,1-.477-.2.679.679,0,0,1,0-.954L8.046,12.6A.675.675,0,1,1,9,13.554L2.277,20.277a.668.668,0,0,1-.477.2Z" fill="#292d32"/>
    <path id="Path_147" data-name="Path 147" d="M13.077,9.2A.668.668,0,0,1,12.6,9a.679.679,0,0,1,0-.954l6.723-6.723a.675.675,0,0,1,.954.954L13.554,9a.668.668,0,0,1-.477.2Z" fill="#292d32"/>
  </g>
</svg>
    )
}
