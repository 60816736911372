import { InfoOutlineIcon } from "@chakra-ui/icons";
import {
  AccordionButton,
  Flex,
  HStack,
  IconButton,
  Square,
  Text,
} from "@chakra-ui/react";
import alphabet from "../../utils/alphabets.json";

export default function OptionItem({
  option,
  index,
  select,
  isSelected,
  answered,
  letterColor,
  letterBg,
  correction,
  questionId,
}: any) {
  return (
    <AccordionButton
      _active={{}}
      _focus={{}}
      _expanded={{ bg: "#7FCF9" }}
      as="span"
      cursor={"pointer"}
      p="0"
      pr="4"
      _hover={
        !answered
          ? {
              "& .nested-alpha-box": { bg: "lightGrey" },
            }
          : {}
      }
      onClick={() => {
        if (!answered) {
          select(questionId, option._id);
        }
      }}
    >
      <HStack justify={"space-between"} w="full">
        <HStack spacing={2} align="stretch">
          <Flex
            p="2"
            align="center"
            justify="center"
            width={"55px"}
            className="alpha-box"
            bg={answered ? letterBg : "darkGrey"}
            height={"auto"}
          >
            <Square
              size={"40px"}
              className="nested-alpha-box"
              bg={
                answered
                  ? "transparent"
                  : isSelected
                  ? "lightGrey"
                  : "transparent"
              }
              transition={"background 0. 12s"}
              rounded={"md"}
              color={
                answered ? letterColor : isSelected ? "blue-light" : "black"
              }
              fontWeight={isSelected ? "bold" : "normal"}
            >
              {alphabet[index]}
            </Square>
          </Flex>
          <Text p="3" fontSize={"14px"} alignSelf="center">
            {option.value}
          </Text>
        </HStack>
        {correction &&
          // @ts-ignore
          Object.values(correction)
            .flat()
            .find((el: any) => el._id === option._id)?.justification && (
            <IconButton
              icon={
                <InfoOutlineIcon
                  color={option.isCorrect ? "green.600" : "gray.600"}
                />
              }
              aria-label="justification"
              bg={"whiteAlpha.700"}
              _hover={{
                bg: "white",
              }}
              size="md"
              _active={{}}
            />
          )}
      </HStack>
    </AccordionButton>
  );
}
