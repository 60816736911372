import { createContext, useContext, ReactElement } from 'react';
import { useDisclosure } from '@chakra-ui/react';

const ModalContext = createContext<{ isOpen: boolean, onOpen: () => void, onClose: () => void }>({
    isOpen: false,
    onOpen: () => {},
    onClose: () => {}
});

export function useModal() {
  return useContext(ModalContext);
}

export function ModalProvider({ children }: { children: ReactElement}) {
  const { isOpen, onOpen, onClose } = useDisclosure()


  return (
    <ModalContext.Provider value={{ isOpen, onOpen, onClose }}>
      {children}
    </ModalContext.Provider>
  );
}
