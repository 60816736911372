import {
  Box,
  Button,
  Divider,
  Heading,
  HStack,
  Stack,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useMutation, useQuery } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import AnswerPartialIcon from "../../../assets/icons/AnswerPartialIcon";
import AnswerRightIcon from "../../../assets/icons/AnswerRightIcon";
import AnswersIcon from "../../../assets/icons/AnswersIcon";
import AnswerWrongIcon from "../../../assets/icons/AnswerWrongIcon";
import ChronoIcon from "../../../assets/icons/ChronoIcon";
import PageHeading from "../../../components/PageHeading/PageHeading";
import MyToast from "../../../components/Toast/Toast";
import SidebarLayout from "../../../layouts/SidebarLayout";
import {
  createNewAttempt,
  getAttemptResult,
} from "../../../services/qcm/services";
import { secondsToHMS } from "../../../utils/timerConverter";
import ExamResult from "../Exams/ExamResult";
import SerieResult from "../Series/SerieResult";

export default function QcmResult() {
  const { type } = useParams<{type: string}>();

  if (type === "examen") {
    return <ExamResult />;
  } else if (type === "serie" || type === "custom") {
    return <SerieResult />;
  } else {
    return (
      <SidebarLayout>
        <Heading color="lightRed">Quelque chose ne va pas bien!</Heading>
      </SidebarLayout>
    );
  }
}
