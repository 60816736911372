import { useHistory } from "react-router-dom";

export default function HomeIcon() {
  const { location } = useHistory()

  return (
    <svg
      id="home"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <rect
        id="Rectangle_20"
        data-name="Rectangle 20"
        width="24"
        height="24"
        rx="5"
        fill={location.pathname.includes("/tableau-de-bord") ? "#4B62F0" : "#fff"}
        opacity="0"
        />
      <g id="Group_21" data-name="Group 21" transform="translate(1.2 1.2)">
        <path
          id="Path_27"
          data-name="Path 27"
          d="M10.8,16.875a.68.68,0,0,1-.675-.675V13.5a.675.675,0,1,1,1.35,0v2.7A.68.68,0,0,1,10.8,16.875Z"
          fill={location.pathname.includes("/tableau-de-bord") ? "#F7D372" : "#fff"}
          />
        <path
          id="Path_28"
          data-name="Path 28"
          d="M15.84,20.3H5.76a3.522,3.522,0,0,1-3.4-2.871l-1.2-7.173A3.706,3.706,0,0,1,2.4,7L8.64,2.007a3.565,3.565,0,0,1,4.32.009L19.2,7a3.778,3.778,0,0,1,1.242,3.258l-1.2,7.164a3.579,3.579,0,0,1-3.4,2.88ZM10.791,2.637a2.118,2.118,0,0,0-1.3.423l-6.237,5a2.408,2.408,0,0,0-.756,1.98l1.2,7.164a2.206,2.206,0,0,0,2.07,1.746H15.84A2.2,2.2,0,0,0,17.91,17.2l1.2-7.164a2.465,2.465,0,0,0-.756-1.98L12.114,3.069a2.14,2.14,0,0,0-1.323-.432Z"
          fill={location.pathname.includes("/tableau-de-bord") ? "#4B62F0" : "#fff"}
        />
      </g>
    </svg>
  );
}
