import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import ExamPage from "../../pages/Unauthorized/Exams/ExamPage";
import Exams from "../../pages/Unauthorized/Exams/Exams";
import SingleExam from "../../pages/Unauthorized/Exams/SingleExam";
import UniversityExams from "../../pages/Unauthorized/Exams/UniversityExams";
import { useAuthProvider } from "../../auth-provider/AuthProvider";
import ProtectedRoute from "./ProtectedRoute";

export default function ExamContainer() {
  const { data: auth } = useAuthProvider();
  const { path, url } = useRouteMatch();

  if (!auth?.email_verified) {
    return <Redirect to="/confirmation-email" exact />;
  }
  return (
    <Switch>
      <Route exact path={`${path}`}>
        <Exams base_url={url} />
      </Route>
      <Route exact path={`${path}/:year/:universityName`}>
        <UniversityExams base_url={url} />
      </Route>
      {/* <Route path={`${path}/:year/:universityName/:examId`}>
        <SingleExam />
      </Route> */}
      <ProtectedRoute
        path={`${path}/:year/:universityName/:examId`}
        component={SingleExam}
        days_left={auth?.days_left}
        current_order={auth?.current_order}
      />
      {/* <Route path={`/qcm/:type/:year/:qcmId/:attemptId`}>
        <ExamPage />
      </Route> */}
      <Route>
        <h1>Page Not Found</h1>
      </Route>
    </Switch>
  );
}
