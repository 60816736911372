import axiosInstance from "../../utils/axiosInstance";

export const getAnnounces = async () => {
  let access_token = localStorage.getItem("access_token");
  return await axiosInstance({
    method: "get",
    url: `/announces`,
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  }).then((res: any) => res.data);
};

export const setReadAnnounce = async (announceId: string) => {
  let access_token = localStorage.getItem("access_token");
  return await axiosInstance({
    method: "put",
    url: `/announces/${announceId}`,
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  }).then((res: any) => res.data);
};