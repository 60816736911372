import { Box, Button, Flex, Heading, Text } from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";
import { useHistory } from "react-router-dom";

export default function ErrorBanner({
  title = "Erreur 😓",
  description,
}: {
  title?: string;
  description?: string;
}) {
  const history = useHistory();
  return (
    <Box textAlign="center" py={10} px={6}>
      <Box display="inline-block">
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          bg={"red.500"}
          rounded={"50px"}
          w={"55px"}
          h={"55px"}
          textAlign="center"
        >
          <CloseIcon boxSize={"20px"} color={"white"} />
        </Flex>
      </Box>
      <Heading as="h2" size="xl" mt={6}>
        {title}
      </Heading>
      <Text color={"gray.500"} mt={6} mb={1}>
        {description}
      </Text>
      <Text color={"gray.500"} mb={6}>
        Merci de retourner vers la page précédente
      </Text>
      <Button variant={"primary"} onClick={() => history.goBack()}>
        Retour
      </Button>
    </Box>
  );
}
