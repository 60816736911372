import React, { ReactElement } from "react";
import { IconButton, Tooltip } from "@chakra-ui/react";

interface button {
  icon: ReactElement;
  onClick: () => void;
  title: string;
  isActive?: boolean;
  isDisabled?: boolean;
}

const IconButtonWithTooltip = ({
  icon: Icon,
  onClick,
  title,
  isActive = false,
  isDisabled = false,
}: button) => (
  <Tooltip label={title} hasArrow>
    <IconButton
      disabled={isDisabled}
      variant="outline"
      onClick={onClick}
      size="lg"
      aria-label={title}
      border="none"
      icon={React.cloneElement(Icon, { color: isActive ? "white" : "#4B62F0" })}
      borderRadius="xl"
      bg={isActive ? "blue-light" : "new-light-blue"}
      _focus={{}}
      _hover={{}}
      _active={{}}
    />
  </Tooltip>
);

export default IconButtonWithTooltip;
