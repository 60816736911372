import {
  useDisclosure,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { memo } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import { submitAnswer, submitManyAnswers } from "../../services/qcm/services";
import { useTimer } from "../../timer-provider/TimerProvider";

function ExamSkippedQCMModal({
  isOpen,
  onClose,
  skippedQuestions,
  setTabIndex,
  timeOut,
}: any) {
  const { time, initialTime } = useTimer();
  const queryClient = useQueryClient();
  const { attemptId, type, qcmId } = useParams<{
    attemptId: string;
    type: string;
    qcmId: string;
  }>();
  const history = useHistory();
  const { mutateAsync: submit, isLoading: submissionLoading } = useMutation(
    submitManyAnswers,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(`resume-attempts-${qcmId}`);
        history.push(`/qcm/${type}/${qcmId}/${attemptId}/result`);
      },
    }
  );

  const resumeQuestionAndCloseModal = () => {
    setTabIndex(skippedQuestions?.[0]?.order - 1);
    onClose();
  };

  const markAsWrongQuestionsAndClose = () => {
    const payload = skippedQuestions.map((question: any) => ({
      attempt: attemptId,
      // @ts-ignore
      questions:
        question?.children?.length > 0
          ? [{ options: [], questionId: question._id }].concat(
              question?.children?.map((el: any) => ({
                options: [],
                questionId: el._id,
              }))
            )
          : [{ options: [], questionId: question._id }],
      duration: time,
      question_duration: initialTime - time,
      last_question: question.order - 1,
    }));
    submit(payload);
  };

  return (
    <Modal
      closeOnEsc={false}
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
      isCentered
      size={useBreakpointValue({ base: "xs", md: "md" })}
    >
      <ModalOverlay />
      <ModalContent rounded={"1rem"}>
        <ModalHeader>{timeOut ? "Temps écoulé" : "Attention"}</ModalHeader>
        <ModalBody>
          <Text fontWeight="bold" mb="1rem">
            Vous avez {skippedQuestions?.length} question(s) sans réponse(s).
          </Text>
          {timeOut ? (
            <Text mb="1rem">
              Cliquez sur «Terminer», les questions sans réponses seront prises
              en compte (0point).
            </Text>
          ) : (
            <>
              <Text mb="1rem">
                Si vous voulez répondre à ces questions, cliquez sur
                «Continuer»..
              </Text>
              <Text mb="1rem">
                Si vous cliquez sur «Terminer», les questions sans réponses
                seront prises en compte (0point).
              </Text>
            </>
          )}
        </ModalBody>

        <ModalFooter>
          <Button
            colorScheme="red"
            mr={timeOut ? 0 : 3}
            onClick={markAsWrongQuestionsAndClose}
            isLoading={submissionLoading}
          >
            Terminer
          </Button>
          {!timeOut && (
            <Button variant="ghost" onClick={resumeQuestionAndCloseModal}>
              Continuer
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default memo(ExamSkippedQCMModal);
