export default function SecurityIcon() {
  return (
    <svg
      id="lock"
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
    >
      <rect
        id="Rectangle_1070"
        data-name="Rectangle 1070"
        width="32"
        height="32"
        rx="5"
        fill="#4b62f0"
        opacity="0"
      />
      <g id="Group_1226" data-name="Group 1226" transform="translate(3.1 3.1)">
        <path
          id="Path_240"
          data-name="Path 240"
          d="M20.025,12.525a.907.907,0,0,1-.9-.9v-2.4c0-3.78-1.068-6.3-6.3-6.3s-6.3,2.52-6.3,6.3v2.4a.9.9,0,1,1-1.8,0v-2.4c0-3.48.84-8.1,8.1-8.1s8.1,4.62,8.1,8.1v2.4A.907.907,0,0,1,20.025,12.525Z"
          transform="translate(0.075 -1.125)"
          fill="#4b62f0"
        />
        <path
          id="Path_241"
          data-name="Path 241"
          d="M11.775,19.275a3.9,3.9,0,1,1,3.9-3.9A3.908,3.908,0,0,1,11.775,19.275Zm0-6a2.1,2.1,0,1,0,2.1,2.1A2.11,2.11,0,0,0,11.775,13.275Z"
          transform="translate(1.125 2.325)"
          fill="#7fcacf"
        />
        <path
          id="Path_242"
          data-name="Path 242"
          d="M20.025,24.525h-12c-5.292,0-6.9-1.608-6.9-6.9v-2.4c0-5.292,1.608-6.9,6.9-6.9h12c5.292,0,6.9,1.608,6.9,6.9v2.4C26.925,22.917,25.317,24.525,20.025,24.525Zm-12-14.4c-4.3,0-5.1.816-5.1,5.1v2.4c0,4.284.8,5.1,5.1,5.1h12c4.3,0,5.1-.816,5.1-5.1v-2.4c0-4.284-.8-5.1-5.1-5.1Z"
          transform="translate(-1.125 1.275)"
          fill="#4b62f0"
        />
      </g>
    </svg>
  );
}
