import {
  Box,
  Button,
  Grid,
  GridItem,
  HStack,
  Icon,
  IconButton,
  LinkBox,
  Skeleton,
  Stack,
  Text,
  Tooltip,
  useColorModeValue,
} from "@chakra-ui/react";
import { ReactNode } from "react";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import DayOneIcon from "../../../assets/icons/DayOneIcon";
import DayTwoIcon from "../../../assets/icons/DayTwoIcon";
import FolderOpenIcon from "../../../assets/icons/FolderOpenIcon";
import SeriesIcon from "../../../assets/icons/SeriesIcon";
import PageHeading from "../../../components/PageHeading/PageHeading";
import useQueryParams from "../../../hooks/useQueryParams";
import SidebarLayout from "../../../layouts/SidebarLayout";
import { getSpecialitiesBooked } from "../../../services/bookmarks/services";

interface ISpeciality {
  jour: string;
  name: string;
  _id: string;
  questionsCount: number;
}

export default function Playlist({ base_url }: { base_url: string }) {
  const bgBox = useColorModeValue("lightGrey", "card-dark");
  const history = useHistory();
  const { data: specialites, isLoading: loadingSpecialities } = useQuery(
    "my-list-specialities",
    getSpecialitiesBooked,
    {
      refetchOnWindowFocus: false,
      retry: false
    }
  );
  const query = useQueryParams();

  const filterByDay = (day: string) => {
    history.push({ search: `jour=${day}` });
  };

  return (
    <SidebarLayout>
      <HStack justify="space-between">
        <PageHeading title="Playlist" />
        <HStack overflowX={"auto"}>
          <FilterButton
            innerText="Tout"
            queryText="tout"
            filterByDay={filterByDay}
          />
          <FilterButton
            innerText="Jour 1"
            queryText="1"
            filterByDay={filterByDay}
          />
          <FilterButton
            innerText="Jour 2"
            queryText="2"
            filterByDay={filterByDay}
          />
        </HStack>
      </HStack>
      <Grid
        gridTemplateColumns={"repeat(auto-fill, minmax(300px, 1fr))"}
        justifyItems="center"
        gap={{ base: "20px", lg: "20px", "2xl": "30px" }}
        my="4"
        mt="6"
      >
        {loadingSpecialities
          ? Array.apply(null, Array(12)).map((el: any, index: number) => (
              <Skeleton
                rounded={"0.8rem"}
                w="full"
                h="36"
                key={index}
                startColor="darkGrey"
                endColor="lightGrey"
              />
            ))
          : specialites
              .filter((el: ISpeciality) =>
                !query.get("jour") || query.get("jour") === "tout"
                  ? el.jour
                  : query.get("jour") === "1"
                  ? el.jour === "1"
                  : el.jour === "2"
              )
              .map((speciality: ISpeciality) => (
                <GridItem width={"full"} key={speciality._id}>
                  <LinkBox
                    as="article"
                    w="full"
                    // maxW={"400px"}
                    h="32"
                    display={"flex"}
                    alignItems="center"
                    bg={bgBox}
                    borderWidth="1px"
                    borderColor={"transparent"}
                    rounded=".8rem"
                    transition={"all 0.15s ease-in-out"}
                    _hover={{
                      borderColor: "darkSky",
                      boxShadow: "0px 0px 64px -35px rgba(127,202,207, 0.8)",
                      cursor: "pointer",
                      bg: "darkGrey",
                      // "& .xxx": { bg: "transparent" },
                    }}
                    onClick={() =>
                      history.push(`${base_url}/${speciality.name}`)
                    }
                    overflow="hidden"
                    p="4"
                  >
                    <HStack spacing={4} alignItems="center">
                      <Box minWidth="40px">
                        <Icon as={FolderOpenIcon} w="full" h="full" />
                      </Box>
                      <Stack spacing={2}>
                        <Text fontSize={"lg"}>{speciality.name}</Text>
                        <Text pb="2" color="gray.500">
                          {speciality.questionsCount} questions
                        </Text>
                      </Stack>
                    </HStack>
                  </LinkBox>
                </GridItem>
              ))}
      </Grid>
    </SidebarLayout>
  );
}

const FilterButton = ({
  innerText,
  queryText,
  filterByDay,
}: {
  innerText: string;
  queryText: string;
  filterByDay: (x: string) => void;
}) => {
  const query = useQueryParams();

  const genIcon = () => {
    switch (queryText) {
      case "tout":
        return <SeriesIcon color="black" />;
      case "1":
        return <DayOneIcon color="black" size="sm" />;
      default:
        return <DayTwoIcon color="black" size="sm" />;
    }
  };

  const activeButton = () => {
    if (
      (query.get("jour") === "tout" || !query.get("jour")) &&
      innerText === "Tout"
    ) {
      return { bg: "blue-light", color: "white !important" };
    } else if (query.get("jour") === "1" && innerText === "Jour 1") {
      return { bg: "blue-light", color: "white !important" };
    } else if (query.get("jour") === "2" && innerText === "Jour 2") {
      return { bg: "blue-light", color: "white !important" };
    }
  };

  return (
    <Tooltip label={innerText} hasArrow>
      <IconButton
        aria-label="afficher l'avancement"
        icon={genIcon()}
        bg={activeButton()?.bg}
        _hover={{}}
        _focus={{}}
        _active={{}}
        onClick={() => filterByDay(queryText)}
        css={{
          "& *": { color: activeButton()?.color, fill: activeButton()?.color },
        }}
        sx={{
          "&:hover *": {
            fill: "blue-light",
          },
        }}
      />
    </Tooltip>
    // <Button
    //   leftIcon={genIcon()}
    //   size="md"
    //   onClick={() => filterByDay(queryText)}
    //   bg={activeButton()?.bg}
    //   css={{
    //     "& *": { color: activeButton()?.color, fill: activeButton()?.color },
    //   }}
    //   _hover={{}}
    //   _focus={{}}
    //   _active={{}}
    //   minW="91px"
    // >
    //   <Text>{innerText}</Text>
    // </Button>
  );
};
