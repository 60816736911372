import {
  Checkbox,
  IconButton,
  Stack,
  Text,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import { useMutation } from "react-query";
import InfoIcon from "../../assets/icons/InfoIcon";
import { useAuthProvider } from "../../auth-provider/AuthProvider";
import { SocketContext } from "../../context/socket";
import { reportQuestion } from "../../services/report/services";
import { useTimer } from "../../timer-provider/TimerProvider";
import Dialog from "../Dialog/Dialog";
import MyToast from "../Toast/Toast";
import ReportErrorGroup from "./RadioGroup";

export default function ReportModal({ question }: any) {
  const [addCommentEnabled, setAddCommentEnabled] = useState<boolean>(false);
  const [comment, setComment] = useState<string>("");
  const { stopTimer, startTimer } = useTimer();
  const { data: auth } = useAuthProvider();
  const socket = useContext(SocketContext);
  const [reason, setReason] = useState("");
  const toast = useToast();
  const { mutateAsync: report, isLoading: loadingReport } = useMutation(
    reportQuestion,
    {
      onSuccess: () => {
        MyToast({
          toast,
          status: "success",
          title: "Merci",
          description: "Nous examinerons votre demande.",
        });
        socket?.emit("report", {
          report: {
            questionId: question,
            username: auth?.fullname || "Un utilisateur",
          },
        });
      },
    }
  );

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setComment(e.target.value);
  };

  const handleReportClick = () => {
    if(addCommentEnabled && comment && reason){
      report({
        message: reason,
        question,
        comment
      });
    } else {
      report({
        message: reason,
        question,
      });
    }
    startTimer();
  };
  
  const handleCancelClick = () => {
    setAddCommentEnabled(false)
    setReason("")
    setComment("")
    startTimer()
  }
  return (
    <Dialog
      question="Signaler une erreur"
      description={
        <Stack spacing={4}>
          <ReportErrorGroup
            onChange={(message: string) => setReason(message)}
          />
          <Checkbox
            isChecked={addCommentEnabled}
            onChange={(e) => setAddCommentEnabled(e.target.checked)}
            alignItems="baseline"
          >
            Fournissez plus d'informations sur l'erreur pour nous aider à la
            corriger.
          </Checkbox>
          {addCommentEnabled && (
            <Textarea
              placeholder="Expliquez l'erreur ici..."
              value={comment}
              onChange={handleChange}
            />
          )}
        </Stack>
      }
      defaultDialogWidth="2xl"
      onClick={handleReportClick}
      onCancel={handleCancelClick}
      isLoading={loadingReport}
      withTooltip
      tooltipText="Signaler"
      noText={"Annuler"}
      yesText={"Confirmer"}
      reportDialog={true}
      selectedOption={addCommentEnabled ? Boolean(comment && Boolean(reason)) : Boolean(reason)}
    >
      <IconButton
        icon={<InfoIcon color="#4B62F0" />}
        aria-label="Signaler une erreur"
        bg="lightGrey"
        color="blue-light"
        onClick={() => stopTimer()}
      />
    </Dialog>
  );
}
