import { Box, Button, Heading, Text } from "@chakra-ui/react";
import { WarningTwoIcon } from "@chakra-ui/icons";
import { useHistory } from "react-router-dom";

export default function NotAvailable() {
  const history = useHistory();

  return (
    <Box  textAlign="center" w="full" py={10} px={6}>
      <WarningTwoIcon boxSize={"50px"} color={"orange.300"} />
      <Heading as="h2" size="xl" mt={6} mb={2}>
        Examens blancs non disponibles
      </Heading>
      <Text color={"gray.500"} mb={6}>
        Vous serez notifié quand il y a de nouveaux examens blancs.
      </Text>
      <Button
        _hover={{
          bgGradient:
            "linear-gradient(90deg, rgba(69, 90, 222, 1) 0%, rgba(69, 90, 222, 1) 100%)",
        }}
        bgGradient="linear-gradient(90deg, rgb(152 165 242) 0%, rgba(69, 90, 222, 1) 100%)"
        onClick={() => history.goBack()}
        color="white"
      >
        Retourner
      </Button>
    </Box>
  );
}
