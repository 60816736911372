import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Progress,
} from "@chakra-ui/react";
import { Fragment } from "react";
import { SelectField } from "../../../../components/Form";

interface IOnBoardingFirstStep {
  setStep: (x: number) => void;
  methods: any;
  universities: any;
  specialities: any;
  status: any;
  promotions: any;
}

const allSpecialities = [
  {
    parent: "Médecine et specialités médicales",
    sub: [
      "Médecine de famille",
      "Médecine interne",
      "Maladies infectieuses",
      "Réanimation médicale",
      "Carcinologie médicale",
      "Nutrition et maladies nutritionnelles",
      "Hématologie clinique",
      "Endocrinologie",
      "Cardiologie",
      "Néphrologie",
      "Neurologie",
      "Pneumologie",
      "Rhumatologie",
      "Gastro-entérologie",
      "Médecine physique, rééducation et réadaptation fonctionnelle",
      "Dermatologie",
      "Pédiatrie",
      "Psychiatrie",
      "Pédo-psychiatrie",
      "Imagerie médicale",
      "Radiothérapie carcinologique",
      "Médecine légale",
      "Médecine du travail",
      "Médecine préventive et communautaire",
      "Anatomie et cytologie pathologique",
      "Médecine d’urgence",
    ],
  },
  {
    parent: "Chirurgie et specialités chirurgicales",
    sub: [
      "Anesthésie - Réanimation",
      "Chirurgie générale",
      "Chrirgie carcinologique",
      "Chirurgie thoracique",
      "Chirurgie vasculaire périphérique",
      "Chirurgie neurologique",
      "Chirurgie urologique",
      "Chirurgie plastique, réparatrice et esthétique",
      "Chirurgie orthopédique et traumatologique",
      "Chirurgie pédiatrique",
      "Chirurgie cardio-vasculaire",
      "Ophtalmologie",
      "Oto-rhino-larygologie",
      "Stomatologie et chirurgie maxillo-faciale",
      "Gynécologie-obstétrique",
    ],
  },
  {
    parent: "Biologie et disciplines fondamentales",
    sub: [
      "Biologie médicale (Option: Biochimie)",
      "Biologie médicale (Option: Microbiologie)",
      "Biologie médicale (Option: Parasitologie)",
      "Biologie médicale (Option: Immunologie)",
      "Biologie médicale (Option: Hématologie)",
      "Histo-enbryologie",
      "Physiologie et exploration fonctionnelle",
      "Biophysique et médecine nucléaire",
      "Pharmacologie",
      "Génétique",
    ],
  },
];

export const serilize = (arr: any[]) => {
  return arr?.map((el: any) => ({
    id: el._id,
    value: el.name,
    label: el.name,
  }));
};
export default function OnboardingFirstStep({
  setStep,
  methods,
  universities,
  specialities,
  status,
  promotions,
}: IOnBoardingFirstStep) {
  const fields = [
    {
      type: "select",
      name: "university",
      placeholder: "Votre faculté",
      options: serilize(universities),
    },
    {
      type: "select",
      name: "status",
      placeholder: "Statut",
      options: serilize(status),
    },
    {
      type: "select",
      name: "promotion",
      placeholder: "Votre promotion",
      options: serilize(promotions),
    },
    // {
    //   type: "select",
    //   name: "speciality_to_learn",
    //   placeholder: "Quelle spécialité souhaitez vous faire?",
    //   options: allSpecialities.map((el: any) => el.sub).flat().map((el: string) => ({
    //     id: el,
    //     value: el,
    //     label: el
    //   })),
    //   height: "250px"
    // },
  ];
  return (
    <Box my="4">
      {fields.map((field: any) => (
        <Box key={field.name} my="4">
          <SelectField
            bg="light-blue-light"
            options={field.options}
            placeholder={field.placeholder}
            name={field.name}
            bg_hover="#e5e7f5"
            value={methods.getValues(field.name)}
            height={field.height}
          />
        </Box>
      ))}
      <SpecialitiesDropDown methods={methods} />
      <Flex align={"center"} justify={"flex-end"} wrap={"wrap"} mt="8">
        {/* <Flex>
            <Progress value={100} max={100} w="3.1rem" h="1" borderRadius={"md"} colorScheme={"messenger"} mr="2"/>
            <Progress value={0} max={100} w="3.1rem" h="1" borderRadius={"md"} colorScheme={"messenger"}/>
          </Flex> */}
        <Button variant={"primary"} onClick={() => setStep(2)}>
          Suivant
        </Button>
      </Flex>
    </Box>
  );
}

export const SpecialitiesDropDown = ({ methods, bg="#F6F7FF",bg_hover="#e5e7f5" }: any) => {
  return (
    <Menu closeOnSelect>
      <MenuButton
        px={4}
        py={2}
        w="100%"
        height={{ base: "2.5rem", "2xl": "3rem" }}
        bg={bg}
        transition="all 0.2s"
        borderRadius="md"
        _hover={{ bg: bg_hover }}
        _active={{ bg: bg_hover }}
        type="button"
      >
        <Flex as={"div"} align="center" justifyContent="space-between">
          {methods.getValues("speciality_to_learn") || "Quelle spécialité souhaitez vous faire?"} <Icon as={ChevronDownIcon} />
        </Flex>
      </MenuButton>
      <MenuList minWidth="240px" h={"200px"} overflowY={"auto"}>
        {allSpecialities.map((parent: any, i: number) => (
          <Fragment key={parent.parent}>
            <MenuOptionGroup
              defaultValue={methods.getValues("speciality_to_learn")}
              title={parent.parent}
              type="radio"
              onChange={(x: any) => methods.setValue("speciality_to_learn", x)}
            >
              {parent.sub.map((sub: string) => (
                <MenuItemOption
                  value={sub}
                  _hover={{ bg: "blue-light", color: "white" }}
                  key={sub}
                >
                  {sub}
                </MenuItemOption>
              ))}
            </MenuOptionGroup>
            {i < (allSpecialities.length-1) && <MenuDivider />}
          </Fragment>
        ))}
      </MenuList>
    </Menu>
  );
};
