import AuthLayout from "../../../layouts/AuthLayout";
import login from "../../../assets/img/login.png";
import session from "../../../assets/img/session.png";
import HeadingWithLines from "../../../components/Heading/HeadingWithLines";
import {
  Box,
  Button,
  Flex,
  Heading,
  Spinner,
  Stack,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { FormProvider, useForm } from "react-hook-form";
import { Link, useHistory, useLocation } from "react-router-dom";
import { IInputField, UseQueryDataType } from "../../../types";
import Field from "../../../components/Field/Field";
import EmailIcon from "../../../assets/icons/EmailIcon";
import KeyIcon from "../../../assets/icons/KeyIcon";
import { useEffect } from "react";
import { loadUser, loginUser } from "../../../services/auth/services";
import { useMutation, useQuery } from "react-query";
import { useToast } from "@chakra-ui/react";
import SocialNetWorkBtns from "../../../components/Social Network Buttons/SocialNetWorkBtns";
import SquareYellowIcon from "../../../assets/icons/SquareYellowIcon";
import useQueryParams from "../../../hooks/useQueryParams";
import usePlan from "../../../auth-provider/PlanProvider";

const loginFields: IInputField[] = [
  {
    type: "text",
    name: "email",
    placeholder: "Adresse email",
    icon: <EmailIcon />,
    rules: {
      required: "Veuillez saisir votre adresse email",
    },
  },
  {
    type: "password",
    name: "password",
    placeholder: "Mot de passe",
    icon: <KeyIcon />,
    rules: {
      required: "Veuillez saisir votre mot de passe",
    },
  },
];

export default function Login({ base_url }: { base_url: string }) {
  const methods = useForm<any>({
    defaultValues: {
      email: "",
      password: "",
    },
  });
  const history = useHistory();
  const toast = useToast();
  const query = useQueryParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const sessionId = searchParams.get("session");
  const { updatePlan } = usePlan();
  // useQuery<UseQueryDataType | undefined>(["user"], loadUser, {
  //   refetchOnWindowFocus: false,
  //   // enabled: Boolean(localStorage.getItem("access_token")),
  //   retry: false,
  // });

  const onSubmit = ({email, password}: {email: string, password: string}) => {
    mutateAsync({email: email.trim().toLowerCase(), password});
  };

  useEffect(() => {
    if (localStorage.getItem("access_token")) {
      // history.push({pathname: "/tableau-de-bord", search: searchParams.toString()});
      window.location.replace(
        `/tableau-de-bord${
          searchParams.toString() ? `?${searchParams.toString()}` : ""
        }`
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage.getItem("access_token")]);

  useEffect(() => {
    const newPlanId = searchParams.get("plan");
    if (newPlanId) {
      updatePlan(String(newPlanId));
    }
    if (!newPlanId) {
      updatePlan("");
    }
  }, []);

  const { mutateAsync, isLoading } = useMutation(loginUser, {
    onError: ({
      response: {
        data: { message },
      },
    }) => {
      toast({
        title: "Impossible de se connecter.",
        description: message,
        status: "error",
        duration: 9000,
        isClosable: true,
        position: "bottom-right",
      });
    },
    onSuccess: (res) => localStorage.setItem("access_token", res.access_token),
  });
  const sizes = useBreakpointValue({ base: "sm", "2xl": "md" });

  useEffect(() => {
    if (query.get("status") === "error") {
      toast({
        title: "Impossible de se connecter.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "bottom-right",
      });
    }
  }, [query.get("status")]);

  if (sessionId) {
    return (
      <AuthLayout illustration={session}>
        <Flex direction={"column"} justify={"space-evenly"} flexGrow={0.7}>
          <HeadingWithLines linesColor="yellow" position="middle">
            Il semble qu'une autre personne est connectée à votre compte.
          </HeadingWithLines>
          <Stack gap="4">
            <Text>
              Si vous ne savez pas qui utilise votre compte, il est conseillé de
              modifier votre mot de passe.
            </Text>
            <Stack direction={{ base: "column", xl: "row" }}
            alignItems={{base: "stretch", xl: "center"}}>
              <Link to="/connexion" style={{ alignSelf: "flex-end", flex: 1, width: "100%" }}>
                <Button variant={"primary"} isFullWidth>
                  Se connecter
                </Button>
              </Link>
              <Link
                to="/mot-de-passe-oublie/email"
                style={{ alignSelf: "flex-end", flex: 1, width: "100%" }}
              >
                <Button variant={"secondary"} isFullWidth>
                  Modifier votre mot de passe
                </Button>
              </Link>
            </Stack>
          </Stack>
        </Flex>
      </AuthLayout>
    );
  }

  return (
    <AuthLayout illustration={login}>
      <Flex direction={"column"} justify={"space-evenly"} flexGrow={0.7}>
        <HeadingWithLines linesColor="yellow" position="end">
          Je me connecte
        </HeadingWithLines>
        <Box>
          <Heading size={sizes} mb="4">
            Continuez avec
          </Heading>
          <SocialNetWorkBtns isLoading={isLoading} />
        </Box>
        <Box>
          <Heading size={sizes} mb="2">
            Ou bien
          </Heading>
          <Box>
            <FormProvider {...methods}>
              <Flex
                as="form"
                direction={"column"}
                onSubmit={methods.handleSubmit(onSubmit)}
                position={"relative"}
              >
                {loginFields.map((field: IInputField) => (
                  <Box key={field.name} mt="4">
                    <Field {...field} bg="light-blue-light" />
                  </Box>
                ))}
                <SquareYellowIcon />
                <Link
                  to="/mot-de-passe-oublie/email"
                  style={{ alignSelf: "flex-end" }}
                >
                  <Text
                    // fontSize="md"
                    my="4"
                    textDecor={"underline"}
                    fontSize={{ base: "0.8rem", "2xl": "1rem" }}
                  >
                    Mot de passe oublié ?
                  </Text>
                </Link>
                {isLoading ? (
                  <Button
                    variant={"primary"}
                    mt="4"
                    leftIcon={<Spinner color="white" />}
                    opacity={0.7}
                    _hover={{}}
                  >
                    <span>Connexion en cours...</span>
                  </Button>
                ) : (
                  <Button type="submit" variant={"primary"} mt="4">
                    Se connecter
                  </Button>
                )}
              </Flex>
            </FormProvider>
            <Link to={`/inscription?${searchParams.toString()}`}>
              <Button
                variant={"ghost"}
                _hover={{ bg: "#ECEFFE" }}
                wordBreak={"break-all"}
                whiteSpace={"initial"}
                w="full"
                my="4"
                color="blue-light"
                fontSize={{ base: "0.8rem", "2xl": "1rem" }}
                height={{ base: "2.5rem", "2xl": "3rem" }}
              >
                Vous êtes nouveau ? Créer un compte
              </Button>
            </Link>
          </Box>
        </Box>
      </Flex>
    </AuthLayout>
  );
}
