export default function Lines({color="yellow"}:{color: string}) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="33.169" height="38.169" viewBox="0 0 33.169 38.169">
            <g id="Group_1235" data-name="Group 1235" transform="translate(-1147.658 -32.658)">
                <line id="Line_1" data-name="Line 1" x1="5" y2="26" transform="translate(1150 35)" fill="none" stroke={color === "yellow" ? "#f7d372" : "#7FCF91"} strokeLinecap="round" strokeWidth="4"/>
                <line id="Line_2" data-name="Line 2" x1="15" y2="16" transform="translate(1163 52)" fill="none" stroke={color === "yellow" ? "#f7d372" : "#7FCF91"} strokeLinecap="round" strokeWidth="4"/>
            </g>
        </svg>
    )
}
