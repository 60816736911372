import { Stack, Skeleton, HStack } from "@chakra-ui/react";

const LoadingSingleQCM = () => {
  return (
    <Stack spacing={8} width="full">
      <Skeleton
        height="20px"
        width={"10vw"}
        startColor="darkGrey"
        endColor={"lightGrey"}
        minW="150px"
      />
      <Stack
        direction={{ base: "column", md: "row" }}
        spacing={{ base: 3, md: 8 }}
        justify={"space-between"}
        alignSelf={"stretch"}
      >
        <Stack
          direction={{ base: "column", md: "row" }}
          spacing={{ base: 3, md: 8 }}
        >
          <HStack>
            {/* <AnswersIcon /> */}
            <Skeleton
              height="20px"
              width={"20px"}
              startColor="darkGrey"
              endColor={"lightGrey"}
            />
            <Skeleton
              height="20px"
              width={"5vw"}
              minW="100px"
              startColor="darkGrey"
              endColor={"lightGrey"}
            />
          </HStack>
          <HStack>
            {/* <ProgressIcon /> */}
            <Skeleton
              height="20px"
              width={"20px"}
              startColor="darkGrey"
              endColor={"lightGrey"}
            />
            <Skeleton
              height="20px"
              width={"5vw"}
              minW="100px"
              startColor="darkGrey"
              endColor={"lightGrey"}
            />
          </HStack>
        </Stack>
      </Stack>
      <Skeleton
        startColor="darkGrey"
        endColor={"lightGrey"}
        h="56"
        width={"full"}
        rounded="2xl"
      />
      <Stack
        direction={{ base: "column-reverse", md: "row" }}
        align={{ base: "stretch", md: "center" }}
        justify="space-between"
      >
        <Skeleton
          startColor="darkGrey"
          endColor={"lightGrey"}
          h="12"
          width={{ base: "100%", md: "150px" }}
          rounded="2xl"
        />
        <Stack
          direction={{ base: "column-reverse", md: "row" }}
          align={{ base: "stretch", md: "center" }}
          spacing={8}
          justify={"flex-end"}
        >
          <Skeleton
            startColor="darkGrey"
            endColor={"lightGrey"}
            h="12"
            width={{ base: "100%", md: "150px" }}
            rounded="2xl"
          />
          <Skeleton
            startColor="darkGrey"
            endColor={"lightGrey"}
            h="12"
            width={{ base: "100%", md: "150px" }}
            rounded="2xl"
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default LoadingSingleQCM;
