import axiosInstance from "../../utils/axiosInstance";

export const addBookmark = async (questionId: string | undefined) => {
  let access_token = localStorage.getItem("access_token");
  return await axiosInstance({
    method: "post",
    url: `/bookmarks/add`,
    data: {
      question: questionId,
    },
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  }).then((res: any) => res.data);
};

// verify if question is bookmarked
export const checkIfQuestionBookmarked = async (questionId: string | undefined) => {
  let access_token = localStorage.getItem("access_token");
  return await axiosInstance({
    method: "get",
    url: `/bookmarks/verify/${questionId}`,
    data: {
      question: questionId,
    },
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  }).then((res: any) => res.data);
};

export const getSpecialitiesBooked = async () => {
  let access_token = localStorage.getItem("access_token");
  return await axiosInstance({
    method: "get",
    url: `/bookmarks/specialites`,
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  }).then((res: any) => res.data);
};


export const getSubjectsBooked = async (specialityName: string | undefined) => {
  let access_token = localStorage.getItem("access_token");
  return await axiosInstance({
    method: "get",
    url: `/bookmarks/subjects/${specialityName}`,
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  }).then((res: any) => res.data);
};

export const getBookedQuestions = async (subjectName: string | undefined) => {
  let access_token = localStorage.getItem("access_token");
  return await axiosInstance({
    method: "get",
    url: `/bookmarks/questions/${subjectName}`,
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  }).then((res: any) => res.data);
};

export const removeBookmarkedQuestions = async (questionId: string | undefined) => {
  let access_token = localStorage.getItem("access_token");
  return await axiosInstance({
    method: "delete",
    url: `/bookmarks/remove/${questionId}`,
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  }).then((res: any) => res.data);
};
