import { Button, Tag, useTab, VStack } from "@chakra-ui/react";
import { forwardRef, useCallback } from "react";
import useQueryParams from "../../hooks/useQueryParams";

const ExamQcmCustomTab = forwardRef((props: any, ref: any) => {
  const { viewMode, status, alreadyAnswered, isSerieMode, ...rest } = props;
  const tabProps = useTab({ ...rest, ref });
  const isSelected = !!tabProps["aria-selected"];
  const queryParams = useQueryParams()

  const genColor = useCallback(() => {
    switch (status?.status) {
      case "fausse":
        return "215, 97, 97";
      case "correct":
        return "127, 207, 145";
      case "partiel":
        return "252, 183, 49";
      default:
        return "midGrey";
    }
  }, [status?.status]);

  return (
    <VStack align={"center"} zIndex={isSelected ? 2 : 0} alignSelf="flex-end">
      <Button
        {...tabProps}
        bg={isSelected && (queryParams.get("view") === "true" || Boolean(status?.status) && isSerieMode)
          ? `rgb(${genColor()})`
          : isSelected 
          ? "blue-light"
          : "midGrey"}
        color={isSelected ? "white" : "black"}
        borderRadius="md"
        width={{ base: "50px", xl: "50px", "2xl": "60px" }}
        transform={isSelected ? "scale(1.3)" : "scale(1)"}
        height={{ base: "28px", "2xl": "30px" }}
        mx="2"
        boxShadow={isSelected ? `0px 5px 20px rgba(midGrey,.7)` : "none"}
        transition="all 0.2s"
        _active={{}}
        _focus={{}}
        _hover={{
          bg: isSelected
            ? Boolean(status?.status)
              ? genColor()
              : "blue-light"
            : "#CBCCCD",
        }}
      >
        {tabProps.children}
      </Button>
      {/* {props.alreadyAnswered && ( */}
      <Tag
        bg={
          (viewMode || isSerieMode)
            ? `rgb(${genColor()})`
            : alreadyAnswered
            ? `blue-light`
            : "lightGrey"
        }
        borderRadius="full"
        width="0.5rem"
        height={"0.5rem"}
        size="5rem"
      />
      {/* )} */}
    </VStack>
  );
});

export default ExamQcmCustomTab;
