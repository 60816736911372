export default function AnswersIcon({color="black"}:{color?:string}) {
  return (
    <svg
      id="task-square"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <rect
        id="Rectangle_32"
        data-name="Rectangle 32"
        width="24"
        height="24"
        rx="5"
        fill={color}
        opacity="0"
      />
      <g id="Group_40" data-name="Group 40" transform="translate(1.2 1.2)">
        <path
          id="Path_54"
          data-name="Path 54"
          d="M15.858,8.658H11.133a.675.675,0,1,1,0-1.35h4.725a.675.675,0,0,1,0,1.35Z"
          fill={color}
        />
        <path
          id="Path_55"
          data-name="Path 55"
          d="M6.408,9.342a.668.668,0,0,1-.477-.2l-.675-.675a.675.675,0,0,1,.954-.954l.2.2L7.956,6.165a.675.675,0,0,1,.954.954L6.885,9.144a.675.675,0,0,1-.477.2Z"
          fill={color}
        />
        <path
          id="Path_56"
          data-name="Path 56"
          d="M15.858,14.958H11.133a.675.675,0,1,1,0-1.35h4.725a.675.675,0,1,1,0,1.35Z"
          fill={color}
        />
        <path
          id="Path_57"
          data-name="Path 57"
          d="M6.408,15.642a.668.668,0,0,1-.477-.2l-.675-.675a.675.675,0,0,1,.954-.954l.2.2,1.548-1.548a.675.675,0,0,1,.954.954L6.885,15.444a.675.675,0,0,1-.477.2Z"
          fill={color}
        />
        <path
          id="Path_58"
          data-name="Path 58"
          d="M13.5,20.475H8.1c-4.887,0-6.975-2.088-6.975-6.975V8.1c0-4.887,2.088-6.975,6.975-6.975h5.4c4.887,0,6.975,2.088,6.975,6.975v5.4C20.475,18.387,18.387,20.475,13.5,20.475Zm-5.4-18c-4.149,0-5.625,1.476-5.625,5.625v5.4c0,4.149,1.476,5.625,5.625,5.625h5.4c4.149,0,5.625-1.476,5.625-5.625V8.1c0-4.149-1.476-5.625-5.625-5.625Z"
          fill={color}
        />
      </g>
    </svg>
  );
}
