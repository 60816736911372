import {
  Stack,
  HStack,
  VStack,
  Heading,
  Text,
  Button,
  Box,
  useBreakpointValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useMutation, useQueryClient } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import useQueryParams from "../../hooks/useQueryParams";
import { submitAnswer } from "../../services/qcm/services";
import { useTimer } from "../../timer-provider/TimerProvider";
import MyToast from "../Toast/Toast";
import ExamQcmActions from "./ExamQCMActions";
import ExamQCMQuestion from "./ExamQCMQuestion";
import BasicUsage from "./ExamSkippedQCM";

const ExamQcmBody = ({
  questions,
  question,
  university,
  year,
  jour,
  counterIsRunning,
  select,
  selectedOptions,
  setTabIndex,
  scrollRight,
  tabIndex,
  alreadyAnswered,
  resumeAttemptLoading,
  resumeAttemptRefetch,
  goToTop,
  checkAllQuestionsAnswered,
  skippedQuestions,
  answers,
  timeSpentOnQuestion = 0,
  pauseTimeSpentOnQuestion,
  resumeTimeSpentOnQuestion,
  setAnswers,
  correction,
  isSerieMode,
}: any) => {
  const queryString = useQueryParams();
  const { attemptId, type, qcmId } = useParams<{
    attemptId: string;
    type: string;
    qcmId: string;
  }>();
  const history = useHistory();
  const { startTimer, stopTimer, time, initialTime } = useTimer();
  const queryClient = useQueryClient();
  const scrollValue = useBreakpointValue({ base: 66, md: 76 });

  const { mutateAsync: submit, isLoading: submissionLoading } = useMutation(
    submitAnswer
    // {
    //   onSuccess: () =>
    //     queryClient.invalidateQueries(`resume-attempts-${qcmId}`),
    // }
  );
  const toast = useToast();

  const verifyAnswer = async () => {
    const questionIds = answers
      .map((x: any) => x.questions.map((y: any) => y.questionId))
      .flat();
    const _selectedOptions = selectedOptions.filter(
      (el: any) => !questionIds.some((el2: any) => el2 === el.questionId)
    );

    let allClinicQuestions: string[] = [question._id];
    if (question.children.length > 0) {
      allClinicQuestions = [
        ...allClinicQuestions,
        ...question.children.map((el: any) => el._id),
      ];
    }

    for (const clinicQuestion of allClinicQuestions) {
      const exist = _selectedOptions.find(
        (el: any) => el.questionId === clinicQuestion
      );
      if (!exist) {
        _selectedOptions.push({ options: [], questionId: clinicQuestion });
      }
    }

    try {
      // send user's answers and await for the correction from the api
      const response = await submit({
        attempt: attemptId,
        // @ts-ignore
        questions: _selectedOptions,
        duration: time,
        question_duration: timeSpentOnQuestion,
        last_question:
          tabIndex === questions.questions?.length - 1
            ? tabIndex
            : tabIndex + 1,
        // ...(queryParams.get("retry") ? { retry: true } : {}),
        // retry: true
      });
      pauseTimeSpentOnQuestion();

      // save correction (answers) in the state
      setAnswers([
        ...answers,
        {
          status: response.status,
          questions: response.questions,
          question: response.parentId,
        },
      ]);
      // then stop the timer
      stopTimer();
      goToTop();
    } catch (err: any) {
      if(err.response?.status === 403){
        window.location.href = `/connexion?session=invalid`
      } else {
        console.log(err);
        MyToast({
          toast,
          description: err.response?.data?.message,
          status: "error",
          title: "Erreur",
        });
      }
    }
  };

  return (
    <Stack spacing={8}>
      <HStack justify={"space-between"} mt={{ base: "20", md: "24" }}>
        <VStack
          align={"flex-start"}
          spacing={1}
          borderBottom="2px solid"
          borderColor={"gold-yellow"}
          pb="2"
          flexGrow={0.4}
        >
          <HStack>
            <Text color="blue-light" fontSize={"sm"}>
              Épreuve / Jour {jour}
            </Text>
          </HStack>
          <Heading size={"md"}>{university?.name + " " + year}</Heading>
        </VStack>
        <Box display={{ base: "none", md: "block" }}>
          <ExamQcmActions
            counterIsRunning={counterIsRunning}
            question={question}
            university={university?.name}
            pauseTimeSpentOnQuestion={pauseTimeSpentOnQuestion}
            resumeTimeSpentOnQuestion={resumeTimeSpentOnQuestion}
          />
        </Box>
      </HStack>
      <ExamQCMQuestion
        select={select}
        question={question}
        selectedOptions={selectedOptions.find(
          (el: any) => el.questionId === question._id
        )}
        alreadyAnswered={alreadyAnswered?.questions?.find(
          (el: any) => el.questionId === question._id
        )}
        correction={
          correction?.find((el: any) => el.questionId === question._id)
            ?.correction
        }
        isSerieMode={isSerieMode}
      />
      {question?.children?.length > 0 &&
        question?.children.map((child: any) => (
          <ExamQCMQuestion
            key={child._id}
            question={child}
            selectedOptions={selectedOptions.find(
              (el: any) => el.questionId === child._id
            )}
            select={select}
            alreadyAnswered={alreadyAnswered?.questions?.find(
              (el: any) => el.questionId === question._id
            )}
            correction={
              correction?.find((el: any) => el.questionId === child._id)
                ?.correction
            }
            isSerieMode={isSerieMode}
          />
        ))}
      {
        <HStack
          justify={"space-between"}
          alignSelf={{ base: "stretch", md: "flex-end" }}
        >
          {/* {tabIndex !== 0 && (
            <Button
              flex={1}
              px="10"
              variant={"secondary"}
              onClick={() => {
                setTabIndex((x: number) => x - 1);
                if (tabIndex > (questions.length - 1) / 3) {
                  scrollRight(-100);
                }
              }}
            >
              Précédent
            </Button>
          )} */}
          <Box display={{ base: "block", md: "none" }}>
            <ExamQcmActions
              counterIsRunning={counterIsRunning}
              question={question}
              university={university?.name}
            />
          </Box>

          <Button
            // flex={1}
            variant={"primary"}
            px="10"
            isLoading={submissionLoading}
            // disabled={resumeAttemptRefetch}
            onClick={async () => {
              checkAllQuestionsAnswered();
              if (
                !alreadyAnswered &&
                selectedOptions.find(
                  (el: any) => el.questionId === question._id
                )
              ) {
                const questionIds = answers
                  .map((x: any) => x.questions.map((y: any) => y.questionId))
                  .flat();
                const _selectedOptions = selectedOptions.filter(
                  (el: any) =>
                    !questionIds.some((el2: any) => el2 === el.questionId)
                );

                let allClinicQuestions: string[] = [question._id];
                if (question.children.length > 0) {
                  allClinicQuestions = [
                    ...allClinicQuestions,
                    ...question.children.map((el: any) => el._id),
                  ];
                }

                for (const clinicQuestion of allClinicQuestions) {
                  const exist = _selectedOptions.find(
                    (el: any) => el.questionId === clinicQuestion
                  );
                  if (!exist) {
                    _selectedOptions.push({
                      options: [],
                      questionId: clinicQuestion,
                    });
                  }
                }

                if (isSerieMode) {
                  verifyAnswer();
                } else {
                  submit({
                    attempt: attemptId,
                    // @ts-ignore
                    questions: _selectedOptions,
                    duration: time,
                    question_duration: timeSpentOnQuestion,
                    last_question:
                      tabIndex === questions.length - 1
                        ? tabIndex
                        : tabIndex + 1,
                  });
                  setAnswers((answers: any) => [
                    ...answers,
                    {
                      question: allClinicQuestions[0],
                      questions: allClinicQuestions.map((cc: any) => ({
                        questionId: cc,
                      })),
                    },
                  ]);
                }
                // setAnswers((answers: any) => ([...answers, {question: allClinicQuestions[0], questions: allClinicQuestions.map((cc: any) => ({questionId: cc}))}]))
              }
              if (
                alreadyAnswered &&
                selectedOptions.find(
                  (el: any) => el.questionId === question._id
                ) &&
                !isSerieMode &&
                queryString.get("view") !== "true"
              ) {
                const questionIds = answers
                .map((x: any) => x.questions.map((y: any) => y.questionId))
                .flat();
                const _selectedOptions = selectedOptions.filter(
                  (el: any) => !questionIds.some((el2: any) => el2 === el.questionId)
                );
            
                let allClinicQuestions: string[] = [question._id];
                if (question.children.length > 0) {
                  allClinicQuestions = [
                    ...allClinicQuestions,
                    ...question.children.map((el: any) => el._id),
                  ];
                }
            
                for (const clinicQuestion of allClinicQuestions) {
                  const exist = _selectedOptions.find(
                    (el: any) => el.questionId === clinicQuestion
                  );
                  if (!exist) {
                    _selectedOptions.push({ options: [], questionId: clinicQuestion });
                  }
                }
                let x = []
                for (const selectedQuestion of selectedOptions) {
                  const matchingPayload = _selectedOptions.find((item: any) => item.questionId === selectedQuestion.questionId);
          
                  if (matchingPayload) {
                      x.push({...matchingPayload, options: selectedQuestion.options});
                  }
              }
                submit({
                  attempt: attemptId,
                  // @ts-ignore
                  questions: x,
                  // questions: selectedOptions.filter(
                  //   (el: any) => el.questionId === question._id
                  // ),
                  duration: time,
                  question_duration: timeSpentOnQuestion,
                  last_question:
                    tabIndex === questions.length - 1 ? tabIndex : tabIndex + 1,
                  retry: true,
                });
              }
              if (isSerieMode) {
                if (tabIndex < questions.length - 1 && alreadyAnswered) {
                  setTabIndex((x: number) => x + 1);
                }
              } else {
                setTabIndex((x: number) => x + 1);
              }
              // if (tabIndex > (questions.length - 1) / 3) {
              if (alreadyAnswered) {
                scrollRight(scrollValue);
              }
              // scrollRight(100);
              // }
              if (tabIndex !== questions.length - 1) {
                // startTimer();
                console.log("Do nothing!");
              }
              if (tabIndex === questions.length - 1 && alreadyAnswered) {
                if (checkAllQuestionsAnswered()?.length === 0) {
                  history.push(`/qcm/${type}/${qcmId}/${attemptId}/result`);
                  // history.push(`/qcm/${type}/${qcmId}/${attemptId}/e/result`);
                }
              }

              goToTop();
            }}
          >
            {tabIndex === questions.length - 1
              ? "Terminer"
              : selectedOptions.find(
                  (el: any) => el.questionId === question._id
                )
              ? "Suivant"
              : "Passer"}
          </Button>
          {/* {!answered && (
            <Button
              variant={"primary"}
              px="10"
              onClick={verifyAnswer}
              isLoading={submissionLoading}
              loadingText={"Vérification en cours"}
            >
              Vérifer
            </Button>
          )} */}
        </HStack>
      }
    </Stack>
  );
};

export default ExamQcmBody;
