import { useHistory } from "react-router-dom";

export default function SeriesIcon({color="white"}:{color?: string}) {
  const { location } = useHistory()
  
  return (
    <svg
      id="note-2"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <rect
        id="Rectangle_23"
        data-name="Rectangle 23"
        width="24"
        height="24"
        rx="5"
        fill={location.pathname.includes("/series") ? "#4B62F0" : color}
        opacity="0"
        />
      <g id="Group_24" data-name="Group 24" transform="translate(1.2 1.2)">
        <path
          id="Path_34"
          data-name="Path 34"
          d="M14.229,18.162a7.093,7.093,0,0,1-.738-.036,9.757,9.757,0,0,1-1.548-.261l-1.512-.36c-4.149-.981-5.508-3.177-4.536-7.317l.882-3.771A9.565,9.565,0,0,1,7.5,4.293c1.548-3.2,4.509-2.907,6.615-2.412l1.5.351c2.106.495,3.438,1.278,4.185,2.475s.855,2.736.36,4.842l-.882,3.762c-.774,3.294-2.385,4.851-5.049,4.851ZM11.808,2.925a3.111,3.111,0,0,0-3.1,1.953,9.148,9.148,0,0,0-.621,1.845l-.882,3.771c-.8,3.4.126,4.887,3.528,5.7l1.512.36a9.274,9.274,0,0,0,1.359.225c2.448.243,3.663-.828,4.347-3.771l.882-3.762c.405-1.737.351-2.952-.189-3.825s-1.6-1.467-3.348-1.872l-1.5-.351a8.629,8.629,0,0,0-1.989-.27Z"
          fill={location.pathname.includes("/series") ? "#4B62F0" : color}
          />
        <path
          id="Path_35"
          data-name="Path 35"
          d="M7.5,20.025c-2.313,0-3.789-1.386-4.734-4.311L1.611,12.159C.333,8.2,1.476,5.967,5.418,4.689L6.84,4.23a9.38,9.38,0,0,1,1.134-.3.652.652,0,0,1,.666.252.7.7,0,0,1,.072.711A8.388,8.388,0,0,0,8.1,6.732L7.218,10.5c-.8,3.4.126,4.887,3.528,5.7l1.512.36a9.274,9.274,0,0,0,1.359.225.692.692,0,0,1,.6.5.676.676,0,0,1-.27.729,8.9,8.9,0,0,1-2.286,1.053l-1.422.468a9.259,9.259,0,0,1-2.745.486ZM7,5.6l-1.161.378C2.628,7.011,1.863,8.523,2.9,11.745L4.05,15.3c1.044,3.213,2.556,3.987,5.769,2.952l1.422-.468c.054-.018.1-.036.153-.054l-.954-.225c-4.149-.981-5.508-3.177-4.536-7.317l.882-3.771c.063-.288.135-.567.216-.819Z"
          fill={location.pathname.includes("/series") ? "#4B62F0" : color}
          />
        <path
          id="Path_36"
          data-name="Path 36"
          d="M15.741,9.459a1.23,1.23,0,0,1-.171-.018L11.2,8.334a.675.675,0,0,1,.333-1.3L15.9,8.136a.673.673,0,1,1-.162,1.323Z"
          fill={location.pathname.includes("/series") ? "#F7D372" : color}
          />
        <path
          id="Path_37"
          data-name="Path 37"
          d="M13.1,12.5a1.23,1.23,0,0,1-.171-.018l-2.619-.666a.675.675,0,0,1,.333-1.3l2.619.666a.674.674,0,0,1,.486.819A.655.655,0,0,1,13.1,12.5Z"
          fill={location.pathname.includes("/series") ? "#F7D372" : color}
        />
      </g>
    </svg>
  );
}
