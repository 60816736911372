import { useHistory } from "react-router-dom";

export default function ExamsIcon({color="white"}:{color?: string}) {
  const { location } = useHistory()

  return (
    <svg
      id="receipt-edit"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <rect
        id="Rectangle_25"
        data-name="Rectangle 25"
        width="24"
        height="24"
        rx="5"
        fill={location.pathname.includes("/examen-blanc") ? "#4B62F0" : color}
        opacity="0"
      />
      <g id="Group_26" data-name="Group 26" transform="translate(1.2 1.2)">
        <path
          id="Path_42"
          data-name="Path 42"
          d="M9.486,19.746a.665.665,0,0,1-.54-.27l-.909-1.215A.908.908,0,0,0,6.57,18.18c-1.3,1.395-2.3,1.278-2.772,1.1s-1.305-.81-1.305-2.808V6.336c0-4,1.152-5.211,4.923-5.211h6.795c3.771,0,4.923,1.215,4.923,5.211V10.17a.675.675,0,1,1-1.35,0V6.336c0-3.249-.567-3.861-3.573-3.861H7.416c-3.006,0-3.573.612-3.573,3.861V16.47c0,.945.234,1.467.45,1.548.162.063.6-.027,1.287-.756a2.252,2.252,0,0,1,3.537.189l.918,1.215a.675.675,0,0,1-.549,1.08Z"
          fill={location.pathname.includes("/examen-blanc") ? "#4B62F0" : color}
        />
        <path
          id="Path_43"
          data-name="Path 43"
          d="M14.4,6.975H7.2a.675.675,0,1,1,0-1.35h7.2a.675.675,0,1,1,0,1.35Z"
          fill={location.pathname.includes("/examen-blanc") ? "#F7D372" : color}
          />
        <path
          id="Path_44"
          data-name="Path 44"
          d="M13.5,10.575H8.1a.675.675,0,0,1,0-1.35h5.4a.675.675,0,0,1,0,1.35Z"
          fill={location.pathname.includes("/examen-blanc") ? "#F7D372" : color}
          />
        <path
          id="Path_45"
          data-name="Path 45"
          d="M13.338,19.6a1.265,1.265,0,0,1-.9-.36,1.28,1.28,0,0,1-.342-1.107l.171-1.215A1.783,1.783,0,0,1,12.726,16l3.186-3.186a2.143,2.143,0,0,1,1.314-.7,2.038,2.038,0,0,1,1.638.7,2.027,2.027,0,0,1,.7,1.638,2.214,2.214,0,0,1-.7,1.314l-3.186,3.186a1.783,1.783,0,0,1-.918.459l-1.215.171a1.607,1.607,0,0,1-.207.018Zm4.041-6.147h-.027a.945.945,0,0,0-.486.315L13.68,16.956a.342.342,0,0,0-.072.153l-.162,1.125,1.125-.162a.5.5,0,0,0,.153-.081l3.186-3.186a.98.98,0,0,0,.315-.486c.018-.18-.162-.4-.315-.549a.918.918,0,0,0-.531-.315Z"
          fill={location.pathname.includes("/examen-blanc") ? "#4B62F0" : color}
          />
        <path
          id="Path_46"
          data-name="Path 46"
          d="M17.928,16.425a.58.58,0,0,1-.18-.027,3.579,3.579,0,0,1-2.466-2.466.684.684,0,0,1,.468-.837.67.67,0,0,1,.828.468,2.219,2.219,0,0,0,1.53,1.53.679.679,0,0,1-.18,1.332Z"
          fill={location.pathname.includes("/examen-blanc") ? "#4B62F0" : color}
        />
      </g>
    </svg>
  );
}
