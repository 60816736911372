import {
  useToast,
  VStack,
  HStack,
  Stack,
  Box,
  Button,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { useQuery, useMutation } from "react-query";
import { useParams, useHistory } from "react-router-dom";
import AnswerPartialIcon from "../../../assets/icons/AnswerPartialIcon";
import AnswerRightIcon from "../../../assets/icons/AnswerRightIcon";
import AnswersIcon from "../../../assets/icons/AnswersIcon";
import AnswerWrongIcon from "../../../assets/icons/AnswerWrongIcon";
import ChronoIcon from "../../../assets/icons/ChronoIcon";
import PageHeading from "../../../components/PageHeading/PageHeading";
import StatsChart from "../../../components/StatsChart/StatsChart";
import MyToast from "../../../components/Toast/Toast";
import SidebarLayout from "../../../layouts/SidebarLayout";
import { createCustomQcm } from "../../../services/custom-qcm/services";
import {
  getAttemptResult,
  createNewAttempt,
} from "../../../services/qcm/services";
import { secondsToHMS } from "../../../utils/timerConverter";

export default function SerieResult() {
  const { attemptId, qcmId, type } = useParams<{
    type: string;
    qcmId: string;
    attemptId: string;
  }>();
  const toast = useToast();
  const history = useHistory();
  const { data: result, isLoading: loadingResult } = useQuery(
    `result-${attemptId}`,
    () => getAttemptResult({ attemptId, type })
  );

  const { mutateAsync: startNewAttempt, isLoading: isStartingNewAttempt } = useMutation(createNewAttempt, {
    onError: (err: any) => {
      MyToast({
        toast,
        description: err.response.data.message,
        status: "error",
        title: "Erreur",
      });
    },
    onSuccess: (res: any) => {
      history.push(`/qcm/serie/${qcmId}/${res.attemptId}`);
    },
  });

  const { mutate: createQcm, isLoading: isCreatingQcm } = useMutation(
    createCustomQcm,
    {
      onSuccess: (response: any) => {
        history.push(`/qcm/serie/${response.qcmId}/${response.attemptId}`);
      },
      onError: (err: any) => {
        MyToast({
          toast,
          description: err.response.data.message,
          status: "error",
          title: "Erreur",
        });
      },
    }
  );

  const percentage = () => {
    const correctPercentage =
      (result?.[0].correct /
        result?.[0]?.answers?.filter((el: any) => el.question.isParent)
          ?.length) *
      100;
    const wrongPercentage =
      (result?.[0].fausse /
        result?.[0]?.answers?.filter((el: any) => el.question.isParent)
          ?.length) *
      100;
    const partielPercentage =
      (result?.[0].partiel /
        result?.[0]?.answers?.filter((el: any) => el.question.isParent)
          ?.length) *
      100;

    return {
      correctPercentage,
      wrongPercentage,
      partielPercentage,
    };
  };

  const displayDuration = (avgTime: number) => {
    const hours = secondsToHMS(avgTime).hours;
    const minutes = secondsToHMS(avgTime).minutes;
    const seconds = secondsToHMS(avgTime).seconds;

    const arr = [];
    if (hours) {
      arr.push(`${hours < 9 ? `0${hours}` : hours} h`);
    }
    // if (minutes) {
    arr.push(`${minutes < 9 ? `0${minutes}` : minutes} min`);
    // }
    // if (seconds) {
    arr.push(`${seconds < 9 ? `0${seconds}` : seconds} sec`);
    // }

    return arr.join(" ");
  };

  const calcAvgTime = () => {
    if (result[0]) {
      const total = result[0]?.answers.reduce(
        (acc: number, val: any) => acc + val.question_duration,
        0
      );
      const avg = total / result?.[0]?.answers?.length;

      return displayDuration(Math.round(avg));
    }
  };

  const startAgain = () => {
    if(type === "custom"){
      createQcm(result?.[0]?.qcm?.subject?._id)
    }else{
      startNewAttempt({ qcmId, type })
    }
  }

  return (
    <SidebarLayout>
      {loadingResult ? (
        <p>Loading...</p>
      ) : (
        <VStack
          justify={"space-between"}
          align="stretch"
          height={"90vh"}
          spacing={6}
        >
          <HStack justify={"space-between"}>
            <VStack
              align={"flex-start"}
              spacing={1}
              // borderBottom="2px solid"
              // borderColor={"gold-yellow"}
              pb="2"
              flexGrow={0.4}
            >
              {type === "serie" && (
                <HStack>
                  <Text color="blue-light" fontSize={"sm"}>
                    {result[0]?.qcm?.speciality?.name} /{" "}
                    {result[0]?.qcm?.subject?.name}
                  </Text>
                </HStack>
              )}
              {/* <Heading size={"md"}>
                {result.university + " " + result.year}
              </Heading> */}
              <PageHeading
                title={type === "custom" ? `${result[0]?.qcm?.speciality?.name} / ${result[0]?.qcm?.subject?.name}` : 
                  (result?.[0]?.qcm?.university?.name +
                  " " +
                  result?.[0]?.qcm?.year)
                }
              />
            </VStack>
          </HStack>
          <VStack
            flexGrow={1}
            // justify="center"
            // align={"center"}
            spacing={6}
            maxW={"2xl"}
            w={{ base: "auto", md: "md" }}
            minW={{ base: "100%", md: "unset" }}
            // alignSelf={"center"}
          >
            <Stack
              bg="lightGrey"
              borderRadius={"lg"}
              minW={{ base: "100%", md: "unset" }}
              // maxW={"xl"}
              w="100%"
              p="6"
              spacing={6}
              alignSelf="stretch"
            >
              <Stack
                // direction={{ base: "column", md: "row" }}
                spacing={{ base: 4, md: 6 }}
              >
                <VStack align="flex-start" flexGrow={1}>
                  <Text color="blue-light" fontWeight={"bold"} fontSize="lg">
                    Moyenne générale
                  </Text>
                  <HStack>
                    <AnswersIcon />
                    <Text>
                      {/* // TODO: moyenne / 20 */}
                      {result?.[0]?.overall?.toFixed(2)} / 20
                    </Text>
                  </HStack>
                </VStack>
                {/* <Divider
                  orientation="vertical"
                  borderWidth={"1px"}
                  borderColor="#B7B8BA"
                  bg={"#B7B8BA"}
                  rounded={"xl"}
                  w={{ base: "60%", md: "2px" }}
                  h="50%"
                  alignSelf={"center"}
                /> */}
                <VStack align="flex-start" flexGrow={1}>
                  <Text color="blue-light" fontWeight={"bold"} fontSize="lg">
                    Temps par question
                  </Text>
                  <HStack>
                    <ChronoIcon bg="black" />
                    {/* // TODO: min sec */}
                    {/* <Text>{displayDuration()}</Text> */}
                    <Text>{calcAvgTime()}</Text>
                  </HStack>
                </VStack>
              </Stack>
              <StatsChart stats={{ correct: result?.[0]?.correct, fausse: result?.[0]?.fausse, partial: result?.[0]?.partiel }}
                questionsCount={result?.[0]?.partiel+result?.[0]?.fausse+result?.[0]?.correct}
              />
              {/* <HStack align="stretch" spacing={4}>
                <VStack
                  width={"5px"}
                  spacing={0}
                  borderRadius="md"
                  overflow={"hidden"}
                  bg="white"
                >
                  <Box
                    bg="light-green"
                    height={percentage().correctPercentage + "%"}
                    width={"100%"}
                  />
                  <Box
                    bg="lightOrange"
                    height={percentage().partielPercentage + "%"}
                    width={"100%"}
                  />
                  <Box
                    bg="lightRed"
                    height={percentage().wrongPercentage + "%"}
                    width={"100%"}
                  />
                </VStack>
                <VStack flexGrow={1} align="flex-start" spacing={4}>
                  <VStack align={"flex-start"}>
                    <Text fontWeight={"bold"} color="light-green">
                      Réussi
                    </Text>
                    <HStack>
                      <AnswerRightIcon />
                      <Text>{result?.[0]?.correct} réponses</Text>
                    </HStack>
                  </VStack>
                  <VStack align={"flex-start"}>
                    <Text fontWeight={"bold"} color="lightOrange">
                      Incomplète
                    </Text>
                    <HStack>
                      <AnswerPartialIcon />
                      <Text>{result?.[0]?.partiel} réponses</Text>
                    </HStack>
                  </VStack>
                  <VStack align={"flex-start"}>
                    <Text fontWeight={"bold"} color="lightRed">
                      Fausse
                    </Text>
                    <HStack>
                      <AnswerWrongIcon />
                      <Text>{result?.[0]?.fausse} réponses</Text>
                    </HStack>
                  </VStack>
                </VStack>
              </HStack> */}
            </Stack>
            <Stack maxW={"xl"} minW={"100%"}>
              <Button
                variant={"primary"}
                flexGrow={1}
                onClick={() =>
                  history.push(
                    `/qcm/serie/${qcmId}/${attemptId}?view=true`
                  )
                }
              >
                Voir les questions
              </Button>
              <Button
                variant={"secondary"}
                flexGrow={1}
                onClick={startAgain}
                isLoading={isCreatingQcm || isStartingNewAttempt}
              >
                Recommencer à zero
              </Button>
              {type === "serie" && (
                <Button
                  variant={"secondary"}
                  flexGrow={1}
                  onClick={() =>
                    history.push(`/qcm/serie/${qcmId}/${attemptId}?retry=true`)
                  }
                >
                  Refaire les questions ratées
                </Button>
              )}
              <Button
                variant={"secondary"}
                flexGrow={1}
                bg="lightRed"
                color={"white"}
                _hover={{
                  bg: "darkRed",
                  "& svg": { filter: "brightness(3)" },
                }}
                height={{ base: "2.5rem", "2xl": "3rem" }}
                onClick={() => {
                  if (type === "serie") {
                    history.push(
                      `/series/${result?.[0]?.qcm?.speciality?.name}/${result?.[0]?.qcm?.subject?.name}`
                    );
                  } else if(type === "custom") {
                    history.push(`/playlist/${result?.[0]?.qcm?.speciality?.name}`);
                  } else {
                    history.push(
                      `/examen-blanc/${result?.[0]?.qcm?.year}/${result?.[0]?.qcm?.university?.name}`
                    );
                  }
                }}
              >
                Quitter
              </Button>
            </Stack>
          </VStack>
        </VStack>
      )}
    </SidebarLayout>
  );
}
