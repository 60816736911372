import AuthLayout from "../../../layouts/AuthLayout";
import reset_pwd from "../../../assets/img/reset_pwd.png";
import HeadingWithLines from "../../../components/Heading/HeadingWithLines";
import KeyIcon from "../../../assets/icons/KeyIcon";
import { IInputField } from "../../../types";
import { FormProvider, useForm } from "react-hook-form";
import { Box, Button, Fade, Flex, Progress, Spinner, Text, useToast } from "@chakra-ui/react";
import Field from "../../../components/Field/Field";
import { useHistory, useParams } from "react-router-dom";
import { resetPassword } from "../../../services/auth/services";
import { useMutation } from "react-query";
import { useState } from "react";
import SquareGreenIcon from "../../../assets/icons/SquareGreenIcon";

const resetPwdFields: IInputField[] = [
  {
    type: "password",
    name: "password",
    placeholder: "Mot de passe",
    icon: <KeyIcon />,
    rules: {
      required: "Veuillez saisir votre mot de passe",
    },
  },
  {
    type: "password",
    name: "confirm_password",
    placeholder: "Confirmer le mot de passe",
    icon: <KeyIcon />,
    rules: {
      required: "Les mot de passe ne sont pas identiques",
    },
  },
];

export default function ForgetPassword() {
  const methods = useForm<any>({
    defaultValues: {
      password: "",
      confirm_password: "",
    },
  });
  const params: { token: string } = useParams();
  const history = useHistory()
  const [success, setSuccess] = useState(false);
  const toast = useToast();

  const onSubmit = ({
    password,
    confirm_password,
  }: {
    password: string;
    confirm_password: string;
  }) => {
    if (password !== confirm_password) {
      return methods.setError("confirm_password", {
        message: "Les mots de passe et sa confirmation ne correspondent pas",
      });
    }

    mutateAsync({ password, token: params.token });
  };

  const { mutateAsync, isLoading } = useMutation(resetPassword, {
    onError: ({
      response: {
        data: { message, statusCode },
      },
    }) => {
        if(statusCode === 400){
            methods.setError("password", { message })
        }else{
            toast({
                title: "Impossible de réinitialiser votre mot de passe.",
                description: message,
                status: "error",
                duration: 9000,
                isClosable: true,
                position: "bottom-right",
            });
        }
    },
    onSuccess: (res) => {
        setSuccess(true);
        localStorage.setItem("access_token", res.access_token);
        setTimeout(() => {
            history.push("/tableau-de-bord")
        }, 3000)
    },
  });
  return (
    <AuthLayout illustration={reset_pwd}>
      <Flex flexGrow={1} direction={"column"} justify={"center"}>
        {!success ? <><HeadingWithLines linesColor="green" position="middle">
          Réinitialiser votre mot de passe
        </HeadingWithLines>
        <FormProvider {...methods}>
          <Flex
            as="form"
            direction={"column"}
            my="6"
            onSubmit={methods.handleSubmit(onSubmit)}
            position={"relative"}
          >
            {resetPwdFields.map((field: IInputField) => (
              <Box key={field.name} mt="4">
                <Field {...field} bg="light-blue-light" />
              </Box>
            ))}
            <SquareGreenIcon />
            {isLoading ? <Button variant={"primary"} mt="4" leftIcon={<Spinner color='white'/>} opacity={0.7} _hover={{}}>
                <span>Modification en cours...</span>
            </Button> : 
            <Button type="submit" variant={"primary"} mt="4">
                Confirmer
            </Button>}
          </Flex>
        </FormProvider></>:(
            <Fade in={success}>
                <HeadingWithLines position="end">
                    Mot de passe changé
                </HeadingWithLines>
                <Text mt="4">
                    Votre mot de passe à été changé avec succès!
                </Text>
                <Text>
                    Dans quelques secondes, vous allez être redirigé vers votre tableau de bord.
                </Text>
                <Progress colorScheme={"messenger"} my="2" borderRadius={"xl"} size='xs' isIndeterminate />
            </Fade>
        )}
      </Flex>
    </AuthLayout>
  );
}
