import { Flex } from "@chakra-ui/react";
import { useQuery } from "react-query";
import { useHistory, useLocation } from "react-router-dom";
import Loading from "../../../components/Loading";
import { loadUserFirstLogin } from "../../../services/auth/services";

export default function Connecting() {
  const { search } = useLocation();
  const { push } = useHistory()

  function useQueryParams() {
    return new URLSearchParams(search);
  }
  let queryParams: any = useQueryParams();

  const { isLoading, data, isSuccess, remove, isError, error } = useQuery(["user"], () => loadUserFirstLogin(queryParams.get("access_token"), push), {
    refetchOnWindowFocus: false,
    enabled: Boolean(queryParams.get("access_token")),
    retry: false,
    })

  return (
    <Flex align={"center"} justify="center" height={"100vh"}>
      <Loading />
    </Flex>
  );
}
