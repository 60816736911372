
export default function ChronoResumeIcon({bg="#fff"}:{bg?:string}) {
  return (
    <svg
      id="timer-start"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <rect
        id="Rectangle_890"
        data-name="Rectangle 890"
        width="24"
        height="24"
        rx="5"
        fill="#4b62f0"
        opacity="0"
      />
      <g id="Group_1133" data-name="Group 1133" transform="translate(1.2 1.2)">
        <path
          id="Path_126"
          data-name="Path 126"
          d="M10.8,12.375a.68.68,0,0,1-.675-.675V7.2a.675.675,0,1,1,1.35,0v4.5A.68.68,0,0,1,10.8,12.375Z"
          fill="#4b62f0"
        />
        <path
          id="Path_127"
          data-name="Path 127"
          d="M10.8,20.475a8.55,8.55,0,1,1,8.55-8.55.675.675,0,1,1-1.35,0,7.2,7.2,0,1,0-7.2,7.2.675.675,0,1,1,0,1.35Z"
          fill="#4b62f0"
        />
        <path
          id="Path_128"
          data-name="Path 128"
          d="M13.5,2.475H8.1a.675.675,0,0,1,0-1.35h5.4a.675.675,0,0,1,0,1.35Z"
          fill="#4b62f0"
        />
        <path
          id="Path_129"
          data-name="Path 129"
          d="M14.535,19.818a1.674,1.674,0,0,1-.864-.225,2.091,2.091,0,0,1-.936-1.9V15.615a2.091,2.091,0,0,1,.936-1.9,2.1,2.1,0,0,1,2.106.135l1.8,1.044a2.092,2.092,0,0,1,1.179,1.755,2.092,2.092,0,0,1-1.179,1.755l-1.8,1.044A2.467,2.467,0,0,1,14.535,19.818Zm.009-4.986a.459.459,0,0,0-.2.045.839.839,0,0,0-.261.729v2.079a.839.839,0,0,0,.261.729.838.838,0,0,0,.756-.135l1.8-1.044c.315-.18.5-.405.5-.585s-.189-.405-.5-.585l-1.8-1.044a1.138,1.138,0,0,0-.558-.189Z"
          fill="#4b62f0"
        />
      </g>
    </svg>
  );
}
