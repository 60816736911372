import axiosInstance from "../../utils/axiosInstance";

export const getStatus = async () => {
    let access_token = localStorage.getItem("access_token");
    return await axiosInstance({
      method: "get",
      url: `/status`,
      headers:{
        Authorization: `Bearer ${access_token}`
      }
    }).then((res: any) => res.data);
};