import { HStack, VStack, Box, Text } from "@chakra-ui/react";
import AnswerPartialIcon from "../../assets/icons/AnswerPartialIcon";
import AnswerRightIcon from "../../assets/icons/AnswerRightIcon";
import AnswerWrongIcon from "../../assets/icons/AnswerWrongIcon";


const StatsChart = ({
  stats,
  questionsCount,
  orientation = "vertical"
}: {
  stats: { correct: number; fausse: number; partial: number };
  questionsCount: number;
  orientation?: "vertical" | "horizontal"
}) => {

  if(orientation === "vertical"){
  return (
    <HStack align="stretch" spacing={4}>
      <VStack
        width={"5px"}
        spacing={0}
        borderRadius="md"
        overflow={"hidden"}
        bg="white"
      >
        <Box bg="light-green" height={(stats.correct/questionsCount)*100 + "%"} width={"100%"} />
        <Box bg="lightOrange" height={(stats.partial/questionsCount)*100 + "%"} width={"100%"} />
        <Box bg="lightRed" height={(stats.fausse/questionsCount)*100 + "%"} width={"100%"} />
      </VStack>
      <VStack flexGrow={1} align="flex-start" spacing={4}>
        <VStack align={"flex-start"}>
          <Text fontWeight={"bold"} color="light-green">
            Réussi
          </Text>
          <HStack>
            <AnswerRightIcon />
            <Text>{stats.correct} réponses</Text>
          </HStack>
        </VStack>
        <VStack align={"flex-start"}>
          <Text fontWeight={"bold"} color="lightOrange">
            Incomplète
          </Text>
          <HStack>
            <AnswerPartialIcon />
            <Text>{stats.partial} réponses</Text>
          </HStack>
        </VStack>
        <VStack align={"flex-start"}>
          <Text fontWeight={"bold"} color="lightRed">
            Fausse
          </Text>
          <HStack>
            <AnswerWrongIcon />
            <Text>{stats.fausse} réponses</Text>
          </HStack>
        </VStack>
      </VStack>
    </HStack>
  )
    }else{
      return (
        <HStack align="stretch" spacing={4}>
        <HStack
          h={"6px"}
          w="full"
          spacing={0}
          borderRadius="md"
          overflow={"hidden"}
          bg="white"
        >
          <Box height="6px" bg="light-green" width={(stats.correct/questionsCount)*100 + "%"}  />
          <Box height="6px" bg="lightOrange" width={(stats.partial/questionsCount)*100 + "%"}  />
          <Box height="6px" bg="lightRed" width={(stats.fausse/questionsCount)*100 + "%"}  />
        </HStack>
        </HStack>
      )
    }
};

export default StatsChart;
