import { Button, Tag, useTab, VStack } from "@chakra-ui/react";
import { forwardRef, useCallback } from "react";

export const CustomTab = forwardRef((props: any, ref: any) => {
  const tabProps = useTab({ ...props, ref });
  const isSelected = !!tabProps["aria-selected"];

  const genColor = useCallback(() => {
    switch (props.status?.status) {
      case "fausse":
        return "215, 97, 97";
      case "correct":
        return "127, 207, 145";
      case "partiel":
        return "252, 183, 49";
      default:
        return "midGrey";
    }
  }, [props.status?.status]);

  return (
    <VStack align={"center"} zIndex={isSelected ? 2 : 0}>
      <Button
        {...tabProps}
        bg={
          isSelected && Boolean(props.status?.status)
            ? `rgb(${genColor()})`
            : isSelected && !Boolean(props.status?.status)
            ? "blue-light"
            : "midGrey"
        }
        color={isSelected ? "white" : "black"}
        borderRadius="md"
        width={{ base: "50px", xl: "50px", "2xl": "60px" }}
        transform={isSelected ? "scale(1.3)" : "scale(1)"}
        height={{ base: "28px", "2xl": "30px" }}
        mx="2"
        boxShadow={isSelected ? `0px 5px 20px rgba(${genColor()},.7)` : "none"}
        transition="all 0.2s"
        _active={{}}
        _focus={{}}
        _hover={{
          bg: isSelected
            ? Boolean(props.status?.status)
              ? genColor()
              : "blue-light"
            : "#CBCCCD",
        }}
      >
        {tabProps.children}
      </Button>
      {/* {props.status && ( */}
        <Tag
          bg={props.status ? `rgb(${genColor()})` : "lightGrey"}
          borderRadius="full"
          width="0.5rem"
          height={"0.5rem"}
          size="5rem"
        />
      {/* )} */}
    </VStack>
  );
});
