import { useHistory } from "react-router-dom";

export default function UserIcon({color= "#fff"}:{color?: string}) {
  const { location } = useHistory()

  return (
    <svg
      id="user-square"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <rect
        id="Rectangle_28"
        data-name="Rectangle 28"
        width="24"
        height="24"
        rx="5"
        fill={location.pathname.includes("/profile") ? "#4B62F0" : color}
        opacity="0"
      />
      <g id="Group_37" data-name="Group 37" transform="translate(1.2 1.2)">
        <path
          id="Path_48"
          data-name="Path 48"
          d="M13.5,20.475H8.1a10.5,10.5,0,0,1-3.015-.369.69.69,0,0,1-.486-.7c.225-2.691,2.952-4.806,6.2-4.806S16.767,16.7,17,19.4a.664.664,0,0,1-.486.7,10.5,10.5,0,0,1-3.015.369ZM6.048,18.954a10.663,10.663,0,0,0,2.052.171h5.4a10.663,10.663,0,0,0,2.052-.171c-.477-1.728-2.448-3.006-4.752-3.006S6.525,17.226,6.048,18.954Z"
          fill={location.pathname.includes("/profile") ? "#F7D372" : color}
        />
        <path
          id="Path_49"
          data-name="Path 49"
          d="M8.1,1.125h5.4c2.493,0,4.182.528,5.315,1.66s1.66,2.821,1.66,5.315v5.4a8.628,8.628,0,0,1-.909,4.332,4.846,4.846,0,0,1-3.056,2.276.675.675,0,0,1-.856-.593c-.169-2-2.3-3.567-4.853-3.567s-4.684,1.567-4.853,3.567a.675.675,0,0,1-.856.593,4.846,4.846,0,0,1-3.056-2.276A8.628,8.628,0,0,1,1.125,13.5V8.1c0-2.493.528-4.182,1.66-5.315S5.607,1.125,8.1,1.125Zm8.742,17.427c1.592-.738,2.283-2.3,2.283-5.052V8.1c0-2.112-.4-3.5-1.265-4.36S15.612,2.475,13.5,2.475H8.1c-2.112,0-3.5.4-4.36,1.265S2.475,5.988,2.475,8.1v5.4c0,2.755.691,4.315,2.283,5.052a5.051,5.051,0,0,1,1.848-2.6A6.947,6.947,0,0,1,10.8,14.6a6.947,6.947,0,0,1,4.194,1.353A5.051,5.051,0,0,1,16.842,18.552ZM10.8,5.625a3.9,3.9,0,1,1-3.9,3.9A3.9,3.9,0,0,1,10.8,5.625Zm0,6.453A2.552,2.552,0,1,0,8.253,9.522,2.554,2.554,0,0,0,10.8,12.078Z"
          fill={location.pathname.includes("/profile") ? "#4B62F0" : color}
        />
        <path
          id="Path_50"
          data-name="Path 50"
          d="M10.8,13.428a3.9,3.9,0,1,1,3.9-3.906,3.906,3.906,0,0,1-3.9,3.906Zm0-6.453a2.552,2.552,0,0,0,0,5.1,2.552,2.552,0,0,0,0-5.1Z"
          fill={location.pathname.includes("/profile") ? "#4B62F0" : color}
        />
      </g>
    </svg>
  );
}
