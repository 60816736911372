import { chakra, shouldForwardProp, Text, Box } from "@chakra-ui/react";
import { motion, isValidMotionProp } from "framer-motion";

const ChakraBox = chakra(motion.div, {
  /**
   * Allow motion props and non-Chakra props to be forwarded.
   */
  shouldForwardProp: (prop) =>
    isValidMotionProp(prop) || shouldForwardProp(prop),
});

export default function Loading() {
  return (
    <Box position={"relative"}>
      <ChakraBox
        animate={{
          scale: [1, 1.5, 1.5, 1, 1],
          rotate: [0, 0, 270, 270, 0],
          borderRadius: ["20%", "20%", "50%", "50%", "20%"],
          backgroundColor: [
            "#2235a9",
            "#2235a9",
            "#4B62F0",
            "#4B62F0",
            "#2235a9",
          ],
        }}
        // @ts-ignore no problem in operation, although type error appears.
        transition={{
          duration: 3,
          ease: "easeInOut",
          repeat: Infinity,
          repeatType: "loop",
        }}
        padding="4"
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="100px"
        height="100px"
      ></ChakraBox>
      <Text
        position={"absolute"}
        top="50%"
        left="50%"
        fontSize={"3xl"}
        transform="translate(-50%,-50%)"
        color="white"
      >
        <strong>ECN+</strong>
      </Text>
    </Box>
  );
}
