import { HStack, TabList, VStack, Button, IconButton, useBreakpointValue } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import ChronoIcon from "../../assets/icons/ChronoIcon";
import ChronoPausedIcon from "../../assets/icons/ChronoPausedIcon";
import useQueryParams from "../../hooks/useQueryParams";
import { CustomTab } from "../../pages/Unauthorized/Qcm/CustomTab";
import { useTimer } from "../../timer-provider/TimerProvider";
import { secondsToHMS } from "../../utils/timerConverter";

export default function QcmHeader({
  questions,
  headerRef,
  answers,
  setCounterIsRunning,
  setisFixed,
  isFixed,
  tabIndex,
  isViewMode,
  scrollRight,
  scrollValue,
  isCustomQcm
}: any) {
  const { time, timerIsRunning } = useTimer();
  const { hours, minutes, seconds } = secondsToHMS(time);
  const queryParams = useQueryParams();
  const iconBreakPoint = useBreakpointValue({ base: "", md: "arrow-btn" })

  useEffect(() => {
    setCounterIsRunning(timerIsRunning);
  }, [timerIsRunning]);

  useEffect(() => {
    const scrollTop: any = window.addEventListener("scroll", () => {
      setisFixed(true);
    });

    return () => {
      window.removeEventListener("scroll", scrollTop);
    };
  }, [tabIndex]);

  return (
    <HStack
      align={"stretch"}
      spacing={0}
      bg={"lightGrey"}
      position={isFixed ? "fixed" : "inherit"}
      top="0px"
      width={{
        base: "100vw",
        md: "calc(100vw - 240px)",
        "2xl": "calc(100vw - 320px)",
      }}
      borderTopLeftRadius={{ base: "0px", md: "1.5rem" }}
      overflow={"hidden"}
      _hover={{ "& .arrow-btn": { display: "inherit" } }}
      zIndex="2"
    >
      {" "}
      {isViewMode && (
        <IconButton
          className={iconBreakPoint}
          icon={<FiChevronLeft fontSize={"2rem"} />}
          aria-label="question suivante"
          onClick={() => scrollRight(-scrollValue)}
          size="xs"
          cursor={"pointer"}
          variant="solid"
          rounded={"0px"}
          bg="blue-light"
          h="100%"
          position="sticky"
          zIndex={2}
          left="0px"
          color="white"
          borderEndRadius={"1rem"}
          alignSelf="center"
          display={"none"}
          _hover={{}}
          _disabled={{}}
        />
      )}
      <TabList
        position={"relative"}
        width={"100%"}
        overflow={isViewMode ? "scroll" : "hidden"}
        ref={headerRef}
        scrollBehavior="smooth"
        className="tab-item-qcm"
        p="4"
        pt="6"
        bg="lightGrey"
      >
        {questions.map((tab: any, index: number) => (
          <CustomTab
            key={index}
            status={answers.find((el: any) => el.question === tab._id)}
          >
            {isCustomQcm ? index+1 : tab.order}
          </CustomTab>
        ))}
      </TabList>
      {isViewMode && <IconButton
        className={iconBreakPoint}
        icon={<FiChevronRight fontSize={"2rem"} />}
        aria-label="question précedente"
        onClick={() => scrollRight(scrollValue)}
        size="xs"
        cursor={"pointer"}
        variant="solid"
        rounded={"0px"}
        bg="blue-light"
        height="100%"
        position="sticky"
        zIndex={2}
        right="0px"
        color="white"
        borderStartRadius={"1rem"}
        alignSelf="center"
        display={"none"}
        _hover={{}}
        _disabled={{}}
      />}
      <VStack
        bg={"#E2E5F4"}
        width="200px"
        borderBottomLeftRadius={"1rem"}
        align="center"
        justify={"center"}
        display={queryParams.get("view") === "true" ? "none" : "inherit"}
      >
        <Button
          variant={"primary"}
          leftIcon={timerIsRunning ? <ChronoIcon /> : <ChronoPausedIcon />}
          size="sm"
          boxShadow={"0px 5px 20px 10px rgba(75, 98, 240,.4)"}
          _hover={{ bg: "rgba(75, 98, 194, 1)" }}
          _active={{}}
          _focus={{}}
        >
          {hours ? `0${hours}:` : ""}
          {minutes < 10 ? `0${minutes}` : minutes}:
          {seconds < 10 ? `0${seconds}` : seconds}
        </Button>
      </VStack>
    </HStack>
  );
}
