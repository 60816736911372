import { Box, Button, Fade, Flex, Spinner, Text } from "@chakra-ui/react";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useMutation } from "react-query";
import EmailIcon from "../../../assets/icons/EmailIcon";
import enter_email from "../../../assets/img/enter_email.png";
import Field from "../../../components/Field/Field";
import HeadingWithLines from "../../../components/Heading/HeadingWithLines";
import AuthLayout from "../../../layouts/AuthLayout";
import { sendResetPwdEmail } from "../../../services/auth/services";

const field = {
  type: "text",
  name: "email",
  placeholder: "Votre adresse e-mail",
  icon: <EmailIcon />,
  rules: {
    required: "Veuillez saisir votre adresse e-mail",
  },
};

export default function EnterEmail() {
  const methods = useForm<any>({
    defaultValues: {
      email: "",
    },
  });
  const [emailSent, setEmailSent] = useState(false);

  const onSubmit = ({email}: any) => {
    mutateAsync(email);
  };

  const { mutateAsync, isLoading } =
  useMutation(sendResetPwdEmail, {
    onError: ({response: {data: { message }}}) => methods.setError("email",{ message}),
    onSuccess: () => setEmailSent(true)
  });

  return (
    <AuthLayout illustration={enter_email}>
      <Flex flexGrow={1} direction={"column"} justify={"center"}>
        {!emailSent ? (
          <>
            <HeadingWithLines linesColor="green" position="end">
              Trouver votre adresse e-mail
            </HeadingWithLines>
            <Text textAlign={"center"} mt="4">
              Saisissez votre adresse e-mail de récupération
            </Text>
            <FormProvider {...methods}>
              <Flex
                as="form"
                direction={"column"}
                my="6"
                onSubmit={methods.handleSubmit(onSubmit)}
              >
                <Box key={"email"} mt="4">
                  <Field {...field} bg="light-blue-light" />
                </Box>
                {isLoading ? <Button variant={"primary"} mt="4" leftIcon={<Spinner color='white'/>} opacity={0.7} _hover={{}}>
                  <span>Envoie en cours...</span>
                </Button> : 
                <Button type="submit" variant={"primary"} mt="4">
                  Envoyer
                </Button>}
              </Flex>
            </FormProvider>
          </>
        ) : (
          <Fade in={emailSent}>
            <HeadingWithLines position="end">
              Vérifier votre boite email
            </HeadingWithLines>
            <Text mt="4">
              Nous avons trouvé votre compte ECN+
            </Text>
            <Text mt="4">
              Un email de réinitialisation du mot de passe a été envoyé à votre
              boite.
            </Text>
          </Fade>
        )}
      </Flex>
    </AuthLayout>
  );
}
