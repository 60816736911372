import { useHistory } from "react-router-dom";
import { CloseIcon } from "@chakra-ui/icons";
import { Stack, Flex, Heading, Button } from "@chakra-ui/react";

export default function EmpltyListBanner() {
  const history = useHistory();

  return (
    <Stack justify={"center"} align="center" minH={"80vh"}>
      <Flex
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        bg={"red.500"}
        rounded={"50px"}
        w={"55px"}
        h={"55px"}
        textAlign="center"
      >
        <CloseIcon boxSize={"20px"} color={"white"} />
      </Flex>
      <Heading maxW={"2xl"} textAlign="center">
        Toutes les questions ont été supprimés
      </Heading>
      <Button onClick={() => history.goBack()}>Retourner</Button>
    </Stack>
  );
}
