import { Stack, useToast } from "@chakra-ui/react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  addBookmark,
  checkIfQuestionBookmarked,
  removeBookmarkedQuestions,
} from "../../services/bookmarks/services";
import { BookMarkButton, CancelBtn } from "../QcmBody/QcmActions";
import MyToast from "../Toast/Toast";

const ExamQcmActions = ({
  counterIsRunning,
  question,
  university,
  pauseTimeSpentOnQuestion,
  resumeTimeSpentOnQuestion,
}: any) => {
  const queryClient = useQueryClient();
  const toast = useToast();

  const { data: isItBookmarked, isLoading: checkIsLoading } = useQuery(
    `bookmarked-${question?._id}`,
    () => checkIfQuestionBookmarked(question?._id),
    {
      refetchOnWindowFocus: false,
      retry: false
    }
  );
  const { mutateAsync: bookmarkThisQuestion, isLoading: bookmarkLoading } =
    useMutation(addBookmark, {
      onSuccess: () => {
        queryClient.invalidateQueries(`bookmarked-${question?._id}`);
        MyToast({
          toast,
          status: "success",
          title: "Question sauvegardée.",
          description: `Question n°${question?.order} ajoutée au Playlist`,
        });
      },
      onError: () =>
        MyToast({
          toast,
          status: "error",
          title: "Quelque chose s'est mal passé.",
          description: `Question n°${question?.order} ajoutée au Playlist`,
        }),
    });

  const { mutateAsync: removeQuestion, isLoading: removeIsLoading } =
    useMutation(removeBookmarkedQuestions, {
      onSuccess: () =>
        queryClient.invalidateQueries(`bookmarked-${question?._id}`),
    });

  return (
    <Stack direction={{ base: "row" }}>
      <BookMarkButton
        isItBookmarked={isItBookmarked}
        checkIsLoading={checkIsLoading}
        bookmarkLoading={bookmarkLoading || removeIsLoading}
        bookmarkThisQuestion={bookmarkThisQuestion}
        questionId={question?._id}
        defaultMarked={false}
        removeQuestion={removeQuestion}
      />
      <CancelBtn
        qcmType="exam"
        university={university}
        pauseTimeSpentOnQuestion={pauseTimeSpentOnQuestion}
        resumeTimeSpentOnQuestion={resumeTimeSpentOnQuestion}
      />
    </Stack>
  );
};

export default ExamQcmActions;
