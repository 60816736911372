import {
  Box,
  Flex,
  Heading,
  HStack,
  Icon,
  LinkBox,
  Progress,
  Stack,
  Text,
  Tooltip,
  useColorModeValue,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { useCallback } from "react";
import AnswersIcon from "../../assets/icons/AnswersIcon";
import FolderClosedIcon from "../../assets/icons/FolderClosedIcon";
import FolderDoneIcon from "../../assets/icons/FolderDoneIcon";
import FolderOpenIcon from "../../assets/icons/FolderOpenIcon";
import { motion } from "framer-motion";
import PercentageIcon from "../../assets/icons/PercentageIcon";
import InfoCircleIcon from "../../assets/icons/InfoCircleIcon";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { useAuthProvider } from "../../auth-provider/AuthProvider";
import LockCircle from "../../assets/icons/LockCircle";
import { useParams } from "react-router-dom";

export default function FolderBox({
  goTo,
  item,
  itemType,
  stats,
  statsFormat,
  seriesPage = false,
  withFooter = true,
}: {
  goTo: any;
  item: any;
  itemType?: string;
  stats?: any;
  statsFormat: "average" | "progress";
  seriesPage?: boolean;
  withFooter?: boolean;
}) {
  const bgBox = useColorModeValue("lightGrey", "card-dark");
  const bgStack = useColorModeValue("gray.800", "white");
  const bgText = useColorModeValue("red.100", "red.900");
  const { data: auth } = useAuthProvider();
  const params = useParams<{ specialityName: string }>();

  const folderStatus = () => {
    const answersCount = stats(item.name)?.answersCount;

    if (!answersCount) {
      return FolderClosedIcon;
    } else if (answersCount === item?.seriesQuestionsCount) {
      return FolderDoneIcon;
    } else {
      return FolderOpenIcon;
    }
  };

  return (
    <Tooltip label={item.name} placement="bottom" openDelay={4000}>
      <LinkBox
        as="article"
        w="full"
        maxW={"400px"}
        bg={bgBox}
        borderWidth="1px"
        borderColor={"transparent"}
        rounded=".8rem"
        transition={"all 0.15s ease-in-out"}
        _hover={{
          borderColor: "darkSky",
          boxShadow: "0px 0px 64px -35px rgba(127,202,207, 0.8)",
          cursor: "pointer",
          bg: "darkGrey",
          // "& .xxx": { bg: "transparent" },
        }}
        onClick={goTo}
        overflow="hidden"
      >
        <Stack color={bgStack} h="full" justify={"space-between"}>
          {item?.archived && (
            <Text
              fontSize={"xs"}
              fontWeight={500}
              bg={bgText}
              p={1}
              px={2}
              color={"red.300"}
              rounded={"full"}
              position={"absolute"}
              top="2"
              right={"2"}
            >
              Archivée
            </Text>
          )}
          {((!auth?.current_order && (item.name !== "Cardiologie-CCV" && params.specialityName !== "Cardiologie-CCV")) ||
            (auth?.current_order &&
              auth?.days_left <= 0 &&
              (item.name !== "Cardiologie-CCV" && params.specialityName !== "Cardiologie-CCV"))) && (
            <Box position="absolute" top="2" right="2">
              <Icon as={LockCircle} />
            </Box>
          )}
          <Flex align={"center"} p="5">
            <Box minWidth="40px">
              <Icon as={folderStatus()} w="full" h="full" />
            </Box>
            <VStack align={"flex-start"} ml="4">
              <Heading
                // fontSize={{ base: "md", md: "lg" }}
                fontSize={"lg"}
                pt="2"
                fontWeight={"400"}
                isTruncated
                maxW={"250px"}
              >
                {item.name}
              </Heading>
              <Text pb="2" color="gray.500">
                {item?.subjectCount || item?.seriesCount}{" "}
                {itemType === "subject" ? "séries" : "sujets"}
              </Text>
            </VStack>
          </Flex>
          {withFooter &&
            (statsFormat === "progress" ? (
              <VStack
                align={"stretch"}
                bg="darkGrey"
                p="5"
                className="xxx"
                transition={"all 0.3s"}
              >
                <HStack>
                  <AnswersIcon />
                  <Text fontSize="sm">
                    {stats(item.name)?.answersCount ?? 0} /{" "}
                    {item?.seriesQuestionsCount
                      ?.toLocaleString()
                      ?.replace(",", " ") ?? 0}
                  </Text>
                </HStack>
                {/* <Progress
                value={
                  ((stats(item.name)?.answersCount ?? 0) /
                    item?.seriesQuestionsCount) *
                  100
                }
                colorScheme="cyan"
                rounded={"lg"}
                size="sm"
                bg="white"
                className="progress-bar-folder"
              /> */}
                <HStack
                  spacing={0}
                  bg={"white"}
                  rounded={"md"}
                  overflow="hidden"
                  width={"100%"}
                >
                  <Tooltip
                    label="Bonne réponse"
                    hasArrow
                    openDelay={2000}
                    rounded={"md"}
                  >
                    <motion.div
                      initial={{ width: "0px" }}
                      style={{ height: "8px", background: "#7FCACF" }}
                      animate={{
                        width:
                          (((stats(item.name)?.answersCount ?? 0) /
                            item?.seriesQuestionsCount) *
                            100 || 0) + "%",
                      }}
                      exit={{ width: "0px" }}
                      transition={{ default: { duration: 0.75 } }}
                    />
                  </Tooltip>
                </HStack>
              </VStack>
            ) : (
              <ProgressBar
                values={stats(item.name)?.result}
                seriesPage={seriesPage}
                showOverall={stats(item.name)?.result?.showOverall}
              />
            ))}
        </Stack>
      </LinkBox>
    </Tooltip>
  );
}

export const ProgressBar = ({
  values,
  seriesPage,
  showOverall,
}: {
  values: { correct: number; partiel: number; fausse: number; overall: number };
  seriesPage?: boolean;
  showOverall?: boolean;
}) => {
  const { isOpen, onClose, onToggle } = useDisclosure();
  return (
    <VStack
      align={"stretch"}
      bg="darkGrey"
      p="5"
      h="20"
      className="xxx"
      transition={"all 0.3s"}
    >
      <HStack>
        <PercentageIcon bg="black" size="24" />
        {!seriesPage || showOverall ? (
          <Text fontSize="sm">
            {values?.overall % 1 === 0
              ? values?.overall
              : values?.overall?.toFixed(2) || 0}{" "}
            / 20
          </Text>
        ) : (
          <Tooltip
            label="Le calcul de la moyenne ne sera fait que si vous avez une moyenne dans tous les sujets de cette specialité."
            placement="auto"
            hasArrow
            rounded={"md"}
            isOpen={isOpen}
            onClose={onClose}
          >
            <HStack justify={"space-between"} flex={1} align="center">
              <Text fontSize="sm">n/a</Text>
              <Icon
                as={AiOutlineInfoCircle}
                fontSize="1.2rem"
                onClick={(e: any) => {
                  e.stopPropagation();
                  onToggle();
                }}
              />
            </HStack>
          </Tooltip>
        )}
      </HStack>
      <HStack
        spacing={0}
        bg="white"
        rounded={"md"}
        overflow="hidden"
        width={"100%"}
      >
        <Tooltip label="Bonne réponse" hasArrow openDelay={2000} rounded={"md"}>
          <motion.div
            initial={{ width: "0px" }}
            style={{ height: "8px", background: "#7FCF91" }}
            animate={{ width: (values?.correct || 0) * 100 + "%" }}
            // exit={{ width: "0px" }}
            // transition={{ default: { duration: 0.75 } }}
            transition={{ default: { duration: 4 } }}
          />
        </Tooltip>
        <Tooltip
          label="Réponse incomplète"
          hasArrow
          openDelay={2000}
          rounded={"md"}
        >
          <motion.div
            initial={{ width: "0px" }}
            style={{ height: "8px", background: "#FCB731" }}
            animate={{ width: (values?.partiel || 0) * 100 + "%" }}
            // transition={{ default: { duration: 0.75 } }}
            transition={{ default: { duration: 4 } }}
          />
        </Tooltip>
        <Tooltip
          label="Fausse réponse"
          hasArrow
          openDelay={2000}
          rounded={"md"}
        >
          <motion.div
            initial={{ width: "0px" }}
            style={{
              height: "8px",
              background: "#D76161",
              borderTopRightRadius: "5px",
              borderBottomRightRadius: "5px",
            }}
            animate={{ width: (values?.fausse || 0) * 100 + "%" }}
            // transition={{  default: { duration: 0.75 } }}
            transition={{ default: { duration: 4 } }}
          />
        </Tooltip>
      </HStack>
    </VStack>
  );
};
