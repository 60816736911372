import { ReactNode, useRef } from 'react'
import { Grid, Navigation } from 'swiper';
import { Swiper } from 'swiper/react';
import DashboardBloc from '../DashboardBloc/DashboardBloc';
import { Controller } from './SliderWrapper';
import "swiper/css/grid";

export default function SliderContainer({children, title, grid={}}: {grid?: any, title: string; children: ReactNode}) {
    const prevRef = useRef(null);
    const nextRef = useRef(null);
  return (
    <DashboardBloc
      extra={<Controller nextRef={nextRef} prevRef={prevRef} />}
      title={title}
    >
      <Swiper
        slidesPerView={1}
        pagination={{
          clickable: false,
          type: "custom",
        }}
        modules={[Navigation, Grid]}
        grid={grid}
        onBeforeInit={(swiper: any) => {
          swiper.params.navigation.prevEl = prevRef.current
            ? prevRef.current
            : undefined;
          swiper.params.navigation.nextEl = nextRef.current
            ? nextRef.current
            : undefined;
        }}
        breakpoints={{
          390: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          425: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          600: {
            slidesPerView: 2,
            spaceBetween: 10,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 16,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 16,
          },
          1200: {
            slidesPerView: 3,
            spaceBetween: 16,
          },
          1600: {
            slidesPerView: 3,
            spaceBetween: 16,
          },
        }}
        grabCursor={true}
        className="mySwiper"
      >
        {children}
      </Swiper>
    </DashboardBloc>
  )
}
