import { useCallback, useRef } from "react";
import {
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
} from "react-query";
import { useHistory, useParams } from "react-router-dom";
import {
  Tooltip,
  IconButton,
  Stack,
  useDisclosure,
  Button,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogCloseButton,
  AlertDialogBody,
  AlertDialogFooter,
  useToast,
  useBreakpointValue,
  Text,
} from "@chakra-ui/react";
import { useTimer } from "../../timer-provider/TimerProvider";
import { saveAttemptProgress } from "../../services/qcm/services";
import BookmarkIcon from "../../assets/icons/BookmarkIcon";
import CancelIcon from "../../assets/icons/CancelIcon";
import PauseBtn from "./PauseBtn";
import MyToast from "../Toast/Toast";
import {
  addBookmark,
  checkIfQuestionBookmarked,
  removeBookmarkedQuestions,
} from "../../services/bookmarks/services";
import useQueryParams from "../../hooks/useQueryParams";

interface QcmActionsI {
  counterIsRunning: boolean;
  answered: boolean;
  question?: {
    _id: string;
    order: number;
  };
  pauseTimeSpentOnQuestion: () => void;
  resumeTimeSpentOnQuestion: () => void;
  isCustomQcm: boolean;
  speciality?: string;
}
export default function QcmActions({
  counterIsRunning,
  answered,
  question,
  pauseTimeSpentOnQuestion,
  resumeTimeSpentOnQuestion,
  isCustomQcm,
  speciality,
}: QcmActionsI) {
  const queryClient = useQueryClient();
  const toast = useToast();

  const { data: isItBookmarked, isLoading: checkIsLoading } = useQuery(
    `bookmarked-${question?._id}`,
    () => checkIfQuestionBookmarked(question?._id),
    {
      refetchOnWindowFocus: false,
      retry: false,
    }
  );
  const { mutateAsync: bookmarkThisQuestion, isLoading: bookmarkLoading } =
    useMutation(addBookmark, {
      onSuccess: () => {
        queryClient.invalidateQueries(`bookmarked-${question?._id}`);
        MyToast({
          toast,
          status: "success",
          title: "Question sauvegardée.",
          description: `Question n°${question?.order} ajoutée au Playlist`,
        });
      },
      onError: () =>
        MyToast({
          toast,
          status: "error",
          title: "Quelque chose s'est mal passé.",
          description: `Question n°${question?.order} ajoutée au Playlist`,
        }),
    });

  const { mutateAsync: removeQuestion, isLoading: removeIsLoading } =
    useMutation(removeBookmarkedQuestions, {
      onSuccess: () =>
        queryClient.invalidateQueries(`bookmarked-${question?._id}`),
    });

  return (
    <Stack direction={{ base: "row" }}>
      {answered ? (
        <Tooltip label="Sauvegarder" hasArrow>
          <BookMarkButton
            isItBookmarked={isItBookmarked}
            checkIsLoading={checkIsLoading}
            bookmarkLoading={bookmarkLoading || removeIsLoading}
            bookmarkThisQuestion={bookmarkThisQuestion}
            questionId={question?._id}
            defaultMarked={false}
            removeQuestion={removeQuestion}
          />
        </Tooltip>
      ) : (
        <PauseBtn
          counterIsRunning={counterIsRunning}
          pauseTimeSpentOnQuestion={pauseTimeSpentOnQuestion}
          resumeTimeSpentOnQuestion={resumeTimeSpentOnQuestion}
        />
      )}
      <CancelBtn
        pauseTimeSpentOnQuestion={pauseTimeSpentOnQuestion}
        resumeTimeSpentOnQuestion={resumeTimeSpentOnQuestion}
        isCustomQcm={isCustomQcm}
        speciality={speciality}
      />
    </Stack>
  );
}

export const CancelBtn = ({
  qcmType = "serie",
  university,
  pauseTimeSpentOnQuestion,
  resumeTimeSpentOnQuestion,
  isCustomQcm,
  speciality,
}: {
  qcmType?: "exam" | "serie";
  university?: string;
  pauseTimeSpentOnQuestion: () => void;
  resumeTimeSpentOnQuestion: () => void;
  isCustomQcm?: boolean;
  speciality?: string;
}) => {
  const { stopTimer, startTimer, time } = useTimer();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef<any>();
  const history = useHistory();
  const { attemptId, type, qcmId, year } = useParams<{
    attemptId: string;
    type: string;
    qcmId: string;
    year: string;
  }>();
  const toast = useToast();

  const openAndStopTimer = useCallback(() => {
    stopTimer();
    onOpen();
    pauseTimeSpentOnQuestion();
  }, []);

  const closeAndStartTimer = useCallback(() => {
    startTimer();
    onClose();
    resumeTimeSpentOnQuestion();
  }, []);

  const { mutateAsync: cancelAndSaveDuration, isLoading: cancelIsLoading } =
    useMutation(saveAttemptProgress, {
      onError: (err: any) => {
        MyToast({
          toast,
          description: err.response.data.message,
          status: "error",
          title: "Erreur",
        });
      },
      onSuccess: () => {
        !isCustomQcm && MyToast({
          toast,
          status: "success",
          title: "Votre progression a été sauvegardée.",
        });
      },
    });

  const queryClient = useQueryClient();
  const queryParams = useQueryParams();

  const cancelSaveAndCloseModal = async () => {
    if (!queryParams.get("view")) {
      await cancelAndSaveDuration({ attemptId, duration: time });
    }
    onClose();
    // @ts-ignore
    const specialityName = queryClient.getQueryData(`questions-${attemptId}`)
      ?.speciality?.name;
    // @ts-ignore
    const subject = queryClient.getQueryData(`questions-${attemptId}`)?.subject
      ?.name;
    history.push(
      qcmType === "exam"
        ? `/examen-blanc/${year}/${university}/${qcmId}`
        : isCustomQcm
        ? `/playlist/${speciality}`
        : `/series/${specialityName}/${subject}/${qcmId}`
    );
  };

  return (
    <>
      <Tooltip label="Quitter" hasArrow>
        <IconButton
          icon={<CancelIcon />}
          aria-label="quitter"
          bg="#FDF7F7"
          _hover={{
            bg: "lightRed",
            "& svg": { filter: "brightness(3)" },
          }}
          onClick={openAndStopTimer}
        />
      </Tooltip>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={closeAndStartTimer}
        isOpen={isOpen}
        isCentered
        closeOnOverlayClick={false}
        closeOnEsc={false}
        size={useBreakpointValue({ base: "xs", md: "md" })}
      >
        <AlertDialogOverlay />

        <AlertDialogContent rounded={"1rem"}>
          <AlertDialogHeader>
            Quitter {qcmType === "serie" ? "la série" : "l'examen"} ?
          </AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            {isCustomQcm ? "Vous ne pourrez pas reprendre cette série si vous quittez." : "Votre progression a été sauvegardée."}
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              ref={cancelRef}
              bg="lightGrey"
              onClick={closeAndStartTimer}
              _hover={{ bg: "light-blue-light", color: "blue-light" }}
              _active={{}}
            >
              Annuler
            </Button>
            <Button
              bg="lightRed"
              color="white"
              ml={3}
              _hover={{ bg: "darkRed" }}
              _active={{}}
              onClick={cancelSaveAndCloseModal}
              isLoading={cancelIsLoading}
            >
              Quitter
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export const BookMarkButton = ({
  defaultMarked,
  isItBookmarked,
  checkIsLoading,
  bookmarkLoading,
  bookmarkThisQuestion,
  questionId,
  removeQuestion,
}: any) => {
  return (
    <Tooltip label="Sauvegarder" hasArrow>
      <IconButton
        icon={<BookmarkIcon bookmarked={defaultMarked || isItBookmarked} />}
        _hover={{ "& svg": { filter: "brightness(5)" }, bg: "blue-light" }}
        aria-label="sauvegarder question"
        onClick={() =>
          isItBookmarked || defaultMarked
            ? removeQuestion(questionId)
            : bookmarkThisQuestion(questionId)
        }
        isLoading={bookmarkLoading || checkIsLoading}
        color="blue-light"
        bg={isItBookmarked || defaultMarked ? "blue-light" : "light-blue-light"}
        _loading={{ bg: "light-blue-light", color: "blue-light", opacity: 1 }}
      />
    </Tooltip>
  );
};
