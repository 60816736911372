import { Box, Button, Flex, Text, useBreakpointValue } from "@chakra-ui/react";
import CircleYellowIcon from "../../../../assets/icons/CircleYellowIcon";
import Lines from "../../../../assets/icons/Lines";
import { useAuthProvider } from "../../../../auth-provider/AuthProvider";

export default function OnboardingLastStep({
  isLoading,
}: {
  isLoading: boolean;
}) {
  const { data: auth } = useAuthProvider();
  const sizes = useBreakpointValue({base: "2rem", "2xl": "2.6rem"})

  return (
    <Box>
      <Flex alignContent={"flex-start"}>
        <Text color={"blue-light"} fontWeight={"light"} fontSize={sizes}>
          <Text display={"inline"} as="span" position={"relative"}>
            Enchanté{" "}
            <Box position={"absolute"} top="-35" right={"-16px"}>
              <Lines color={"yellow"} />
            </Box>
          </Text>
          <Text fontWeight={"900"} as="span" fontSize={sizes} wordBreak={"break-word"} display={"inline"}>
            Dr.{auth?.fullname}
          </Text>
        </Text>
      </Flex>
      <Button alignSelf={"stretch"} my="4" variant={"primary"} type="submit" w="full">
        {isLoading ? "Chargement..." : "Continuer vers la plate-forme"}
      </Button>
      <Box float={"right"}>
        <CircleYellowIcon />
      </Box>
    </Box>
  );
}
