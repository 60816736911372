export default function AnswerPartialIcon() {
  return (
    <svg
      id="percentage-circle"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <rect
        id="Rectangle_92"
        data-name="Rectangle 92"
        width="24"
        height="24"
        rx="5"
        fill="#fcb731"
        opacity="0"
      />
      <g id="Group_158" data-name="Group 158" transform="translate(1.2 1.2)">
        <path
          id="Path_111"
          data-name="Path 111"
          d="M7.713,14.418a.668.668,0,0,1-.477-.2.679.679,0,0,1,0-.954l5.895-5.9a.675.675,0,0,1,.954.954L8.19,14.22a.668.668,0,0,1-.477.2Z"
          fill="#fcb731"
        />
        <path
          id="Path_114"
          data-name="Path 114"
          d="M10.8,20.475A9.675,9.675,0,1,1,20.475,10.8,9.683,9.683,0,0,1,10.8,20.475Zm0-18A8.325,8.325,0,1,0,19.125,10.8,8.335,8.335,0,0,0,10.8,2.475Z"
          fill="#fcb731"
        />
      </g>
    </svg>
  );
}
