export default function FolderOpenIcon() {
  return (
    <svg
      id="folder-open"
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
    >
      <rect
        id="Rectangle_40"
        data-name="Rectangle 40"
        width="40"
        height="40"
        rx="5"
        fill="#292d32"
        opacity="0"
      />
      <g id="Group_47" data-name="Group 47" transform="translate(4.345 3.92)">
        <path
          id="Path_72"
          data-name="Path 72"
          d="M26.5,27.975H7.627c-5.1,0-5.37-2.805-5.6-5.07l-.6-7.515a5.688,5.688,0,0,1,1.215-4.08,5.61,5.61,0,0,1,4.38-2.085H27.1a5.6,5.6,0,0,1,4.305,2.01l.255.345a5.531,5.531,0,0,1,1.035,3.825l-.6,7.485c-.225,2.28-.495,5.085-5.595,5.085Zm-19.47-16.5a3.339,3.339,0,0,0-2.6,1.23l-.1.1A3.37,3.37,0,0,0,3.682,15.2l.6,7.515c.21,2.19.3,3.015,3.345,3.015H26.5c3.06,0,3.135-.825,3.345-3.03l.6-7.515a3.27,3.27,0,0,0-.75-2.46l-.15-.18a3.341,3.341,0,0,0-2.46-1.065H7.027Z"
          transform="translate(-1.407 4.23)"
          fill="#292d32"
        />
        <path
          id="Path_73"
          data-name="Path 73"
          d="M29.1,17.562a1.133,1.133,0,0,1-1.125-1.125V13.752c0-4.47-.78-5.25-5.25-5.25H18.9a3.076,3.076,0,0,1-2.925-1.455L14.04,4.482A1.985,1.985,0,0,0,11.88,3.4H9.975c-4.47,0-5.25.78-5.25,5.25v7.725a1.125,1.125,0,1,1-2.25,0V8.652c0-5.745,1.755-7.5,7.5-7.5H11.9a4.084,4.084,0,0,1,3.96,1.98l1.92,2.55c.405.54.435.57,1.14.57H22.74c5.745,0,7.5,1.755,7.5,7.5v2.685A1.155,1.155,0,0,1,29.1,17.562Z"
          transform="translate(-0.695 -1.152)"
          fill="#292d32"
        />
        <path
          id="Path_74"
          data-name="Path 74"
          d="M16.647,16.875H8.937a1.125,1.125,0,1,1,0-2.25h7.71a1.125,1.125,0,0,1,0,2.25Z"
          transform="translate(2.863 7.83)"
          fill="#f7d372"
        />
      </g>
    </svg>
  );
}
