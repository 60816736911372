import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  IconButton,
  Spinner,
  Square,
  Stack,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Tooltip,
  useTab,
  VStack,
} from "@chakra-ui/react";
import { forwardRef, useCallback, useRef, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import SidebarLayout from "../../../layouts/SidebarLayout";
import {
  getBookedQuestions,
  removeBookmarkedQuestions,
} from "../../../services/bookmarks/services";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
// @ts-ignore
import Output from "editorjs-react-renderer";
import alphabet from "../../../utils/alphabets.json";
import { CloseIcon, InfoOutlineIcon } from "@chakra-ui/icons";
import NotFound from "../../Authorized/Notfound/Notfound";
import { BookMarkButton } from "../../../components/QcmBody/QcmActions";
import EmpltyListBanner from "../../../components/EmptyListBanner/EmpltyListBanner";
import CancelIcon from "../../../assets/icons/CancelIcon";

export default function PlaylistQuestions() {
  const { subjectName, specialityName } = useParams<{
    specialityName: string;
    subjectName: string;
  }>();
  const history = useHistory();
  const {
    data: questions,
    isLoading: loadingQuestions,
    isError,
  } = useQuery(
    `my-list-questions-${subjectName}`,
    () => getBookedQuestions(subjectName),
    {
      refetchOnWindowFocus: false,
      retry: false
    }
  );
  const headerRef = useRef<any>();
  const containerRef = useRef<any>();
  const queryClient = useQueryClient();

  const { mutateAsync: removeQuestion, isLoading: removeIsLoading } =
    useMutation(removeBookmarkedQuestions, {
      onSuccess: () =>
        queryClient.invalidateQueries(`my-list-questions-${subjectName}`),
    });

  const scrollRight = (scrollOffset: number) => {
    if (headerRef?.current) {
      headerRef.current.scrollLeft += scrollOffset;
    }
  };

  const [tabIndex, setTabIndex] = useState(0);
  const handleChangeIndex = useCallback((index: number) => {
    setTabIndex(index);
  }, []);


  return (
    <SidebarLayout withoutPadding>
      {loadingQuestions ? (
        <Stack align={"center"} justify="center" minH={"100vh"}>
          <Spinner color="blue-light" size={"lg"} />
        </Stack>
      ) : isError ? (
        <NotFound />
      ) : questions?.length === 0 ? (
        <EmpltyListBanner />
      ) : (
        <Tabs
          isFitted
          variant={"unstyled"}
          onChange={handleChangeIndex}
          index={tabIndex}
          isLazy
          ref={containerRef}
        >
          <TabList p="4" bg="lightGrey" alignItems={"center"}>
            <IconButton
              icon={<FiChevronLeft />}
              aria-label="question suivante"
              onClick={() => scrollRight(-200)}
              size="sm"
              cursor={"pointer"}
              variant="outline"
              mr="3"
            />
            <Flex
              width={"100%"}
              overflow="scroll"
              scrollBehavior="smooth"
              className="tab-item-qcm"
              ref={headerRef}
              flex={1}
              py="2"
              px="1"
            >
              {questions?.map((el: any, index: number) => (
                <CustomTab key={index}>{index + 1}</CustomTab>
              ))}
            </Flex>

            <IconButton
              icon={<FiChevronRight />}
              aria-label="question précedente"
              onClick={() => scrollRight(200)}
              size="sm"
              cursor={"pointer"}
              variant="outline"
              ml="4"
            />
          </TabList>
          <TabPanels
            p={{ base: "0", "2xl": "8" }}
            pt={{ base: "0", "2xl": "2" }}
          >
            {questions?.map((el: any) => (
              <TabPanel key={el._id} tabIndex={el._id}>
                <HStack justify={"space-between"} mb="6">
                  <VStack
                    align={"flex-start"}
                    spacing={1}
                    borderBottom="2px solid"
                    borderColor={"gold-yellow"}
                    pb="2"
                    flexGrow={0.4}
                  >
                    <Stack>
                      {el?.question?.qcm?.type?.name === "Examen" && <Text color="gray" fontSize={"sm"}>
                      Épreuve /{" "}
                        Jour {el?.question?.qcm?.jour}
                        {/* {el?.question?.qcm?.subject?.name || el?.question?.subject?.name} */}
                      </Text>}
                      <Text color="blue-light" fontSize={"sm"}>
                        {/* {el?.question?.qcm?.speciality?.name || el?.question?.speciality?.name} /{" "} */}
                        {el?.question?.qcm?.subject?.name || el?.question?.subject?.name}
                      </Text>
                    </Stack>
                    <Heading size={"md"}>
                      {el.question.qcm.university.name +
                        " " +
                        el.question.qcm.year}
                    </Heading>
                  </VStack>
                  <HStack>
                    <BookMarkButton
                      defaultMarked={true}
                      //  checkIsLoading={checkIsLoading}
                      bookmarkLoading={removeIsLoading}
                      //  bookmarkThisQuestion={bookmarkThisQuestion}
                      removeQuestion={removeQuestion}
                      questionId={el.question?._id}
                    />
                    <Tooltip label="Retourner" hasArrow>
                      <IconButton
                        icon={<CancelIcon />}
                        aria-label="quitter"
                        bg="#FDF7F7"
                        _hover={{
                          bg: "lightRed",
                          "& svg": { filter: "brightness(3)" },
                        }}
                        onClick={() =>
                          history.push(`/playlist/${specialityName}`)
                        }
                      />
                    </Tooltip>
                  </HStack>
                </HStack>
                <QuestionBody
                  question={el.question}
                  handleChangeIndex={handleChangeIndex}
                  tabIndex={tabIndex}
                  questionsCount={questions.length - 1}
                />
                {/* {questions.children.length > 0 &&
                  questions?.children.map((child: any) => (
                    <QcmQuestion
                      key={child._id}
                      question={child}
                      answered={answered?.questions.find(
                        (el: any) => el.questionId === child._id
                      )}
                      selectedOptions={selectedOptions.find(
                        (el: any) => el.questionId === child._id
                      )}
                      correction={
                        correction?.find((el: any) => el.questionId === child._id)
                          ?.correction
                      }
                      select={select}
                    />
                  ))} */}
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>
      )}
    </SidebarLayout>
  );
}

export const CustomTab = forwardRef((props: any, ref: any) => {
  const tabProps = useTab({ ...props, ref });
  const isSelected = !!tabProps["aria-selected"];

  return (
    <VStack align={"center"} zIndex={isSelected ? 2 : 0}>
      <Button
        {...tabProps}
        bg={isSelected ? "blue-light" : "midGrey"}
        color={isSelected ? "white" : "black"}
        borderRadius="md"
        width={{ base: "50px", xl: "50px", "2xl": "60px" }}
        transform={isSelected ? "scale(1.3)" : "scale(1)"}
        height={{ base: "28px", "2xl": "30px" }}
        mx="2"
        transition="all 0.2s"
        _active={{}}
        _focus={{}}
        _hover={{
          bg: isSelected ? "blue-light" : "#CBCCCD",
        }}
      >
        {tabProps.children}
      </Button>
    </VStack>
  );
});

const QuestionBody = ({
  question,
  handleChangeIndex,
  tabIndex,
  questionsCount,
}: any) => {

  return (
    <Stack spacing={8}>
      <QcmQuestion question={question} />
      {question?.children?.length > 0 &&
        question?.children?.map((child: any, index: number) => (
          <QcmQuestion key={index} question={child} />
        ))}
      <HStack align={"center"} justify={{base: tabIndex === 0 ? "flex-end" : "space-between", md: "flex-end"}}>
        {tabIndex !== 0 && (
          <Button
            variant={"secondary"}
            px="10"
            onClick={() => handleChangeIndex(tabIndex - 1)}
            loadingText={"Vérification en cours"}
            minW="150px"
          >
            Précedent
          </Button>
        )}
        {tabIndex < questionsCount && (
          <Button
            minW="150px"
            variant={"primary"}
            px="10"
            onClick={() => handleChangeIndex(tabIndex + 1)}
            loadingText={"Vérification en cours"}
          >
            Suivant
          </Button>
        )}
      </HStack>
    </Stack>
  );
};

const QcmQuestion = ({ question }: any) => {
  return (
    <>
      <Box rounded={"lg"} bg="lightGrey" p="4" fontSize={"14px"}>
        <Output data={question.value} />
      </Box>
      <Accordion allowMultiple rounded="md">
        <VStack align={"stretch"} spacing={2}>
          {question?.options?.map((option: any, index: number) => (
            <AccordionItem
              key={option._id}
              bg={option.isCorrect ? "#F2FAF4" : "lightGrey"}
              rounded={"lg"}
              overflow="hidden"
              border={
                option.isCorrect ? `1px solid #7FCF91` : "1px solid transparent"
              }
              transition="all 0.5s"
            >
              <OptionItem option={option} index={index} />
              {option.justification && (
                <AccordionPanel pb={4}>
                  <Box
                    p="4"
                    rounded={"lg"}
                    bg="white"
                    border={`1px solid ${
                      option.isCorrect ? "#7FCF91" : "gray"
                    }`}
                  >
                    {option.justification}
                  </Box>
                </AccordionPanel>
              )}
            </AccordionItem>
          ))}
        </VStack>
      </Accordion>
    </>
  );
};

const OptionItem = ({ option, index }: any) => {
  return (
    <AccordionButton
      _active={{}}
      _focus={{}}
      _expanded={{ bg: "#7FCF9" }}
      as="span"
      cursor={"pointer"}
      p="0"
      pr="4"
      _hover={{}}
    >
      <HStack justify={"space-between"} w="full">
        <HStack spacing={2} align="stretch">
          <Flex
            p="2"
            align="center"
            justify="center"
            width={"55px"}
            className="alpha-box"
            bg={"darkGrey"}
            height={"auto"}
            borderBottomRightRadius="8px"
          >
            <Square
              size={"40px"}
              className="nested-alpha-box"
              bg={"lightGrey"}
              transition={"background 0. 12s"}
              rounded={"md"}
              color={option.isCorrect ? "#7FCF91" : "black"}
              fontWeight={option.isCorrect ? "bold" : "normal"}
            >
              {alphabet[index]}
            </Square>
          </Flex>
          <Text p="3" fontSize={"14px"} alignSelf="center">
            {option.value}
          </Text>
        </HStack>
        {option.justification && (
          <IconButton
            icon={
              <InfoOutlineIcon
                color={option.isCorrect ? "green.600" : "gray.600"}
              />
            }
            aria-label="justification"
            bg={"whiteAlpha.700"}
            _hover={{
              bg: "white",
            }}
            size="md"
            _active={{}}
          />
        )}
      </HStack>
    </AccordionButton>
  );
};
