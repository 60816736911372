import { Redirect, useLocation } from "react-router-dom";
import usePlan from "../../auth-provider/PlanProvider";

const RedirectComponent = () => {
  // const planId = localStorage.getItem("plan");
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const plan = searchParams.get("plan");;

  if (plan) {
    return <Redirect to={`/plan/${plan}`} />;
  }

  return null;
};

export default RedirectComponent;
