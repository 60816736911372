
export default function ChronoPausedIcon({bg="#fff"}:{bg?:string}) {
  return (
    <svg
      id="timer-pause"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <rect
        id="Rectangle_889"
        data-name="Rectangle 889"
        width="24"
        height="24"
        rx="5"
        fill={bg}
        opacity="0"
      />
      <g id="Group_1132" data-name="Group 1132" transform="translate(1.2 1.2)">
        <path
          id="Path_121"
          data-name="Path 121"
          d="M10.8,20.475a8.55,8.55,0,1,1,8.55-8.55.675.675,0,1,1-1.35,0,7.2,7.2,0,1,0-7.2,7.2.675.675,0,1,1,0,1.35Z"
          fill={bg}
        />
        <path
          id="Path_122"
          data-name="Path 122"
          d="M10.8,12.375a.68.68,0,0,1-.675-.675V7.2a.675.675,0,1,1,1.35,0v4.5A.68.68,0,0,1,10.8,12.375Z"
          fill={bg}
        />
        <path
          id="Path_123"
          data-name="Path 123"
          d="M13.5,2.475H8.1a.675.675,0,0,1,0-1.35h5.4a.675.675,0,0,1,0,1.35Z"
          fill={bg}
        />
        <path
          id="Path_124"
          data-name="Path 124"
          d="M17.1,19.575a.68.68,0,0,1-.675-.675V15.3a.675.675,0,0,1,1.35,0v3.6A.68.68,0,0,1,17.1,19.575Z"
          fill={bg}
        />
        <path
          id="Path_125"
          data-name="Path 125"
          d="M14.4,19.575a.68.68,0,0,1-.675-.675V15.3a.675.675,0,0,1,1.35,0v3.6A.68.68,0,0,1,14.4,19.575Z"
          fill={bg}
        />
      </g>
    </svg>
  );
}
