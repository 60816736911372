import {
  Box,
  Button,
  ButtonGroup,
  Grid,
  GridItem,
  HStack,
  Icon,
  LinkBox,
  Skeleton,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { useState } from "react";
import { MdChevronRight } from "react-icons/md";
import { useMutation, useQuery } from "react-query";
import { useHistory, useLocation, useParams } from "react-router-dom";
import FolderOpenIcon from "../../../assets/icons/FolderOpenIcon";
import MyListIcon from "../../../assets/icons/MyListIcon";
import SeriesIcon from "../../../assets/icons/SeriesIcon";
import BreadCrumb from "../../../components/BreadCrumb/BreadCrumb";
import EmpltyListBanner from "../../../components/EmptyListBanner/EmpltyListBanner";
import SidebarLayout from "../../../layouts/SidebarLayout";
import { getSubjectsBooked } from "../../../services/bookmarks/services";
import { createCustomQcm } from "../../../services/custom-qcm/services";
import capitalizeText from "../../../utils/capitalizeText";
import NotFound from "../../Authorized/Notfound/Notfound";
import PlaylistModeSelectorDialog from "./PlaylistModeSelector";

interface ISubjects {
  name: string;
  questionsCount?: number;
  _id: string;
}

export default function PlaylistSubjects() {
  const [playlistMode, setPlaylistMode] = useState<string>("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const bgBox = useColorModeValue("lightGrey", "card-dark");
  const history = useHistory();
  const { pathname } = useLocation();
  const { specialityName } = useParams<{ specialityName: string }>();
  const {
    data: subjects,
    isLoading: loadingSubjects,
    isError,
  } = useQuery(
    `my-list-subjects-${specialityName}`,
    () => getSubjectsBooked(specialityName),
    {
      refetchOnWindowFocus: false,
      retry: false,
    }
  );

  const breadcrumb = [
    {
      title: "Playlist",
      link: "/playlist",
      isCurrent: false,
    },
    {
      title: capitalizeText(specialityName),
      link: `${pathname}/${specialityName}`,
      isCurrent: true,
    },
  ];

  const { mutate: createQcm, isLoading: isCreatingQcm } = useMutation(
    createCustomQcm,
    {
      onSuccess: (response: any) => {
        history.push(`/qcm/serie/${response.qcmId}/${response.attemptId}`);
      },
    }
  );

  const handleCreateQcm = (subjectId: string) => {
    console.log(subjectId)
  }

  const [selectedSubject, setSelectedSubject] = useState<ISubjects>({_id: '', name: ''});
  const handleOpen = (subject: ISubjects) => {
    setSelectedSubject(subject)
    onOpen()
  }

  const start = () => {
    if(selectedSubject._id && selectedSubject.name){
      if (playlistMode === "serie") {
        createQcm(selectedSubject?._id);
      } else if (playlistMode === "playlist") {
        history.push(`${pathname}/${selectedSubject?.name}`);
      }
    }
  };

  return (
    <SidebarLayout>
      <BreadCrumb items={breadcrumb} />
      <Grid
        gridTemplateColumns={"repeat(auto-fill, minmax(300px, 1fr))"}
        justifyItems="center"
        gap={{ base: "20px", lg: "20px", "2xl": "30px" }}
        my="4"
        mt="6"
      >
        {loadingSubjects ? (
          Array.apply(null, Array(12)).map((el: any, index: number) => (
            <Skeleton
              rounded={"0.8rem"}
              w="full"
              h="36"
              key={index}
              startColor="darkGrey"
              endColor="lightGrey"
            />
          ))
        ) : isError ? (
          <GridItem gridColumn={"1 / span 4"}>
            <NotFound />
          </GridItem>
        ) : subjects.length === 0 ? (
          <GridItem gridColumn={"1 / span 4"}>
            <EmpltyListBanner />
          </GridItem>
        ) : (
          subjects.map((subject: ISubjects) => (
            <GridItem width={"full"} key={subject._id}>
              <Box
                as="article"
                w="full"
                bg={bgBox}
                h="32"
                display={"flex"}
                alignItems="center"
                borderWidth="1px"
                borderColor={"transparent"}
                rounded=".8rem"
                transition={"all 0.15s ease-in-out"}
                _hover={{
                  borderColor: "darkSky",
                  boxShadow: "0px 0px 64px -35px rgba(127,202,207, 0.8)",
                  cursor: "pointer",
                  bg: "darkGrey",
                }}
                overflow="hidden"
                p="4"
                onClick={() => handleOpen(subject)}
              >
                <HStack spacing={4} alignItems="center">
                  <Box w="40px">
                    <Icon as={FolderOpenIcon} w="full" h="full" />
                  </Box>
                  <Stack spacing={2}>
                    <Text whiteSpace="nowrap" fontSize={"lg"}>
                      {subject.name}
                    </Text>
                    <Text pb="2" color="gray.500">
                      {subject.questionsCount} questions
                    </Text>
                  </Stack>
                </HStack>
              </Box>
            </GridItem>
          ))
        )}
      </Grid>
      <PlaylistModeSelectorDialog
        isOpen={isOpen}
        onClose={onClose}
        createQcm={handleCreateQcm}
        isCreatingQcm={isCreatingQcm}
        playlistMode={playlistMode}
        setPlaylistMode={setPlaylistMode}
        start={start}
      />
    </SidebarLayout>
  );
}
