import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  CircularProgress,
  Grid,
  GridItem,
  Heading,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  Skeleton,
  Spinner,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useMutation, useQuery } from "react-query";
import { useAuthProvider } from "../../../auth-provider/AuthProvider";
import PageHeading from "../../../components/PageHeading/PageHeading";
import SidebarLayout from "../../../layouts/SidebarLayout";
import { sendVerifyEmail } from "../../../services/auth/services";
import { RiSearch2Line, RiSendPlaneLine } from "react-icons/ri";
import SmallCard from "../../../components/SmallCard/SmallCard";
import PercentageIcon from "../../../assets/icons/PercentageIcon";
import ChronoIcon from "../../../assets/icons/ChronoIcon";
import { getOverview, search } from "../../../services/dashboard/dashboard";
import SliderWrapper, {
  SpecialityProgressItem,
} from "../../../components/Slider/SliderWrapper";
import SeriesUpdatesSection from "../../../components/DashboardBloc/SeriesUpdatesSection";
import WorseAttemptsSection from "../../../components/DashboardBloc/WorseAttemptsSection";
import { debounce } from "../../../utils/debounce";
import { Fragment, useEffect, useState } from "react";
import { Link, Route, useHistory, useLocation } from "react-router-dom";
import useQueryParams from "../../../hooks/useQueryParams";
import { getResultsSeriesByDay } from "../../../services/performance/services";
import NotFound from "../../Authorized/Notfound/Notfound";
import compareDates from "../../../utils/compareDate";
import usePlan from "../../../auth-provider/PlanProvider";

export default function Home() {
  const history = useHistory();
  const queryParams = useQueryParams();
  const { data: auth } = useAuthProvider();
  const { updatePlan } = usePlan();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [keyword, setKeyword] = useState("");
  const [questionOptions, setQuestionOptions] = useState([]);
  const [subjectOptions, setSubjectOptions] = useState([]);
  const [inputFocused, setInputFocused] = useState(false);
  const { mutate, isLoading, data, isError } = useMutation(search, {
    onSuccess: (res: any) => {
      // if (res?.length === 1) {
      // history.push(`/series/${res?.[0]?.speciality?.name}/${res?.[0]?.name}`);
      // }
      setQuestionOptions(res.questions);
      setSubjectOptions(res.subjects);
    },
    onError: (err: any) => {
      if (err.response.status === 404) {
        setSubjectOptions([]);
        setQuestionOptions([]);
      }
    },
  });

  useEffect(() => {
    if (queryParams.get("query")) {
      mutate(queryParams.get("query") ?? "");
    }
  }, [queryParams.get("query")]);

  // useEffect(() => {
  //   if(auth?.current_order){
  //     localStorage.removeItem("plan")
  //   }
  // }, [auth])
  useEffect(() => {
    const newPlanId = searchParams.get("plan");
    if (newPlanId) {
      updatePlan(String(newPlanId));
    }
    if (!newPlanId) {
      updatePlan("");
    }
  }, [auth]);

  const handleSearch = (event: any) => {
    // history.push(`/tableau-de-bord/search?query=${event.target.value}`);
    setInputFocused(true);
    setKeyword(event.target.value);
    mutate(event.target.value);
  };

  return (
    <SidebarLayout>
      <HStack justify={"space-between"}>
        <PageHeading
          title={queryParams.get("query") ? "Recherche" : "Bonjour"}
        />
        <Box
          // maxW={"300px"}
          width={inputFocused ? "50%" : "300px"}
          transition="all 0.25s ease-in-out"
          display={{ base: "none", md: "inherit" }}
          position="relative"
        >
          <InputGroup>
            <InputLeftElement
              pointerEvents="none"
              children={
                isLoading ? (
                  <Spinner color="blue-light" size={"sm"} />
                ) : (
                  <RiSearch2Line style={{ color: "#4B62F0" }} size="1.3rem" />
                )
              }
            />
            <Input
              type="search"
              placeholder="Numéro / Nom du sujet"
              rounded={"full"}
              bg="light-blue-light"
              border="none"
              _placeholder={{ fontWeight: "light" }}
              onChange={debounce(handleSearch, 600)}
              defaultValue={queryParams.get("query") ?? ""}
              onClick={() => setInputFocused(true)}
            />
          </InputGroup>
          {(questionOptions.length !== 0 || subjectOptions.length !== 0) &&
            inputFocused && (
              <Box
                position={"absolute"}
                top="110%"
                left="0px"
                width={"100%"}
                minHeight="100%"
                bg="light-blue-light"
                borderColor="#3182ce"
                boxShadow={"0 0 0 1px #3182ce"}
                rounded="xl"
                p="2"
                zIndex={3}
                maxH="400px"
                overflowY={"auto"}
                onMouseEnter={() => setInputFocused(true)}
                onMouseLeave={() => setInputFocused(false)}
              >
                {questionOptions.length !== 0 && (
                  <Fragment>
                    <Text fontWeight={"bold"} color="gray.500" mb="0">
                      Questions:
                    </Text>
                    {questionOptions.map((option: any) => (
                      <VStack
                        key={option._id}
                        align={"flex-start"}
                        spacing="0"
                        _hover={{
                          bg: "blue-light",
                          color: "white !important",
                          "& .subtitle": {
                            color: "whiteAlpha.800",
                          },
                        }}
                        rounded="xl"
                        p="2"
                        cursor={"pointer"}
                        onClick={() =>
                          history.push(
                            `/question/${option?._id}`
                          )
                        }
                      >
                        <Text>
                          {option.value.blocks[0]?.data?.text
                            ?.replace(/<[^>]+>/g, "")
                            ?.slice(0, 30) || "" + "..."}
                        </Text>
                        <Text fontSize={"small"}>code: {option.code}</Text>
                        <Text
                          fontSize={"small"}
                          color="blue-light"
                          className="subtitle"
                        >
                          {option.qcm.speciality.name}/{option.qcm.subject.name}
                          /{option.qcm.university.name}/{option.qcm.year}
                        </Text>
                      </VStack>
                    ))}
                  </Fragment>
                )}
                {subjectOptions.length !== 0 && (
                  <Fragment>
                    <Text fontWeight={"bold"} color="gray.500" mb="0">
                      Sujets:
                    </Text>
                    {subjectOptions.map((option: any) => (
                      <VStack
                        key={option._id}
                        align={"flex-start"}
                        spacing="0"
                        _hover={{
                          bg: "blue-light",
                          color: "white !important",
                        }}
                        rounded="xl"
                        p="2"
                        cursor={"pointer"}
                        onClick={() =>
                          history.push(
                            `/series/${option?.speciality?.name}/${option?.name}`
                          )
                        }
                      >
                        <Text>{option.name}</Text>
                        <Text fontSize={"small"}>code: {option.code}</Text>
                      </VStack>
                    ))}
                  </Fragment>
                )}
              </Box>
            )}
          {questionOptions.length === 0 &&
            subjectOptions.length === 0 &&
            keyword !== "" &&
            inputFocused && (
              <Box
                position={"absolute"}
                top="110%"
                left="0px"
                width={"100%"}
                minHeight="100%"
                bg="light-blue-light"
                borderColor="#3182ce"
                boxShadow={"0 0 0 1px #3182ce"}
                rounded="xl"
                p="2"
                zIndex={3}
                maxH="400px"
                overflowY={"auto"}
              >
                <Text textAlign={"center"} color="gray.500" py="2">
                  Aucun résultat trouvé.
                </Text>
              </Box>
            )}
        </Box>
      </HStack>
      <Route exact path={"/tableau-de-bord"} component={HomeStatistics} />
      <Route
        exact
        path={"/tableau-de-bord/search"}
        render={(props: any) => (
          <SearchResult
            {...props}
            isLoading={isLoading}
            data={data}
            query={queryParams.get("query")}
            isError={isError}
          />
        )}
      />
    </SidebarLayout>
  );
}

const SearchResult = ({
  data,
  isLoading,
  query,
  isError,
}: {
  data: any;
  isLoading: boolean;
  query: string | undefined | null;
  isError: boolean;
}) => {
  return (
    <Stack>
      <Heading
        as="h2"
        color="blue-light"
        fontSize={{ base: "20px", md: "25px" }}
        mt="8"
      >
        {isError ? 0 : data?.length ?? ""} sujets trouvés:
      </Heading>
      <Grid
        gridTemplateColumns={
          isError ? "1fr" : "repeat(auto-fill, minmax(300px, 1fr))"
        }
        gap={{ base: "20px", lg: "20px", "2xl": "30px" }}
        my="4"
      >
        {isLoading ? (
          Array.apply(null, Array(8)).map((el: any, index: number) => (
            <GridItem key={index}>
              <Skeleton
                rounded={"0.7rem"}
                w="full"
                h="36"
                endColor={"white"}
                startColor={"whiteAlpha.600"}
              />
            </GridItem>
          ))
        ) : isError ? (
          <NotFound isSearchResult />
        ) : (
          data?.map((el: any) => (
            <GridItem key={el._id}>
              <Link to={`/series/${el.speciality.name}/${el.name}`}>
                <SpecialityProgressItem
                  value={el.name}
                  subValue={el?.speciality?.name}
                  bg="lightGrey"
                />
              </Link>
            </GridItem>
          ))
        )}
      </Grid>
    </Stack>
  );
};

const HomeStatistics = () => {
  const { data: auth } = useAuthProvider();

  const { mutateAsync, isLoading } = useMutation(sendVerifyEmail);
  const { data: overview, isLoading: loadingOverview } = useQuery(
    "overview",
    getOverview,
    {
      staleTime: 60000 * 15,
    }
  );
  const { data: moyenne, isLoading: loadingMoyenne } = useQuery(
    `moyenne-Série`,
    () => getResultsSeriesByDay("serie"),
    {
      staleTime: 60000 * 15,
    }
  );

  return (
    <>
      {auth?.provider === "email" && !auth?.email_verified && (
        <Alert status="info" borderRadius={"1rem"} my="6">
          <AlertIcon />
          <Box flex="1">
            <AlertTitle>Vérifier votre compte maintenant</AlertTitle>
            <AlertDescription display="block">
              Vérifier votre inscription grâce au mail qui vient de vous être
              envoyé à{" "}
              <em>
                <b>{auth?.email}</b>
              </em>
              <br />
              <br />
              Vérifier dans votre Spams si vous ne voyez pas l'email.
            </AlertDescription>
          </Box>
          <Button
            variant={"ghost"}
            onClick={() => mutateAsync()}
            isLoading={isLoading}
            leftIcon={<RiSendPlaneLine />}
            loadingText="Envoi en cours"
            position="absolute"
            right="8px"
            top="8px"
          >
            Re-envoyer
          </Button>
        </Alert>
      )}
      <Stack
        my="6"
        spacing={{ base: "20px", lg: "20px", "2xl": "30px" }}
        direction={{ base: "column", lg: "row" }}
      >
        <SmallCard
          icon={
            <CircularProgress
              isIndeterminate={loadingOverview}
              value={
                loadingOverview
                  ? 0
                  : overview.answeredQuestionsCount / overview.questionsCount
              }
              color="blue-light"
              capIsRound
              size="3rem"
            />
          }
          value={
            loadingOverview
              ? 0
              : (
                  (overview.answeredQuestionsCount / overview.questionsCount) *
                  100
                )?.toFixed(2)
          }
          secondValue={
            loadingOverview ? 0 : `(${overview.answeredQuestionsCount})`
          }
          title="Questions réalisées"
          isLoading={false}
          isGradient
          flexGrow={1}
          unit={"%"}
        />
        <SmallCard
          icon={<PercentageIcon bg="#7FCACF" size="3.6rem" />}
          value={
            loadingMoyenne
              ? 0
              : ((moyenne.moy1.moy + moyenne.moy2.moy) / 2)?.toFixed(2) || 0
          }
          title="Moyenne générale"
          isLoading={false}
          flexGrow={1}
          unit={"/20"}
        />
        <SmallCard
          icon={<ChronoIcon bg={"#7FCACF"} size="3.6rem" />}
          value={overview?.avgTimePerQuestion ?? 0}
          title="Temps par question"
          isLoading={false}
          flexGrow={1}
          unit={"secondes"}
          avgTime={true}
        />
      </Stack>
      <SliderWrapper />
      <SeriesUpdatesSection />
      <WorseAttemptsSection />
    </>
  );
};
