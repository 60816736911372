import axiosInstance from "../../utils/axiosInstance";

export const getOverview = async () => {
  let access_token = localStorage.getItem("access_token");
  return await axiosInstance({
    url: `/attempts/dashboard/overview`,
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  }).then((res: any) => res.data);
};


export const getWorseAttempts = async () => {
  let access_token = localStorage.getItem("access_token");
  return await axiosInstance({
    url: `/attempts/dashboard/worse-attempts`,
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  }).then((res: any) => res.data);
};

export const search = async (keyword: string) => {
  let access_token = localStorage.getItem("access_token");
  
  return await axiosInstance({
    url: `/subject/search-by-sbj-spc`,
    method: 'post',
    data: {
      keyword
    },
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  }).then((res: any) => res.data);
};