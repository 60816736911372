
export default function FolderOpenedGreenIcon() {
  return (
    <svg
      id="folder-open"
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
    >
      <rect
        id="Rectangle_40"
        data-name="Rectangle 40"
        width="32"
        height="32"
        rx="5"
        fill="#292d32"
        opacity="0"
      />
      <g id="Group_47" data-name="Group 47" transform="translate(3.476 3.136)">
        <path
          id="Path_72"
          data-name="Path 72"
          d="M21.479,24.225H6.383c-4.08,0-4.3-2.244-4.476-4.056l-.48-6.012A4.55,4.55,0,0,1,2.4,10.893,4.488,4.488,0,0,1,5.9,9.225H21.959A4.483,4.483,0,0,1,25.4,10.833l.2.276a4.425,4.425,0,0,1,.828,3.06l-.48,5.988c-.18,1.824-.4,4.068-4.476,4.068ZM5.9,11.025a2.671,2.671,0,0,0-2.076.984l-.084.084A2.7,2.7,0,0,0,3.227,14l.48,6.012c.168,1.752.24,2.412,2.676,2.412h15.1c2.448,0,2.508-.66,2.676-2.424l.48-6.012a2.616,2.616,0,0,0-.6-1.968l-.12-.144a2.673,2.673,0,0,0-1.968-.852H5.9Z"
          transform="translate(-1.407 1.539)"
          fill="#292d32"
        />
        <path
          id="Path_73"
          data-name="Path 73"
          d="M23.775,14.28a.907.907,0,0,1-.9-.9V11.232c0-3.576-.624-4.2-4.2-4.2h-3.06a2.461,2.461,0,0,1-2.34-1.164L11.727,3.816A1.588,1.588,0,0,0,10,2.952H8.475c-3.576,0-4.2.624-4.2,4.2v6.18a.9.9,0,1,1-1.8,0V7.152c0-4.6,1.4-6,6-6h1.536a3.267,3.267,0,0,1,3.168,1.584l1.536,2.04c.324.432.348.456.912.456h3.06c4.6,0,6,1.4,6,6V13.38A.924.924,0,0,1,23.775,14.28Z"
          transform="translate(-1.051 -1.152)"
          fill="#292d32"
        />
        <path
          id="Path_74"
          data-name="Path 74"
          d="M14.88,16.425H8.712a.9.9,0,1,1,0-1.8H14.88a.9.9,0,1,1,0,1.8Z"
          transform="translate(0.728 3.339)"
          fill="#7fcacf"
        />
      </g>
    </svg>
  );
}
