import { HStack, Text } from "@chakra-ui/react";
import moment from "moment";
import SeriesIcon from "../../assets/icons/SeriesIcon";
import NotificationWrapper from "./NotificationWrapper";

const SerieNotification = ({ notification, createdAt, seen }: any) => {
  return (
    <NotificationWrapper
      notificationTitle="Nouvelle série"
      notificationIcon={<SeriesIcon color="#4B62F0" />}
      notificationDate={moment(createdAt).fromNow()}
      notificationBody={
        <HStack spacing={2} align="flex-start">
          {/* <Text fontSize={"xs"} color="gray.500">
            <strong>Spécialité:</strong> {notification.speciality}
          </Text> */}
          <Text fontSize={"xs"} color="gray.500">
            <strong>Sujet:</strong> {notification.subject}
          </Text>
          <Text fontSize={"xs"} color="gray.500">
            <strong>Faculté:</strong> {notification.university}
          </Text>
          <Text fontSize={"xs"} color="gray.500">
            <strong>Année:</strong> {notification.year}
          </Text>
        </HStack>
      }
      notificationLink={`/series/${notification.speciality}/${notification.subject}/${notification.qcmId}`}
      seen={seen}
    />
  );
};

export default SerieNotification;
