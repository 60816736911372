import {
    Box,
  Button,
  Container,
  Flex,
  HStack,
  Heading,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { InfoIcon } from "@chakra-ui/icons";
import { useMutation, QueryCache } from "react-query";
import { useHistory } from "react-router-dom";
import { sendVerifyEmail } from "../../../services/auth/services";

export default function ConfirmEmailMessage() {
  const { mutateAsync, isLoading } = useMutation(sendVerifyEmail);
  const { push } = useHistory();
  const queryCache = new QueryCache();

  const logout = () => {
    localStorage.removeItem("access_token");
    queryCache.clear();
    push("/connexion");
  };

  return (
    <Container maxW={"2xl"}>
      <Flex
        textAlign="center"
        py={10}
        px={6}
        minH={"100vh"}
        flexDirection={"column"}
        alignItems={"center"}
        justifyContent={"center"}
        gap="6"
      >
        <InfoIcon boxSize={"50px"} color={"blue.500"} />
        <Heading as="h2" size="xl" mb={4}>
          Finalisez votre inscription
        </Heading>
        <VStack alignItems={"flex-start"}>
          <Text color={"black"} fontWeight={700}>
            Un e-mail vient de vous être envoyé
          </Text>
          <Text color={"black"} textAlign={"left"}>
            Pour finaliser votre inscription, rendez-vous dans votre boîte
            e-mail pour activer votre compte.
          </Text>
          <Text color={"gray.400"} fontSize={"sm"} textAlign={"left"}>
            Pas d'e-mail reçu dans votre boite de réception ou vos <b>spam</b> ?
          </Text>
          <Button
            variant="link"
            color="blue.300"
            textDecoration={"underline"}
            cursor={"pointer"}
            onClick={() => mutateAsync()}
            disabled={isLoading}
            loadingText="Envoie encours..."
          >
            Re-envoyer l'e-mail.
          </Button>
        </VStack>
        <Box
          alignSelf={"flex-start"}
          textAlign="left"
          mt="10"
        //   gap={1}
        //   flexDirection={{ base: "column", md: "row" }}
        //   alignItems={{ base: "flex-start", md: "baseline" }}
        >
          <Text color={"gray.400"} fontSize={"sm"} as="span">
            Si vous n'avez pas accès à votre boîte e-mail,
          </Text>
          <Button
            variant="link"
            as="span"
            color="blue.300"
            textDecoration={"underline"}
            cursor={"pointer"}
            onClick={logout}
            mx="1"
          >
            cliquez ici
          </Button>
          <Text color={"gray.400"} fontSize={"sm"} as="span">
            pour vous déconnecter.
          </Text>
        </Box>
      </Flex>
    </Container>
  );
}
