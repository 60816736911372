import axiosInstance from "../../utils/axiosInstance";

export const activateVoucher = async ({ voucher }: { voucher: string }) => {
  let access_token = localStorage.getItem("access_token");
  return await axiosInstance({
    method: "post",
    url: `/orders/activate-voucher`,
    data: {
      voucher,
    },
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  }).then((res: any) => res.data);
};
