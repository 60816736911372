import {
  Box,
  Accordion,
  VStack,
  AccordionItem,
  AccordionButton,
  HStack,
  Flex,
  Square,
  Text,
  Stack,
  AccordionPanel,
} from "@chakra-ui/react";
// @ts-ignore
import Output from "editorjs-react-renderer";
import { useCallback } from "react";
import useQueryParams from "../../hooks/useQueryParams";
import alphabet from "../../utils/alphabets.json";
import ReportModal from "../Report/ReportModal";

export default function ExamQCMQuestion({
  question,
  selectedOptions,
  select,
  correction,
  alreadyAnswered,
  isSerieMode
}: any) {
  const queryParams = useQueryParams()
  const generateBorderColor = useCallback(
    (id: string) => {
      const correctAndSelected = correction?.correctSelectedOptions?.find(
        (el: any) => el?._id === id
      );
      const correctAndNOTSelected = correction?.correctNotSelectedOptions?.find(
        (el: any) => el?._id === id
      );
      const wrongSelectedOptions = correction?.wrongSelectedOptions?.find(
        (el: any) => el?._id === id
      );

      if (correctAndSelected) {
        return {
          bg: "#F2FAF4",
          borderColor: "#7FCF91",
          textColor: "#7FCF91",
          textBackground: "#E8EFEA",
          justificationBorderColor: "#7FCACF",
        };
      } else if (correctAndNOTSelected) {
        return {
          bg: "#FFF8EA",
          borderColor: "#FCB731",
          textColor: "#FCB731",
          textBackground: "#F4EDE0",
          justificationBorderColor: "#F8C561",
        };
      } else if (wrongSelectedOptions) {
        return {
          bg: "#FBEFEF",
          borderColor: "#D76161",
          textColor: "#D76161",
          textBackground: "#F0E5E5",
          justificationBorderColor: "#D76161",
        };
      } else {
        return {
          bg: "#F4F4F4",
          borderColor: "#f4f4f4",
          textColor: "black",
          textBackground: "darkGrey",
          justificationBorderColor: "#7FCACF",
        };
      }
    },
    [correction]
  );

  // const generateBorderColor = useCallback(() => {
  //   return {
  //     bg: "#F4F4F4",
  //     borderColor: "#f4f4f4",
  //     textColor: "black",
  //     textBackground: "darkGrey",
  //     justificationBorderColor: "#7FCACF",
  //   };
  // }, []);

  const highlightSelectedOptions = useCallback(
    (optionId: string) => {
      if (selectedOptions) {
        if (selectedOptions.options.includes(optionId)) {
          return true;
        }
        return false;
      }
    },
    [selectedOptions]
  );

  return (
    <Stack spacing={{base: "20px", lg: "20px", "2xl": "30px"}}>
      <Box rounded={"lg"} bg="lightGrey" p="4" fontSize={"14px"} position="relative">
        <Output data={question.value} />
        <Box position={"absolute"} top="0px" right={"0px"}>
          <ReportModal question={question._id}/>
        </Box>
      </Box>
      <Accordion allowMultiple rounded="md">
        <VStack align={"stretch"} spacing={2}>
          {question.options.map((option: any, index: number) => (
            <AccordionItem
              key={option._id}
              bg={generateBorderColor(option._id).bg}
              rounded={"lg"}
              overflow="hidden"
              border={`1px solid ${
                (queryParams.get("view") === "true" || (isSerieMode && alreadyAnswered))
                  ? generateBorderColor(option._id).borderColor
                  : highlightSelectedOptions(option._id)
                  ? "#4B62F0"
                  : "#f4f4f4"
              }`}
              transition="all 0.5s"
            >
              <OptionItem
                option={option}
                questionId={question._id}
                index={index}
                select={select}
                isSelected={highlightSelectedOptions(option._id)}
                answered={alreadyAnswered}
                letterColor={generateBorderColor(option._id).textColor}
                letterBg={generateBorderColor(option._id).textBackground}
                correction={correction}
                isSerieMode={isSerieMode}
              />
              {correction &&
                // @ts-ignore
                Object.values(correction)
                  .flat()
                  .find((el: any) => el._id === option._id)?.justification && (
                  <AccordionPanel pb={4}>
                    <Box
                      p="4"
                      rounded={"lg"}
                      bg={(alreadyAnswered && isSerieMode)
                        ? generateBorderColor(option._id)
                            .bg
                        : highlightSelectedOptions(option._id)
                        ? "#4B62F0"
                        : "#f4f4f4"}
                      border={`1px solid ${
                        (alreadyAnswered && isSerieMode)
                          ? generateBorderColor(option._id)
                              .justificationBorderColor
                          : highlightSelectedOptions(option._id)
                          ? "#4B62F0"
                          : "#f4f4f4"
                      }`}
                    >
                      <Text fontSize={"14px"}>
                      {correction &&
                        // @ts-ignore
                        Object.values(correction)
                          .flat()
                          .find((el: any) => el._id === option._id)
                          ?.justification}

                      </Text>
                    </Box>
                  </AccordionPanel>
                )}
            </AccordionItem>
          ))}
        </VStack>
      </Accordion>
    </Stack>
  );
}

const OptionItem = ({
  questionId,
  option,
  index,
  isSelected,
  select,
  correction,
  letterBg,
  letterColor,
  answered,
  isSerieMode
}: any) => {
  const queryParams = useQueryParams()

  return (
    <AccordionButton
      _active={{}}
      _focus={{}}
      _expanded={{ bg: "#7FCF9" }}
      as="span"
      cursor={"pointer"}
      p="0"
      pr="4"
      _hover={
        !answered
          ? {
              "& .nested-alpha-box": { bg: "lightGrey" },
            }
          : {}
      }
      // _hover={{
      //   "& .nested-alpha-box": { bg: "lightGrey" },
      // }}
      onClick={() => {
        if(queryParams.get("view") === "true"){
          return
        }
        if(isSerieMode && answered){
          return;
        }
        if (queryParams.get("view") !== "true" || !(answered && isSerieMode)) {
          select(questionId, option._id);
        }
      }}
    >
      <HStack justify={"space-between"} w="full">
        <HStack spacing={2} align="stretch">
          <Flex
            p="2"
            align="center"
            justify="center"
            width={"55px"}
            className="alpha-box"
            bg={(answered && isSerieMode) ? letterBg : "darkGrey"}
            height={"auto"}
          >
            <Square
              size={"40px"}
              className="nested-alpha-box"
              bg={(answered && isSerieMode) ? "transparent" : isSelected ? "lightGrey" : "transparent"}
              transition={"background 0. 12s"}
              rounded={"md"}
              color={
                (queryParams.get("view") === "true" || (answered && isSerieMode))
                  ? letterColor
                  : isSelected
                  ? "blue-light"
                  : "black"
              }
              fontWeight={isSelected ? "bold" : "normal"}
            >
              {alphabet[index]}
            </Square>
          </Flex>
          <Text p="3" fontSize={"14px"} alignSelf="center">
            {option.value}
          </Text>
        </HStack>
      </HStack>
    </AccordionButton>
  );
};
