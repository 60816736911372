import { Flex, Stack, Image, Fade } from "@chakra-ui/react";
import logo from "../assets/img/ecnplus-logo.svg";
import { IAuthLayout } from "../types";

export default function AuthLayout({
  illustration,
  children,
}: IAuthLayout): JSX.Element {
  return (
    <Stack h={"100vh"} direction={{ base: "column", md: "row" }}>
      <Flex
        transition={"3"}
        flex={{ base: 0.7, "2xl": 0.75 }}
        bg="light-blue-light"
        align={"center"}
        justify={"center"}
        h="100vh"
        display={{ base: "none", md: "flex" }}
        position={"relative"}
      >
        <Fade
          in={Boolean(illustration)}
          transition={{ enter: { duration: 0.25 }, exit: { duration: 0.25 } }}
          key={illustration}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <Image
            alt={"Login Image"}
            src={illustration}
            objectFit={"cover"}
            maxH={"70%"}
          />
        </Fade>
        <Image
          alt="ECN+"
          src={logo}
          position={"absolute"}
          top={"50px"}
          left={"50px"}
        />
      </Flex>
      <Flex
        p={{base: 6, "2xl": 8}}
        flex={{ base: 1, md: 0.3, "2xl": 0.25 }}
        align={"center"}
        justify={"center"}
        overflowY={"auto"}
        h="100vh"
      >
        <Stack spacing={4} w={"full"} h="100%">
          {children}
        </Stack>
      </Flex>
    </Stack>
  );
}
