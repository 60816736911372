import { useCallback } from "react";
import {
  Box,
  Accordion,
  VStack,
  AccordionItem,
  AccordionPanel,
  Stack,
  Text,
} from "@chakra-ui/react";
// @ts-ignore
import Output from "editorjs-react-renderer";
import OptionItem from "./OptionItem";
import ReportModal from "../Report/ReportModal";

function QcmQuestion({
  question,
  answered,
  selectedOptions,
  select,
  correction,
}: any) {
  const generateBorderColor = useCallback(
    (id: string) => {
      const correctAndSelected = correction?.correctSelectedOptions?.find(
        (el: any) => el?._id === id
      );
      const correctAndNOTSelected = correction?.correctNotSelectedOptions?.find(
        (el: any) => el?._id === id
      );
      const wrongSelectedOptions = correction?.wrongSelectedOptions?.find(
        (el: any) => el?._id === id
      );

      if (correctAndSelected) {
        return {
          bg: "#F2FAF4",
          borderColor: "#7FCF91",
          textColor: "#7FCF91",
          textBackground: "#E8EFEA",
          justificationBorderColor: "#7FCACF",
        };
      } else if (correctAndNOTSelected) {
        return {
          bg: "#FFF8EA",
          borderColor: "#FCB731",
          textColor: "#FCB731",
          textBackground: "#F4EDE0",
          justificationBorderColor: "#F8C561",
        };
      } else if (wrongSelectedOptions) {
        return {
          bg: "#FBEFEF",
          borderColor: "#D76161",
          textColor: "#D76161",
          textBackground: "#F0E5E5",
          justificationBorderColor: "#D76161",
        };
      } else {
        return {
          bg: "#F4F4F4",
          borderColor: "#f4f4f4",
          textColor: "black",
          textBackground: "darkGrey",
          justificationBorderColor: "#7FCACF",
        };
      }
    },
    [correction]
  );

  const highlightSelectedOptions = useCallback((optionId: string) => {
    if (selectedOptions) {
      if (selectedOptions.options.includes(optionId)) {
        return true;
      }
      return false;
    }
  }, [selectedOptions]);

  return (
    <Stack spacing={{base: "20px", lg: "20px", "2xl": "30px"}}>
      <Box rounded={"lg"} bg="lightGrey" p="4" fontSize={"14px"} position="relative">
        <Output data={question.value} />
        <Box position={"absolute"} top="0px" right={"0px"}>
          <ReportModal question={question._id}/>
        </Box>
      </Box>
      <Accordion allowMultiple rounded="md">
        <VStack align={"stretch"} spacing={2}>
          {question?.options?.map((option: any, index: number) => (
            <AccordionItem
              key={option._id}
              bg={generateBorderColor(option._id).bg}
              rounded={"lg"}
              overflow="hidden"
              border={`1px solid ${
                answered
                  ? generateBorderColor(option._id).borderColor
                  : highlightSelectedOptions(option._id)
                  ? "#4B62F0"
                  : "#f4f4f4"
              }`}
              transition="all 0.5s"
            >
              <OptionItem
                option={option}
                questionId={question._id}
                index={index}
                select={select}
                isSelected={highlightSelectedOptions(option._id)}
                answered={answered}
                letterColor={generateBorderColor(option._id).textColor}
                letterBg={generateBorderColor(option._id).textBackground}
                correction={correction}
              />
              {correction &&
                // @ts-ignore
                Object.values(correction)
                  .flat()
                  .find((el: any) => el._id === option._id)?.justification && (
                  <AccordionPanel pb={4}>
                    <Box
                      p="4"
                      rounded={"lg"}
                      bg={answered
                        ? generateBorderColor(option._id)
                            .bg
                        : highlightSelectedOptions(option._id)
                        ? "#4B62F0"
                        : "#f4f4f4"}
                      border={`1px solid ${
                        answered
                          ? generateBorderColor(option._id)
                              .justificationBorderColor
                          : highlightSelectedOptions(option._id)
                          ? "#4B62F0"
                          : "#f4f4f4"
                      }`}
                    >
                      <Text fontSize={"14px"}>
                      {correction &&
                        // @ts-ignore
                        Object.values(correction)
                          .flat()
                          .find((el: any) => el._id === option._id)
                          ?.justification}

                      </Text>
                    </Box>
                  </AccordionPanel>
                )}
            </AccordionItem>
          ))}
        </VStack>
      </Accordion>
    </Stack>
  );
}

export default QcmQuestion;
