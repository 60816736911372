export default function KeyIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <g id="key" opacity="0.5">
                <rect id="Rectangle_16" data-name="Rectangle 16" width="24" height="24" rx="5" fill="#ededed" opacity="0"/>
                <g id="Group_18" data-name="Group 18" transform="translate(1.2 1.2)">
                <path id="Path_21" data-name="Path 21" d="M5.562,20.475a2.548,2.548,0,0,1-.27-.018l-1.953-.27A2.363,2.363,0,0,1,1.413,18.27L1.143,16.3a2.482,2.482,0,0,1,.63-1.908L5.724,10.44a7.5,7.5,0,1,1,5.445,5.427l-3.96,3.951a2.423,2.423,0,0,1-1.647.657ZM12.987,2.484A6.139,6.139,0,0,0,7.119,10.44a.671.671,0,0,1-.171.675l-4.23,4.23a1.162,1.162,0,0,0-.252.756l.27,1.971a1.032,1.032,0,0,0,.765.774l1.962.27a1.115,1.115,0,0,0,.765-.243l4.248-4.239a.672.672,0,0,1,.675-.162,6.137,6.137,0,0,0,6.156-10.2,6.054,6.054,0,0,0-4.32-1.791Z" fill="#292d32"/>
                <path id="Path_22" data-name="Path 22" d="M8.271,18.486a.668.668,0,0,1-.477-.2l-2.07-2.07a.675.675,0,0,1,.954-.954l2.07,2.07a.679.679,0,0,1,0,.954A.668.668,0,0,1,8.271,18.486Z" fill="#292d32"/>
                <path id="Path_23" data-name="Path 23" d="M13.05,10.575A2.025,2.025,0,1,1,15.075,8.55,2.028,2.028,0,0,1,13.05,10.575Zm0-2.7a.675.675,0,1,0,.675.675A.68.68,0,0,0,13.05,7.875Z" fill="#292d32"/>
                </g>
            </g>
        </svg>
    )
}
