import { Redirect, Route } from "react-router-dom";

const ProtectedRoute = ({
  component: Component,
  days_left,
  current_order,
  ...rest
}: any) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (props.match.params.specialityName === "Cardiologie-CCV") {
          return <Component {...props} {...rest} />;
        }

        if (!current_order || days_left <= 0) {
          return (
            <Redirect
              to={{
                pathname: "/tableau-de-bord",
                state: { from: props.location },
              }}
            />
          );
        } else {
          return <Component {...props} {...rest} />;
        }
      }}
    />
  );
};

export default ProtectedRoute;
