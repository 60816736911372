import axiosInstance from "../../utils/axiosInstance";

export const getSubjectsBySpecialityName = async (specialityName: string) => {
  let token = localStorage.getItem("access_token");
  return await axiosInstance({
    method: "get",
    url: `/subject/by-spec/${specialityName}?archived=false`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res: any) => res.data);
};

export const getSeriesBySubject = async (subjectId: string, query: any) => {
  let token = localStorage.getItem("access_token");
  return await axiosInstance({
    method: "get",
    url: `/qcm/by-university/${subjectId}?archived=false${
      query.toString() ? "&" + query.toString() : ""
    }`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res: any) => res.data);
};

export const getSerieById = async (serieId: string) => {
  let token = localStorage.getItem("access_token");
  return await axiosInstance({
    method: "get",
    url: `/qcm/${serieId}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res: any) => res.data);
};

export const getAttemptsStatsByType = async (
  type: string,
  withSubjects: boolean = false
) => {
  let token = localStorage.getItem("access_token");
  return await axiosInstance({
    method: "get",
    url:
      type === "examen"
        ? "/attempts/exam/performance"
        : type === "serie"
        ? `/attempts/serie/results-by-type${
            withSubjects ? "?withSubjects=true" : "?withSubjects=false"
          }`
        : "",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res: any) => res.data);
};

export const getAttemptsStatsBySpeciality = async (speciality: string) => {
  let token = localStorage.getItem("access_token");
  return await axiosInstance({
    method: "get",
    url: `/attempts/results-by-speciality/${speciality}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res: any) => res.data);
};

export const getAttemptsStatsBySubject = async (subject: string) => {
  let token = localStorage.getItem("access_token");
  return await axiosInstance({
    method: "get",
    url: `/attempts/results-by-subject/${subject}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res: any) => res.data);
};

export const getRecentSeriesUpdates = async () => {
  let token = localStorage.getItem("access_token");
  return await axiosInstance({
    method: "get",
    url: `/qcm/recent/series`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res: any) => res.data);
};

export const getAttemptsStatsExamen = async () => {
  let token = localStorage.getItem("access_token");
  return await axiosInstance({
    method: "get",
    url: `/question/exams`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res: any) => res.data);
};

export const getSpecialitiesAndSubjects = async () => {
  let token = localStorage.getItem("access_token");
  return await axiosInstance({
    method: "get",
    url: `/attempts/serie/statistics`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res: any) => res.data);
};
