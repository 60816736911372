import { useStopwatch } from "react-timer-hook";

import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";

interface TimerContextType {
  seconds: any;
  minutes: any;
  hours: any;
  isRunning: any;
  start: any;
  pause: any;
  toggleTimer: any;
  reset: any;
  time: number;
  timerIsRunning: boolean;
  stopTimer: any;
  startTimer: any;
  resetTimer: any;
  startInitialTimer: any;
  initialTime: number
}

type TimerProviderType = {
  children: ReactNode;
};

const TimerContext = createContext<TimerContextType>({} as TimerContextType);

export const useTimer = () => useContext(TimerContext);

export const TimerProvider = ({ children }: TimerProviderType) => {
  const { seconds, minutes, hours, isRunning, start, pause, reset } = useStopwatch({
    autoStart: false,
  });
  const { toggleTimer, time, timerIsRunning, stopTimer, startTimer, resetTimer, startInitialTimer,initialTime } = useTimerProvider({
    seconds,
    minutes,
    hours,
    isRunning,
    start,
    pause,
    reset
  });

  return (
    <TimerContext.Provider
      value={{
        seconds,
        minutes,
        hours,
        isRunning,
        start,
        pause,
        toggleTimer,
        reset,
        time,
        timerIsRunning,
        stopTimer,
        startTimer,
        resetTimer,
        startInitialTimer,
        initialTime
      }}
    >
      {children}
    </TimerContext.Provider>
  );
};

const useTimerProvider = ({
  seconds,
  minutes,
  hours,
  isRunning,
  start,
  pause,
  reset,
}: any) => {
  const [initialTime, setInitialTime] = useState(0)
  const [time, setTime] = useState(0);
  const [timerIsRunning, setTimerIsRunning] = useState(false);
  const [desc, setDesc] = useState(false);

  useEffect(() => {
    let interval: any;
    if (timerIsRunning && time >= 0) {
      interval = setInterval(() => {
        desc ? setTime((prevTime) => prevTime - 1) : setTime((prevTime) => prevTime + 1);
      }, 1000);
    } else if (!timerIsRunning) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [timerIsRunning]);

  useEffect(() => {
    if(time <= 0){
      resetTimer();
      setTimerIsRunning(false)
    }
  }, [time])


  // Pause / Start Timer
  const toggleTimer = () => {
    if (isRunning) {
      pause();
      return;
    }
    start();
  };

  const stopTimer = () => {
    setTimerIsRunning(false)
  }
  const startTimer = () => {
    setTimerIsRunning(true)
  }
  const resetTimer = () => {
    setTime(0)
  };


  const startInitialTimer = (seconds: number, timerInitialyRunning: boolean = true, desc: boolean = false) => {
    setInitialTime(seconds);
    setTime(seconds);
    setTimerIsRunning(timerInitialyRunning);
    setDesc(desc)
  }

  return {
    seconds,
    minutes,
    hours,
    isRunning,
    start,
    pause,
    toggleTimer,

    time,
    timerIsRunning,
    stopTimer,
    startTimer,
    resetTimer,
    startInitialTimer,
    initialTime
  };
};

export default useTimerProvider;