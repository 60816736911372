import { Route, Switch, useRouteMatch } from 'react-router-dom'
import Register from '../../pages/Authorized/Register/Register';

export default function RegisterContainer() {
    const { path, url } = useRouteMatch();
    return (
        <Switch>
                <Route exact path={`${path}`}>
                  <Register base_url={url} />
                </Route>
                <Route>
                  <h1>Page Not Found</h1>
                </Route>
              </Switch>
    )
}
