export default function AnswerWrongIcon() {
  return (
    <svg
      id="close-circle"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <rect
        id="Rectangle_91"
        data-name="Rectangle 91"
        width="24"
        height="24"
        rx="5"
        fill="#d76161"
        opacity="0"
      />
      <g id="Group_157" data-name="Group 157" transform="translate(1.2 1.2)">
        <path
          id="Path_108"
          data-name="Path 108"
          d="M10.8,20.475A9.675,9.675,0,1,1,20.475,10.8,9.683,9.683,0,0,1,10.8,20.475Zm0-18A8.325,8.325,0,1,0,19.125,10.8,8.335,8.335,0,0,0,10.8,2.475Z"
          fill="#d76161"
        />
        <path
          id="Path_109"
          data-name="Path 109"
          d="M8.253,14.022a.668.668,0,0,1-.477-.2.679.679,0,0,1,0-.954L12.87,7.776a.675.675,0,0,1,.954.954L8.73,13.824A.653.653,0,0,1,8.253,14.022Z"
          fill="#d76161"
        />
        <path
          id="Path_110"
          data-name="Path 110"
          d="M13.347,14.022a.668.668,0,0,1-.477-.2L7.776,8.73a.675.675,0,0,1,.954-.954l5.094,5.094a.679.679,0,0,1,0,.954.668.668,0,0,1-.477.2Z"
          fill="#d76161"
        />
      </g>
    </svg>
  );
}
