
export default function FolderClosedGreen() {
  return (
    <svg
      id="folder-minus"
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
    >
      <rect
        id="Rectangle_43"
        data-name="Rectangle 43"
        width="32"
        height="32"
        rx="5"
        fill="#292d32"
        opacity="0"
      />
      <g id="Group_55" data-name="Group 55" transform="translate(3.1 3.1)">
        <path
          id="Path_75"
          data-name="Path 75"
          d="M14.712,14.22h-6a.9.9,0,0,1,0-1.8h6a.9.9,0,0,1,0,1.8Z"
          transform="translate(1.104 2.64)"
          fill="#7fcacf"
        />
        <path
          id="Path_76"
          data-name="Path 76"
          d="M20.025,26.925h-12c-5.292,0-6.9-1.608-6.9-6.9v-12c0-5.292,1.608-6.9,6.9-6.9h1.8a3.711,3.711,0,0,1,3.6,1.8l1.8,2.4c.4.528.456.6,1.2.6h3.6c5.292,0,6.9,1.608,6.9,6.9v7.2C26.925,25.317,25.317,26.925,20.025,26.925Zm-12-24c-4.3,0-5.1.816-5.1,5.1v12c0,4.284.8,5.1,5.1,5.1h12c4.3,0,5.1-.816,5.1-5.1v-7.2c0-4.284-.8-5.1-5.1-5.1h-3.6a2.755,2.755,0,0,1-2.64-1.32L11.985,4a2.018,2.018,0,0,0-2.16-1.08Z"
          transform="translate(-1.125 -1.125)"
          fill="#292d32"
        />
      </g>
    </svg>
  );
}
