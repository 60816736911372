import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import ExamPage from "../../pages/Unauthorized/Exams/ExamPage";
import Qcm from "../../pages/Unauthorized/Qcm/Qcm";
import QcmResult from "../../pages/Unauthorized/Qcm/QcmResult";
import { TimerProvider } from "../../timer-provider/TimerProvider";
import { useAuthProvider } from "../../auth-provider/AuthProvider";

export default function QcmContainer() {
  const { data: auth } = useAuthProvider();
  const { path } = useRouteMatch();

  if(!auth?.email_verified){
    return <Redirect to="/confirmation-email" exact/>
  }

  return (
    <Switch>
      <TimerProvider>
        <Route exact path={`${path}/:type/:qcmId/:attemptId`}>
          <Qcm />
        </Route>
        <Route exact path={`/qcm/:type/:year/:day/:qcmId/:attemptId`}>
          <ExamPage />
        </Route>
        <Route exact path={`${path}/:type/:qcmId/:attemptId/result`}>
          <QcmResult />
        </Route>
      </TimerProvider>
      <Route>
        <h1>Page Not Found</h1>
      </Route>
    </Switch>
  );
}
