import {
  Text,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useModal } from "../../modal-provider/ModalProvider";

function UpgradeModal() {
  const { isOpen, onClose } = useModal();

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Abonnement requis</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text>Pour continuer, vous devez acheter une abonnement.</Text>
        </ModalBody>

        <ModalFooter>
          <Button
            variant="default"
            mr={3}
            onClick={onClose}
            height={useBreakpointValue({ base: "2.5rem", "2xl": "3rem" })}
          >
            Annuler
          </Button>
          <Button variant="primary">
            <a
              href="https://www.ecnplus.tn#abonnements"
              target="_blank"
              rel="noopener noreferrer"
            >
              Voir nos offres
            </a>
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default UpgradeModal;
