import {
  HStack,
  IconButton,
  Stack,
  Text,
  Tooltip,
  useColorModeValue,
} from "@chakra-ui/react";
import { useState } from "react";
import { useQuery } from "react-query";
import AnswerPartialSquareIcon from "../../../assets/icons/AnswerPartialSquareIcon";
import AnswerRightSquareIcon from "../../../assets/icons/AnswerRightSquareIcon";
import AnswersIcon from "../../../assets/icons/AnswersIcon";
import CancelIcon from "../../../assets/icons/CancelIcon";
import DayOneIcon from "../../../assets/icons/DayOneIcon";
import DayTwoIcon from "../../../assets/icons/DayTwoIcon";
import ExamsIcon from "../../../assets/icons/ExamsIcon";
import PercentageIcon from "../../../assets/icons/PercentageIcon";
import SeriesIcon from "../../../assets/icons/SeriesIcon";
import PageHeading from "../../../components/PageHeading/PageHeading";
import SidebarLayout from "../../../layouts/SidebarLayout";
import { getResultsSeriesByDay } from "../../../services/performance/services";
import {
  getAttemptsStatsByType,
  getSpecialitiesAndSubjects,
} from "../../../services/series/services";
import PerformanceBanner from "./PerformanceBanner";
import PerformanceTable from "./PerformanceTable";

const columns1 = [
  {
    title: `Jour 1`,
    icon: <DayOneIcon color="white" size="sm" />,
    weight: "bold",
    width: "33%",
    isLeft: true,
  },
  {
    title: "Moyenne",
    icon: <PercentageIcon bg="white" size="24px" />,
    width: "13.2%",
  },
  {
    title: "Répondu",
    icon: <AnswersIcon color="white" />,
    width: "13.2%",
  },
  {
    title: "Réussi",
    icon: <AnswerRightSquareIcon />,
    width: "13.2%",
  },
  {
    title: "Incomplète",
    icon: <AnswerPartialSquareIcon />,
    width: "13.2%",
  },
  {
    title: "Fausse",
    icon: <CancelIcon color="white" />,
    width: "13.2%",
  },
];
const columns2 = [
  {
    title: `Jour 2`,
    icon: <DayTwoIcon color="white" size="sm" />,
    weight: "bold",
    width: "33%",
    isLeft: true,
  },
  {
    title: "Moyenne",
    icon: <PercentageIcon bg="white" size="24px" />,
    width: "13.2%",
  },
  {
    title: "Répondu",
    icon: <AnswersIcon color="white" />,
    width: "13.2%",
  },
  {
    title: "Réussi",
    icon: <AnswerRightSquareIcon />,
    width: "13.2%",
  },
  {
    title: "Incomplète",
    icon: <AnswerPartialSquareIcon />,
    width: "13.2%",
  },
  {
    title: "Fausse",
    icon: <CancelIcon color="white" />,
    width: "13.2%",
  },
];

export default function Performance3({ base_url }: { base_url: string }) {
  const [typeSelected, setTypeSelected] = useState("Série");
  // const [moyenneDays, setMoyenneDays] = useState({
  //   day1: 0,
  //   day2: 0,
  //   general: 0,
  // });
  // const { data: stats, isLoading: isLoadingStats } = useQuery(
  //   `stats-${typeSelected}`,
  //   () => getStats(typeSelected),
  //   {
  //     refetchOnWindowFocus: false,
  //   }
  // );
  const {
    data: specialitiesAndSubjects,
    isLoading: isLoadingSpecialitiesAndSubjects,
  } = useQuery(`stats-${typeSelected}`, getSpecialitiesAndSubjects, {
    refetchOnWindowFocus: false,
    staleTime: 60000 * 15,
    retry: false
  });

  const { data: statsByType, isLoading: isLoadingStatsByType } = useQuery(
    `stats-analyse-${typeSelected}`,
    () =>
      getAttemptsStatsByType(
        typeSelected === "Série" ? "serie" : "examen",
        true
      ),
    {
      refetchOnWindowFocus: false,
      staleTime: 60000 * 15
    }
  );


  const { data: moyenne, isLoading: isLoadingMoyenne } = useQuery(
    `moyenne-${typeSelected}`,
    () => getResultsSeriesByDay(typeSelected === "Série" ? "serie" : "examen"),
    {
      refetchOnWindowFocus: false,
    }
  );

  // const findSubjectObject = (subjectId: string, specialityId: string) => {
  //   const spc = statsByType.stats.find(
  //     (el: any) => el.speciality._id === specialityId
  //   );
  //   if (spc) {
  //     const subject = spc.subject.find((sub: any) => sub._id === subjectId);
  //     if (subject) {
  //       return subject;
  //     }
  //     return 0;
  //   }
  // };

  // const moyenneSubject = (subjectId: string, specialityId: string) => {
  //   const subject = findSubjectObject(subjectId, specialityId);
  //   if (!subject) {
  //     return 0;
  //   }
  //   return subject.overall / subject.seriesCount;
  // };

  // const caluclSpecMoyenne = (speciality: any) => {
  //   // sum of all overall subject and divide it by number of subject
  //   let moy = 0;
  //   speciality.subjects.forEach((subject: any) => {
  //     moy = moy + moyenneSubject(subject._id, speciality._id._id);
  //   });
  //   return moy / speciality.subjects.length;
  // };

  // const caluclSpecialityMoyenne = () => {
  //   // sum of all overall specialities per day and divide it by number of specialities
  //   let sumOverallSpecialitiesDayOne = 0;
  //   let sumOverallSpecialitiesDayTwo = 0;
  //   let nbSpecialitiesDayOne = statsByType.subjectsAndSpecialities.filter(
  //     (el: any) => el._id.jour === "1"
  //   ).length;
  //   let nbSpecialitiesDayTwo = statsByType.subjectsAndSpecialities.filter(
  //     (el: any) => el._id.jour === "2"
  //   ).length;
  //   statsByType.subjectsAndSpecialities.forEach((speciality: any) => {
  //     if (speciality._id.jour === "1") {
  //       sumOverallSpecialitiesDayOne =
  //         sumOverallSpecialitiesDayOne + caluclSpecMoyenne(speciality);
  //     } else {
  //       sumOverallSpecialitiesDayTwo =
  //         sumOverallSpecialitiesDayTwo + caluclSpecMoyenne(speciality);
  //     }
  //   });

  //   const moyenneDayOne = sumOverallSpecialitiesDayOne / nbSpecialitiesDayOne;
  //   const moyenneDayTwo = sumOverallSpecialitiesDayTwo / nbSpecialitiesDayTwo;

  //   // setMoyenneDays({
  //   //   day1: +moyenneDayOne.toFixed(2),
  //   //   day2: +moyenneDayTwo.toFixed(2),
  //   //   general: +((moyenneDayOne + moyenneDayTwo) / 2).toFixed(2),
  //   // });
  // };

  // useEffect(() => {
  //   if (statsByType) {
  //     caluclSpecialityMoyenne();
  //   }
  // }, [statsByType]);

  const textColorCurrent = useColorModeValue("black", "white");
  return (
    <SidebarLayout>
      <Stack spacing={6}>
        <HStack
          justify={"space-between"}
          align={{ base: "flex-start", md: "center" }}
        >
          <HStack>
            <PageHeading title={"Analyse"} />
            <Text color={textColorCurrent}>
              {typeSelected === "Série" ? "/ Série" : "/ Examen"}
            </Text>
          </HStack>
          <PerformanceFilterButtons
            setTypeSelected={setTypeSelected}
            typeSelected={typeSelected}
          />
        </HStack>
        <PerformanceBanner
          moyenneDays={moyenne}
          isLoadingStats={isLoadingMoyenne}
          isExam={typeSelected === "Examen"}
        />
        <PerformanceTable
          isLoading={isLoadingStatsByType || isLoadingSpecialitiesAndSubjects}
          rows={specialitiesAndSubjects?.subjectsAndSpecialities?.filter(
            (el: any) => el._id.jour === "1"
          )}
          columns={columns1}
          stats={statsByType?.filter((el: any) => el?.specialityDay === "1")}
          isExam={typeSelected === "Examen"}
        />
        <PerformanceTable
          isLoading={isLoadingStatsByType || isLoadingSpecialitiesAndSubjects}
          rows={specialitiesAndSubjects?.subjectsAndSpecialities?.filter(
            (el: any) => el._id.jour === "2"
          )}
          columns={columns2}
          stats={statsByType?.filter((el: any) => el.specialityDay === "2")}
          isExam={typeSelected === "Examen"}
        />
        {/* <CollapsibleTable
          stats={statsByType}
          isLoadingStats={isLoadingStatsByType}
          jour={"1"}
          typeSelected={typeSelected}
        />
        <CollapsibleTable
          stats={statsByType}
          isLoadingStats={isLoadingStatsByType}
          jour={"2"}
          typeSelected={typeSelected}
        /> */}
      </Stack>
    </SidebarLayout>
  );
}

const PerformanceFilterButtons = ({ setTypeSelected, typeSelected }: any) => {
  return (
    <Stack direction={"row"}>
      <Tooltip label="Série" hasArrow>
        <IconButton
          variant={typeSelected === "Série" ? "primary" : "default"}
          aria-label="Série"
          icon={
            <SeriesIcon color={typeSelected === "Série" ? "white" : "black"} />
          }
          height={"2.5rem"}
          _hover={{}}
          _focus={{}}
          _active={{}}
          onClick={() => setTypeSelected("Série")}
          sx={{
            "&:hover *": {
              fill: typeSelected === "Série" ? "white" : "blue-light",
            },
          }}
        />
      </Tooltip>
      <Tooltip label="Examen blanc" hasArrow>
        <IconButton
          variant={typeSelected === "Examen" ? "primary" : "default"}
          aria-label="Examen blanc"
          icon={
            <ExamsIcon color={typeSelected === "Examen" ? "white" : "black"} />
          }
          height={"2.5rem"}
          _hover={{}}
          _focus={{}}
          _active={{}}
          onClick={() => setTypeSelected("Examen")}
          sx={{
            "&:hover *": {
              fill: typeSelected === "Examen" ? "white" : "blue-light",
            },
          }}
        />
      </Tooltip>
    </Stack>
  );
};
