import {
  Fragment,
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  IconButton,
  Box,
  CloseButton,
  Flex,
  Icon,
  useColorModeValue,
  Drawer,
  DrawerContent,
  Text,
  useDisclosure,
  BoxProps,
  FlexProps,
  Button,
  Fade,
  HStack,
  Tooltip,
  MenuButton,
  Menu,
  MenuList,
  MenuItem,
  ButtonGroup,
  useBreakpointValue,
  Stack,
  VStack,
  Avatar,
  MenuDivider,
  Image,
  DrawerOverlay,
  DrawerBody,
  Input,
  InputLeftElement,
  InputGroup,
  DrawerHeader,
  Spinner,
  AvatarBadge,
  Circle,
  Badge,
  useToast,
  Heading,
} from "@chakra-ui/react";
import { ReactText, ReactElement } from "react";
import HomeIcon from "../assets/icons/HomeIcon";
import SeriesIcon from "../assets/icons/SeriesIcon";
import ExamsIcon from "../assets/icons/ExamsIcon";
import PerformanceIcon from "../assets/icons/PerformanceIcon";
import MyListIcon from "../assets/icons/MyListIcon";
import logo_white from "../assets/img/logo_white.svg";
import UserIcon from "../assets/icons/UserIcon";
import { useAuthProvider } from "../auth-provider/AuthProvider";
import BellIcon from "../assets/icons/BellIcon";
import { useHistory, Link as RouterLink } from "react-router-dom";
import { logout } from "../services/auth/services";
import { FiLogOut } from "react-icons/fi";
import { ChevronDownIcon } from "@chakra-ui/icons";
import logo from "../assets/img/ecnplus-logo.svg";
import UserOctagon from "../assets/icons/UserOctagon";
import Shop from "../assets/icons/Shop";
import { RiSearch2Line } from "react-icons/ri";
import LogoutIcon from "../assets/icons/LogoutIcon";
import useQueryParams from "../hooks/useQueryParams";
import { QueryCache, useMutation, useQuery } from "react-query";
import { search } from "../services/dashboard/dashboard";
import { debounce } from "../utils/debounce";
import { io } from "socket.io-client";
import { SocketContext } from "../context/socket";
import { getNotSeenNotificationCount } from "../services/notifications/services";
import { HiOutlineExternalLink } from "react-icons/hi";
import Dot from "../components/Dot";
import TicketIcon from "../assets/icons/TicketIcon";

interface LinkItemProps {
  name: string;
  icon: ReactNode;
  href: string;
}
const LinkItems: Array<LinkItemProps> = [
  { name: "Accueil", icon: HomeIcon, href: "/tableau-de-bord" },
  { name: "Série", icon: SeriesIcon, href: "/series" },
  { name: "Examen", icon: ExamsIcon, href: "/examen-blanc" },
  { name: "Playlist", icon: MyListIcon, href: "/playlist" },
  { name: "Analyse", icon: PerformanceIcon, href: "/performance" },
  { name: "Appliquer coupon", icon: TicketIcon, href: "/coupon" },
];

export default function SidebarLayout({
  children,
  withoutPadding = false,
}: {
  withoutPadding?: boolean;
  children: ReactNode;
}) {
  // const [socket, setSocket] = useState<any>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { location } = useHistory();
  const brkValue = useBreakpointValue({ base: true, md: false });
  const socket = useContext(SocketContext);
  const [newNotification, setNewNotification] = useState(false);
  const { data: auth } = useAuthProvider();
  const toast = useToast();

  const { data: notificationCount } = useQuery(
    [`notification-${auth?._id}`, newNotification],
    getNotSeenNotificationCount,
    {
      // enabled: Boolean(newNotification)
      staleTime: 60000 * 5,
    }
  );

  useEffect(() => {
    // as soon as the component is mounted, do the following tasks:

    // emit USER_ONLINE event
    // socket.emit("USER_ONLINE", userId);

    // subscribe to socket events
    socket.on("message", (x: any) => {
      setNewNotification(true);
      // toast({
      //   isClosable: true,
      //   title: "Nouvel examen crée",
      //   // description: x,
      //   variant: "solid",
      //   status: "info",
      //   position: "bottom-right"
      // })
    });
    socket.emit("patientJoin");

    return () => {
      // before the component is destroyed
      // unbind all event handlers used in this component
      socket.off("message");
      // socket.emit("disconnectClient");
    };
  }, [socket]);

  // useEffect(() => {
  //   setSocket(io("http://localhost:8080"));
  // }, []);

  // useEffect(() => {
  //   socket?.on("message", (x: any) => {
  //     console.log(x)
  //   })
  // }, [socket]);

  return (
    <Box
      className={useBreakpointValue({ base: "", md: "full-height" })}
      bg={useColorModeValue("blue-light", "gray.900")}
    >
      <SidebarContent
        onClose={() => onClose}
        display={{ base: "none", md: "flex" }}
        newNotification={newNotification}
        notificationCount={notificationCount}
      />
      {/* <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer> */}
      {/* mobilenav */}
      {!location.pathname.includes("/qcm/") && (
        <>
          <MobileNavBottom
            display={{ base: "flex", md: "none" }}
            onOpen={onOpen}
          />
          <MobileNavTop
            display={{ base: "flex", md: "none" }}
            onOpen={onOpen}
            newNotification={newNotification}
            notificationCount={notificationCount}
          />
        </>
      )}
      <Box
        ml={{ base: 0, md: 60, "2xl": 80 }}
        p={withoutPadding ? 0 : { base: "6", "2xl": "8" }}
        mb={!location.pathname.includes("/qcm/") && brkValue ? "16" : "0"}
        borderTopLeftRadius={{ base: 0, md: "1.5rem" }}
        borderBottomLeftRadius={{ base: 0, md: "1.5rem" }}
        bg="white"
        // h={"100vh"}
        className={useBreakpointValue({ base: "", md: "full-height" })}
        overflowY={"auto"}
        width={{
          base: "100%",
          md: "calc(100% - 240px)",
          "2xl": "calc(100% - 20rem)",
        }}
        boxShadow={{ base: "none", md: "-20px 1px 0px 3px rgb(69,90,222)" }}
      >
        <Fade in key={location.pathname}>
          {children}
        </Fade>
      </Box>
    </Box>
  );
}

interface SidebarProps extends BoxProps {
  onClose: () => void;
  newNotification?: boolean;
  notificationCount?: number;
}

const SidebarContent = ({
  onClose,
  newNotification,
  notificationCount,
  ...rest
}: SidebarProps) => {
  const { data: auth } = useAuthProvider();
  const { location, push } = useHistory();
  const queryCache = new QueryCache();

  const logout = () => {
    localStorage.removeItem("access_token");
    queryCache.clear();
    push("/connexion");
  };

  return (
    <Flex
      direction={"column"}
      bg={useColorModeValue("blue-light", "gray.900")}
      w={{ base: "full", md: 60, "2xl": 80 }}
      pos="fixed"
      h="full"
      bgGradient={
        "linear-gradient(90deg, rgba(75,98,240,1) 0%, rgba(69,90,222,1) 100%)"
      }
      {...rest}
    >
      <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
        <Text fontSize="2xl" fontFamily="monospace" fontWeight="bold">
          <img src={logo_white} alt="ECNPlus" />
        </Text>
        <CloseButton display={{ base: "flex", md: "none" }} onClick={onClose} />
      </Flex>
      <Box flexGrow={1}>
        {LinkItems.map((link) => (
          <NavItem key={link.name} icon={link.icon} href={link.href}>
            {link.name}
          </NavItem>
        ))}
        <NavItem
          key={"abonnement"}
          icon={Shop}
          href={"https://www.ecnplus.tn#abonnements"}
          inNewTab
          isExternal
        >
          <HStack align="center" as="span">
            <Text>Abonnements</Text> <HiOutlineExternalLink />
          </HStack>
        </NavItem>
        {auth?.current_order &&
          (auth?.days_left <= 10 || auth?.days_left <= 0) && (
            <Box bg="#7688F4" rounded="8px" p="4" mx="4">
              <Heading color="white" fontSize={"2xl"}>
                Votre abonnement{" "}
                <Heading as="span" color="gold-yellow" fontSize={"2xl"}>
                  {auth?.days_left > 0
                    ? `finira dans ${auth?.days_left} jour${
                        auth?.days_left > 1 ? "s" : ""
                      }.`
                    : "est expiré."}
                </Heading>
              </Heading>
            </Box>
          )}
        {!auth?.current_order && (
          <Box bg="#7688F4" rounded="8px" p="4" mx="4">
            <Heading color="white" fontSize={"2xl"}>
              Abonnez-vous pour voir toutes{" "}
              <Heading as="span" color="gold-yellow" fontSize={"2xl"}>
                nos séries et examens
              </Heading>
            </Heading>
          </Box>
        )}
      </Box>
      <Flex px="4" justify={"space-between"} mb="30px">
        <Menu>
          <MenuButton
            as={Button}
            rightIcon={<ChevronDownIcon />}
            bg={
              location.pathname.includes("/profile")
                ? "muted-blue"
                : "transparent"
            }
            color={"white"}
            _active={{ bg: "white", color: "blue-light" }}
            _focus={{}}
            _hover={{ bg: "muted-blue" }}
            fontSize={{ base: "0.8rem", "2xl": "1rem" }}
          >
            {auth?.fullname || "Nom et prénom"}
          </MenuButton>
          <MenuList bg="muted-blue" border="none" p="0" overflow={"hidden"}>
            <RouterLink to="/profile">
              <MenuItem
                icon={<UserIcon />}
                _hover={{ bg: "whiteAlpha.300" }}
                _focus={{ bg: "whiteAlpha.300" }}
                color="white"
              >
                Mon compte
              </MenuItem>
            </RouterLink>
            <MenuItem
              icon={<LogoutIcon color="#fff" />}
              color="white"
              _hover={{ bg: "whiteAlpha.300" }}
              _focus={{ bg: "whiteAlpha.300" }}
              onClick={logout}
            >
              Déconnexion
            </MenuItem>
          </MenuList>
        </Menu>
        <Button
          variant={"ghost"}
          px="0"
          _hover={{ bg: "muted-blue" }}
          _active={{ bg: "muted-blue" }}
          onClick={() => push("/notifications")}
          bg={
            location.pathname.includes("/notifications")
              ? "white"
              : "transparent"
          }
        >
          <BellIcon
            color={
              location.pathname.includes("/notifications") ? "#4B62F0" : "white"
            }
          />
          {(newNotification || notificationCount !== 0) && (
            <Badge
              bg="red"
              rounded={"full"}
              position={"absolute"}
              top="1"
              right={"1"}
              color="white"
              fontSize={"xs"}
            >
              {notificationCount}
            </Badge>
          )}
        </Button>
      </Flex>
    </Flex>
  );
};

interface NavItemProps extends FlexProps {
  icon: any;
  href: string;
  isExternal?: boolean;
  inNewTab?: boolean;
  children: ReactText | ReactElement;
}
const NavItem = ({
  icon,
  children,
  href,
  inNewTab,
  isExternal,
  ...rest
}: NavItemProps) => {
  const { location } = useHistory();

  const NavItemContent = (
    <Box
      style={{ textDecoration: "none" }}
      _focus={{ boxShadow: "none" }}
      color={"white"}
      fontWeight={"light"}
      position={"relative"}
      className={location.pathname.includes(href) ? "link-item" : undefined}
      as="span"
    >
      <Flex
        my="3"
        align="center"
        px="4"
        py="3"
        mx="4"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        _hover={{
          bg: location.pathname.includes(href) ? "white" : "muted-blue",
          color: location.pathname.includes(href) ? "blue-light" : "white",
        }}
        bg={location.pathname.includes(href) ? "white" : "transparent"}
        color={location.pathname.includes(href) ? "blue-light" : "white"}
        h={{ base: "2.5rem", "2xl": "3.5rem" }}
        {...rest}
      >
        {icon && <Icon as={icon} />}
        <Text
          ml="4"
          fontSize={{ base: "0.8rem", "2xl": "1rem" }}
          fontWeight={"normal"}
          as="span"
        >
          {children}
        </Text>
      </Flex>
    </Box>
  );

  if (isExternal) {
    return (
      <a
        href={href}
        target={inNewTab ? "_blank" : "_self"}
        rel="noopener noreferrer"
      >
        {NavItemContent}
      </a>
    );
  }
  return <RouterLink to={href}>{NavItemContent}</RouterLink>;
};

const NavItemMobile = ({ icon, children, href, ...rest }: NavItemProps) => {
  const { location } = useHistory();

  return (
    <RouterLink to={href} style={{ flex: 1 }}>
      <Box
        style={{ textDecoration: "none" }}
        _focus={{ boxShadow: "none" }}
        color={"white"}
        fontWeight={"light"}
        position={"relative"}
        flex={1}
        // flexGrow={1}
        rounded="0"
        // borderRight={"1px solid"}
        // borderColor="light-blue-light"
        whiteSpace="pre-wrap"
        wordBreak={"break-all"}
        fontSize="sm"
        // p="2"
        h="full"
        bg="blue-light"
        // bg={location.pathname.includes(href) ? "light-blue-light" : "blue-light"}
        borderColor="whiteAlpha.200"
        borderRight={"none"}
        // className={location.pathname.includes(href) ? "link-item" : undefined}
      >
        <VStack
          // my="3"
          align="center"
          // px="4"
          py="3"
          mx="0"
          // borderRadius="lg"
          role="group"
          cursor="pointer"
          _hover={{
            bg: location.pathname.includes(href) ? "white" : "muted-blue",
            color: location.pathname.includes(href) ? "blue-light" : "white",
          }}
          bg={"transparent"}
          color={location.pathname.includes(href) ? "blue-light" : "white"}
          // h={{ base: "2.5rem", "2xl": "3.5rem" }}
          h="full"
          justify={"center"}
          spacing={0}
          // {...rest}
        >
          {icon && (
            <Box
              bg={
                location.pathname.includes(href)
                  ? "light-blue-light"
                  : "transparent"
              }
              p="2"
              rounded={"lg"}
            >
              <Icon as={icon} />
            </Box>
          )}
          {/* <Text
            ml="4"
            fontSize={{ base: "0.8rem", "2xl": "1rem" }}
            fontWeight={"normal"}
            isTruncated
            maxW={"76px"}
          >
            {children}
          </Text> */}
        </VStack>
      </Box>
    </RouterLink>
  );
};

interface MobileProps extends FlexProps {
  onOpen: () => void;
  newNotification?: boolean;
  notificationCount?: number;
}
const MobileNavBottom = ({ onOpen, ...rest }: MobileProps) => {
  return (
    <Flex
      ml={{ base: 0, md: 60, "2xl": 80 }}
      // px={{ base: 4, md: 24 }}
      p="0"
      height="16"
      alignItems="center"
      bg={useColorModeValue("blue-light", "gray.900")}
      borderBottomColor={useColorModeValue("gray.200", "gray.700")}
      justifyContent="flex-start"
      {...rest}
      position="fixed"
      bottom={"0px"}
      w="full"
      zIndex={20}
      // borderTop="1px solid"
      borderColor="light-blue-light"
      borderTopLeftRadius={"1rem"}
      borderTopRightRadius={"1rem"}
      // overflow="hidden"
    >
      {/* <HStack align={"stretch"} h="full" spacing={0}> */}
      <ButtonGroup
        gap={0}
        spacing={0}
        flex={1}
        width="full"
        h="full"
        sx={{
          "& button:last-child": { borderRight: "none" },
        }}
      >
        {LinkItems.map((link) => (
          <NavItemMobile key={link.name} icon={link.icon} href={link.href}>
            {link.name}
          </NavItemMobile>
          // <Button
          //   flex={1}
          //   // flexGrow={1}
          //   rounded="0"
          //   borderRight={"1px solid"}
          //   borderColor="light-blue-light"
          //   whiteSpace="pre-wrap"
          //   wordBreak={"break-all"}
          //   fontSize="sm"
          //   p="2"
          //   h="full"
          //   bg="white"
          //   fontWeight={"medium"}
          // >
          //   {link.name}
          // </Button>
        ))}
      </ButtonGroup>
      {/* </HStack> */}
      {/* <IconButton
        variant="outline"
        onClick={onOpen}
        aria-label="open menu"
        icon={<HomeIcon />}
      />
      <Text fontSize="2xl" ml="8" fontFamily="monospace" fontWeight="bold">
        Logo
      </Text> */}
    </Flex>
  );
};

const MobileNavTop = ({
  onOpen,
  newNotification,
  notificationCount,
  ...rest
}: MobileProps) => {
  const { data: auth } = useAuthProvider();
  const { location, push } = useHistory();
  const queryCache = new QueryCache();

  const logout = () => {
    localStorage.removeItem("access_token");
    queryCache.clear();
    push("/connexion");
  };

  return (
    <>
      <Flex
        ml={{ base: 0, md: 60, "2xl": 80 }}
        px={{ base: 6, md: 24 }}
        // p="0"
        height="16"
        alignItems="center"
        bg={useColorModeValue("white", "gray.900")}
        borderBottom="1px solid"
        borderBottomColor={useColorModeValue("gray.200", "gray.700")}
        // justifyContent="flex-start"
        justifyContent="space-between"
        position={"sticky"}
        top="0px"
        zIndex={2}
        {...rest}
      >
        <Image src={logo} alt="ECN+" />
        {/* <Text
        fontSize="2xl"
        // ml="8"
        fontFamily="monospace"
        fontWeight="bold"
      >
        Logo
      </Text> */}
        <HStack align={"center"}>
          <SearchDrawer />
          {/* <Button
          variant={"ghost"}
          bg="light-blue-light"
          px="0"
          _hover={{}}
          _active={{}}
        >
          <RiSearch2Line style={{ color: "#4B62F0" }} size="1.3rem" />
        </Button> */}
          <Button
            variant={"ghost"}
            px="0"
            _hover={{}}
            _active={{}}
            position="relative"
            onClick={() => push("/notifications")}
            bg={
              location.pathname.includes("/notifications")
                ? "#4B62F0"
                : "light-blue-light"
            }
          >
            <BellIcon
              color={
                location.pathname.includes("/notifications")
                  ? "white"
                  : "#4B62F0"
              }
            />
            {(newNotification || notificationCount !== 0) && (
              <Badge
                bg="red"
                rounded={"full"}
                position={"absolute"}
                top="1"
                right={"1"}
                color="white"
                fontSize={"xs"}
              >
                {notificationCount}
              </Badge>
            )}
          </Button>
          <Menu offset={[0, 13]}>
            <MenuButton
              as={Button}
              px="2"
              _active={{ bg: "blue-light", "& *": { fill: "white" } }}
              css={{
                "& *": {
                  fill: location.pathname.includes("/profile")
                    ? "white"
                    : "#4B62F0",
                },
              }}
              bg={
                location.pathname.includes("/profile")
                  ? "#4B62F0"
                  : "light-blue-light"
              }
              // bg={
              //   location.pathname.includes("/profile")
              //     ? "light-blue-light"
              //     : "light-blue-light"
              // }
              color={"black"}
              _focus={{}}
              _hover={{ bg: "light-blue-light" }}
            >
              <Box position="relative">
                <Icon as={UserOctagon} />
                {(!auth?.current_order ||
                  (auth?.current_order && auth?.days_left <= 10) ||
                  auth?.days_left <= 0) && <Dot />}
              </Box>
            </MenuButton>
            <MenuList bg="light-blue-light" border="none" boxShadow={"xl"}>
              <RouterLink to="/profile">
                <MenuItem
                  icon={<UserIcon color="#4B62F0" />}
                  _hover={{ bg: "" }}
                  _focus={{ bg: "" }}
                  color="blue-light"
                  isTruncated
                  maxW={"160px"}
                >
                  {/* // TODO: for loooong name */}
                  {auth?.fullname?.split(" ")[0]?.slice(0, 1) +
                    "." +
                    auth?.fullname?.split(" ")[1] || "Nom et prénom"}
                </MenuItem>
              </RouterLink>
              <a
                href={"https://www.ecnplus.tn#abonnements"}
                target="_blank"
                rel="noopener noreferrer"
              >
                <MenuItem icon={<Shop color="#4B62F0" />}>
                  <HStack align="center" color="blue-light">
                    <Text>Abonnements</Text> <HiOutlineExternalLink />
                  </HStack>
                </MenuItem>
              </a>
              <MenuDivider borderColor={"gray.300"} />
              <MenuItem
                icon={<LogoutIcon />}
                color="blue-light"
                _hover={{ bg: "" }}
                _focus={{ bg: "" }}
                onClick={logout}
              >
                Déconnexion
              </MenuItem>
            </MenuList>
          </Menu>
        </HStack>
      </Flex>
      {auth?.current_order &&
        (auth?.days_left <= 10 || auth?.days_left <= 0) && (
          <Box
            rounded="8px"
            py="4"
            mx="6"
            display={{ base: "block", md: "none" }}
          >
            <Heading color="white" fontSize={"md"}>
              Votre abonnement{" "}
              <Heading as="span" color="gold-yellow" fontSize={"md"}>
                {auth?.days_left > 0
                  ? `finira dans ${auth?.days_left} jour${
                      auth?.days_left > 1 ? "s" : ""
                    }.`
                  : "est expiré."}
              </Heading>
            </Heading>
          </Box>
        )}
      {!auth?.current_order && (
        <Box
          rounded="8px"
          py="4"
          mx="6"
          display={{ base: "block", md: "none" }}
        >
          <Heading color="white" fontSize={"md"}>
            Abonnez-vous pour voir toutes{" "}
            <Heading as="span" color="gold-yellow" fontSize={"md"}>
              nos séries et examens
            </Heading>
          </Heading>
        </Box>
      )}
    </>
  );
};

const SearchDrawer = () => {
  const btnRef = useRef<any>();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const history = useHistory();
  const queryParams = useQueryParams();

  const [keyword, setKeyword] = useState("");
  const [questionOptions, setQuestionOptions] = useState([]);
  const [subjectOptions, setSubjectOptions] = useState([]);
  const [inputFocused, setInputFocused] = useState(false);
  const { mutate, isLoading } = useMutation(search, {
    onSuccess: (res: any) => {
      setQuestionOptions(res.questions);
      setSubjectOptions(res.subjects);
    },
    onError: (err: any) => {
      if (err.response.status === 404) {
        setSubjectOptions([]);
        setQuestionOptions([]);
      }
    },
  });

  const handleSearch = (event: any) => {
    setInputFocused(true);
    setKeyword(event.target.value);
    mutate(event.target.value);
  };

  return (
    <>
      <Button
        ref={btnRef}
        onClick={onOpen}
        variant={"ghost"}
        bg="light-blue-light"
        px="0"
        _hover={{}}
        _active={{}}
      >
        <RiSearch2Line style={{ color: "#4B62F0" }} size="1.3rem" />
      </Button>
      <Drawer
        isOpen={isOpen}
        placement="top"
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent height="calc(100% - 60px)">
          <DrawerBody py="3">
            <Box>
              <InputGroup
                position={"sticky"}
                top="0px"
                bg="white"
                boxShadow={"2xl"}
                border="1px solid #3182ce"
                width="100%"
                rounded={"lg"}
              >
                <InputLeftElement
                  pointerEvents="none"
                  children={
                    isLoading ? (
                      <Spinner color="blue-light" size={"sm"} />
                    ) : (
                      <RiSearch2Line
                        style={{ color: "#4B62F0" }}
                        size="1.3rem"
                      />
                    )
                  }
                />
                <Input
                  type="search"
                  placeholder="Numéro / Nom du sujet"
                  rounded={"lg"}
                  bg="light-blue-light"
                  border="none"
                  _placeholder={{ fontWeight: "light" }}
                  _focus={{}}
                  onChange={debounce(handleSearch, 1000)}
                  defaultValue={queryParams.get("query") ?? ""}
                />
              </InputGroup>
              {(questionOptions.length !== 0 || subjectOptions.length !== 0) &&
                inputFocused && (
                  <Box
                    width={"100%"}
                    minHeight="100%"
                    bg="light-blue-light"
                    rounded="xl"
                    p="2"
                    zIndex={3}
                    onMouseEnter={() => setInputFocused(true)}
                    onMouseLeave={() => setInputFocused(false)}
                    mt="5"
                  >
                    {questionOptions.length !== 0 && (
                      <Fragment>
                        <Text fontWeight={"bold"} color="gray.500" mb="0">
                          Questions:
                        </Text>
                        {questionOptions.map((option: any) => (
                          <VStack
                            key={option._id}
                            align={"flex-start"}
                            spacing="0"
                            _hover={{
                              bg: "blue-light",
                              color: "white !important",
                              "& .subtitle": {
                                color: "whiteAlpha.800",
                              },
                            }}
                            rounded="xl"
                            p="2"
                            cursor={"pointer"}
                            onClick={() =>
                              history.push(
                                `/question/${option?._id}`
                              )
                            }
                          >
                            <Text>
                              {option.value.blocks[0]?.data?.text
                                ?.replace(/<[^>]+>/g, "")
                                ?.slice(0, 30) || "" + "..."}
                            </Text>
                            <Text fontSize={"small"}>code: {option.code}</Text>
                            <Text
                              fontSize={"small"}
                              color="blue-light"
                              className="subtitle"
                            >
                              {option.qcm.speciality.name}/
                              {option.qcm.subject.name}/
                              {option.qcm.university.name}/{option.qcm.year}
                            </Text>
                          </VStack>
                        ))}
                      </Fragment>
                    )}
                    {subjectOptions.length !== 0 && (
                      <Fragment>
                        <Text fontWeight={"bold"} color="gray.500" mb="0">
                          Sujets:
                        </Text>
                        {subjectOptions.map((option: any) => (
                          <VStack
                            key={option._id}
                            align={"flex-start"}
                            spacing="0"
                            _hover={{
                              bg: "blue-light",
                              color: "white !important",
                            }}
                            rounded="xl"
                            p="2"
                            cursor={"pointer"}
                            onClick={() =>
                              history.push(
                                `/series/${option?.speciality?.name}/${option?.name}`
                              )
                            }
                          >
                            <Text>{option.name}</Text>
                            <Text fontSize={"small"}>code: {option.code}</Text>
                          </VStack>
                        ))}
                      </Fragment>
                    )}
                  </Box>
                )}
              {questionOptions.length === 0 &&
                subjectOptions.length === 0 &&
                keyword !== "" &&
                inputFocused && (
                  <Box
                    width={"100%"}
                    minHeight="100%"
                    bg="light-blue-light"
                    rounded="xl"
                    p="2"
                    zIndex={3}
                    maxH="400px"
                    overflowY={"auto"}
                    mt="5"
                  >
                    <Text textAlign={"center"} color="gray.500" py="2">
                      Aucun résultat trouvé.
                    </Text>
                  </Box>
                )}
            </Box>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};
