import { Button, Grid, GridItem, Heading } from "@chakra-ui/react";
import { AiFillCheckCircle } from "react-icons/ai";
import { useQuery } from "react-query";
import { useHistory, useLocation } from "react-router-dom";
import { verifyEmail } from "../../../services/auth/services";

export default function VerifyEmail() {
  const { search } = useLocation();
  const { push } = useHistory()

  function useQueryParams() {
    return new URLSearchParams(search);
  }
  let queryParams: any = useQueryParams();

  const { isLoading, data, isSuccess, remove, isError, error } = useQuery(["user"], () => verifyEmail(queryParams.get("token")), {
    refetchOnWindowFocus: false,
    // enabled: Boolean(queryParams.get("token")),
    retry: false,
  })

  return (
    <Grid placeItems={"center"} className="full-height">
      <GridItem textAlign={"center"}>
        <AiFillCheckCircle color="green" size={"100px"} style={{textAlign: "center", margin: "auto", marginBottom: "30px"}}/>
        <Heading textAlign={"center"}>Compte vérifié!</Heading>
        <Button mt="4" variant={"primary"} onClick={() => push("/tableau-de-bord")}>Commencer!</Button>
      </GridItem>
    </Grid>
  );
}
