import axiosInstance from "../../utils/axiosInstance";

export const reportQuestion = async ({message, question, comment}: {message: string, question: string, comment?: string}) => {
    let token = localStorage.getItem("access_token");
    return await axiosInstance({
      method: "post",
      url: `/report/create`,
      data:{
        message,
        question,
        comment
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((res: any) => res.data);
  };