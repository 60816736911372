export default function ChronoIcon({bg="#fff", size="25"}:{bg?:string, size?:string}) {
  return (
    <svg
      id="timer-1"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
    >
      <rect
        id="Rectangle_891"
        data-name="Rectangle 891"
        width="40"
        height="40"
        rx="5"
        fill={bg}
        opacity="0"
      />
      <g
        id="Group_1134"
        data-name="Group 1134"
        transform="translate(3.45 2.325)"
      >
        <path
          id="Path_130"
          data-name="Path 130"
          d="M10.8,20.475a8.55,8.55,0,1,1,8.55-8.55A8.558,8.558,0,0,1,10.8,20.475Zm0-15.75a7.2,7.2,0,1,0,7.2,7.2A7.21,7.21,0,0,0,10.8,4.725Z"
          transform="translate(-2.25 -1.125)"
          fill={bg}
        />
        <path
          id="Path_131"
          data-name="Path 131"
          d="M10.8,12.375a.68.68,0,0,1-.675-.675V7.2a.675.675,0,1,1,1.35,0v4.5A.68.68,0,0,1,10.8,12.375Z"
          transform="translate(-2.25 -1.125)"
          fill={bg}
        />
        <path
          id="Path_132"
          data-name="Path 132"
          d="M13.5,2.475H8.1a.675.675,0,0,1,0-1.35h5.4a.675.675,0,0,1,0,1.35Z"
          transform="translate(-2.25 -1.125)"
          fill={bg}
        />
      </g>
    </svg>
  );
}
