import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import Home from "../../pages/Unauthorized/Dashboard/Home";
import { useAuthProvider } from "../../auth-provider/AuthProvider";

export default function DashboardContainer() {
  const { data: auth } = useAuthProvider();
  const { path, url } = useRouteMatch();

  if(!auth?.email_verified){
    return <Redirect to="/confirmation-email" exact/>
  }
  
  return (
    <Switch>
      <Route path={`${path}`}>
        <Home />
      </Route>
      <Route>
        <h1>Page Not Found</h1>
      </Route>
    </Switch>
  );
}
