import AuthLayout from "../../../layouts/AuthLayout";
import onboarding_1 from "../../../assets/img/onboarding_1.png";
import onboarding_2 from "../../../assets/img/onboarding_2.png";
import onboarding_3 from "../../../assets/img/onboarding_3.png";
import { Box, Flex, Fade } from "@chakra-ui/react";
import HeadingWithLines from "../../../components/Heading/HeadingWithLines";
import { FormProvider, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import OnboardingFirstStep from "./steps/OnboardingFirstStep";
import OnboardingSecStep from "./steps/OnboardingSecStep";
import OnboardingLastStep from "./steps/OnboardingLastStep";
import { saveExtraInfo } from "../../../services/auth/services";
import { useMutation, useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { useAuthProvider } from "../../../auth-provider/AuthProvider";
import { getUniversities } from "../../../services/university/services";
import { getStatus } from "../../../services/status/services";
import { getPromotion } from "../../../services/promotion/services";
import { IonBoarding } from "../../../types";
import { getSpecialities } from "../../../services/specialities/services";

export default function Onboarding() {
  const [step, setStep] = useState(1);
  const history = useHistory();
  const { data: auth } = useAuthProvider();
  const methods = useForm<any>({
    defaultValues: {
      university: "",
      status: "",
      promotion: "",
      speciality_to_learn: "",
      heard_about_us: "",
    },
  });
  const universitiesQuery = useQuery("universities", getUniversities, {
    refetchOnWindowFocus: false,
  });
  const statusQuery = useQuery("status", getStatus, {
    refetchOnWindowFocus: false,
  });
  const promotionsQuery = useQuery("promotions", getPromotion, {
    refetchOnWindowFocus: false,
  });
  const specialitiesQuery = useQuery("specialities", getSpecialities, {
    refetchOnWindowFocus: false,
  });

  const { mutateAsync, isLoading } = useMutation(saveExtraInfo, {
    onSuccess: () => history.push("/tableau-de-bord"),
  });

  useEffect(() => {
    if (auth?.first_login === false) {
      history.push("/tableau-de-bord");
    }
  }, [auth?.first_login]);

  const onSubmit = (data: any) => {
    mutateAsync(data);
  };

  return (
    <AuthLayout
      illustration={
        step === 1 ? onboarding_1 : step === 2 ? onboarding_2 : onboarding_3
      }
    >
      <Flex flexGrow={1} direction={"column"} justify={"center"}>
        {step !== 2 && (
          <HeadingWithLines position="middle">
            A propos de vous
          </HeadingWithLines>
        )}
        {universitiesQuery.isLoading ||
        statusQuery.isLoading ||
        specialitiesQuery.isLoading ||
        promotionsQuery.isLoading ? (
          <h1>Loading...</h1>
        ) : (
          <FormProvider {...methods}>
            <Box as="form" mt="4" onSubmit={methods.handleSubmit(onSubmit)}>
            <Fade in={true} transition={{enter: {duration: 0.25}, exit:{duration: 0.25}}} key={step}>
              <OnBoardingSteps
                setStep={setStep}
                step={step}
                methods={methods}
                isLoading={isLoading}
                universities={universitiesQuery?.data}
                status={statusQuery?.data}
                promotions={promotionsQuery?.data}
                specialities={specialitiesQuery?.data}
              />
              </Fade>
            </Box>
          </FormProvider>
        )}
      </Flex>
    </AuthLayout>
  );
}

const OnBoardingSteps = ({
  step,
  setStep,
  methods,
  isLoading,
  universities,
  status,
  promotions,
  specialities
}: IonBoarding) => {
  switch (step) {
    case 1:
      return (
        <OnboardingFirstStep
          setStep={setStep}
          methods={methods}
          universities={universities}
          status={status}
          promotions={promotions}
          specialities={specialities}
        />
      );
    // case 2:
    //   return <OnboardingSecStep setStep={setStep} methods={methods} specialities={specialities}/>;
    default:
      return <OnboardingLastStep isLoading={isLoading} />;
  }
};
