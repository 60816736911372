import { Grid, GridItem, Heading, HStack, Stack } from "@chakra-ui/react";
import { useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import BreadCrumb from "../../../components/BreadCrumb/BreadCrumb";
import NotAvailable from "../../../components/ErrorBanner/NotAvailable";
import LoadingExams from "../../../components/LoadingExams/LoadingExams";
import SeriesFilterButtons from "../../../components/SeriesFilterButtons/SeriesFilterButtons";
import SidebarLayout from "../../../layouts/SidebarLayout";
import { getExamByUniversity, getQuestionssCounts, getResultsByDay } from "../../../services/qcm/services";
import NotFound from "../../Authorized/Notfound/Notfound";
import { ExamBox } from "./Exams";

export default function UniversityExams({ base_url }: { base_url: string }) {
  const [statsFormat, setStatsFormat] = useState<"average" | "progress">(
    "progress"
  );
  const { universityName, year } = useParams<{
    universityName: string;
    year: string;
  }>();
  const breadcrumb = [
    {
      title: "Examen",
      link: "/examen-blanc",
      isCurrent: false,
    },
    {
      title: "Épreuve",
      link: `#`,
      isCurrent: false,
    },
  ];

  const { data: exams, isLoading: examsLoading, isError } = useQuery(
    `exams-${universityName}-${year}`,
    () => getExamByUniversity({ universityName, year }),
    { staleTime: 60000 * 60 }
  );
  const { data: examsStats, isLoading: examsStatsLoading, isError: errorStats } = useQuery(
    `exams-stats-${universityName}-${year}`,
    () => getResultsByDay({ universityName, year }),
    { staleTime: 60000 * 15 }
  );

  const { data: examQuestionsCount, isLoading: examQuestionsCountLoading, isError: errorexamQuestionsCount } = useQuery(
    `exams-count-${universityName}-${year}`,
    () => getQuestionssCounts({ universityName, year }),
    { staleTime: 60000 * 60 }
  );




  return (
    <SidebarLayout>
      <HStack wrap="wrap" justify={"space-between"}>
        <BreadCrumb items={breadcrumb} />
        <SeriesFilterButtons
          setStatsFormat={setStatsFormat}
          statsFormat={statsFormat}
          withFilter={false}
        />
      </HStack>
      <Stack spacing={8} mt="8">
        {examsLoading || examsStatsLoading ? (
          Array.apply(null, Array(1)).map((el: any, index: number) => (
            <LoadingExams key={index} />
          ))
        ) : isError ? (
            <NotFound />
        ) : exams?.length === 0 ? <NotAvailable /> : (
          <ExamsList exams={exams[0]} statsFormat={statsFormat} examsStats={examsStats} examQuestionsCountLoading={examQuestionsCountLoading} examQuestionsCount={examQuestionsCount}/>
        )}
      </Stack>
    </SidebarLayout>
  );
}

const ExamsList = ({ exams, statsFormat, examsStats,examQuestionsCount,examQuestionsCountLoading }: any) => {

  return (
    <Stack spacing={5}>
      <Heading color="blue-light" fontSize={"25px"}>
        {exams?._id}
      </Heading>
      {/* <Stack spacing={30} direction={{base: "column", md: "row"}}> */}
        <Grid gridTemplateColumns={{base: "1fr", md: "1fr 1fr 1fr", "2xl": "1fr 1fr 1fr 1fr"}} gap={{base: "20px", lg: "20px", "2xl": "30px"}}>
        {exams?.data.map((exam: any) => (
          <GridItem key={exam._id}>
          <ExamBox
            university={{ name: "Jour " + exam.jour }}
            statsFormat={statsFormat}
            link={`/examen-blanc/${exam.year}/${exam.university.name}/${exam._id}`}
            examStats={examsStats.find((el: any) => el.subject === exam.jour)}
            examQuestionsCount={{exams: examQuestionsCount?.find((el: any) => el.jour === exam.jour)?.questionsCount}}
            examQuestionsCountLoading={examQuestionsCountLoading}
            isProtected
            />
            </GridItem>
        ))}

        </Grid>
      {/* </Stack> */}
    </Stack>
  );
};
