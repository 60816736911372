import {
  Divider,
  Grid,
  HStack,
  IconButton,
  Skeleton,
  Tooltip,
} from "@chakra-ui/react";
import { useCallback, useState } from "react";
import { useQuery } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import AnswersIcon from "../../../assets/icons/AnswersIcon";
import FilterIcon from "../../../assets/icons/FilterIcon";
import PercentageIcon from "../../../assets/icons/PercentageIcon";
import BreadCrumb from "../../../components/BreadCrumb/BreadCrumb";
import FolderBox from "../../../components/FolderBox/FolderBox";
import SeriesFilterButtons from "../../../components/SeriesFilterButtons/SeriesFilterButtons";
import SidebarLayout from "../../../layouts/SidebarLayout";
import {
  getAttemptsStatsBySpeciality,
  getSubjectsBySpecialityName,
} from "../../../services/series/services";
import capitalizeText from "../../../utils/capitalizeText";

type SerieStat = {
  subject: string;
  answersCount: number;
  result: {
    overall: number;
    correct: number;
    fausse: number;
    partiel: number;
  };
};

export default function SpecialitiesSubjects() {
  const [statsFormat, setStatsFormat] = useState<"average" | "progress">(
    "progress"
  );
  const { specialityName } = useParams<{ specialityName: string }>();
  const { data: subjects, isLoading: isLoadingSubjects } = useQuery(
    `subjects-${specialityName}`,
    () => getSubjectsBySpecialityName(specialityName),
    { refetchOnWindowFocus: false, staleTime: 60000 * 60 }
  );

  const { data: seriesStats, isLoading: seriesStatsIsLoading } = useQuery(
    `series-stats-${specialityName}`,
    () => getAttemptsStatsBySpeciality(specialityName),
    {
      refetchOnWindowFocus: false,
      staleTime: 60000 * 15
    }
  );

  const stats = useCallback(
    (subjectName: string) => {
      if (seriesStats) {
        return seriesStats.find(
          (stat: SerieStat) => stat.subject === subjectName
        );
      }
    },
    [seriesStats]
  );

  const history = useHistory();

  const breadcrumb = [
    {
      title: "Série",
      link: "/series",
      isCurrent: false,
    },
    {
      title: capitalizeText(specialityName),
      link: `/series/${specialityName}`,
      isCurrent: true,
    },
  ];

  return (
    <SidebarLayout>
      <HStack justify={"space-between"}>
        <BreadCrumb
          items={breadcrumb}
          isArchived={!isLoadingSubjects && subjects[0]?.speciality?.archived}
        />
        <SeriesFilterButtons
          setStatsFormat={setStatsFormat}
          statsFormat={statsFormat}
        />
        {/* <HStack>
          <Tooltip label="Afficher moyenne" hasArrow>
            <IconButton
              aria-label="afficher moyenne"
              icon={<PercentageIcon size="24" />}
              bg="lightGrey"
            />
          </Tooltip>
          <Tooltip label="Afficher l'avancement" hasArrow>
            <IconButton
              aria-label="afficher l'avancement"
              icon={<AnswersIcon />}
              bg="lightGrey"
            />
          </Tooltip>
          <Divider
            orientation="vertical"
            h="20px"
            borderWidth={"2px"}
            rounded="xl"
          />
          <Tooltip label="Filtrer" hasArrow>
            <IconButton
              aria-label="filtrer"
              icon={<FilterIcon />}
              bg="lightGrey"
            />
          </Tooltip>
        </HStack> */}
      </HStack>
      <Grid
        gridTemplateColumns={"repeat(auto-fill, minmax(300px, 1fr))"}
        justifyItems="center"
        gap={{base: "20px", lg: "20px", "2xl": "30px"}}
        my="4"
      >
        {isLoadingSubjects
          ? Array.apply(null, Array(12)).map((el: any, index: number) => (
              <Skeleton
                rounded={"0.8rem"}
                w="full"
                h="36"
                key={index}
                startColor="darkGrey"
                endColor={"lightGrey"}
              />
            ))
          : subjects.map((subject: any) => (
              <FolderBox
                goTo={() =>
                  history.push(`${history.location.pathname}/${subject.name}`)
                }
                item={subject}
                key={subject._id}
                itemType="subject"
                stats={stats}
                statsFormat={statsFormat}
              />
            ))}
      </Grid>
    </SidebarLayout>
  );
}
