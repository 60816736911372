import {
  useRadio,
  Box,
  useRadioGroup,
  HStack,
  Stack,
} from "@chakra-ui/react";
import {
  HiOutlineDocumentDuplicate,
  HiOutlineDocumentText,
} from "react-icons/hi";
import { BsListCheck } from "react-icons/bs";

function RadioCard({ icon, ...rest }: any) {
  const { getInputProps, getCheckboxProps } = useRadio(rest);

  const input: any = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box as="label" flex={1}>
      <input {...input}/>
      <Stack
        {...checkbox}
        cursor="pointer"
        borderWidth="1px"
        borderRadius="1rem"
        _checked={{
          bg: "blue-light",
          color: "white",
          borderColor: "blue-light",
        }}
        _focus={{
          boxShadow: "none",
        }}
        _hover={{
            bg: input?.checked ? "blue-light" : "light-blue-light"
        }}
        px={5}
        py={3}
        textAlign="center"
        align={"center"}
        direction={{base: "row", md: "column"}}
        h="full"
        transition={"0.3s"}
      >
        {icon}
        <Box>{rest.children}</Box>
      </Stack>
    </Box>
  );
}

function ReportErrorGroup({onChange}:{onChange: (reason: string) => void}) {
  const reports = [
    {
      icon: <HiOutlineDocumentText fontSize={"1.5rem"}/>,
      title: "Explication",
    },
    {
      icon: <HiOutlineDocumentText fontSize={"1.5rem"}/>,
      title: "L'énoncé",
    },
    {
      icon: <HiOutlineDocumentDuplicate fontSize={"1.5rem"}/>,
      title: "La question est dupliquée",
    },
    {
      icon: <BsListCheck fontSize={"1.5rem"}/>,
      title: "Les réponses proposées",
    },
  ];

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: "report",
    defaultValue: "",
    onChange
  });

  const group = getRootProps();

  return (
    <Stack direction={{ base: "column", lg: "row" }} {...group}>
      {reports.map(({ icon, title }) => {
        const radio = getRadioProps({ value: title });
        return (
          <RadioCard key={title} {...radio} icon={icon}>
            {title}
          </RadioCard>
        );
      })}
    </Stack>
  );
}

export default ReportErrorGroup;
