
export default function ProgressIcon({size="14"}:{size?:string}) {
  return (
    <svg
      id="routing"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
    >
      <rect
        id="Rectangle_86"
        data-name="Rectangle 86"
        width={size}
        height={size}
        rx="5"
        fill="#f9f9f9"
        opacity="0"
      />
      <g id="Group_149" data-name="Group 149" transform="translate(1.2 1.2)">
        <path
          id="Path_98"
          data-name="Path 98"
          d="M4.923,9.675a2.564,2.564,0,0,1-1.782-.711C1.485,7.389.819,5.67,1.206,3.987A3.716,3.716,0,0,1,4.923,1.125,3.719,3.719,0,0,1,8.64,3.987c.387,1.683-.288,3.4-1.935,4.977a2.549,2.549,0,0,1-1.782.711ZM2.52,4.293c-.342,1.5.657,2.835,1.557,3.69a1.231,1.231,0,0,0,1.7,0c.9-.855,1.9-2.187,1.548-3.7A2.361,2.361,0,0,0,4.932,2.475,2.379,2.379,0,0,0,2.52,4.293Z"
          fill="#292d32"
        />
        <path
          id="Path_99"
          data-name="Path 99"
          d="M4.959,5.58a.9.9,0,1,1,0-1.8h.009a.9.9,0,1,1-.009,1.8Z"
          fill="#292d32"
        />
        <path
          id="Path_100"
          data-name="Path 100"
          d="M16.641,20.475a2.6,2.6,0,0,1-1.791-.711c-1.665-1.575-2.34-3.294-1.944-4.986a3.738,3.738,0,0,1,3.735-2.862,3.738,3.738,0,0,1,3.735,2.862c.387,1.683-.288,3.411-1.953,4.977a2.519,2.519,0,0,1-1.782.72Zm0-7.2a2.388,2.388,0,0,0-2.421,1.818c-.351,1.5.657,2.844,1.557,3.7a1.245,1.245,0,0,0,1.719,0c.9-.855,1.908-2.187,1.557-3.69a2.359,2.359,0,0,0-2.412-1.827Z"
          fill="#292d32"
        />
        <path
          id="Path_101"
          data-name="Path 101"
          d="M16.677,16.38a.9.9,0,0,1,0-1.8h.009a.9.9,0,1,1-.009,1.8Z"
          fill="#292d32"
        />
        <path
          id="Path_102"
          data-name="Path 102"
          d="M10.8,17.775H8.388a2.476,2.476,0,0,1-1.629-4.338L13.95,7.146a1.123,1.123,0,0,0-.738-1.971H10.8a.675.675,0,0,1,0-1.35h2.412a2.476,2.476,0,0,1,1.629,4.338L7.65,14.454a1.123,1.123,0,0,0,.738,1.971H10.8a.675.675,0,1,1,0,1.35Z"
          fill="#292d32"
        />
        <path
          id="Path_103"
          data-name="Path 103"
          d="M10.8,17.775H8.388a2.476,2.476,0,0,1-1.629-4.338L13.95,7.146a1.123,1.123,0,0,0-.738-1.971H10.8a.675.675,0,0,1,0-1.35h2.412a2.476,2.476,0,0,1,1.629,4.338L7.65,14.454a1.123,1.123,0,0,0,.738,1.971H10.8a.675.675,0,1,1,0,1.35Z"
          fill="#292d32"
        />
      </g>
    </svg>
  );
}
