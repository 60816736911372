import { useBreakpointValue } from "@chakra-ui/react";

export default function DayOneIcon({color="#7fcacf", size="lg"}:{color?:string, size?:string}) {
  const lgIconSize = useBreakpointValue({base: "40", "2xl": "48"})
  const sizePx = size === "lg" ? lgIconSize : "20";
  
  return (
    <svg
      id="Group_1327"
      data-name="Group 1327"
      xmlns="http://www.w3.org/2000/svg"
      width={sizePx}
      height={sizePx}
      viewBox="0 0 35.1 38.7"
    >
      <path
        id="Path_274"
        data-name="Path 274"
        d="M7.875,9.225a1.36,1.36,0,0,1-1.35-1.35v-5.4a1.35,1.35,0,0,1,2.7,0v5.4A1.36,1.36,0,0,1,7.875,9.225Z"
        transform="translate(2.475 -1.125)"
        fill={color}
      />
      <path
        id="Path_275"
        data-name="Path 275"
        d="M15.075,9.225a1.36,1.36,0,0,1-1.35-1.35v-5.4a1.35,1.35,0,0,1,2.7,0v5.4A1.36,1.36,0,0,1,15.075,9.225Z"
        transform="translate(9.675 -1.125)"
        fill={color}
      />
      <path
        id="Path_276"
        data-name="Path 276"
        d="M24.975,38.475h-10.8c-10.116,0-12.15-4.77-12.15-12.474V14.895c0-8.532,2.88-12.006,10.278-12.42H26.847c7.4.414,10.278,3.888,10.278,12.42V26C37.125,33.705,35.091,38.475,24.975,38.475Zm-12.6-33.3c-5.04.288-7.65,1.872-7.65,9.72V26c0,6.894,1.314,9.774,9.45,9.774h10.8c8.136,0,9.45-2.88,9.45-9.774V14.895c0-7.83-2.592-9.432-7.686-9.72Z"
        transform="translate(-2.025 0.225)"
        fill={color}
      />
      <path
        id="Path_277"
        data-name="Path 277"
        d="M35.1,17.865H3.6a1.35,1.35,0,1,1,0-2.7H35.1a1.35,1.35,0,1,1,0,2.7Z"
        transform="translate(-1.8 12.915)"
        fill={color}
      />
      <path
        id="Path_285"
        data-name="Path 285"
        d="M7.248,4.016q.96,0,.96.816,0,.848-.96.848H1.84q-.976,0-.976-.848,0-.816.976-.816H3.5v-7.3l-1.536.944a.827.827,0,0,1-.4.128A.7.7,0,0,1,1-2.5a1,1,0,0,1-.232-.64.8.8,0,0,1,.416-.72l2.48-1.536a1.782,1.782,0,0,1,.912-.288.973.973,0,0,1,.72.272,1.016,1.016,0,0,1,.272.752V4.016Z"
        transform="translate(13.062 17.67)"
        fill={color}
      />
    </svg>
  );
}
