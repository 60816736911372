import { Box, HStack, Stack, Text, VStack } from "@chakra-ui/react";
import { ISmallCard } from "../../types";
import { secondsToHMS } from "../../utils/timerConverter";

export default function SmallCard({
  isLoading,
  icon,
  title,
  value,
  isGradient,
  flexGrow,
  unit,
  secondValue,
  avgTime,
}: ISmallCard) {
  return (
    <Stack
      bg={"lightGrey"}
      rounded="1rem"
      p="6"
      // bgGradient={isGradient ? "linear(to-r, #F5F5FD, #F4F4F4)" : "none"}
      flexGrow={flexGrow}
      h="24"
      justify={"center"}
    >
      <HStack spacing={2} align="center">
        {icon}
        <VStack align="flex-start" spacing={0}>
          <Text color={"gray.500"} fontSize="lg">
            {title}
          </Text>
          <HStack>
            <HStack spacing={0}>
              {avgTime ? (
                <Text>
                  {secondsToHMS(value).hours
                    ? <Text as="span" fontWeight={"bold"}>{secondsToHMS(value).hours}h </Text>
                    : ""}
                  {secondsToHMS(value).minutes
                    ? <Text as="span" fontWeight={"bold"}>{secondsToHMS(value).minutes}min </Text>
                    : ""}
                  <Text as="span" fontWeight={"bold"}>{Math.floor(secondsToHMS(value).seconds)}sec</Text>
                </Text>
              ) : (
                <>
                  <Text fontWeight={"bold"}>{value}</Text>
                  <Text fontWeight={"bold"}>{unit}</Text>
                </>
              )}
            </HStack>
            {secondValue && <Text>{secondValue}</Text>}
          </HStack>
        </VStack>
      </HStack>
    </Stack>
  );
}
