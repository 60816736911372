import { useColorModeValue } from "@chakra-ui/react";

export default function UniversityIcon({isCheked = false}:{isCheked?: boolean}) {
  const color = useColorModeValue("#292d32", "white")
  return (
    <svg
      id="building-4"
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <rect
        id="Rectangle_1008"
        data-name="Rectangle 1008"
        width="16"
        height="16"
        rx="5"
        fill={isCheked ? "#fff" : color}
        opacity="0"
      />
      <g
        id="Group_1168"
        data-name="Group 1168"
        transform="translate(0.95 2.156)"
      >
        <path
          id="Path_153"
          data-name="Path 153"
          d="M13.875,20.025H.675a.45.45,0,0,1,0-.9h13.2a.45.45,0,1,1,0,.9Z"
          transform="translate(-0.225 -7.731)"
          fill={isCheked ? "#fff" : color}
        />
        <path
          id="Path_154"
          data-name="Path 154"
          d="M17.577,18.7a.453.453,0,0,1-.45-.45V15.57a.45.45,0,1,1,.9,0v2.676a.449.449,0,0,1-.45.45Z"
          transform="translate(-5.859 -6.396)"
          fill={isCheked ? "#fff" : color}
        />
        <path
          id="Path_155"
          data-name="Path 155"
          d="M16.935,14.019a1.769,1.769,0,0,1-1.77-1.77V10.887a1.77,1.77,0,0,1,3.54,0v1.362a1.769,1.769,0,0,1-1.77,1.77Zm0-4a.871.871,0,0,0-.87.87v1.362a.871.871,0,0,0,1.74,0V10.893a.871.871,0,0,0-.87-.87Z"
          transform="translate(-5.205 -4.395)"
          fill={isCheked ? "#fff" : color}
        />
        <path
          id="Path_156"
          data-name="Path 156"
          d="M8.982,14.328a.453.453,0,0,1-.45-.45V4.3c0-.954-.4-1.362-1.338-1.362H3.456c-.942,0-1.35.408-1.35,1.362v9.582a.45.45,0,1,1-.9,0V4.3a2.035,2.035,0,0,1,2.25-2.262H7.194A2.029,2.029,0,0,1,9.432,4.3v9.582A.453.453,0,0,1,8.982,14.328Z"
          transform="translate(-0.552 -2.034)"
          fill={isCheked ? "#fff" : color}
        />
        <path
          id="Path_157"
          data-name="Path 157"
          d="M7.965,7.65H5a.45.45,0,1,1,0-.9h2.97a.45.45,0,0,1,0,.9Z"
          transform="translate(-1.665 -3.606)"
          fill={isCheked ? "#fff" : color}
        />
        <path
          id="Path_158"
          data-name="Path 158"
          d="M7.965,11.025H5a.45.45,0,1,1,0-.9h2.97a.45.45,0,0,1,0,.9Z"
          transform="translate(-1.665 -4.731)"
          fill={isCheked ? "#fff" : color}
        />
        <path
          id="Path_159"
          data-name="Path 159"
          d="M7.2,18.9a.453.453,0,0,1-.45-.45V16.2a.45.45,0,1,1,.9,0v2.25A.453.453,0,0,1,7.2,18.9Z"
          transform="translate(-2.4 -6.606)"
          fill={isCheked ? "#fff" : color}
        />
      </g>
    </svg>
  );
}
