import { Stack, HStack, Skeleton, useBreakpointValue } from "@chakra-ui/react";

export default function LoadingExams({itemsCount=4}:{itemsCount?:number}) {
  return (
    <Stack spacing={5}>
      <HStack justify={"space-between"}>
        <Skeleton
          height="20px"
          width={"10vw"}
          minW="160px"
          startColor="darkGrey"
          endColor={"lightGrey"}
        />
        <HStack spacing={4}>
          <Skeleton
            height="20px"
            width={"20px"}
            startColor="darkGrey"
            endColor={"lightGrey"}
            rounded="full"
          />

          <Skeleton
            height="20px"
            width={"20px"}
            startColor="darkGrey"
            endColor={"lightGrey"}
            rounded="full"
          />
        </HStack>
      </HStack>
      <HStack spacing={8}>
        {Array.apply(null, Array(useBreakpointValue({base: 2, md: 3, lg: itemsCount}))).map((el: any, index: number) => (
          <Skeleton
            height="48"
            flex={1}
            startColor="darkGrey"
            endColor={"lightGrey"}
            rounded="xl"
            key={index}
          />
        ))}
      </HStack>
    </Stack>
  );
}
