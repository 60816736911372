export default function ViewIcon() {
    return (
        <svg id="eye-slash" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
  <rect id="Rectangle_1002" data-name="Rectangle 1002" width="24" height="24" rx="5" fill="#292d32" opacity="0"/>
  <g id="Group_1164" data-name="Group 1164" transform="translate(1.2 1.2)">
    <path id="Path_148" data-name="Path 148" d="M10.8,14.7a3.9,3.9,0,1,1,3.9-3.9A3.9,3.9,0,0,1,10.8,14.7Zm0-6.444A2.547,2.547,0,1,0,13.347,10.8,2.55,2.55,0,0,0,10.8,8.253Z" fill="#4b62f0"/>
    <path id="Path_149" data-name="Path 149" d="M10.8,18.918c-3.384,0-6.579-1.98-8.775-5.418a5.452,5.452,0,0,1,0-5.4c2.2-3.438,5.4-5.418,8.775-5.418s6.57,1.98,8.766,5.418a5.452,5.452,0,0,1,0,5.4C17.37,16.938,14.175,18.918,10.8,18.918Zm0-14.886c-2.907,0-5.688,1.746-7.632,4.8a4.09,4.09,0,0,0,0,3.942c1.944,3.051,4.725,4.8,7.632,4.8s5.688-1.746,7.632-4.8a4.09,4.09,0,0,0,0-3.942c-1.944-3.051-4.725-4.8-7.632-4.8Z" fill="#4b62f0"/>
  </g>
</svg>

    )
}
