import {
  TableContainer,
  Table,
  HStack,
  Text,
  Skeleton,
  Stack,
  Tooltip,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import { useCallback, useState } from "react";
import AnswerPartialSquareIcon from "../../assets/icons/AnswerPartialSquareIcon";
import AnswerRightSquareIcon from "../../assets/icons/AnswerRightSquareIcon";
import AnswersIcon from "../../assets/icons/AnswersIcon";
import CancelIcon from "../../assets/icons/CancelIcon";
import FolderClosedGreen from "../../assets/icons/FolderClosedGreen";
import FolderOpenedGreenIcon from "../../assets/icons/FolderOpenedGreenIcon";
import PercentageIcon from "../../assets/icons/PercentageIcon";
import { Td, Th, Tr } from "../Table/Table";

const defaultColumns = [
  {
    title: `Specialités`,
    weight: "bold",
    width: "33%",
    isLeft: true,
  },
  {
    title: "Moyenne",
    icon: <PercentageIcon bg="white" size="24px" />,
    width: "13.2%",
  },
  {
    title: "Répondu",
    icon: <AnswersIcon color="white" />,
    width: "13.2%",
  },
  {
    title: "Réussi",
    icon: <AnswerRightSquareIcon />,
    width: "13.2%",
  },
  {
    title: "Incomplète",
    icon: <AnswerPartialSquareIcon />,
    width: "13.2%",
  },
  {
    title: "Fausse",
    icon: <CancelIcon color="white" />,
    width: "13.2%",
  },
];

export default function StatsTable({
  columns = defaultColumns,
  rows,
  isLoading,
}: {
  columns?: any;
  rows: any;
  isLoading: boolean;
}) {
  return (
    <TableContainer rounded={"1rem"}>
      <Table
        className="uk-table uk-table-hover uk-table-middle uk-table-divider"
        bg="lightGrey"
      >
        <thead>
          <Tr>
            {columns.map((column: any, i: number) => (
              <Th key={i}>
                <HStack justify={column.isLeft ? "flex-start" : "center"}>
                  {column.icon}
                  <Text fontWeight={column.weight || "light"}>
                    {column.title}
                  </Text>
                </HStack>
              </Th>
            ))}
          </Tr>
        </thead>
        <tbody>
          {isLoading
            ? Array.apply(null, Array(12)).map((el: any, index: number) => (
                <Tr h={{ base: "unset", lg: "16", "2xl": "unset" }} key={index}>
                  {Array.apply(null, Array(6)).map((el: any, index: number) => (
                    <Td
                      textAlign="center"
                      p="0"
                      borderColor="midGrey"
                      key={index}
                    >
                      <Skeleton
                        height="50%"
                        endColor={"midGrey"}
                        startColor={"lightGrey"}
                        width={"85%"}
                        rounded="full"
                        mx="auto"
                      />
                    </Td>
                  ))}
                </Tr>
              ))
            : rows.map((row: any) => (
                <SpecialityRow key={row.id} speciality={row} />
              ))}
        </tbody>
      </Table>
    </TableContainer>
  );
}

const SpecialityRow = ({ speciality }: { speciality: any }) => {
  const [selected, setSelected] = useState<string | null>(null);

  const toggleCollapse = useCallback(
    (specialityId: string) => {
      if (selected) {
        return setSelected(null);
      }
      setSelected(specialityId);
    },
    [selected]
  );
  const totalQuestions =
    speciality.correct + speciality.partiel + speciality.fausse;
  return (
    <>
      <Tr
        bg={selected === speciality.id ? "midGrey" : "lightGrey"}
        onClick={() => toggleCollapse(speciality.id)}
        cursor="pointer"
        _hover={{ bg: "midGrey" }}
        transition="all 0.2s"
        h={{ base: "unset", lg: "16", "2xl": "unset" }}
      >
        <Td borderColor="midGrey" width={"33%"}>
          <HStack>
            {selected === speciality.id ? (
              <FolderOpenedGreenIcon />
            ) : (
              <FolderClosedGreen />
            )}
            <Stack
              align="flex-start"
              flexGrow={1}
              alignSelf={"stretch"}
              maxW={"400px"}
            >
              <Tooltip
                label={speciality.name}
                openDelay={1000}
                hasArrow
                rounded={"md"}
              >
                <Text>{speciality.name}</Text>
              </Tooltip>
              <HStack
                spacing={0}
                bg="white"
                rounded={"md"}
                overflow="hidden"
                width={"200px"}
              >
                <Tooltip
                  label="Bonne réponse"
                  hasArrow
                  openDelay={2000}
                  rounded={"md"}
                >
                  <motion.div
                    initial={{ width: "0px" }}
                    style={{ height: "0.25rem", background: "#7FCF91" }}
                    animate={{
                      width: (speciality.correct / totalQuestions) * 100 + "%",
                    }}
                    exit={{ width: "0px" }}
                    transition={{ delay: 0.5, default: { duration: 0.4 } }}
                  />
                </Tooltip>
                <Tooltip
                  label="Réponse incomplète"
                  hasArrow
                  openDelay={2000}
                  rounded={"md"}
                >
                  <motion.div
                    initial={{ width: "0px" }}
                    style={{ height: "0.25rem", background: "#FCB731" }}
                    animate={{
                      width: (speciality.partiel / totalQuestions) * 100 + "%",
                    }}
                    transition={{ delay: 0.5, default: { duration: 0.45 } }}
                  />
                </Tooltip>
                <Tooltip
                  label="Fausse réponse"
                  hasArrow
                  openDelay={2000}
                  rounded={"md"}
                >
                  <motion.div
                    initial={{ width: "0px" }}
                    style={{
                      height: "0.25rem",
                      background: "#D76161",
                      borderTopRightRadius: "5px",
                      borderBottomRightRadius: "5px",
                    }}
                    animate={{
                      width: (speciality.fausse / totalQuestions) * 100 + "%",
                    }}
                    transition={{ delay: 0.5, default: { duration: 0.5 } }}
                  />
                </Tooltip>
              </HStack>
            </Stack>
          </HStack>
        </Td>
        <Td borderColor="midGrey" textAlign="center">
          {speciality.note % 1 === 0
            ? speciality.note
            : speciality.note.toFixed(2)}
          /20
        </Td>
        <Td borderColor="midGrey" textAlign="center">
          {totalQuestions}/{totalQuestions}
        </Td>
        <Td borderColor="midGrey" textAlign="center">
          {speciality.correct} (
          {Math.round((speciality.correct * 100) / totalQuestions)}
          %)
        </Td>
        <Td borderColor="midGrey" textAlign="center">
          {speciality.partiel} (
          {Math.round((speciality.partiel * 100) / totalQuestions)}
          %)
        </Td>
        <Td borderColor="midGrey" textAlign="center">
          {speciality.fausse} (
          {Math.round((speciality.fausse * 100) / totalQuestions)}
          %)
        </Td>
      </Tr>
      {selected === speciality.id &&
        speciality.subjects.map((subject: any) => (
          <SubjectRow key={subject.id} subject={subject} />
        ))}
    </>
  );
};

const SubjectRow = ({ subject }: any) => {
  const totalQuestions = subject.correct + subject.partiel + subject.fausse;
  return (
    <Tr p="0" bg={"darkGrey"}>
      <Td borderColor="midGrey" pl={{ base: 2, "2xl": 10 }} maxW={"200px"}>
        <HStack
          align={"center"}
          alignSelf={"flex-start"}
          width={"100%"}
          justify={"flex-start"}
        >
          <Stack
            align={"flex-start"}
            justify="flex-start"
            flexGrow={1}
            maxW={"300px"}
            ml={{ base: "10", "2xl": "4" }}
          >
            <Tooltip
              label={subject.name}
              openDelay={1000}
              hasArrow
              rounded={"md"}
            >
              <Text isTruncated maxWidth={{ base: "180px", xl: "unset" }}>
                {subject.name}
              </Text>
            </Tooltip>
            <HStack
              spacing={0}
              bg="white"
              rounded={"md"}
              overflow="hidden"
              // width={"100%"}
              width="180px"
            >
              <Tooltip
                label="Bonne réponse"
                hasArrow
                openDelay={2000}
                rounded={"md"}
              >
                <motion.div
                  initial={{ width: "0px" }}
                  style={{ height: "0.25rem", background: "#7FCF91" }}
                  animate={{
                    width: (subject.correct / totalQuestions || 0) * 100 + "%",
                  }}
                  transition={{ delay: 0.5, default: { duration: 0.4 } }}
                />
              </Tooltip>
              <Tooltip
                label="Réponse incomplète"
                hasArrow
                openDelay={2000}
                rounded={"md"}
              >
                <motion.div
                  initial={{ width: "0px" }}
                  style={{ height: "0.25rem", background: "#FCB731" }}
                  animate={{
                    width: (subject.partiel / totalQuestions || 0) * 100 + "%",
                  }}
                  transition={{ delay: 0.5, default: { duration: 0.45 } }}
                />
              </Tooltip>
              <Tooltip
                label="Fausse réponse"
                hasArrow
                openDelay={2000}
                rounded={"md"}
              >
                <motion.div
                  initial={{ width: "0px" }}
                  style={{
                    height: "0.25rem",
                    background: "#D76161",
                    borderTopRightRadius: "5px",
                    borderBottomRightRadius: "5px",
                  }}
                  animate={{
                    width: (subject.fausse / totalQuestions || 0) * 100 + "%",
                  }}
                  transition={{ delay: 0.5, default: { duration: 0.5 } }}
                />
              </Tooltip>
            </HStack>
          </Stack>
        </HStack>
      </Td>
      <Td borderColor="midGrey" p="0" textAlign="center">
        {subject.note % 1 === 0 ? subject.note : subject.note.toFixed(2)}/20
      </Td>
      <Td borderColor="midGrey" p="0" textAlign="center">
        <Text>{totalQuestions + "/" + totalQuestions}</Text>
      </Td>
      <Td borderColor="midGrey" p="0" textAlign="center">
        {subject.correct} ({Math.round((subject.correct * 100) / totalQuestions)}%)
      </Td>
      <Td borderColor="midGrey" p="0" textAlign="center">
        {subject.partiel} ({Math.round((subject.partiel * 100) / totalQuestions)}%)
      </Td>
      <Td borderColor="midGrey" p="0" textAlign="center">
        {subject.fausse} ({Math.round((subject.fausse * 100) / totalQuestions)}%)
      </Td>
    </Tr>
  );
};
