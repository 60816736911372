import { IMyToast } from "../../types";

const MyToast = ({toast, title, description, status}: IMyToast) => {
  return toast({
    title,
    description,
    status,
    duration: 3000,
    isClosable: true,
    position: "bottom-right",
  })
}

export default MyToast;
