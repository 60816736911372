import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  Flex,
  IconButton,
  useColorModeValue,
} from "@chakra-ui/react";
import CancelIcon from "../../assets/icons/CancelIcon";

export default function RightDrawer({
  btnRef,
  onClose,
  isOpen,
  title,
  footer,
  children,
}: any) {
  return (
    <Drawer
      isOpen={isOpen}
      placement="right"
      onClose={onClose}
      finalFocusRef={btnRef}
      size="sm"
    >
      <DrawerOverlay />
      <DrawerContent
        borderTopLeftRadius={"1.5rem"}
        borderBottomLeftRadius={"1.5rem"}
        bg={useColorModeValue("white", "dark-blue-dark")}
      >
        <Flex align={"center"} justify="space-between">
          <DrawerHeader color={"darkSky"} fontSize={{ base: "xl", md: "4xl" }}>
            {title}
          </DrawerHeader>
          <IconButton
            aria-label="close drawer"
            icon={<CancelIcon color="#000" />}
            m="6"
            onClick={onClose}
          />
        </Flex>

        <DrawerBody>{children}</DrawerBody>

        {footer && <DrawerFooter>{footer}</DrawerFooter>}
      </DrawerContent>
    </Drawer>
  );
}
