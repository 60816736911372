import {
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from "react-router-dom";
import { useAuthProvider } from "../../auth-provider/AuthProvider";
import { useQuery } from "react-query";
import { getPlanById } from "../../services/plan/services";
import { Box } from "@chakra-ui/react";
import usePlan from "../../auth-provider/PlanProvider";

export default function PlanContainer() {
  const { data: auth } = useAuthProvider();
  const { path } = useRouteMatch();
  const history = useHistory();
  // const planId = localStorage.getItem("plan") || "";
  const { plan } = usePlan();

  const { isLoading, isError } = useQuery(
    `planId-${plan}`,
    () => getPlanById({ planId: plan }),
    {
      onError: () => history.push("/tableau-de-bord"),
      onSuccess: () =>
        window.open(
          `https://ecnplus.tn/confirmation?user=${auth?._id}&email=${auth?.email}&plan=${plan}`, "_self"
        ),
        enabled: Boolean(plan)
    }
  );

  //   if(!auth?.email_verified){
  //     return <Redirect to="/confirmation-email" exact/>
  //   }

  return (
    <Switch>
      <Route path={`${path}`}>
        <Box>
          {isLoading ? <h1>LOADING...</h1> : isError ? <>ERROR</> : <p>Redirecting</p>}
        </Box>
      </Route>
      <Route>
        <h1>Page Not Found</h1>
      </Route>
    </Switch>
  );
}
