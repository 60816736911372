import {
  useRadio,
  Box,
  useRadioGroup,
  HStack,
  Stack,
  Text,
} from "@chakra-ui/react";
import {
  HiOutlineDocumentDuplicate,
  HiOutlineDocumentText,
} from "react-icons/hi";
import { BsListCheck } from "react-icons/bs";
import SeriesIcon from "../../../assets/icons/SeriesIcon";
import ExamsIcon from "../../../assets/icons/ExamsIcon";

function RadioCard({ icon, ...rest }: any) {
  const { getInputProps, getCheckboxProps } = useRadio(rest);

  const input: any = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box as="label" flex={1}>
      <input {...input} />
      <Stack
        {...checkbox}
        cursor="pointer"
        borderWidth="1px"
        borderRadius="1rem"
        css={{
          "& svg": {
            width: "50px",
            height: "50px",
          },
          "& path": {
            fill: "#4B62F0",
          },
        }}
        _checked={{
          bg: "blue-light",
          color: "white",
          borderColor: "blue-light",
          "& path": {
            fill: "white",
          },
        }}
        _focus={{
          boxShadow: "none",
        }}
        _hover={{
          bg: input?.checked ? "blue-light" : "light-blue-light",
        }}
        px={5}
        py={3}
        textAlign="center"
        align={"center"}
        direction={{ base: "row", md: "column" }}
        h="40"
        transition={"0.3s"}
        alignItems="center"
        justify={"center"}
      >
        {icon}
        <Box fontWeight={"extrabold"}>{rest.children}</Box>
      </Stack>
      <Text></Text>
    </Box>
  );
}

function ExamModeGroup({
  onChange,
  isSerieMode,
}: {
  isSerieMode: string;
  onChange: (reason: string) => void;
}) {
  const modes = [
    {
      icon: <SeriesIcon color="#4B62F0" />,
      title: "Mode série",
      value: "true",
    },
    {
      icon: <ExamsIcon color="#4B62F0" />,
      title: "Mode examen",
      value: "false",
    },
  ];

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: "isSerieMode",
    defaultValue: "",
    onChange,
  });

  const group = getRootProps();

  return (
    <Stack>
      <Stack direction={{ base: "column", md: "row" }} {...group}>
        {modes.map(({ icon, title, value }) => {
          const radio = getRadioProps({ value });
          return (
            <RadioCard key={title} {...radio} icon={icon}>
              {title}
            </RadioCard>
          );
        })}
      </Stack>
      {/* {isSerieMode && (
        <Box
          bg="gray.50"
          border="1px solid"
          borderColor="gray.200"
          rounded={"lg"}
          p="3"
        >
          <Text>
            {isSerieMode === "true"
              ? "desc exercice Lorem ipsum, dolor sit amet consectetur adipisicing elit. Possimus distinctio reprehenderit maiores minus harum dolor ducimus excepturi? Nobis accusamus explicabo, delectus adipisci, cumque vel fuga ullam quo aut fugit quia!"
              : "desc examen Lorem ipsum, dolor sit amet consectetur adipisicing elit. Possimus distinctio reprehenderit maiores minus harum dolor ducimus excepturi? Nobis accusamus explicabo, delectus adipisci, cumque vel fuga ullam quo aut fugit quia!"}
          </Text>
        </Box>
      )} */}
    </Stack>
  );
}

export default ExamModeGroup;
