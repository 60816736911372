import {
  TableContainer,
  HStack,
  Skeleton,
  Stack,
  Tooltip,
  Text,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import { useCallback, useState } from "react";
import FolderClosedGreen from "../../../assets/icons/FolderClosedGreen";
import FolderOpenedGreenIcon from "../../../assets/icons/FolderOpenedGreenIcon";
import { Table, Td, Th, Tr } from "../../../components/Table/Table";

export default function PerformanceTable({
  columns,
  isLoading,
  rows,
  stats,
  isExam,
}: any) {
  return (
    <TableContainer rounded={"1rem"}>
      <Table
        className="uk-table uk-table-hover uk-table-middle uk-table-divider"
        bg="lightGrey"
      >
        <thead>
          <Tr>
            {columns.map((column: any, i: number) => (
              <Th key={i}>
                <HStack justify={column.isLeft ? "flex-start" : "center"}>
                  {column.icon}
                  <Text fontWeight={column.weight || "light"}>
                    {column.title}
                  </Text>
                </HStack>
              </Th>
            ))}
          </Tr>
        </thead>
        <tbody>
          {isLoading
            ? Array.apply(null, Array(12)).map((el: any, index: number) => (
                <Tr h={{ base: "unset", lg: "16", "2xl": "unset" }} key={index}>
                  {Array.apply(null, Array(6)).map((el: any, index: number) => (
                    <Td
                      textAlign="center"
                      p="0"
                      borderColor="midGrey"
                      key={index}
                    >
                      <Skeleton
                        height="50%"
                        endColor={"midGrey"}
                        startColor={"lightGrey"}
                        width={"85%"}
                        rounded="full"
                        mx="auto"
                      />
                    </Td>
                  ))}
                </Tr>
              ))
            : rows.map((row: any) => (
                <SpecialityRow
                  key={row._id._id}
                  speciality={row._id}
                  subjects={row.subjects}
                  stats={stats.find(
                    (stat: any) => stat.speciality === row._id.name
                  )}
                  isExam={isExam}
                />
              ))}
        </tbody>
      </Table>
    </TableContainer>
  );
}

const SpecialityRow = ({ speciality, subjects, stats, isExam }: any) => {
  const [selected, setSelected] = useState<string | null>(null);

  const toggleCollapse = useCallback(
    (specialityId: string) => {
      if (selected) {
        return setSelected(null);
      }
      setSelected(specialityId);
    },
    [selected]
  );


  return (
    <>
      <Tr
        bg={selected === speciality._id ? "midGrey" : "lightGrey"}
        onClick={() => toggleCollapse(speciality._id)}
        cursor="pointer"
        _hover={{ bg: "midGrey" }}
        transition="all 0.2s"
        h={{ base: "unset", lg: "16", "2xl": "unset" }}
      >
        <Td borderColor="midGrey" width={"33%"}>
          <HStack>
            {selected === speciality._id ? (
              <FolderOpenedGreenIcon />
            ) : (
              <FolderClosedGreen />
            )}
            <Stack
              align="flex-start"
              flexGrow={1}
              alignSelf={"stretch"}
              maxW={"400px"}
            >
              <Tooltip
                label={speciality.name}
                openDelay={1000}
                hasArrow
                rounded={"md"}
              >
                <Text>{speciality.name}</Text>
              </Tooltip>
              <HStack
                spacing={0}
                bg="white"
                rounded={"md"}
                overflow="hidden"
                width={"200px"}
              >
                <Tooltip
                  label="Bonne réponse"
                  hasArrow
                  openDelay={2000}
                  rounded={"md"}
                >
                  <motion.div
                    initial={{ width: "0px" }}
                    style={{ height: "0.25rem", background: "#7FCF91" }}
                    animate={{
                      width:
                        ((stats?.result?.correct ?? 0 / stats?.answersCount) ||
                          0) *
                          100 +
                        "%",
                    }}
                    exit={{ width: "0px" }}
                    transition={{ delay: 0.5, default: { duration: 0.4 } }}
                  />
                </Tooltip>
                <Tooltip
                  label="Réponse incomplète"
                  hasArrow
                  openDelay={2000}
                  rounded={"md"}
                >
                  <motion.div
                    initial={{ width: "0px" }}
                    style={{ height: "0.25rem", background: "#FCB731" }}
                    animate={{
                      width:
                        ((stats?.result?.partiel ?? 0 / stats?.answersCount) ||
                          0) *
                          100 +
                        "%",
                    }}
                    transition={{ delay: 0.5, default: { duration: 0.45 } }}
                  />
                </Tooltip>
                <Tooltip
                  label="Fausse réponse"
                  hasArrow
                  openDelay={2000}
                  rounded={"md"}
                >
                  <motion.div
                    initial={{ width: "0px" }}
                    style={{
                      height: "0.25rem",
                      background: "#D76161",
                      borderTopRightRadius: "5px",
                      borderBottomRightRadius: "5px",
                    }}
                    animate={{
                      width:
                        ((stats?.result?.fausse ?? 0 / stats?.answersCount) ||
                          0) *
                          100 +
                        "%",
                    }}
                    transition={{ delay: 0.5, default: { duration: 0.5 } }}
                  />
                </Tooltip>
              </HStack>
            </Stack>
          </HStack>
        </Td>
        <Td borderColor="midGrey" textAlign="center">
          {isExam
            ? (stats?.result?.overall
                ? stats?.result?.overall % 1 === 0
                  ? stats?.result?.overall
                  : stats?.result?.overall.toFixed(2)
                : 0) + "/20"
            : stats?.result?.showOverall
            ? (stats?.result?.overall
                ? stats?.result?.overall % 1 === 0
                  ? stats?.result?.overall
                  : stats?.result?.overall.toFixed(2)
                : 0) + "/20"
            : "n/a"}
        </Td>
        <Td borderColor="midGrey" textAlign="center">
          {stats?.answersCount ?? 0}/
          {isExam
            ? speciality?.examQuestionsCount
            : speciality.seriesQuestionsCount}
        </Td>
        <Td borderColor="midGrey" textAlign="center">
          {stats?.result?.correct ?? 0} (
          {Math.round(
            ((stats?.result?.correct ?? 0) / stats?.answersCount) * 100
          ) || 0}
          %)
        </Td>
        <Td borderColor="midGrey" textAlign="center">
          {stats?.result?.partiel ?? 0} (
          {Math.round(
            ((stats?.result?.partiel ?? 0) / stats?.answersCount) * 100
          ) || 0}
          %)
        </Td>
        <Td borderColor="midGrey" textAlign="center">
          {stats?.result?.fausse ?? 0} (
          {Math.round(
            ((stats?.result?.fausse ?? 0) / stats?.answersCount) * 100
          ) || 0}
          %)
        </Td>
      </Tr>
      {selected === speciality._id &&
        subjects.map((subject: any) => (
          <SubjectRow
            key={subject._id}
            subject={subject}
            stats={
              stats?.subjects.find(
                (stat: any) => (isExam ? stat.name : stat.subject) === subject.name
              ) || {}
            }
            isExam={isExam}
          />
        ))}
    </>
  );
};

const SubjectRow = ({ subject, stats, isExam }: any) => {
  return (
    <Tr p="0" bg={"darkGrey"}>
      <Td borderColor="midGrey" pl={{ base: 2, "2xl": 10 }} maxW={"200px"}>
        <HStack
          align={"center"}
          alignSelf={"flex-start"}
          width={"100%"}
          justify={"flex-start"}
        >
          <Stack
            align={"flex-start"}
            justify="flex-start"
            flexGrow={1}
            maxW={"300px"}
            ml={{ base: "10", "2xl": "4" }}
          >
            <Tooltip
              label={subject.name}
              openDelay={1000}
              hasArrow
              rounded={"md"}
            >
              <Text isTruncated maxWidth={{ base: "180px", xl: "unset" }}>
                {subject.name}
              </Text>
            </Tooltip>
            <HStack
              spacing={0}
              bg="white"
              rounded={"md"}
              overflow="hidden"
              // width={"100%"}
              width="180px"
            >
              <Tooltip
                label="Bonne réponse"
                hasArrow
                openDelay={2000}
                rounded={"md"}
              >
                <motion.div
                  initial={{ width: "0px" }}
                  style={{ height: "0.25rem", background: "#7FCF91" }}
                  animate={{
                    width:
                      ((stats?.result?.correct ?? 0 / stats?.answersCount) ||
                        0) *
                        100 +
                      "%",
                  }}
                  transition={{ delay: 0.5, default: { duration: 0.4 } }}
                />
              </Tooltip>
              <Tooltip
                label="Réponse incomplète"
                hasArrow
                openDelay={2000}
                rounded={"md"}
              >
                <motion.div
                  initial={{ width: "0px" }}
                  style={{ height: "0.25rem", background: "#FCB731" }}
                  animate={{
                    width:
                      ((stats?.result?.partiel ?? 0 / stats?.answersCount) ||
                        0) *
                        100 +
                      "%",
                  }}
                  transition={{ delay: 0.5, default: { duration: 0.45 } }}
                />
              </Tooltip>
              <Tooltip
                label="Fausse réponse"
                hasArrow
                openDelay={2000}
                rounded={"md"}
              >
                <motion.div
                  initial={{ width: "0px" }}
                  style={{
                    height: "0.25rem",
                    background: "#D76161",
                    borderTopRightRadius: "5px",
                    borderBottomRightRadius: "5px",
                  }}
                  animate={{
                    width:
                      ((stats?.result?.fausse ?? 0 / stats?.answersCount) ||
                        0) *
                        100 +
                      "%",
                  }}
                  transition={{ delay: 0.5, default: { duration: 0.5 } }}
                />
              </Tooltip>
            </HStack>
          </Stack>
        </HStack>
      </Td>
      <Td borderColor="midGrey" p="0" textAlign="center">
        {(stats?.result?.overall
          ? stats?.result?.overall % 1 === 0
            ? stats?.result?.overall
            : stats?.result?.overall.toFixed(2)
          : 0) + "/20"}
      </Td>
      <Td borderColor="midGrey" p="0" textAlign="center">
        <Text>
          {stats?.answersCount ?? 0}/{isExam ? subject?.examQuestionsCount : subject.seriesQuestionsCount}
        </Text>
      </Td>
      <Td borderColor="midGrey" p="0" textAlign="center">
        {stats?.result?.correct ?? 0} (
        {Math.round((stats?.result?.correct * 100) / stats?.answersCount) || 0}
        %)
      </Td>
      <Td borderColor="midGrey" p="0" textAlign="center">
        {stats?.result?.partiel ?? 0} (
        {Math.round((stats?.result?.partiel * 100) / stats?.answersCount) || 0}
        %)
      </Td>
      <Td borderColor="midGrey" p="0" textAlign="center">
        {stats?.result?.fausse ?? 0} (
        {Math.round((stats?.result?.fausse * 100) / stats?.answersCount) || 0}%)
      </Td>
    </Tr>
  );
};
