export default function TestIcon({color="#7fcacf", size="sm"}:{color?:string, size?:string}) {
  return (
    <svg
      id="document-text"
      xmlns="http://www.w3.org/2000/svg"
      width={size === "sm" ? "32" : "40"}
      height={size === "sm" ? "32" : "40"}
      viewBox="0 0 32 32"
    >
      <rect
        id="Rectangle_49"
        data-name="Rectangle 49"
        width="32"
        height="32"
        rx="5"
        fill="#292d32"
        opacity="0"
      />
      <g id="Group_63" data-name="Group 63" transform="translate(4.3 3.1)">
        <path
          id="Path_81"
          data-name="Path 81"
          d="M18.525,26.925h-9.6c-4.38,0-6.9-2.52-6.9-6.9v-12c0-4.38,2.52-6.9,6.9-6.9h9.6c4.38,0,6.9,2.52,6.9,6.9v12C25.425,24.4,22.905,26.925,18.525,26.925Zm-9.6-24c-3.432,0-5.1,1.668-5.1,5.1v12c0,3.432,1.668,5.1,5.1,5.1h9.6c3.432,0,5.1-1.668,5.1-5.1v-12c0-3.432-1.668-5.1-5.1-5.1Z"
          transform="translate(-2.025 -1.125)"
          fill="#292d32"
        />
        <path
          id="Path_82"
          data-name="Path 82"
          d="M18.075,9.975h-2.4a3.3,3.3,0,0,1-3.3-3.3v-2.4a.9.9,0,0,1,1.8,0v2.4a1.5,1.5,0,0,0,1.5,1.5h2.4a.9.9,0,0,1,0,1.8Z"
          transform="translate(1.425 -0.375)"
          fill="#292d32"
        />
        <path
          id="Path_83"
          data-name="Path 83"
          d="M12.225,12.825h-4.8a.9.9,0,1,1,0-1.8h4.8a.9.9,0,0,1,0,1.8Z"
          transform="translate(-0.525 2.175)"
          fill={color}
        />
        <path
          id="Path_84"
          data-name="Path 84"
          d="M17.025,16.425h-9.6a.9.9,0,1,1,0-1.8h9.6a.9.9,0,0,1,0,1.8Z"
          transform="translate(-0.525 3.375)"
          fill={color}
        />
      </g>
    </svg>
  );
}
