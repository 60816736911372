export const timeConverter = (
  hours: number,
  minutes: number,
  seconds: number
) => {
  const hms = `${hours}:${minutes}:${seconds}`;
  let arr = hms.split(":");
  
  const timeInSeconds = +arr[0] * 60 * 60 + +arr[1] * 60 + +arr[2];

  return timeInSeconds;
};


export const secondsToHMS = (totalSeconds: number|string) => {
    let sec_num = totalSeconds;
    let hours = Math.floor(+sec_num / 3600);
    let minutes = Math.floor(+sec_num / 60) % 60;
    let seconds = +sec_num % 60;

    return {
        hours,
        minutes,
        seconds
    }
  };