import { Route, Switch, useRouteMatch } from "react-router-dom";
import Login from "../../pages/Authorized/Login/Login";

export default function LoginContainer() {
  const { path, url } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${path}`}>
        <Login base_url={url} />
      </Route>
      <Route>
        <h1>Page Not Found</h1>
      </Route>
    </Switch>
  );
}
