import axiosInstance from "../../utils/axiosInstance";
import compareDates from "../../utils/compareDate";

//load user
export const loadUser = async () => {
    let token = localStorage.getItem("access_token");
    return await axiosInstance({
      method: "get",
      url: `/auth/profile`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    // }).then((res: any) => ({...res.data, days_left: 0}));
    }).then((res: any) => ({...res.data, days_left: compareDates(res.data?.current_order?.expires_at)}));
};

//load user
export const loadUserFirstLogin = async (access_token: string, push: (x: string) => void) => {
    localStorage.setItem("access_token", access_token)
    return await axiosInstance({
      method: "get",
      url: `/auth/profile`,
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    }).then((res: any) => {
      if(res.data.first_login){
        push("/bienvenue")
      } else {
        push("/tableau-de-bord")
      }
    // return res.data
    return {...res.data, days_left: compareDates(res.data?.current_order?.expires_at)}
    });
};

// Login user
export const loginUser = async (data: { email: string; password: string }) => {
  return await axiosInstance({
    method: "post",
    url: `/auth/login`,
    data,
  }).then((res: any) => res.data);
};


// Register user
export const registerUser = async (data: { email: string; password: string, username: string, fullname: string }) => {
  return await axiosInstance({
    method: "post",
    url: `/auth/register`,
    data: {...data, fullname: data.fullname.trim(), username: data.username.toLowerCase().trim()},
  }).then((res: any) => res.data);
};

// Register user
export const verifyEmail = async (verification_token: string) => {
  let access_token = localStorage.getItem("access_token");
  return await axiosInstance({
    method: "get",
    url: `/auth/verify?token=${verification_token}`,
    headers:{
      Authorization: `Bearer ${access_token}`
    }
  }).then((res: any) => res.data);
};
// Register user
export const sendVerifyEmail = async () => {
  let access_token = localStorage.getItem("access_token");
  return await axiosInstance({
    method: "get",
    url: `/auth/send-verify-link`,
    headers:{
      Authorization: `Bearer ${access_token}`
    }
  }).then((res: any) => res.data);
};


// enter email to reset password
export const sendResetPwdEmail = async (email: string) => {
  return await axiosInstance({
    method: "post",
    url: `/auth/reset-email`,
    data: {email}
  }).then((res: any) => res.data);
};

// reset password
export const resetPassword = async (data: {token: string, password: string}) => {
  return await axiosInstance({
    method: "post",
    url: `/auth/reset-password`,
    data,
  }).then((res: any) => res.data);
};

// Logout User
export const logout = (push: (path: string)=>void) => {
  localStorage.removeItem("access_token");
  push("/connexion")
}


export const saveExtraInfo = async (data: any) => {
  let access_token = localStorage.getItem("access_token");
  return await axiosInstance({
    method: "put",
    url: `/auth/extra-info`,
    data,
    headers:{
      Authorization: `Bearer ${access_token}`
    }
  }).then((res: any) => res.data);
}