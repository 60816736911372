import { LESS_THAN_10_DAYS, VALID_SUBSCRIPTION, EXPIRED_SUBSCRIPTION } from "./constants";

function compareDates(dateTime: Date): number {
  const currentDate = new Date();
  const timeDifference = new Date(dateTime).getTime() - currentDate.getTime();
  const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

  return daysDifference
}

export default compareDates;
