import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import Series from "../../pages/Unauthorized/Series/Series";
import SeriesBySubject from "../../pages/Unauthorized/Series/SeriesSubject";
import SingleSerie from "../../pages/Unauthorized/Series/SingleSerie";
import SpecialitiesSubjects from "../../pages/Unauthorized/Series/SpecialitiesSubjects";
import { useAuthProvider } from "../../auth-provider/AuthProvider";
import ProtectedRoute from "./ProtectedRoute";

export default function SeriesContainer() {
  const { data: auth } = useAuthProvider();
  const { path, url } = useRouteMatch();

  if (!auth?.email_verified) {
    return <Redirect to="/confirmation-email" exact />;
  }

  return (
    <Switch>
      <Route exact path={`${path}`}>
        <Series base_url={url} />
      </Route>
      <Route exact path={`${path}/:specialityName`}>
        <SpecialitiesSubjects />
      </Route>
      <Route exact path={`${path}/:specialityName/:subjectName`}>
        <SeriesBySubject />
      </Route>
      <ProtectedRoute
        exact
        path={`${path}/:specialityName/:subjectName/:serieId`}
        component={SingleSerie}
        days_left={auth?.days_left}
        current_order={auth?.current_order}
      />
      <Route>
        <h1>Page Not Found</h1>
      </Route>
    </Switch>
  );
}
